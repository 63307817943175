/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box,
  Avatar,
  Typography,
  Tooltip,
  Badge,
  Divider,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { noop } from "shared";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import Service from "../service";
import { toast } from "react-toastify";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { NOTIFICATION_DISPLAY_NAMES } from "shared/constants";

const defaultState = {
  deletingEntryIds: null,
  selectedNotifications: [],
  allSelected: false,
  deletingEntryId: null,
  selectedNotificationTypeArray: [],
};

const CollapsibleGrid = ({
  notificationType,
  notification,
  selectedNotificationType = [],
  selectedNotification = [],
  key,
  isLoadingMore = false,
  hasMore = true,
  markNotificationRead = noop,
  fetchMoreNotifications = noop,
  handleSelection = noop,
  handleDeleteNotification = noop,
  onReady = noop,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [state, setState] = useState(defaultState);
  const classes = useStyles();

  const observer = useRef();
  const lastNotificationRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreNotifications(notification.type);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore, notification.type]
  );

  const toggleCollapse = (key) => {
    if (key === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(key);
    }
  };

  const handleDelete = async (notificationId) => {
    setState((prevState) => ({
      ...prevState,
      deletingEntryId: notificationId,
    }));

    const { error } = await Service.deleteNotification(notificationId);

    setState((prevState) => ({
      ...prevState,
      deletingEntryId: null,
      selectedNotifications: [],
      allSelected: false,
      selectedNotificationTypeArray: [],
    }));
    selectedNotificationType = [];
    selectedNotification = [];
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Notification removed successfully.");
      handleDeleteNotification(notificationId);
    }
  };

  const handleSelectAll = (event, type) => {
    const { checked } = event.currentTarget;

    let newSelectedNotifications = [...selectedNotification];

    if (checked) {
      notification.notifications.forEach((notif) => {
        if (!newSelectedNotifications.includes(notif.id)) {
          newSelectedNotifications.push(notif.id);
        }
      });
    } else {
      newSelectedNotifications = newSelectedNotifications.filter(
        (id) => !notification.notifications.some((notif) => notif.id === id)
      );
    }

    newSelectedNotifications = [...new Set(newSelectedNotifications)];

    let updatedNotificationType = [...selectedNotificationType];

    if (checked) {
      if (!updatedNotificationType.includes(type)) {
        updatedNotificationType.push(type);
      }
    } else {
      updatedNotificationType = updatedNotificationType.filter(
        (t) => t !== type
      );
    }

    handleSelection(updatedNotificationType, newSelectedNotifications);

    setState((prevState) => ({
      ...prevState,
      selectedNotificationTypeArray: updatedNotificationType,
      selectedNotifications: newSelectedNotifications,
      allSelected: checked,
    }));
  };

  const handleSelectOne = (event, notificationId) => {
    const { checked } = event.currentTarget;

    let newSelectedNotifications = [...selectedNotification];
    if (checked) {
      if (!newSelectedNotifications.includes(notificationId)) {
        newSelectedNotifications.push(notificationId);
      }
    } else {
      newSelectedNotifications = newSelectedNotifications.filter(
        (id) => id !== notificationId
      );
    }
    const selectedNotificationIds = newSelectedNotifications.filter(
      (selectedId) =>
        notification?.notifications?.some((notif) => notif?.id === selectedId)
    );

    const allSelected =
      notification?.notifications?.length === selectedNotificationIds.length;

    let updatedNotificationType = [...selectedNotificationType];
    if (allSelected) {
      if (!updatedNotificationType.includes(notification.type)) {
        updatedNotificationType.push(notification.type);
      }
    } else {
      updatedNotificationType = updatedNotificationType.filter(
        (t) => t !== notification.type
      );
    }

    handleSelection(updatedNotificationType, newSelectedNotifications);

    setState((prevState) => ({
      ...prevState,
      selectedNotificationTypeArray: updatedNotificationType,
      selectedNotifications: newSelectedNotifications,
      allSelected,
    }));
  };

  onReady({
    resetSelection: () =>
      setState((prevState) => ({
        ...prevState,
        selectedNotifications: [],
        allSelected: false,
        selectedNotificationTypeArray: [],
      })),
  });

  return (
    <ExpansionPanel
      key={key}
      expanded={expandedIndex === key}
      onChange={() => toggleCollapse(key)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
      >
        <div className={classes.expansionPanel}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.allSelected}
                onChange={(evt) => handleSelectAll(evt, notification.type)}
                onClick={(evt) => evt.stopPropagation()}
                color="primary"
              />
            }
          />
          <Typography className="d-inline-block w-100" variant="h6" noWrap>
            {NOTIFICATION_DISPLAY_NAMES[notification.type] || notification.type}
          </Typography>
          <Badge
            badgeContent={notification.count || 0}
            color="error"
            classes={{ badge: classes.badge }}
          />
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid
          className={classes.Grid}
          item
          xs={12}
          md={12}
          component={Paper}
          elevation={0}
        >
          {notification.notifications.map((notif, i) => {
            const isLastNotification =
              notification.notifications.length === i + 1;
            const notificationCategory = notificationType[notif.type] || {};
            const data = notif.metadata;
            return (
              <>
                <Box
                  key={i}
                  component={Link}
                  to={{
                    pathname:
                      notificationCategory.path &&
                      notificationCategory.path(notif.metadata),
                    state: data,
                  }}
                  onClick={() => {
                    if (!notif.is_read) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedNotifications: [],
                        allSelected: false,
                        selectedNotificationTypeArray: [],
                      }));
                      selectedNotificationType = [];
                      selectedNotification = [];
                      markNotificationRead(notif.id);
                      if (
                        notif.type === "room-user-removed" ||
                        notif.type === "room-user-added" ||
                        notif.type === "room-left" ||
                        notif.type === "room-name-changed"
                      ) {
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }
                    }
                  }}
                  className={clsx(
                    "d-flex f-justify-between c-pointer pl-2 pr-4 pt-2 pb-2",
                    {
                      [classes.unreadBackground]: !notif.is_read,
                      [classes.readBackground]: notif.is_read,
                    }
                  )}
                  ref={isLastNotification ? lastNotificationRef : null}
                >
                  <div className="d-flex f-align-center">
                    <Checkbox
                      checked={state.selectedNotifications.includes(notif.id)}
                      onChange={(e) => handleSelectOne(e, notif.id, notif.type)}
                      onClick={(evt) => evt.stopPropagation()}
                      color="primary"
                    />
                    {!notif.is_read && (
                      <div
                        className={clsx("bg-primary", classes.readIndicator)}
                      />
                    )}
                    <Avatar
                      variant="square"
                      className={clsx("bg-primary ml-2", {
                        "ml-4": notif.is_read,
                      })}
                    >
                      {notificationCategory.icon}
                    </Avatar>
                    <div className={clsx("ml-4", classes.boxWidth)}>
                      <Typography variant="body1">{notif.title}</Typography>
                      <Typography variant="body2">
                        {notif.description}
                      </Typography>
                    </div>
                  </div>
                  <div className="d-flex f-align-center">
                    <div
                      className={clsx(
                        "d-flex f-align-center f-justify-end pl-4",
                        classes.relativeTimeWrapper
                      )}
                    >
                      <Typography variant="body2" className="mr-2 mb-4">
                        {notif.created_at
                          ? formatDistanceToNowStrict(
                              new Date(notif.created_at)
                            )
                          : "-"}
                      </Typography>
                    </div>
                    <div className="mb-3 ml-4">
                      {state.deletingEntryId === notif.id ? (
                        <CircularProgress size={18} className="progress-btn" />
                      ) : (
                        <Tooltip title="Remove" placement="top-start">
                          <DeleteForeverOutlinedIcon
                            color="error"
                            onClick={(evt) => {
                              handleDelete(notif.id);
                              evt.preventDefault();
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </Box>
                <Divider />
              </>
            );
          })}
          {isLoadingMore && (
            <Box className="d-flex f-justify-center p-4">
              <CircularProgress size={24} />
            </Box>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CollapsibleGrid;
