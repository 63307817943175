/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useCallback, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import clsx from "clsx";

import NoRecords from "assets/images/norecord.svg";
import { noop } from "shared";
import Grid from "./grid";
import useStyles from "./style";
import GridLoader from "./loader";
import BillGrid from "./billingGrid";

let gridHelpers = {};

const defaultState = {
  openStates: {},
  selectedRows: {},
};

let collapsedRows = [];

const SingleCollapsibleGrid = ({
  rows = [],
  totalEntries = [],
  columns = [],
  entries = [],
  isLoading = false,
  gridActions = [],
  isTableLoading = false,
  hasSelection = true,
  gridClasses = {},
  rowEvents = [],
  onReady = noop,
  clearAllEntries = noop,
  onSelectionChange = noop,
  expandLocation = noop,
  gridRefs = null,
  wrapRef1 = null,
  tableHeadRef = null,
  handleScroll = noop,
  syncChildGridsScroll = noop,
  handleRowOpen = noop,
  boxborder = null,
  selectedRef = null,
  isBillingAndSettelementReview = false,
  onSortChange = noop,
  order = null,
  orderBy = null,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const GridComponent = isBillingAndSettelementReview ? BillGrid : Grid;

  const toggleCollapse = useCallback(
    (customerId, customerName) => {
      setState((prevState) => {
        const isOpen = !prevState.openStates?.[customerId];

        let updatedOpenStates = { ...prevState.openStates };

        if (isOpen) {
          expandLocation(customerName);

          if (wrapRef1.current) {
            wrapRef1.current.scrollLeft = 0;
          }
          syncChildGridsScroll(0);
        }

        if (!isOpen) {
          if (updatedOpenStates?.[customerId]) {
            collapsedRows = collapsedRows.filter(
              (item) => item !== customerId && item !== null
            );
          }

          updatedOpenStates = {
            ...updatedOpenStates,
            [customerId]: false,
          };
        }

        collapsedRows = prevState.openStates?.[customerId]
          ? collapsedRows.filter((item) => item !== customerId && item !== null)
          : [...collapsedRows, customerId];

        handleRowOpen(collapsedRows);

        return {
          ...prevState,
          openStates: {
            ...updatedOpenStates,
            [customerId]: isOpen,
          },
        };
      });
    },
    [expandLocation]
  );

  const handleSelectionChange = useCallback(
    (evt, locationId) => {
      setState((prevState) => {
        const updatedSelectedRows = {
          ...prevState.selectedRows,
          [locationId]: evt,
        };
        onSelectionChange(Object.values(updatedSelectedRows).flat());
        return {
          ...prevState,
          selectedRows: updatedSelectedRows,
        };
      });
    },
    [onSelectionChange]
  );

  useEffect(() => {
    onReady({
      resetSelection: () => {
        Object.keys(gridHelpers).map((key) => {
          gridHelpers[key] && gridHelpers[key].resetSelection();
        });
        setState((prevState) => ({
          ...prevState,
          selectedRows: {},
        }));
      },
      setSelection: (selectedRows) =>
        setState((prevState) => ({
          ...prevState,
          selectedRows,
        })),
      getSelection: () => state.selectedRows,
      toggle: (id, customerName) => toggleCollapse(id, customerName),
      toggleAll: (open = false) => {
        Object.keys(gridHelpers).forEach((key) => {
          if (gridHelpers[key]) {
            gridHelpers[key].toggle(open);

            if (!open) {
              clearAllEntries();
              setState((prevState) => {
                return {
                  ...prevState,
                  openStates: {},
                };
              });
            }
          }
        });
      },
    });
  }, [onReady, state.selectedRows]);

  if (rows?.length === 0 && !isLoading) {
    return (
      <div className="d-flex f-justify-center">
        <img alt="No record" className={classes.noRecordsImg} src={NoRecords} />
      </div>
    );
  }

  return (
    <div className="w-100" style={{ overflowX: "hidden" }}>
      {isLoading ? (
        [...new Array(5).fill("Loading")].map((_, index) => (
          <GridLoader key={index} />
        ))
      ) : (
        <>
          {rows.map(({ customer }) => (
            <Fragment key={customer.id}>
              <>
                <TableRow
                  hover
                  className={classes.tablePadding}
                  onClick={() => {
                    toggleCollapse(customer.id, customer.customer_name);
                  }}
                >
                  {/* <TableCell></TableCell> */}
                  <TableCell className="p-2 no-border">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCollapse(customer.id, customer.customer_name);
                      }}
                    >
                      {state.openStates[customer.id] || false ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className={clsx("no-border", classes.fullWidthCell)}
                  >
                    <div className="d-flex f-align-center f-justify-between">
                      <Typography variant="body2" className={classes.kamName}>
                        {customer.customer_name}
                      </Typography>
                      {totalEntries[customer.customer_name] > 0 && (
                        <Typography
                          variant="body1"
                          className="mr-6 text-bold"
                          color="black"
                        >
                          {`${totalEntries[customer.customer_name]} Record(s)`}
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                {(state.openStates[customer.id] || false) && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className={clsx("no-border", classes.tableCellShadow)}
                    >
                      <Collapse
                        in={state.openStates[customer.id] || false}
                        unmountOnExit
                      >
                        {isTableLoading[customer.customer_name] ? (
                          <GridLoader
                            open={state.openStates[customer.id] || false}
                            columns={columns.length}
                            pageSize={
                              entries && entries[customer.customer_name]?.length
                            }
                          />
                        ) : (
                          (entries[customer.customer_name] || []).length >
                            0 && (
                            <div className={classes.tableContainer}>
                              <GridComponent
                                rows={entries[customer.customer_name] || []}
                                columns={columns}
                                hasSelection={hasSelection}
                                classes={gridClasses}
                                rowEvents={rowEvents}
                                isLoading={
                                  isTableLoading[customer.customer_name] ||
                                  false
                                }
                                onReady={(gridHelper) =>
                                  (gridHelpers[customer.customer_name] =
                                    gridHelper)
                                }
                                onSelectionChange={(evt) =>
                                  handleSelectionChange(evt, customer.id)
                                }
                                customer={customer}
                                index={customer.id}
                                gridRefs={gridRefs}
                                handleScroll={handleScroll}
                                tableHeadRef={tableHeadRef}
                                boxborder={boxborder}
                                selectedRef={selectedRef}
                                onSortChange={onSortChange}
                                order={
                                  order
                                    ? order[customer.customer_name] || null
                                    : null
                                }
                                orderBy={
                                  orderBy
                                    ? orderBy[customer.customer_name] || null
                                    : null
                                }
                              />
                            </div>
                          )
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </>
            </Fragment>
          ))}
          {!!rows.length && (
            <div
              className={clsx("d-flex f-align-center", gridClasses.gridActions)}
            >
              {gridActions.map((action) => action)}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SingleCollapsibleGrid;
