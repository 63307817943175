/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, TextField, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import useStyles from "./style";
import { Dialog, noop, VALIDATIONS } from "shared";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { SharedService } from "modules/shared";
import { preventInputKeyCodes, validator } from "utils";

const defaultState = {
  payload: {},
  selectedType: null,
  rate: null,
  quantity: null,
  description: "",
  amount: 0,
  errors: {
    selectedType: " ",
    rate: " ",
    quantity: " ",
    description: " ",
    amount: " ",
  },
};

const AddInvoiceType = ({
  selectedRow = {},
  open = noop,
  onClose = noop,
  isEditing = false,
  handleAddInvoiceType = noop,
  invoiceId = null,
  isLoading = false,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!!selectedRow) {
      setState((prevState) => ({
        ...prevState,
        selectedType:
          {
            ...prevState.selectedType,
            id: selectedRow.typeId,
            value: selectedRow.type,
          } || defaultState.selectedType,
        rate: selectedRow?.rate || defaultState.rate,
        quantity: selectedRow?.quantity || defaultState.quantity,
        description: selectedRow?.description || defaultState.description,
        amount: selectedRow?.amount || defaultState.amount,
      }));
    }
  }, [selectedRow]);

  const fetchInvoiceTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: true,
    }));
    const { data, error } = await SharedService.getInvoiceTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      invoiceTypeList:
        data?.rows?.filter((type) => type.is_active) ||
        defaultState.invoiceTypeList,
      isFetchingList: false,
    }));
  };

  useEffect(() => {
    fetchInvoiceTypeList();
  }, []);

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      selectedType: [{ type: VALIDATIONS.REQUIRED, value: true }],
      description: [{ type: VALIDATIONS.REQUIRED, value: true }],
      rate: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 10, inputType: "number" },
        { type: VALIDATIONS.MIN_LENGTH, value: 0, inputType: "number" },
        { type: VALIDATIONS.MIN, value: 0 },
      ],
      quantity: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
    };

    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleChange = (evt, selectedValue) => {
    const isAutoComplete = selectedValue !== undefined;
    const name = isAutoComplete
      ? "selectedType"
      : evt.currentTarget?.name || evt.target?.name;
    let value = isAutoComplete
      ? selectedValue
      : evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    const errorMessage = validate(name, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    if (isEditing) {
      const parseValue = (val) => (val == null || isNaN(val) ? val : +val);

      const initialValues = {
        description: selectedRow.description,
        rate: parseValue(selectedRow.rate),
        quantity: parseValue(selectedRow.quantity),
        selectedType: selectedRow.type,
      };

      const newValue =
        name === "selectedType" ? parseValue(value.value) : parseValue(value);

      const originalValue = parseValue(initialValues[name]);

      const isFormChanged = newValue !== originalValue;

      const allFieldsBackToOriginal = Object.keys(initialValues).every(
        (key) => {
          const currentValue =
            key === "selectedType"
              ? parseValue(key === name ? value?.value : state[key]?.value)
              : parseValue(key === name ? value : state[key]);

          const initialValue = parseValue(initialValues[key]);

          return currentValue === initialValue;
        }
      );

      setState((prevState) => ({
        ...prevState,
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: errorMessage,
        },
        isFormChanged: isFormChanged || !allFieldsBackToOriginal,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: errorMessage,
        },
      }));
    }
  };

  const handleClose = () => {
    onClose(false, null);
  };

  useEffect(() => {
    if (state.rate && !state.quantity) {
      setState((prevState) => ({
        ...prevState,
        amount: (+prevState.rate * 1).toFixed(2),
      }));
    } else if (!state.rate && state.quantity) {
      setState((prevState) => ({
        ...prevState,
        amount: (+prevState.quantity * 1).toFixed(2),
      }));
    } else if (state.rate && state.quantity) {
      setState((prevState) => ({
        ...prevState,
        amount: (+prevState.rate * +prevState.quantity).toFixed(2),
      }));
    }
  }, [state.rate, state.quantity]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.descriptionPaper }}
    >
      <Dialog.Title hasClose>{`${
        isEditing ? "Edit" : "Add"
      } Additional LineItem`}</Dialog.Title>
      <Dialog.Content>
        <div
          className="d-flex f-align-center flex-column"
          style={{ overflow: "hidden", width: 400 }}
        >
          {state.isFetchingList ? (
            <Skeleton variant="rect" width="100%" height="41px" />
          ) : (
            <Autocomplete
              className="mt-4"
              fullWidth
              name="selectedType"
              disableClearable
              size="small"
              classes={{
                paper: "mb-2",
              }}
              value={state.selectedType}
              options={state.invoiceTypeList}
              getOptionLabel={(option) => option?.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type"
                  variant="outlined"
                  required
                  helperText={" "}
                />
              )}
              onChange={(evt, selectedValue) =>
                handleChange(evt, selectedValue)
              }
            />
          )}
          {!state.isFetchingList && (
            <>
              <div style={{ marginBottom: 10, width: 400 }}>
                <TextField
                  required
                  className="mr-4 mt-4"
                  label="Description"
                  name="description"
                  value={state.description}
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  error={!!state.errors.description?.trim()}
                  helperText={state.errors.description}
                />
              </div>
              <div className="d-flex f-align-center">
                <TextField
                  className="mr-4 mt-4"
                  name="quantity"
                  type="number"
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  required
                  value={state.quantity}
                  fullWidth
                  onChange={handleChange}
                  error={!!state.errors?.quantity?.trim()}
                  onWheel={(event) => event.target.blur()}
                  onKeyDown={(evt) =>
                    preventInputKeyCodes(evt, {
                      HYPHEN: true,
                    })
                  }
                  helperText={state.errors?.quantity}
                />
                <TextField
                  className="mt-4"
                  name="rate"
                  type="number"
                  label="Rate"
                  variant="outlined"
                  required
                  size="small"
                  value={state.rate}
                  fullWidth
                  onChange={handleChange}
                  error={!!state.errors?.rate?.trim()}
                  onKeyDown={preventInputKeyCodes}
                  onWheel={(event) => event.target.blur()}
                  helperText={state.errors?.rate}
                />
              </div>
              <div style={{ marginBottom: 10, width: 400 }}>
                <TextField
                  required
                  className="mr-4 mt-4"
                  label="Amount"
                  name="amount"
                  value={state.amount}
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  onWheel={(event) => event.target.blur()}
                  onKeyDown={(evt) => preventInputKeyCodes(evt)}
                  error={!!state.errors.amount?.trim()}
                  helperText={state.errors.amount}
                />
              </div>
            </>
          )}
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={
              isLoading || validate() || (isEditing && !state.isFormChanged)
            }
            onClick={() => {
              handleAddInvoiceType(
                invoiceId,
                state.description.trim(),
                state.rate,
                state.quantity,
                state.amount,
                state.selectedType.id,
                selectedRow && selectedRow.id
              );
            }}
          >
            {isEditing ? "Save" : "Add"}
            {isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default AddInvoiceType;
