export const vehicleData = [
  {
    pdx: "33",
    vehicle_info: "",
    garaged: "Doylestown, PA",
    year: "2011",
    make: "Freightliner",
    model: "M2CA170",
    vehicle_type: "Sprinter",
    latitude: 40.3101,
    longitude: -75.1299,
  },
  {
    pdx: "38",
    vehicle_info: "Transtar Levittown ( Julio Maldonado)",
    garaged: "Levittown, PA",
    year: "2017",
    make: "RAM",
    model: "Promaster City",
    vehicle_type: "Truck",
    latitude: 40.156,
    longitude: -74.8288,
  },
  {
    pdx: "54",
    vehicle_info: "Eddie Matos",
    garaged: "Philadelphia, PA",
    year: "2020",
    make: "Mercedes Benz",
    model: "M2CA76",
    vehicle_type: "Van",
    latitude: 39.9526,
    longitude: -75.1652,
  },
  {
    pdx: "65",
    vehicle_info: "",
    garaged: "Jenkintown, PA",
    year: "2022",
    make: "Ram",
    model: "Promaster",
    vehicle_type: "2500",
    latitude: 40.094,
    longitude: -75.1308,
  },
  {
    pdx: "68",
    vehicle_info: "",
    garaged: "Glenolden, PA",
    year: "2023",
    make: "Ram",
    model: "2500",
    vehicle_type: "Promaster",
    latitude: 39.9001,
    longitude: -75.2891,
  },
  {
    pdx: "102",
    vehicle_info: "Dealer Tire",
    garaged: "Levittown, PA",
    year: "2017",
    make: "Mitsubishi",
    model: "FE160",
    vehicle_type: "Truck",
    latitude: 40.1551, // Adjusted latitude (original: 40.156)
    longitude: -74.8288,
  },
  {
    pdx: "T1002",
    vehicle_info: "Used for PDX for Dealer Tire Transfer Program",
    garaged: "Philadelphia, PA",
    year: "2024",
    make: "Stoughton Trailer",
    model: "53' Dry Van Trailer",
    vehicle_type: "Trailer",
    latitude: 39.954396, // 200m North
    longitude: -75.1652,
  },
  {
    pdx: "T1003",
    vehicle_info: "Used for PDX for Dealer Tire Transfer Program",
    garaged: "Philadelphia, PA",
    year: "2024",
    make: "Stoughton Trailer",
    model: "53' Dry Van Trailer",
    vehicle_type: "Trailer",
    latitude: 39.950804, // 200m South
    longitude: -75.1652,
  },
  {
    pdx: "T1004",
    vehicle_info: "Used for PDX for Dealer Tire Transfer Program",
    garaged: "Philadelphia, PA",
    year: "2020",
    make: "Stoughton Trailer",
    model: "53' Dry Van Trailer",
    vehicle_type: "Trailer",
    latitude: 39.9526,
    longitude: -75.162925, // 200m East
  },
  {
    pdx: "T1005",
    vehicle_info: "Used for PDX for Dealer Tire Transfer Program",
    garaged: "Philadelphia, PA",
    year: "2024",
    make: "Wabash Trailer",
    model: "53' Dry Van Trailer",
    vehicle_type: "Trailer",
    latitude: 39.9526,
    longitude: -75.167475, // 200m West
  },
  {
    pdx: "T1006",
    vehicle_info: "Used for PDX for Dealer Tire Transfer Program",
    garaged: "Philadelphia, PA",
    year: "2024",
    make: "Wabash Trailer",
    model: "53' Dry Van Trailer",
    vehicle_type: "Trailer",
    latitude: 39.954396, // 200m North-East
    longitude: -75.162925,
  },
  {
    pdx: "31-1",
    vehicle_info: "David Douglas",
    garaged: "Merchantville, NJ",
    year: "2014",
    make: "Mercedes Benz",
    model: "Sprinter",
    vehicle_type: "Van",
    latitude: 39.9476,
    longitude: -75.048,
  },
  {
    pdx: "52-1",
    vehicle_info: "NTW (Jerome Coleman)",
    garaged: "Carlisle, PA",
    year: "2019",
    make: "Ford",
    model: "Transit",
    vehicle_type: "Van",
    latitude: 40.2015,
    longitude: -77.1889,
  },
  {
    pdx: "55-1",
    vehicle_info: "Michael Kinda",
    garaged: "Naugatuck, CT",
    year: "2021",
    make: "Ford",
    model: "Transit C",
    vehicle_type: "Van",
    latitude: 41.4859,
    longitude: -73.0507,
  },
  {
    pdx: "56-1",
    vehicle_info: "Arch Auto Elmont ( Alex Quiro)",
    garaged: "Hempstead, NY",
    year: "2019",
    make: "RAM",
    model: "Promaster",
    vehicle_type: "Van",
    latitude: 40.7062,
    longitude: -73.6187,
  },
  {
    pdx: "57-1",
    vehicle_info: "Pmac ( Jerome Barnes)",
    garaged: "Moorestown, NJ",
    year: "2020",
    make: "Ford",
    model: "T350HD",
    vehicle_type: "Transit Van",
    latitude: 39.9712,
    longitude: -74.9429,
  },
  {
    pdx: "61-1",
    vehicle_info: "Hassett Ford ( Tom Obrien)",
    garaged: "Freeport, NY",
    year: "2019",
    make: "Ford",
    model: "Transit",
    vehicle_type: "Cargo Van",
    latitude: 40.6576,
    longitude: -73.5832,
  },
  {
    pdx: "62-1",
    vehicle_info: "Jose Sanchez",
    garaged: "Lebanon, PA",
    year: "2021",
    make: "Mercedes",
    model: "M2CA",
    vehicle_type: "Sprinter",
    latitude: 40.3409,
    longitude: -76.4114,
  },
  {
    pdx: "105-1",
    vehicle_info: "DT 640 (Miguel Sheppard)",
    garaged: "Mansfield, MA",
    year: "2017",
    make: "Mitsubishi",
    model: "FE160",
    vehicle_type: "Truck",
    latitude: 42.0334,
    longitude: -71.2189,
  },
  {
    pdx: "108-1",
    vehicle_info: "DT 610 ( Edwin Duran)",
    garaged: "Lyndhurst, NJ",
    year: "2017",
    make: "Mitsu",
    model: "FE160",
    vehicle_type: "Truck",
    latitude: 40.8123,
    longitude: -74.1243,
  },
  {
    pdx: "109-1",
    vehicle_info: "DT 640 Mansfield MA (Wilson Rodriguez)",
    garaged: "Mansfield, MA",
    year: "2017",
    make: "Mitsu",
    model: "FE160",
    vehicle_type: "Truck",
    latitude: 42.0335, // Adjusted latitude (original: 42.0334)
    longitude: -71.2189,
  },
  {
    pdx: "5-2",
    vehicle_info: "L.C. Jones",
    garaged: "Tampa, FL",
    year: "2022",
    make: "Toyota",
    model: "Tacoma Sr5",
    vehicle_type: "Pickup",
    latitude: 27.9506,
    longitude: -82.4572,
  },
  {
    pdx: "30-2",
    vehicle_info: "Galloway Ford(Diaunte Bell)",
    garaged: "Fort Myers, FL",
    year: "2014",
    make: "Ford",
    model: "F-150",
    vehicle_type: "Pickup",
    latitude: 26.6406,
    longitude: -81.8723,
  },
  {
    pdx: "37-2",
    vehicle_info: "NTW/Carrolls(John Solis)",
    garaged: "Tampa, FL",
    year: "2017",
    make: "Ford",
    model: "Transit",
    vehicle_type: "Truck",
    latitude: 27.9507, // Adjusted latitude (original: 27.9506)
    longitude: -82.4572,
  },
  {
    pdx: "64-2",
    vehicle_info: "Wallace Automotive Grou - Billy Chorn",
    garaged: "Stuart, FL",
    year: "2022",
    make: "Ram",
    model: "Promaster",
    vehicle_type: "High Roof Van",
    latitude: 27.1975,
    longitude: -80.2528,
  },
  {
    pdx: "116-2",
    vehicle_info: "US Auto Force (Carlos Nhome)",
    garaged: "Fort Lauderdale, FL",
    year: "2020",
    make: "Ford",
    model: "Transit Cutaway",
    vehicle_type: "Truck",
    latitude: 26.1224,
    longitude: -80.1373,
  },
  {
    pdx: "117-2",
    vehicle_info: "US Autoforce",
    garaged: "Fl. Lauderdale, FL",
    year: "2022",
    make: "Ford",
    model: "Transit",
    vehicle_type: "16ft. Cut Away",
    latitude: 26.1225, // Adjusted latitude (original: 26.1224)
    longitude: -80.1373,
  },
  {
    pdx: "15-3",
    vehicle_info: "Jason Glisson",
    garaged: "Round Rock, TX",
    year: "2025",
    make: "Chevrolet",
    model: "Silverado",
    vehicle_type: "Pickup",
    latitude: 30.5083,
    longitude: -97.6789,
  },
  {
    pdx: "101-3",
    vehicle_info: "Dallas/ Us Autoforce (Isaiah Duncan)",
    garaged: "Dallas , TX",
    year: "2017",
    make: "Mitsubishi",
    model: "FUSO",
    vehicle_type: "Truck",
    latitude: 32.7767,
    longitude: -96.797,
  },
  {
    pdx: "107-3",
    vehicle_info: "U.S. AutoForce(Rich Otero)",
    garaged: "Windcrest, TX",
    year: "2017",
    make: "Mitsu",
    model: "FE160",
    vehicle_type: "Truck",
    latitude: 29.5151,
    longitude: -98.3806,
  },
  {
    pdx: "111-3",
    vehicle_info: "Austin/ US Autoforce (Isaiah Ortiz)",
    garaged: "Austin , TX",
    year: "2020",
    make: "Ford",
    model: "T350HD",
    vehicle_type: "Transit Cut Away-16ft.",
    latitude: 30.2672,
    longitude: -97.7431,
  },
  {
    pdx: "110-4",
    vehicle_info: "Richard Powell",
    garaged: "Omaha, NE",
    year: "2020",
    make: "Ford",
    model: "T350HD",
    vehicle_type: "Truck",
    latitude: 41.2565,
    longitude: -95.9345,
  },
  {
    pdx: "14-5",
    vehicle_info: "Demetreus Brown",
    garaged: "Las Vegas, NV",
    year: "2025",
    make: "Chevrolet",
    model: "Silverado",
    vehicle_type: "Pickup",
    latitude: 36.1699,
    longitude: -115.1398,
  },
];
