import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: "16.8rem",
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiOutlinedInput-root": {
      width: "17.4rem",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  customerDropdown: {
    width: 160,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  paper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
    minWidth: "620px !important",
    maxWidth: "800px !important",
    minHeight: "500px !important",
    maxHeight: "800px !important",
  },
  paperSize: (props) => ({
    minWidth: props.isTabletView ? "90%" : 800,
  }),
  tableHeaderwidth: {
    minWidth: 144,
    maxWidth: 144,
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "0px",
  },
  list: {
    overflowY: "auto",
    minHeight: 325,
    maxHeight: 325,
    "&::-webkit-scrollbar": {
      width: "5px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
      borderRadius: "60px",
    },
  },
  listItemSelected: {
    backgroundColor: "#e3d5e6",
  },
  invoice: {
    height: "30%",
    width: "100%",
  },
  noRecordsImg: {
    maxWidth: 1200,
    maxHeight: 800,
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  paperDialog: {
    width: 316,
  },
}));

export default useStyles;
