import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/get-notifications/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getNotifications = (query) => {
  return responseFormatter(
    http.get(`/notifications/types-list/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const markNotificationRead = (id) => {
  return responseFormatter(
    http.patch(
      `/mark-as-read/${id}`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const deleteNotification = (notificationId) => {
  return responseFormatter(
    http.patch(
      `/mark-as-deleted/${notificationId}`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const deleteSelectedNotification = (query) => {
  return responseFormatter(
    http.patch(
      `/mark-deleted/${query || ""}`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const markReadNotification = (query) => {
  return responseFormatter(
    http.patch(
      `/mark-read/${query || ""}`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const deleteAllNotification = () => {
  return responseFormatter(
    http.patch(
      `/mark-all-deleted`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const deleteCategoryNotification = (type = "") => {
  return responseFormatter(
    http.patch(
      `/mark-deleted-by-category/${type}`,
      {},
      {
        setAuthHeader: true,
      }
    )
  );
};

const NotificationService = {
  get,
  markNotificationRead,
  deleteNotification,
  deleteAllNotification,
  deleteCategoryNotification,
  deleteSelectedNotification,
  markReadNotification,
  getNotifications,
};

export default NotificationService;
