/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { APX_COMPANY, isTransferScheduleEnable, noop } from "shared/constants";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
  ERROR_CODES,
  PERMISSION,
  ROLE,
  RATE_TYPES,
} from "modules/shared/constants";
import Service from "../service";
import { AppContext } from "shared/contexts";
import SharedService from "../../shared/service";
import {
  queryStringBuilderNew,
  getTokenData,
  getDateString,
  getPageConfig,
  hasFeatureAccess,
  getUTCDateString,
  getFormattedTime,
  setPageConfig,
  validator,
  LoadingOverlay,
} from "utils";
import { ActionDialog, FilterComponent, Grid } from "shared/components";
import Form from "../form";
import DesktopView from "./desktop";
import ResponsiveView from "./responsive";
import FlagIcon from "@material-ui/icons/Flag";
import { DynamicForm } from "modules/shared/components";
import ForceSubmitDialog from "../forceSubmit";
import { VALIDATIONS } from "shared/constants";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { PAGE_KEYS } from "shared/constants";
import useStyles from "./style";
import { Badge, Checkbox, IconButton, TextField } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import TransferSchedule from "../transfer-schedule";
import TransferWhiteIcon from "../../../assets/icons/transfer-white.svg";
import IcForm from "modules/shared/components/ic-form";
import TransferYellowIcon from "../../../assets/icons/transfer-yellow.svg";
import ReplayIcon from "@material-ui/icons/Replay";

let schedulesChecked = {};
let schedulesUnchecked = {};

let gridHelper = null,
  timeout = null;

const RECORD_STATUS = {
  PENDING: "PENDING",
  SUBMITTED: "SUBMITTED",
  UNDER_REVIEW: "UNDER REVIEW",
};

const defaultState = {
  isFetching: false,
  isFetchingTransferEntries: false,
  isFetchingList: {
    customers: false,
    users: false,
    IC: false,
    pendingItems: false,
    submissionHistory: false,
    pdxCompany: false,
    standByRateTypes: false,
  },
  standByRateTypeList: [],
  pageFilters: [],
  customerlocationList: [],
  isPriceMatrixFetched: false,
  locationList: [],
  cautionFlagSubmitReasonList: [],
  kamNotes: null,
  isTableLoading: false,
  rejectedNotes: "",
  errors: {
    kamNotes: "",
    rejectedNotes: " ",
  },
  stateList: [],
  tabIndex: 1,
  pdxCompanyList: [],
  isFiltering: false,
  entries: [],
  isFetchingLeaveTypeList: false,
  isFetchingCautionFlagTypeList: false,
  transferEntries: [],
  totalEntries: 0,
  originalKAM: [],
  totalTransferEntries: 0,
  isFetchingOriginalKam: false,
  search: "",
  isTransfering: false,
  selectedRows: [],
  showTransferredErrorPopup: false,
  showTransferredErrorData: [],
  deletingEntryId: null,
  showDetailedErrorPopup: false,
  isTransferSchedule: false,
  showDetailedErrorMessage: "",
  isLoading: false,
  rowBeingEdited: null,
  isNew: false,
  searchColumns: [],
  scheduleDate: null,
  endDate: null,
  userRole: null,
  isKAMNotesAdded: false,
  isKAMNotesPresent: null,
  selectedKAM: null,
  selectOriginalKAM: null,
  confirmDialog: null,
  rowEntries: [],
  scheduleSubmitted: false,
  transferScheduleSubmitted: false,
  isTransferBsApproved: false,
  isIcFetching: false,
  wantToTransfer: false,
  selectedLocation: [],
  hasRendered: false,
  transferScheduleDetails: [],
  priceMatrix: [],
  isNoSchedule: false,
  isForceSubmitted: false,
  clickedRowCustomerName: null,
  confirmScheduleSubmit: null,
  isPartialSubmitted: false,
  isTransferPartialSubmitted: false,
  openForceSubmitDialog: false,
  openTransferPendingDialog: false,
  weekend: [],
  fetchingDataList: [],
  isFetchingData: false,
  transferRecordStatus: [],
  dimensions: 0,
  rosterValue: "weekdays",
  previousScheduleActiveDate: null,
  isCalenderOpen: false,
  isUnderReview: false,
  icDetails: {},
  showIcDetails: false,
  vehicleTypeList: [],
  dateWithoutRecords: [],
  showDatesWithoutRecordsDialog: false,
  showSubmittedTransferredRecords: false,
  openRevertForcedSubmitDialog: false,
  isTransferLoading: false,
  entriesTotransfer: [],
  isFetchingEntries: false,
  isTransferStatusDialog: false,
  isFetchingSubmitEntries: false,
  payQuantityExceedDaily: null,
  entriesToSubmit: [],
  isTransferType: "",
  isRowOpen: false,
  contractorList: [],
  filters: {
    status: [],
    acceptedType: [],
  },
  dynamicColumns: {
    select_all: {
      label: "Select All",
      value: "select_all",
      isSelected: true,
    },
    dont_bill: {
      label: "Do Not Bill",
      value: "dont_bill",
      isSelected: true,
    },
    dont_pay: {
      label: "Do Not Pay",
      value: "dont_pay",
      isSelected: true,
    },
    dont_bill_miles: {
      label: "Do Not Bill Extra Miles",
      value: "dont_bill_miles",
      isSelected: true,
    },
    dont_pay_miles: {
      label: "Do Not Pay Extra Miles",
      value: "dont_pay_miles",
      isSelected: true,
    },
    dont_bill_tolls: {
      label: "Do Not Bill Toll",
      value: "dont_bill_tolls",
      isSelected: true,
    },
    dont_pay_tolls: {
      label: "Do Not Pay Toll",
      value: "dont_pay_tolls",
      isSelected: true,
    },
    schedule_date: {
      label: "Schedule Date",
      value: "schedule_date",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },
    WCP_Driver: {
      label: "WCP-Driver",
      value: "WCP_Driver",
      isSelected: true,
    },

    pay_rate_type: {
      label: "Pay Rate Type",
      value: "pay_rate_type",
      isSelected: true,
    },
    pay_rate: { label: "Pay Rate", value: "pay_rate", isSelected: true },
    pay_quantity: {
      label: "Pay Quantity",
      value: "pay_quantity",
      isSelected: true,
    },
    pay_fuel_surcharge: {
      label: "Pay Fuel Surcharge%",
      value: "pay_fuel_surcharge",
      isSelected: true,
    },
    pay_toll_amount: {
      label: "Pay Toll Amount",
      value: "pay_toll_amount",
      isSelected: true,
    },
    pay_extra_miles: {
      label: "Pay Extra Miles",
      value: "pay_extra_miles",
      isSelected: true,
    },
    reimbursement: {
      label: "Incentive",
      value: "reimbursement",
      isSelected: true,
    },
    kam_notes: { label: "KAM Notes", value: "kam_notes", isSelected: true },
    review_notes: {
      label: "Review Notes",
      value: "review_notes",
      isSelected: true,
    },
    review_reason: {
      label: "Review Reason",
      value: "review_reason",
      isSelected: true,
    },
    transfer_to: {
      label: "Transfer to KAM",
      value: "transfer_to",
      isSelected: true,
    },
    transfer_notes: {
      label: "Transfer Notes",
      value: "transfer_notes",
      isSelected: true,
    },
    is_accepted: {
      label: "Is Accepted",
      value: "is_accepted",
      isSelected: true,
    },
    accepted_by: {
      label: "Accepted By",
      value: "accepted_by",
      isSelected: true,
    },
    is_rejected: {
      label: "Is Rejected",
      value: "is_rejected",
      isSelected: true,
    },
    rejected_by: {
      label: "Rejected By",
      value: "rejected_by",
      isSelected: true,
    },
    submitted_by: {
      label: "Submitted By",
      value: "submitted_by",
      isSelected: true,
    },
    submitted_at: {
      label: "Last Submitted At",
      value: "submitted_at",
      isSelected: true,
    },
    reverted_by: {
      label: "Reverted By",
      value: "reverted_by",
      isSelected: true,
    },
    reverted_at: {
      label: "Reverted At",
      value: "reverted_at",
      isSelected: true,
    },
  },
  tabTwoDynamicColumn: {
    original_kam: {
      label: "Original KAM",
      value: "original_kam",
      isSelected: true,
    },
    transfer_to: {
      label: "Transfer To KAM",
      value: "transfer_to",
      isSelected: true,
    },
    transfer_notes: {
      label: "Transfer Notes",
      value: "transfer_notes",
      isSelected: true,
    },
    is_accepted: {
      label: "Is Accepted",
      value: "is_accepted",
      isSelected: true,
    },
    accepted_by: {
      label: "Accepted By",
      value: "accepted_by",
      isSelected: true,
    },
    status: { label: "Status", value: "status", isSelected: true },
  },
  submissionHistory: {
    completeSubmitted: [],
    bsApproved: [],
    notCreated: [],
    notSubmitted: [],
    partialSubmitted: [],
    forcedlySubmitted: [],
    transferred: [],
    underReview: [],
  },
  scheduleError: {
    transferred: false,
    noSchedule: false,
    message: "",
  },
  clickedRowId: null,
  formAlertFields: {},
  fieldAlert: {
    show: false,
    id: null,
    errors: {},
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
  isMultiSchedule: false,
  isBsApproved: false,
};

const ViewDailySchedule = ({ match, history, location }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const pageConfig = getPageConfig(PAGE_KEYS.DAILY_SCHEDULE);
  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleView = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleADMIN = (userData?.role || "").toLowerCase() === ROLE.ADMIN;
  const inputRef = useRef();
  const data = location.state || {};
  const viewData = location.viewState || {};
  const isTransfered = history.location.state || {};
  const selectedRef = useRef(null);
  const tableHeadRef = useRef(null);
  const { setHeaderElements, appData, updateContextData } =
    useContext(AppContext);

  const [state, setState] = useState({
    ...defaultState,
    scheduleDate: !!data?.rowDate
      ? data.rowDate
      : getDateString(
          (match.params.schedule_date || "").replace(/-/g, "/") || new Date()
        ),
    endDate: !!data?.rowDate
      ? data.rowDate
      : getDateString((match.params.end_date || "").replace(/-/g, "/")) || null,
    selectOriginalKAM: !!data?.selectedKAM
      ? data.selectedKAM
      : defaultState.selectOriginalKAM,
    tabIndex: !!data?.tabIndex ? data.tabIndex : defaultState.tabIndex,
    clickedRowId:
      (pageConfig && pageConfig?.clickedRowId?.id) || defaultState.clickedRowId,
    clickedRowCustomerName:
      (pageConfig && pageConfig?.clickedRowId?.customerName) ||
      defaultState.clickedRowCustomerName,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    selectedLocation:
      (pageConfig && pageConfig?.locationFilter?.value) ||
      defaultState.selectedLocation,
    filters: {
      status: [
        RECORD_STATUS.SUBMITTED,
        RECORD_STATUS.PENDING,
        RECORD_STATUS.UNDER_REVIEW,
      ],
      acceptedType: [],
    },
    userRole: (userData.role || "").toLowerCase(),
    isMultiSchedule: !!match.params.end_date,
    testId: data.daily_schedule_id || null,
  });

  const fetchMasterIcList = async (id) => {
    let queryString = `?filter[where][and][0][or][0][master_ic_id]=${id}`;

    const { data, error } = await SharedService.getIcList(queryString);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      contractorList:
        data?.contractors?.filter((item) => item.id !== id) ||
        defaultState.contractorList,
    }));
  };

  useEffect(() => {
    if (tableHeadRef.current) {
      const currentDimensions =
        tableHeadRef.current.clientWidth + tableHeadRef.current.clientHeight;

      setState((prevState) => {
        if (currentDimensions > prevState.dimensions) {
          return {
            ...prevState,
            dimensions: currentDimensions,
          };
        }
        return prevState;
      });
    }
  }, [state.isRowOpen, state.rowEntries]);

  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);
  const gridRefs = useRef({});

  const handleRowOpen = (value) =>
    setState((prevState) => ({
      ...prevState,
      isRowOpen: value || defaultState.isRowOpen,
    }));

  const handleMultiCollapsibleGridScroll = (event) => {
    const targetDiv = event.target;

    if (wrapRef1.current) {
      if (targetDiv === wrapRef1.current) {
        syncChildGridsScroll(targetDiv.scrollLeft);
      } else {
        syncParentScroll(targetDiv.scrollLeft);
      }
    }
  };

  const syncChildGridsScroll = (scrollLeft) => {
    Object.values(gridRefs.current).forEach((grid) => {
      if (grid) {
        grid.scrollLeft = scrollLeft;
      }
    });
  };

  const syncParentScroll = (scrollLeft) => {
    if (wrapRef1.current) {
      wrapRef1.current.scrollLeft = scrollLeft;
    }
  };

  const handleCollapsibleGridScroll = (event) => {
    const targetDiv = event.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  const kamId =
    state.userRole == ROLE.KAM ? userData?.id : state.selectedKAM?.id;
  const canViewAllSchedules = hasFeatureAccess(PERMISSION.VIEW_ALL_SCHEDULES);
  const getSearchArray = (searchColumns, search) => {
    const columns = searchColumns.length
      ? [...searchColumns]
      : [
          "icid",
          "contractor_first_name",
          "customer_name",
          "location",
          "route",
          "vehicle_type",
        ];
    if (columns.includes("contractor_first_name")) {
      columns.push("contractor_last_name");
    }
    return columns.map((column) => {
      const searchArr = search.split(" ");
      if (
        (column === "contractor_first_name" ||
          column === "contractor_last_name") &&
        searchArr.length > 1
      ) {
        return {
          field: column,
          value:
            column === "contractor_first_name" ? searchArr[0] : searchArr[1],
        };
      }

      return {
        field: column,
        value: search,
      };
    });
  };

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
      }
    );

    const IC = appData.IC;
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label:
          list?.name ||
          list?.full_name ||
          `${list.first_name || ""} ${list.last_name || ""}`,
      }));
    };

    const ICOptions = IC.map((list) => ({
      value: list,
      label: `${list?.icid || ""}/${list?.first_name || ""} ${
        list?.last_name || ""
      }`,
    }));

    return {
      kamUsers: users.kam,
      IC,
      icList,
      customers,
      kamUsersOptions: getOptionList(users.kam),
      ICOptions,
    };
  }, [appData]);

  const locationOPtions = Object.entries(state.customerlocationList)
    .map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const getPzriceMatrix = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isPriceMatrixFetched: true,
    }));
    const { data, error } = await Service.priceMatrix();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPriceMatrixFetched: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      priceMatrix: data.rows || defaultState.priceMatrix,
      isPriceMatrixFetched: false,
    }));
  }, []);

  useEffect(() => {
    const handlePageLoad = () => {
      if (performance.navigation.type === 1) {
        history.replace({ ...history.location, state: {} });
      }
    };

    window.onload = handlePageLoad;

    return () => {
      window.onload = null;
    };
  }, [history]);

  useEffect(() => {
    getPzriceMatrix();
  }, []);

  const quantityExceedColumnConfig = useMemo(
    () => [
      {
        id: "schedule_date",
        label: "Schedule Date",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          const scheduleDate = getUTCDateString(row.schedule_date);
          return (
            <Tooltip title={scheduleDate || ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {scheduleDate || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "customer",
        label: "Customer Name",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.customer} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "location",
        label: "Location",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.location} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.location}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "route",
        label: "Route",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.route} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.route}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const filterConfig = useMemo(
    () => [
      {
        field: "route_name",
        fieldToDisplay: "Route",
        operatorType: "string",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList?.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "vehicle_type",
        fieldToDisplay: "Vehicle",
        operatorType: "string",
        options: state.vehicleTypeList?.map((vehicle) => ({
          ...vehicle,
          label: `${vehicle.value}`,
          value: vehicle.value,
        })),
      },
      {
        field: "driver_name",
        fieldToDisplay: "WCP-Driver",
        operatorType: "contain",
      },

      {
        field: "pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: Array.from(
          new Map(
            [
              { label: "Daily", value: "Daily" },
              { label: "Hourly", value: "Hourly" },
              { label: "Per Miles", value: "Per Miles" },
              { label: "Per Tire", value: "Per Tire" },
              { label: "Per Run", value: "Per Run" },
              ...state.standByRateTypeList?.map((standRateType) => ({
                label: standRateType.value,
                value: standRateType.value,
              })),
            ].map((item) => [item.value, item])
          ).values()
        ),
      },

      {
        field: "pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "number",
      },

      {
        field: "pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "number",
      },
      {
        field: "pay_fuel_surcharge",
        fieldToDisplay: "Pay Fuel Surcharge%",
        operatorType: "number",
      },

      {
        field: "pay_toll_amount",
        fieldToDisplay: "Pay Toll Amount",
        operatorType: "number",
      },

      {
        field: "pay_extra_miles",
        fieldToDisplay: "Pay Extra Miles",
        operatorType: "number",
      },
      {
        field: "reimbursement",
        fieldToDisplay: "Incentive",
        operatorType: "number",
      },
      {
        field: "transfer_to",
        fieldToDisplay: "Transfer to KAM",
        operatorType: "eq",
        options: appDataList?.kamUsers?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""} - (${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "submitted_by",
        fieldToDisplay: "Submitted By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "submitted_at",
        fieldToDisplay: "Last Submitted At",
        operatorType: "range",
        isDateField: true,
      },
    ],
    [
      state.vehicleTypeList,
      state.customerlocationList,
      state.stateList,
      state.pdxCompanyList,
      appDataList.customers,
      appDataList?.icList,
    ]
  );

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
  }, []);

  const fetchKamSetting = async () => {
    const { data, error } = await Service.getKamSetting(kamId);
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      weekend: data.settings.week_off_days,
    }));
  };

  const getDatesInRange = (startDate, endDate) => {
    let d1 = new Date(startDate);
    let d2 = new Date(endDate);

    const date = d1.getTime();

    const data = new Date(date);
    data.setDate(data.getDate());

    const dates = [];

    while (data <= d2) {
      dates.push(new Date(data));
      data.setDate(data.getDate() + 1);
    }

    const weekendDates = dates.filter(
      (date) => date.getDay() === 6 || date.getDay() === 0
    );

    const weekdaysDates = dates.filter(
      (date) => date.getDay() !== 6 && date.getDay() !== 0
    );

    let isAllWeekend = weekendDates.length === dates.length;
    let isAllWeekday = weekendDates.length === 0;

    if (endDate === null || endDate === "") {
      if (d1.getDay() !== 6 && d1.getDay !== 0) {
        isAllWeekday = true;
        isAllWeekend = false;
      } else {
        isAllWeekday = false;
        isAllWeekend = true;
      }
    }

    let rosterValue = isAllWeekend ? "weekend" : "weekdays";
    if (!isAllWeekend && !isAllWeekday) {
      rosterValue = weekendDates.some((date) =>
        state.submissionHistory.notCreated.includes(getDateString(date))
      )
        ? "weekend"
        : rosterValue;
      rosterValue = weekdaysDates.some((date) =>
        state.submissionHistory.notCreated.includes(getDateString(date))
      )
        ? "weekdays"
        : rosterValue;
    }

    setState((prevState) => ({
      ...prevState,
      rosterValue,
      isBothDateRange: !isAllWeekend && !isAllWeekday,
    }));
  };

  const specificDateRosterValue = (scheduleDate) => {
    const date = new Date(scheduleDate);
    const isWeekend = date.getDay() === 6 || date.getDay() === 0;
    setState((prevState) => ({
      ...prevState,
      rosterValue: isWeekend ? "weekend" : "weekdays",
    }));
  };

  const handleTabChange = (event, tabIndex) => {
    if (gridHelper) {
      gridHelper.resetSelection();
      gridHelper.toggleAll(false);
    }
    history.replace({ ...history.location, state: {} });
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        tabIndex,
        selectedRows: defaultState.selectedRows,
        selectOriginalKAM: defaultState.selectOriginalKAM,
        dynamicColumns:
          tabIndex === 2
            ? (() => {
                const dynamicColumnsCopy = { ...defaultState.dynamicColumns };
                delete dynamicColumnsCopy.is_rejected;
                delete dynamicColumnsCopy.rejected_by;
                return {
                  select_all: { ...defaultState.dynamicColumns.select_all },
                  ...Object.entries(dynamicColumnsCopy).reduce(
                    (acc, [key, value], index) =>
                      index === 0
                        ? { [key]: value, ...defaultState.tabTwoDynamicColumn }
                        : { ...acc, [key]: value },
                    {}
                  ),
                };
              })()
            : pageConfig?.tabTwoDynamicColumn || defaultState.dynamicColumns,
        selectedLocation: defaultState.selectedLocation,
        pageFilters: defaultState.pageFilters,
        search: defaultState.search,
        searchColumns: defaultState.searchColumns,
        originalKAM: defaultState.originalKAM,
        filters: {
          status: [
            RECORD_STATUS.SUBMITTED,
            RECORD_STATUS.PENDING,
            RECORD_STATUS.UNDER_REVIEW,
          ],
        },
      }));
      inputRef.current.value = "";
    }, 300);
  };

  useEffect(() => {
    if (state.tabIndex === 2 && state.originalKAM.length === 1) {
      setState((prevState) => ({
        ...prevState,
        selectOriginalKAM: state.originalKAM[0],
      }));
    }
  }, [state.tabIndex, state.originalKAM]);

  const handleRevertForcedSubmit = async (date = "") => {
    setState((prevState) => ({
      ...prevState,
      isRevertingDate: true,
    }));

    const payload = {
      forcedSubmitDate: date,
    };

    const { error } = await Service.revertForcedSubmit(payload);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const today = new Date();
    fetchSubmissionHistory(
      kamId,
      state.previousScheduleActiveDate
        ? state.previousScheduleActiveDate.getMonth() + 1
        : today.getMonth() + 1,
      state.previousScheduleActiveDate
        ? state.previousScheduleActiveDate.getFullYear()
        : today.getFullYear()
    );
    fetchList("pendingItems", noop, kamId);
    fetchEntries(
      state.search,
      state.scheduleDate,
      state.endDate,
      state.searchColumns,
      state.selectedKAM,
      state.selectedLocation,
      state.pageFilters,
      state.rowBeingEdited,
      state.filters.status
    );
    setState((prevState) => ({
      ...prevState,
      openRevertForcedSubmitDialog: false,
    }));
  };

  const fetchOriginalKam = useCallback(
    async (scheduleDate, endDate, hasLoader = true) => {
      let filters = [];

      if (endDate) {
        filters = [
          ...filters,
          {
            field: "schedule_date",
            type: "lte",
            value: endDate,
            notIncludeInBuilder: true,
          },
        ];
      } else {
        filters = [
          ...filters,
          {
            field: "schedule_date",
            type: "lte",
            value: scheduleDate,
            notIncludeInBuilder: true,
          },
        ];
      }

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, []);

      if (endDate || scheduleDate) {
        queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;
      }

      setState((prevState) => ({
        ...prevState,
        isFetchingOriginalKam: hasLoader,
      }));
      const { data, error } = await Service.getOriginalKam(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetchingOriginalKam: false,
        }));
        return toast.error(
          Array.isArray(error)
            ? error[0]?.message
            : error.message === "kam id is required"
            ? "Please select the original kam."
            : error.message
        );
      }

      const kamDetails = appDataList.kamUsers;
      const originalKams = data.originalKamIds;

      const kamMatches = originalKams.reduce((matches, originalKamId) => {
        const matchingKam = kamDetails.find((kam) => kam?.id === originalKamId);

        if (matchingKam) {
          matches.push(matchingKam);
        }

        return matches;
      }, []);

      setState((prevState) => ({
        ...prevState,
        isFetchingOriginalKam: false,
        originalKAM: kamMatches || defaultState.originalKAM,
      }));
    },
    [appDataList.kamUsers]
  );

  const fetchTransferEntriesStatus = useCallback(
    async (
      search,
      scheduleDate,
      endDate,
      searchColumns,
      selectedOriginalKAM,
      selectedLocation,
      pageFilters = [],
      filters,
      hasLoader = true
    ) => {
      if (!isTransferScheduleEnable) return;
      const searchArray = getSearchArray(searchColumns, search);
      let filtersArr = [
        ...pageFilters.map((filter) => {
          if (filter?.field === "submitted_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "submitted_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "submitted_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (
            filter?.field === "submitted_at" &&
            filter?.type === "ra"
          ) {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "submitted_at",
                type: "gte",
                value: `${startDate} 00:00:00`,
              },
              {
                ...filter,
                field: "submitted_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else {
            return {
              ...filter,
              value: filter?.value?.value || filter?.value,
            };
          }
        }),
      ].flat();

      if (endDate) {
        filtersArr = [
          ...filtersArr,
          {
            field: "schedule_date",
            type: "lte",
            value: endDate,
            notIncludeInBuilder: true,
          },
        ];
      } else {
        filtersArr = [
          ...filtersArr,
          {
            field: "schedule_date",
            type: "lte",
            value: scheduleDate,
            notIncludeInBuilder: true,
          },
        ];
      }

      filtersArr.push({
        field: "kam_id",
        type: "=",
        value: selectedOriginalKAM?.id,
        notIncludeInBuilder: true,
      });

      if (selectedLocation.length) {
        filtersArr.push({
          field: "location",
          notIncludeInBuilder: false,
          type: "=",
          value: selectedLocation?.map((item) => item.value),
        });
      }

      if (filters.acceptedType?.length) {
        filtersArr = [...filtersArr, ...filters.acceptedType];
      }

      let queryString = queryStringBuilderNew(
        0,
        0,
        searchArray,
        filtersArr,
        {},
        search && search.length > 0
      );

      if (endDate || scheduleDate) {
        queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;
      }

      setState((prevState) => ({
        ...prevState,
        isFetchingTransferEntries: hasLoader,
      }));

      if (queryString.includes("submitted_at_gte")) {
        queryString = queryString.replace(/submitted_at_gte/g, "submitted_at");
      }

      const { data, error } = await Service.getTransferRecords(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetchingTransferEntries: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      const sortedEntries = (record) =>
        record?.sort((rowA, rowB) => {
          const rowAcustomerName = (
            rowA?.customer_branch?.customer?.name || ""
          ).toLowerCase();
          const rowAlocation = (
            rowA?.customer_branch?.location || ""
          ).toLowerCase();
          const rowAroute = (rowA?.route || "").toLowerCase();
          const rowBcustomerName = (
            rowB?.customer_branch?.customer?.name || ""
          ).toLowerCase();
          const rowBlocation = (
            rowB?.customer_branch?.location || ""
          ).toLowerCase();
          const rowBroute = (rowB?.route || "").toLowerCase();
          const valueA = `${rowAcustomerName}${rowAlocation}${rowAroute}`;
          const valueB = `${rowBcustomerName}${rowBlocation}${rowBroute}`;
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        });

      const transferRecords = sortedEntries(
        data.transferRecords?.filter(
          (entry) => entry?.transfer_to === userData?.id
        ) || defaultState.transferEntries
      );

      setState((prevState) => ({
        ...prevState,
        transferEntries: transferRecords,
        transferRecordStatus:
          data.transferRecords?.filter(
            (entry) => entry?.transfer_to === kamId
          ) || defaultState.transferRecordStatus,
        wantToTransfer: true,
        totalTransferEntries: data.count || defaultState.totalEntries,
        isTransferPartialSubmitted:
          data.isPartialSubmitted || defaultState.isPartialSubmitted,
        isFetchingTransferEntries: false,
        hasRendered: true,
        transferScheduleSubmitted:
          data.isCompleteSubmission || defaultState.transferScheduleSubmitted,
        isTransferBsApproved:
          data.isBsApproved || defaultState.isTransferBsApproved,
        selectedRows: defaultState.selectedRows,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    },
    [kamId]
  );

  const buildFilters = (
    pageFilters,
    scheduleDate,
    endDate,
    selectedKAM,
    selectedLocation,
    userRole,
    userData,
    status
  ) => {
    let filters = [
      ...pageFilters.map((filter) => {
        if (filter?.field === "submitted_at" && filter?.type === "eq") {
          return [
            {
              ...filter,
              field: "submitted_at",
              type: "lte",
              value: `${filter?.value} 23:59:59.999999`,
            },
            {
              ...filter,
              field: "submitted_at_gte",
              type: "gte",
              value: `${filter?.value} 00:00:00`,
            },
          ];
        } else if (filter?.field === "submitted_at" && filter?.type === "ra") {
          const [startDate, endDate] = filter?.value.split(" - ");
          return [
            {
              ...filter,
              field: "submitted_at",
              type: "gte",
              value: `${startDate} 00:00:00`,
            },
            {
              ...filter,
              field: "submitted_at_gte",
              type: "lte",
              value: `${endDate} 23:59:59.999999`,
            },
          ];
        } else {
          return {
            ...filter,
            value: filter?.value?.value || filter?.value,
          };
        }
      }),
    ].flat();

    if (endDate) {
      filters = [
        ...filters,
        {
          field: "schedule_date",
          type: "lte",
          value: endDate,
          notIncludeInBuilder: true,
        },
      ];
    } else {
      filters = [
        ...filters,
        {
          field: "schedule_date",
          type: "lte",
          value: scheduleDate,
          notIncludeInBuilder: true,
        },
      ];
    }
    if (selectedKAM?.id) {
      filters.push({
        field: "kam_id",
        type: "=",
        value: selectedKAM.id,
        notIncludeInBuilder: true,
      });
    }

    if (userRole == ROLE.KAM) {
      filters.push({
        field: "kam_id",
        type: "=",
        value: userData?.id,
        notIncludeInBuilder: true,
      });
    }

    if (selectedLocation.length) {
      filters.push({
        field: "location",
        type: "=",
        value: Array.isArray(selectedLocation)
          ? selectedLocation.map((item) => item.value)
          : selectedLocation,
        notIncludeInBuilder: false,
      });
    }

    if (status.length === 1 && status.includes("PENDING")) {
      filters.push({
        field: "status",
        type: "eq",
        value: "PENDING",
        notIncludeInBuilder: false,
      });
    }
    if (status.length === 2 && status.includes("SUBMITTED")) {
      filters.push({
        field: "status",
        type: "neq",
        value: "PENDING",
        notIncludeInBuilder: false,
      });
    }

    return filters;
  };

  const handleError = (
    error,
    viewData,
    history,
    scheduleDate,
    endDate,
    selectedKAM,
    setState,
    defaultState,
    canViewAllSchedules,
    search,
    pageFilters,
    selectedLocation
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      isTableLoading: false,
    }));

    if (
      viewData.isTransferredScheduledReceived === true &&
      viewData.isScheduledTransferred === false
    ) {
      return;
    }

    if (
      Array.isArray(error) &&
      (error[0]?.code === ERROR_CODES.SCHEDULE_NOT_FOUND ||
        error[0]?.code === ERROR_CODES.FORCE_SUBMITTED)
    ) {
      if (
        search.length > 0 ||
        pageFilters.length > 0 ||
        selectedLocation.length > 0
      ) {
        setState((prevState) => ({
          ...prevState,
          entries: defaultState.entries,
          transferEntries: defaultState.transferEntries,
          totalEntries: defaultState.totalEntries,
          hasRendered: true,
        }));
      } else {
        if (!canViewAllSchedules) {
          let date = getDateString(scheduleDate)?.split("/");
          date = `${date[2]}-${date[0]}-${date[1]}`;
          if (endDate) {
            let endDateString = getDateString(endDate)?.split("/");
            date += `/${endDateString[2]}-${endDateString[0]}-${endDateString[1]}`;
          }
          history.push(
            `/daily-schedule/create/day/${date}${
              selectedKAM?.id ? `/kam/${selectedKAM.id}` : ""
            }`
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            isNoSchedule: true,
            isForceSubmitted: error[0]?.code === ERROR_CODES.FORCE_SUBMITTED,
          }));
        }
      }
    } else {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    }
  };

  const fetchSubmitEntries = useCallback(
    async (scheduleDate, endDate, selectedKAM, hasLoader = true) => {
      setState((prevState) => ({
        ...prevState,
        isFetchingData: hasLoader,
      }));
      let filters = [];
      if (endDate) {
        filters = [
          ...filters,
          {
            field: "schedule_date",
            type: "lte",
            value: endDate,
            notIncludeInBuilder: true,
          },
        ];
      } else {
        filters = [
          ...filters,
          {
            field: "schedule_date",
            type: "lte",
            value: scheduleDate,
            notIncludeInBuilder: true,
          },
        ];
      }
      if (selectedKAM?.id) {
        filters.push({
          field: "kam_id",
          type: "=",
          value: selectedKAM.id,
          notIncludeInBuilder: true,
        });
      }

      if (state.userRole === ROLE.KAM) {
        filters.push({
          field: "kam_id",
          type: "=",
          value: userData?.id,
          notIncludeInBuilder: true,
        });
      }

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, []);
      queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;

      const { data, error } = await Service.get(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetchingData: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        fetchingDataList: data.dailyRecord || defaultState.fetchingDataList,
        isFetchingData: false,
      }));

      if (gridHelper) {
        gridHelper.resetSelection();
      }
    },
    []
  );

  const fetchEntries = useCallback(
    async (
      search,
      scheduleDate,
      endDate,
      searchColumns,
      selectedKAM,
      selectedLocation,
      pageFilters = [],
      rowBeingEdited,
      status,
      hasLoader = true
    ) => {
      const searchArray = getSearchArray(searchColumns, search);
      const filters = buildFilters(
        pageFilters,
        scheduleDate,
        endDate,
        selectedKAM,
        selectedLocation,
        state.userRole,
        userData,
        status
      );

      let queryString = queryStringBuilderNew(
        0,
        0,
        searchArray,
        filters,
        {},
        search && search.length > 0
      );
      queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;

      fetchEntriesToSubmit(scheduleDate, endDate, selectedKAM);

      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
        isNoSchedule: false,
        isForceSubmitted: false,
      }));

      if (queryString.includes("submitted_at_gte")) {
        queryString = queryString.replace(/submitted_at_gte/g, "submitted_at");
      }

      const { data, error } = await Service.getDSEntries(queryString);

      if (error) {
        handleError(
          error,
          viewData,
          history,
          scheduleDate,
          endDate,
          selectedKAM,
          setState,
          defaultState,
          canViewAllSchedules,
          search,
          pageFilters,
          selectedLocation
        );
        return;
      }

      const sortCustomerLocations = (data) => {
        const sortLocations = (locations) =>
          locations.sort((a, b) => a.location.localeCompare(b.location));

        return data.customerLocations
          .sort((a, b) =>
            a.customer.customer_name.localeCompare(b.customer.customer_name)
          )
          .map((customerObj) => ({
            ...customerObj,
            customer_locations: sortLocations(customerObj.customer_locations),
          }));
      };

      const sortedCustomerLocations = sortCustomerLocations(data);

      setState((prevState) => ({
        ...prevState,
        customerEntries:
          sortedCustomerLocations || defaultState.customerEntries,
        wantToTransfer: true,
        totalEntries: data.count || defaultState.totalEntries,
        scheduleSubmitted:
          data.isCompleteSubmission || defaultState.scheduleSubmitted,
        isPartialSubmitted:
          data.isPartialSubmitted || defaultState.isPartialSubmitted,
        isFetching: false,
        hasRendered: true,
        isBsApproved: data.isBsApproved || defaultState.isBsApproved,
        selectedRows: defaultState.selectedRows,
      }));

      if (gridHelper) {
        gridHelper.resetSelection();
      }
      if (!rowBeingEdited) {
        gridHelper && gridHelper.toggleAll(false);
      }
    },
    []
  );

  const fetchRowEntries = useCallback(
    async (
      search,
      scheduleDate,
      endDate,
      searchColumns,
      selectedKAM,
      pageFilters = [],
      selectedLocation,
      customerName,
      locationId,
      status,
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isTableLoading: {
          ...prevState.isTableLoading,
          [locationId]: hasLoader,
        },
      }));

      const searchArray = getSearchArray(searchColumns, search);
      const filters = buildFilters(
        pageFilters,
        scheduleDate,
        endDate,
        selectedKAM,
        selectedLocation,
        state.userRole,
        userData,
        status
      );

      if (customerName.length) {
        filters.push({
          field: "customer_name",
          type: "=",
          value: customerName,
          notIncludeInBuilder: false,
        });
      }

      let queryString = queryStringBuilderNew(
        0,
        0,
        searchArray,
        filters,
        {},
        search && search.length > 0
      );
      queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;

      if (queryString.includes("submitted_at_gte")) {
        queryString = queryString.replace(/submitted_at_gte/g, "submitted_at");
      }

      const { data, error } = await Service.get(queryString);

      if (error) {
        handleError(
          error,
          viewData,
          history,
          scheduleDate,
          endDate,
          selectedKAM,
          setState,
          defaultState,
          canViewAllSchedules,
          search,
          pageFilters,
          selectedLocation
        );
        return;
      }

      const sortedEntries = data.dailyRecord.sort((a, b) => {
        const collator = new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
        });
        return collator.compare(a.route, b.route);
      });

      setState((prevState) => ({
        ...prevState,
        rowEntries: {
          ...prevState.rowEntries,
          [locationId]:
            sortedEntries.filter(
              (entry) => entry.customer_branch?.id === locationId
            ) || [],
        },
        entries: sortedEntries || [],
        isTableLoading: {
          ...prevState.isTableLoading,
          [locationId]: false,
        },
      }));
    },
    []
  );

  const fetchEntriesToTransfer = useCallback(
    async (scheduleDate, endDate, selectedKAM, hasLoader = true) => {
      setState((prevState) => ({
        ...prevState,
        isFetchingEntries: hasLoader,
      }));

      const filters = buildFilters(
        [],
        scheduleDate,
        endDate,
        selectedKAM,
        [],
        state.userRole,
        userData,
        []
      );

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, []);
      queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;

      const { data, error } = await Service.get(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetchingEntries: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        entriesTotransfer: data.dailyRecord,
        isFetchingEntries: false,
      }));
    },
    []
  );

  useEffect(() => {
    if (
      state.wantToTransfer &&
      isTransfered.wantToTransfer &&
      state.tabIndex === 1 &&
      (state.selectedKAM?.id || state.userRole === ROLE.KAM)
    ) {
      fetchEntriesToTransfer(
        state.scheduleDate,
        state.endDate,
        state.selectedKAM
      );
    }
  }, [
    state.wantToTransfer,
    isTransfered.wantToTransfer,
    state.tabIndex,
    state.selectedKAM,
  ]);

  const fetchEntriesToSubmit = useCallback(
    async (scheduleDate, endDate, selectedKAM, hasLoader = true) => {
      setState((prevState) => ({
        ...prevState,
        isFetchingSubmitEntries: hasLoader,
      }));

      const filters = buildFilters(
        [],
        scheduleDate,
        endDate,
        selectedKAM,
        [],
        state.userRole,
        userData,
        []
      );

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, []);
      queryString += `&filter[where][schedule_date][gte]=${scheduleDate}`;

      const { data, error } = await Service.get(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetchingSubmitEntries: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        entriesToSubmit: data.dailyRecord,
        isFetchingSubmitEntries: false,
      }));
    },
    []
  );

  const handleUpdateEntries = (data) => {
    setState((prevState) => {
      const updatedRowEntries = { ...prevState.rowEntries };
      const currentKey = `${data.customer_branch?.id}`;

      // Find the existing key of the entry (if any)
      let previousKey = null;
      for (const key in updatedRowEntries) {
        if (updatedRowEntries[key].some((entry) => entry.id === data.id)) {
          previousKey = key;
          break;
        }
      }

      // Remove the entry from the previous key if it exists
      if (previousKey && previousKey !== currentKey) {
        updatedRowEntries[previousKey] = updatedRowEntries[previousKey].filter(
          (entry) => entry.id !== data.id
        );

        // Remove the key if no entries are left
        if (updatedRowEntries[previousKey].length === 0) {
          delete updatedRowEntries[previousKey];
        }
      }

      // Add or update the entry in the current key
      if (currentKey) {
        if (!updatedRowEntries[currentKey]) {
          updatedRowEntries[currentKey] = [];
        }

        const entryIndex = updatedRowEntries[currentKey].findIndex(
          (entry) => entry.id === data.id
        );
        if (entryIndex !== -1) {
          updatedRowEntries[currentKey][entryIndex] = data;
        } else {
          updatedRowEntries[currentKey].push(data);
        }
      }

      // Update the top-level entries array
      const updatedEntries = prevState.entries
        .map((entry) => (entry.id === data.id ? data : entry))
        .sort((a, b) => {
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });
          return collator.compare(a.route, b.route);
        });

      return {
        ...prevState,
        rowEntries: updatedRowEntries,
        entries: updatedEntries,
      };
    });
  };

  const expandLocation = useCallback(
    (locationId, locationName, customerName) => {
      if (!state.rowEntries[locationId]) {
        fetchRowEntries(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectedKAM,
          state.pageFilters,
          locationName,
          customerName,
          locationId,
          state.filters.status
        );
      }
    },
    [
      fetchRowEntries,
      state.rowEntries,
      state.search,
      state.scheduleDate,
      state.endDate,
      state.searchColumns,
      state.selectedKAM,
      state.pageFilters,
      state.filters.status,
    ]
  );

  const clearAllEntries = () => {
    setState((prevState) => ({
      ...prevState,
      rowEntries: defaultState.rowEntries,
      entries: defaultState.entries,
    }));
  };

  useEffect(() => {
    if (
      !!state.selectOriginalKAM &&
      !state.originalKAM?.some((ele) => ele?.id === state.selectOriginalKAM?.id)
    ) {
      setState((prevState) => ({
        ...prevState,
        selectOriginalKAM: !!data?.selectedKAM
          ? data.selectedKAM
          : defaultState.selectOriginalKAM,
      }));
    }
  }, [state.selectOriginalKAM, state.originalKAM, data.selectedKAM]);

  const handleCancel = () => {
    if (isTransfered.wantToTransfer === true) {
      const newState = {
        ...history.location.state,
        wantToTransfer: false,
      };

      history.replace({ ...history.location, state: newState });
    }
    setState((prevState) => ({
      ...prevState,
      isTransferSchedule: false,
      wantToTransfer: false,
    }));
  };

  useEffect(() => {
    const isTransferRequestSubmit = state.transferRecordStatus.some(
      (item) => item.status === "PENDING" && !!item.is_accepted
    );
    updateContextData(
      "alertBar",
      isTransferRequestSubmit &&
        (state.isBsApproved || state.scheduleSubmitted) ? (
        <div
          className="d-flex f-align-center f-justify-center p-2"
          style={{
            background: "pink",
            color: "#000",
          }}
        >
          <Typography variant="body1">
            Due to the pending status of your transferred record, your schedule
            has been converted to partial submission. Please submit the transfer
            record.
          </Typography>
        </div>
      ) : state.tabIndex === 1 &&
        (state.isBsApproved || state.scheduleSubmitted) ? (
        <div
          className="d-flex f-align-center f-justify-center p-2"
          style={{
            background: state.isBsApproved ? "#bbdefb" : "#4caf50",
            color: state.isBsApproved ? "#000" : "#fff",
          }}
        >
          <Typography variant="body1">
            {`Schedules has been ${
              state.isBsApproved ? "approved" : "submitted"
            }  for this date.`}
          </Typography>
        </div>
      ) : state.tabIndex === 2 &&
        !!state.selectOriginalKAM &&
        (state.transferScheduleSubmitted || state.isTransferBsApproved) ? (
        <div
          className="d-flex f-align-center f-justify-center p-2"
          style={{
            background: state.isTransferBsApproved ? "#bbdefb" : "#4caf50",
            color: state.isTransferBsApproved ? "#000" : "#fff",
          }}
        >
          <Typography variant="body1">
            {`Schedules has been ${
              state.isTransferBsApproved ? "approved" : "submitted"
            }  for this date.`}
          </Typography>
        </div>
      ) : null
    );
    return () => updateContextData("alertBar", null);
  }, [
    state.isBsApproved,
    state.scheduleSubmitted,
    state.isMultiSchedule,
    state.tabIndex,
    state.transferScheduleSubmitted,
    state.transferRecordStatus,
    state.isTransferBsApproved,
    state.selectOriginalKAM,
  ]);

  useEffect(() => {
    if (kamId) {
      fetchKamSetting();
    }
  }, [state.selectedKAM]);

  const handleScheduleSubmit = async (payload) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { message, error, data } = await Service.submitSchedule(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        confirmScheduleSubmit: false,
        selectedRows: defaultState.selectedRows,
      }));

      if (error[0] !== undefined && error[0].code === "PDX-DSS-001") {
        const messages = error.map((error) => error.message);
        setState((prevState) => ({
          ...prevState,
          showDetailedErrorPopup: true,
          showDetailedErrorMessage: messages,
        }));
      } else {
        toast.error(
          Array.isArray(error) ? (
            <>
              {error.map((item) => (
                <>
                  {item.message}
                  <ul>
                    {(
                      item.dateWithoutRecords ||
                      item.forcedSubmitDates ||
                      []
                    ).map((date) => (
                      <li>
                        <b>{date}</b>
                      </li>
                    ))}
                  </ul>
                </>
              ))}
            </>
          ) : (
            error.message
          )
        );
      }
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      return;
    }

    if (data.code === "PDX-ARDS-002") {
      toast.success(message || "Records submitted.");
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        showDatesWithoutRecordsDialog: true,
        dateWithoutRecords: data.dateWithoutRecords || [],
      }));
    } else if (data.code === "PDX-KNP-001") {
      if (state.tabIndex === 1) {
        fetchSubmitEntries(
          state.scheduleDate,
          state.endDate,
          state.selectedKAM
        );
      }
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isKAMNotesAdded: true,
        isKAMNotesPresent: data.records || null,
      }));
    } else if (data.code === "PDX-URDS-001") {
      toast.success("Submitted schedule is under review process.");
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isUnderReview: true,
      }));
    } else if (data.code === "PDX-SBST-002") {
      return setState((prevState) => ({
        ...prevState,
        isLoading: false,
        confirmScheduleSubmit: false,
        showTransferredErrorPopup: true,
        showTransferredErrorData: data.scheduleData,
      }));
    } else if (data.code === "PDX-ARDS-003") {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        confirmScheduleSubmit: false,
        showSubmittedTransferredRecords: true,
      }));
    } else {
      toast.success(message);
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      confirmScheduleSubmit: false,
      selectedRows:
        data.code === "PDX-KNP-001"
          ? state.selectedRows
          : defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }
    fetchList("pendingItems", noop, kamId);
    if (isTransferScheduleEnable) {
      fetchList("transferRecords", noop, "", kamId);
    }
    if (state.tabIndex === 1) {
      fetchEntries(
        state.search,
        state.scheduleDate,
        state.endDate,
        state.searchColumns,
        state.selectedKAM,
        state.selectedLocation,
        state.pageFilters,
        state.rowBeingEdited,
        state.filters.status
      );
    } else if (state.tabIndex === 2 && !!state.selectOriginalKAM) {
      fetchTransferEntriesStatus(
        state.search,
        state.scheduleDate,
        state.endDate,
        state.searchColumns,
        state.selectOriginalKAM,
        state.selectedLocation,
        state.pageFilters,
        state.filters
      );
    }
  };

  useEffect(() => {
    if (!!state.selectOriginalKAM) {
      fetchTransferEntriesStatus(
        state.search,
        state.scheduleDate,
        state.endDate,
        state.searchColumns,
        state.selectOriginalKAM,
        state.selectedLocation,
        state.pageFilters,
        state.filters
      );
    }
  }, [
    fetchTransferEntriesStatus,
    state.search,
    state.scheduleDate,
    state.endDate,
    state.selectOriginalKAM,
    state.selectedLocation,
    state.pageFilters,
    state.filters.acceptedType,
  ]);

  useEffect(() => {
    if (state.tabIndex === 1) {
      fetchTransferEntriesStatus(
        state.search,
        state.scheduleDate,
        state.endDate,
        state.searchColumns,
        kamId,
        state.selectedLocation,
        state.pageFilters,
        state.filters
      );
    }
  }, [
    fetchTransferEntriesStatus,
    state.search,
    state.scheduleDate,
    state.tabIndex,
    state.endDate,
    kamId,
    state.selectedLocation,
    state.pageFilters,
    state.filters.acceptedType,
  ]);

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();

    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const handleFieldAlertSubmit = async (id, payload, errors) => {
    if (state.rowBeingEdited) {
      setState((prevState) => ({
        ...prevState,
        fieldAlert: defaultState.fieldAlert,
        formAlertFields: {
          ...prevState.formAlertFields,
          ...payload,
          errors: { ...errors },
        },
      }));
    }
  };

  const handleApproveTransferSchedule = async (ids = []) => {
    setState((prevState) => ({ ...prevState, isTransferLoading: true }));

    const selectedRowsIds = state.transferEntries
      .filter((ele) => ids.includes(ele?.id) && !ele.is_accepted)
      .map((e) => e?.id);

    const { error } = await Service.approveTransferShedule({
      ds_ids: selectedRowsIds,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isTransferLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(`Record(s) approved successfully.`);
    setState((prevState) => ({
      ...prevState,
      isTransferLoading: false,
      selectedRows: defaultState.selectedRows,
    }));
    handleTransferStatusDialog(false);
    fetchTransferEntriesStatus(
      state.search,
      state.scheduleDate,
      state.endDate,
      state.searchColumns,
      state.selectOriginalKAM,
      state.selectedLocation,
      state.pageFilters,
      state.filters
    );
    if (gridHelper) {
      gridHelper.resetSelection();
    }
  };

  const handleRejectTransferSchedule = async (ids = [], rejectedNotes) => {
    setState((prevState) => ({
      ...prevState,
      isTransferLoading: true,
    }));

    const { error } = await Service.rejectTransferShedule({
      ds_ids: ids,
      rejection_reason: rejectedNotes,
    });

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isTransferLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(`Record(s) rejected successfully.`);
    setState((prevState) => ({
      ...prevState,
      isTransferLoading: false,
      selectedRows: defaultState.selectedRows,
      rejectedNotes: defaultState.rejectedNotes,
    }));
    handleTransferStatusDialog(false);
    fetchTransferEntriesStatus(
      state.search,
      state.scheduleDate,
      state.endDate,
      state.searchColumns,
      state.selectOriginalKAM,
      state.selectedLocation,
      state.pageFilters,
      state.filters
    );
    if (gridHelper) {
      gridHelper.resetSelection();
    }
  };

  const handleTransferStatusDialog = (value, type) => {
    setState((prevState) => ({
      ...prevState,
      isTransferStatusDialog: value,
      isTransferType: type || defaultState.isTransferType,
    }));
  };

  const fetchTransferScheduleDetails = async (fromDate, toDate, kamId) => {
    if (!isTransferScheduleEnable) return;
    setState((prevState) => ({
      ...prevState,
      isTransferScheduleDetails: true,
    }));

    if (!(fromDate && toDate && kamId)) return;

    let queryString = queryStringBuilderNew();

    if (kamId) {
      queryString += `?filter[where][and][0][or][0][kam_id]=${kamId}&filter[where][and][0][or][1][transfer_to]=${kamId}`;
    }
    if (fromDate) {
      queryString += `&filter[where][schedule_date][gte]=${fromDate}`;
    }
    if (toDate) {
      queryString += `&filter[where][schedule_date][lte]=${toDate}`;
    }

    const { data, error } = await Service.getTransferRecords(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isTransferScheduleDetails: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      transferScheduleDetails:
        data.transferRecords || defaultState.transferScheduleDetails,
      isTransferScheduleDetails: false,
    }));
  };

  const isTransferScheduled = (details, kamId, targetKamId, event) => {
    return (
      !!details &&
      details.length > 0 &&
      details.some((item) => {
        const formattedEndDate = getUTCDateString(item.schedule_date);
        const formattedEventEnd = getDateString(event);
        return (
          (targetKamId === "org"
            ? item.KAM?.id === kamId
            : item.transfer_to === kamId) &&
          formattedEndDate === formattedEventEnd
        );
      })
    );
  };

  useEffect(() => {
    if (state.previousScheduleActiveDate) {
      const activeDate = state.previousScheduleActiveDate;
      const month = activeDate.getMonth() + 1;
      const year = activeDate.getFullYear();

      const startDate = `${month}/1/${year}`;
      const endDate = `${month}/${new Date(year, month, 0).getDate()}/${year}`;

      const kamId =
        state.userRole === ROLE.KAM ? userData?.id : state.selectedKAM?.id;

      fetchSubmissionHistory(kamId, month, year);
      fetchTransferScheduleDetails(startDate, endDate, kamId);
    }
  }, [state.previousScheduleActiveDate]);

  const showFieldAlert = (field, row, errors) => {
    const alertFieldGroup = [
      {
        group: "bill",
        fields: ["bill_rate_type", "bill_quantity", "bill_rate"],
      },
      { group: "pay", fields: ["pay_rate_type", "pay_quantity", "pay_rate"] },
    ];

    const group = (
      alertFieldGroup.find((item) => item.fields.includes(field)) || {}
    ).group;
    if (!group || !row) {
      return;
    }

    const validations = [
      { type: VALIDATIONS.MIN, value: 0 },
      { type: VALIDATIONS.LIMIT, value: 10.2 },
    ];

    const standByOptions =
      row?.individual_contractor?.StandByRates?.map(
        (rate) => rate.rateType.value
      ) || [];

    setState((prevState) => ({
      ...prevState,
      fieldAlert: {
        ...prevState.fieldAlert,
        show: true,
        id: row?.id,
        errors: {
          ...errors,
        },
        scheduleDate: row?.scheduleDate,
        subHeading:
          group === "bill"
            ? `Bill Rate Type, Bill Quantity, and Bill Rate are interrelated fields.`
            : `Pay Rate Type, Pay Quantity, and Pay Rate are interrelated fields.`,

        onChange: (field, value, updatedState = {}, callback) => {
          if (field === "bill_rate_type") {
            updatedState = {
              ...updatedState,
              bill_quantity:
                value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
              errors: {
                ...updatedState.errors,
                bill_quantity: " ",
              },
            };
            callback(updatedState);
          }
          if (field === "pay_rate_type") {
            updatedState = {
              ...updatedState,
              pay_quantity: !!row?.individual_contractor?.StandByRates?.length
                ? "1"
                : value === "Hourly"
                ? "8"
                : value === "Daily"
                ? "1"
                : "",
              pay_rate:
                row?.individual_contractor?.StandByRates?.find(
                  (rate) => rate.rateType.value === value
                )?.rate_value || row.pay_rate,

              errors: {
                ...updatedState.errors,
                pay_quantity: " ",
              },
            };
            callback(updatedState);
          }
        },
        updateValidations: (fields = {}, fieldValidatorMap = {}) => {
          let updatedFieldValidatorMap = { ...fieldValidatorMap };

          if (fields["bill_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["bill_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["bill_quantity"] = [
                {
                  type: VALIDATIONS.MAX,
                  value: row?.pdxCompany === APX_COMPANY ? 10000 : 24,
                  id: "max_error",
                },
                ...updatedFieldValidatorMap["bill_quantity"],
              ];
            }
          }

          if (fields["pay_rate_type"] === "Daily") {
            updatedFieldValidatorMap["pay_quantity"] = [
              { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
              ...updatedFieldValidatorMap["pay_quantity"],
            ];
          }

          if (fields["pay_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["pay_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["pay_quantity"] = [
                {
                  type: VALIDATIONS.MAX,
                  value: row?.pdxCompany === APX_COMPANY ? 10000 : 24,
                  id: "max_error",
                },
                ...updatedFieldValidatorMap["pay_quantity"],
              ];
            }
          }

          return updatedFieldValidatorMap;
        },
        configs: [
          {
            type: "autoComplete",
            name: `${group}_rate_type`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate Type`,
            value: row[`${group}_rate_type`],
            options:
              group === "bill"
                ? RATE_TYPES
                : !!standByOptions.length
                ? standByOptions
                : RATE_TYPES,

            disableClearable: true,
            required: true,
          },
          {
            type: "textField",
            name: `${group}_quantity`,
            label: `${group === "bill" ? "Bill" : "Pay"} Quantity`,
            value: row[`${group}_quantity`],
            inputType: "number",
            validations: validations,
          },
          {
            type: "textField",
            name: `${group}_rate`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate`,
            disabled:
              group === "bill"
                ? row.confirmDontPayValue
                : !!standByOptions.length || row.confirmDontPayValue,
            value:
              group === "Pay" && row.confirmDontPayValue
                ? "0"
                : row[`${group}_rate`],
            inputType: "number",
            inputProps: {
              startAdornment: <InputAdornment>$</InputAdornment>,
            },
            validations: validations,
          },
        ],
      },
    }));
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      kamNotes: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      rejectedNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(field, state);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const moreActions = (
    row,
    scheduleSubmitted = false,
    transferScheduleSubmitted = false
  ) => {
    return (
      <div className="d-flex f-align-center f-justify-end">
        {!isRoleView && (
          <>
            {state.tabIndex === 1 && row.is_reverted ? (
              <Tooltip title="Reverted" placement="top-start">
                <ReplayIcon className="ml-2 mr-2" color="error" />
              </Tooltip>
            ) : (
              <div className="ml-8 mr-2 mt-2"></div>
            )}

            <Tooltip title="Edit" placement="top-start">
              <EditOutlinedIcon
                className={clsx("ml-2 mr-2 c-pointer", {
                  disabled:
                    (state.tabIndex === 1
                      ? scheduleSubmitted
                      : transferScheduleSubmitted) ||
                    row.status === RECORD_STATUS.SUBMITTED ||
                    row.status === RECORD_STATUS.UNDER_REVIEW ||
                    (!!row.KAM && !row.KAM.is_active) ||
                    (!!row.kamUser && !row.kamUser.is_active) ||
                    (state.userRole === ROLE.KAM &&
                      state.tabIndex === 2 &&
                      !row.is_accepted),
                })}
                onClick={() => {
                  getPzriceMatrix();
                  setState((prevState) => ({
                    ...prevState,
                    rowBeingEdited: row,
                  }));
                }}
              />
            </Tooltip>
            {state.tabIndex === 1 && (
              <Tooltip title="Delete" placement="top-start">
                <DeleteForeverOutlinedIcon
                  className={clsx("ml-2 mr-2 c-pointer", {
                    disabled:
                      scheduleSubmitted ||
                      row.status === RECORD_STATUS.SUBMITTED ||
                      row.status === RECORD_STATUS.UNDER_REVIEW ||
                      (!!row.KAM && !row.KAM.is_active) ||
                      (!!row.kamUser && !row.kamUser.is_active),
                  })}
                  color={
                    scheduleSubmitted ||
                    row.status === RECORD_STATUS.SUBMITTED ||
                    row.status === RECORD_STATUS.UNDER_REVIEW ||
                    (!!row.KAM && !row.KAM.is_active) ||
                    (!!row.kamUser && !row.kamUser.is_active)
                      ? "disabled"
                      : "error"
                  }
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      deletingEntryId: row.id,
                    }))
                  }
                />
              </Tooltip>
            )}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      updateContextData("pendingItems", []);
      if (canViewAllSchedules) {
        updateContextData("transferRecords", []);
      }
    };
  }, []);

  useEffect(() => {
    if (canViewAllSchedules && !state.selectedKAM) return;
    if (state.tabIndex === 1) {
      fetchEntries(
        state.search,
        state.scheduleDate,
        state.endDate,
        state.searchColumns,
        state.selectedKAM,
        state.selectedLocation,
        state.pageFilters,
        state.rowBeingEdited,
        state.filters.status
      );
    }
  }, [
    fetchEntries,
    state.search,
    state.userRole,
    state.selectedKAM,
    state.selectedLocation,
    state.scheduleDate,
    state.endDate,
    state.pageFilters,
    state.tabIndex,
    state.filters.status,
  ]);

  useEffect(() => {
    !match.params.end_date
      ? specificDateRosterValue(match.params.schedule_date)
      : getDatesInRange(match.params.schedule_date, match.params.end_date);
    setState((prevState) => ({
      ...prevState,
      scheduleDate: !!data?.rowDate
        ? getDateString((data.rowDate || "").replace(/-/g, "/") || new Date())
        : getDateString(
            (match.params.schedule_date || "").replace(/-/g, "/") || new Date()
          ),
      endDate: getDateString(
        (match.params.end_date || "").replace(/-/g, "/") || null
      ),
      isMultiSchedule: !!match.params.end_date,
      selectOriginalKAM: !!data?.selectedKAM
        ? data.selectedKAM
        : defaultState.selectOriginalKAM,
      tabIndex: !!data?.tabIndex ? data.tabIndex : defaultState.tabIndex,
      dynamicColumns:
        !!data?.tabIndex && data.tabIndex === 2
          ? (() => {
              const dynamicColumnsCopy = { ...defaultState.dynamicColumns };
              delete dynamicColumnsCopy.is_rejected;
              delete dynamicColumnsCopy.rejected_by;
              return {
                select_all: { ...defaultState.dynamicColumns.select_all },
                ...Object.entries(dynamicColumnsCopy).reduce(
                  (acc, [key, value], index) =>
                    index === 0
                      ? { [key]: value, ...defaultState.tabTwoDynamicColumn }
                      : { ...acc, [key]: value },
                  {}
                ),
              };
            })()
          : defaultState.dynamicColumns,
    }));
  }, [
    data.rowDate,
    data.selectedKAM,
    data.tabIndex,
    match.params.schedule_date,
    match.params.end_date,
  ]);

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchSubmissionHistory = async (kamId, month, year) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
      },
    }));

    const { data, error } = await Service.getSubmissionHistory(
      kamId,
      month,
      year
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      submissionHistory: {
        ...prevState.submissionHistory,
        completeSubmitted: (data?.completeSubmitted || []).map((date) =>
          getDateString(date)
        ),
        bsApproved: (data?.bsApproved || []).map((date) => getDateString(date)),
        notCreated: (data?.notCreated || []).map((date) => getDateString(date)),
        notSubmitted: (data?.notSubmitted || []).map((date) =>
          getDateString(date)
        ),
        partialSubmitted: (data?.partialSubmitted || []).map((date) =>
          getDateString(date)
        ),
        forcedlySubmitted: (data?.forcedlySubmitted || []).map((data) => ({
          date: getDateString(data.date),
          reason: data.reason,
        })),
        transferred: (data?.transferred || []).map((date) =>
          getDateString(date)
        ),
        underReview: (data?.underReview || []).map((date) =>
          getDateString(date)
        ),
      },
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
      },
    }));
  };

  useEffect(() => {
    getDatesInRange(state.scheduleDate, state.endDate);
    const today = new Date();
    if (kamId && !state.isRevertingDate) {
      fetchSubmissionHistory(
        kamId,
        state.previousScheduleActiveDate
          ? state.previousScheduleActiveDate.getMonth() + 1
          : today.getMonth() + 1,
        state.previousScheduleActiveDate
          ? state.previousScheduleActiveDate.getFullYear()
          : today.getFullYear()
      );
      fetchKamSetting();
    }
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", noop, "", kamId);
      }
      fetchList("pendingItems", noop, kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    if (state.search) {
      if (state.tabIndex === 1) {
        fetchEntries(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectedKAM,
          state.selectedLocation,
          state.pageFilters,
          state.rowBeingEdited,
          state.filters.status
        );
      } else if (state.tabIndex === 2) {
        fetchTransferEntriesStatus(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectOriginalKAM,
          state.selectedLocation,
          state.pageFilters,
          state.filters
        );
      }
    }
  }, [state.searchColumns, state.tabIndex]);

  useEffect(() => {
    if (isRoleKAM && state.tabIndex === 2) {
      fetchOriginalKam(state.scheduleDate, state.endDate);
    }
  }, [state.endDate, state.scheduleDate, state.tabIndex]);

  useEffect(() => {
    setHeaderElements([
      isTransferScheduleEnable &&
        canViewAllSchedules &&
        !!appData.transferRecords &&
        appData.transferRecords.length > 0 && (
          <div className="d-flex f-align-center" key="transferRecords">
            <Tooltip title="TRANSFER REQUEST RECEIVED" placement="top-start">
              <IconButton
                className="color-white"
                onClick={() => history.push("/transfer-schedule")}
              >
                <Badge
                  color="error"
                  badgeContent={
                    appData.transferRecords.filter(
                      (item) => item.status === "PENDING"
                    ).length
                  }
                  className="c-pointer"
                >
                  <img
                    src={TransferWhiteIcon}
                    alt="reports"
                    height={30}
                    width={30}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        ),
      !!appData.pendingItems.length && (
        <div className="d-flex f-align-center m-2" key="pendingItems">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>
      ),
    ]);
    return () => setHeaderElements([]);
  }, [appData.pendingItems, appData.transferRecords]);

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingLeaveTypeList: true,
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingLeaveTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingLeaveTypeList: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleTransferSchedule = async (
    scheduleChecked,
    scheduleUnchecked,
    transfer_notes
  ) => {
    schedulesChecked = scheduleChecked;
    schedulesUnchecked = scheduleUnchecked;
    const mergedSchedule = Object.values([
      ...Object.values(scheduleChecked),
      ...Object.values(scheduleUnchecked),
    ]).reduce((acc, kam) => {
      const id = kam?.id;
      if (!acc[id]) {
        acc[id] = { to_kam_id: id, ds_ids: [], transfer_notes: [] };
      }

      const dsIds = kam.row?.id;
      if (dsIds) {
        acc[id].ds_ids.push(dsIds);
        const note = transfer_notes.find((note) => note.ds_id === dsIds);
        if (note) {
          acc[id].transfer_notes.push(note);
        }
      }

      return acc;
    }, {});

    const filteredSchedule = Object.values(mergedSchedule)?.filter(
      (item) => item.ds_ids.length > 0
    );

    const transferScheduleData = {
      from_kam_id:
        state.userRole == ROLE.KAM
          ? state.tabIndex === 2
            ? state.selectOriginalKAM?.id
            : userData?.id
          : state.selectedKAM?.id,
      transfer_data: filteredSchedule,
    };

    setState((prevState) => ({
      ...prevState,
      isTransfering: true,
    }));

    const { error } = await Service.transferSchedule(transferScheduleData);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isTransfering: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else {
      toast.success("Schedule(s) transferred successfully.");

      if (isTransfered.wantToTransfer === true) {
        const newState = {
          ...history.location.state,
          wantToTransfer: false,
        };

        history.replace({ ...history.location, state: newState });
      }
      setState((prevState) => ({
        ...prevState,
        isTransfering: false,
        selectedRows: defaultState.selectedRows,
        wantToTransfer: false,
      }));
      if (state.tabIndex === 1) {
        fetchEntries(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectedKAM,
          state.selectedLocation,
          state.pageFilters,
          state.rowBeingEdited,
          state.filters.status
        );
      } else if (state.tabIndex === 2 && !!state.selectOriginalKAM) {
        fetchTransferEntriesStatus(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectOriginalKAM,
          state.selectedLocation,
          state.pageFilters,
          state.filters
        );
      }
      schedulesChecked = {};
      schedulesUnchecked = {};
      handleCancel();
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    }
  };

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCautionFlagTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  useEffect(() => {
    fetchLocalResources();
    fetchLeaveTypeList();
    fetchCautionFlagTypeList();
    fetchCustomerLocation();
    fetchVehicleTypeList();
    fetchPDXCompanyList();
    fetchList("customers", noop);
    // fetchList("IC");
    fetchList("icList");
    fetchList("users");
    fetchStandByRateTypeList();
    if (canViewAllSchedules) {
      fetchList("users", (data) => {
        const KAMUsers = (data || []).filter(
          (user) => (user.role || "").toLowerCase() === ROLE.KAM
        );
        const kamIndex = KAMUsers.findIndex(
          (item) => item.id == match.params.kam_id
        );
        setState((prevState) => ({
          ...prevState,
          selectedKAM:
            kamIndex < 0 ? defaultState.selectedKAM : KAMUsers[kamIndex],
        }));
      });
    }
  }, [match.params.path]);

  useEffect(() => {
    document.body.style.overflowX = "hidden";
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  const handleDelete = async (entryIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.delete(entryIds);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        deletingEntryId: null,
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      const entryIdsArray =
        typeof entryIds === "string"
          ? entryIds.split(",").map(Number)
          : [entryIds];
      setState((prevState) => {
        const updatedRowEntries = { ...prevState.rowEntries };
        Object.keys(updatedRowEntries).forEach((locationId) => {
          updatedRowEntries[locationId] = updatedRowEntries[locationId].filter(
            (entry) => !entryIdsArray.includes(entry.id)
          );
        });

        const updatedEntries = prevState.entries.filter(
          (entry) => !entryIdsArray.includes(entry.id)
        );

        return {
          ...prevState,
          isLoading: false,
          deletingEntryId: null,
          rowEntries: updatedRowEntries,
          entries: updatedEntries,
          selectedRows: defaultState.selectedRows,
        };
      });

      if (gridHelper) {
        gridHelper.resetSelection();
      }

      toast.success("Deleted successfully.");
      fetchEntries(
        state.search,
        state.scheduleDate,
        state.endDate,
        state.searchColumns,
        state.selectedKAM,
        state.selectedLocation,
        state.pageFilters,
        true,
        state.filters.status,
        false
      );
    }
  };

  const getDayClassName = useCallback(
    (date) => {
      const isScheduledTransferred = isTransferScheduled(
        state.transferScheduleDetails,
        kamId,
        "org",
        date
      );
      const activeDay = state.previousScheduleActiveDate || new Date();

      const firstDay = new Date(
        activeDay.getFullYear(),
        activeDay.getMonth(),
        1
      );
      const lastDay = new Date(
        activeDay.getFullYear(),
        activeDay.getMonth() + 1,
        0
      );

      const isFutureDate = date > new Date();

      const isWeeklyOff = state.weekend.includes(date.getDay().toString());
      let dayClass = clsx({
        [classes.notCreatedDay]: !isFutureDate,
        [classes.backgroundWhite]: isFutureDate,
        [classes.weekend]: isWeeklyOff,
        [classes.weekoff]: isWeeklyOff && isFutureDate,
      });

      const dateString = getDateString(date);
      if (isScheduledTransferred) {
        dayClass = clsx(classes.transfer, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.notSubmitted.includes(dateString)) {
        dayClass = clsx(classes.notSubmittedDay, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.partialSubmitted.includes(dateString)
      ) {
        dayClass = clsx(classes.partialSubmittedDay, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.bsApproved.includes(dateString)) {
        dayClass = clsx(classes.bsApproved, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.completeSubmitted.includes(dateString)
      ) {
        dayClass = clsx(classes.completeSubmittedDay, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.forcedlySubmitted.some(
          (item) => item.date === dateString
        )
      ) {
        dayClass = clsx(classes.forcedSubmitDay, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.transferred.includes(dateString)) {
        dayClass = clsx(classes.transfer, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.underReview.includes(dateString)) {
        dayClass = clsx(classes.underReview, {
          [classes.weekend]: isWeeklyOff,
        });
      }

      return date > lastDay || date < firstDay
        ? clsx("disabled", classes.bgColorNone)
        : state.isFetchingList.submissionHistory
        ? clsx("loading disabled", classes.colorNone)
        : clsx(classes.calendarDay, dayClass);
    },
    [
      state.submissionHistory,
      state.transferScheduleDetails,
      state.isFetchingList.submissionHistory,
      state.weekend,
      classes,
    ]
  );

  const renderDayContents = (day, date) => {
    let tooltipText = "";
    const reasonForcedSubmitDate =
      state.submissionHistory.forcedlySubmitted.find(
        (item) => item.date === getDateString(date)
      );
    if (reasonForcedSubmitDate) {
      tooltipText = `Forced Submit-${reasonForcedSubmitDate.reason}`;
    }

    return <span title={tooltipText}>{day}</span>;
  };

  const getDayClassNamePrevious = useCallback(
    (date) => {
      const activeDay = state.previousScheduleActiveDate || new Date();

      const firstDay = new Date(
        activeDay.getFullYear(),
        activeDay.getMonth(),
        1
      );
      const lastDay = new Date(
        activeDay.getFullYear(),
        activeDay.getMonth() + 1,
        0
      );

      const isFutureDate = date > new Date();

      const isWeeklyOff = state.weekend.includes(date.getDay().toString());
      let dayClass = clsx("disabled color-grey", {
        // [classes.notCreatedDay]: !isFutureDate,
        [classes.backgroundWhite]: isFutureDate || !isFutureDate,
        [classes.weekend]: isWeeklyOff,
        // [classes.weekoff]: isWeeklyOff && isFutureDate,
      });

      const dateString = getDateString(date);
      if (state.submissionHistory.notSubmitted.includes(dateString)) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.partialSubmitted.includes(dateString)
      ) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.bsApproved.includes(dateString)) {
        dayClass = clsx(classes.clickableDate, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.completeSubmitted.includes(dateString)
      ) {
        dayClass = clsx(classes.clickableDate, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (
        state.submissionHistory.forcedlySubmitted.some(
          (item) => item.date === dateString
        )
      ) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.transferred.includes(dateString)) {
        dayClass = clsx("disabled", {
          [classes.weekend]: isWeeklyOff,
        });
      } else if (state.submissionHistory.underReview.includes(dateString)) {
        dayClass = clsx("disabled color-grey", classes.backgroundWhite, {
          [classes.weekend]: isWeeklyOff,
        });
      }

      return date > lastDay || date < firstDay
        ? clsx("disabled", classes.bgColorNone)
        : state.isFetchingList.submissionHistory
        ? clsx("loading disabled", classes.colorNone)
        : clsx(dayClass, classes.calendarDay);
    },
    [
      state.submissionHistory,
      state.isFetchingList.submissionHistory,
      state.weekend,
      classes,
    ]
  );

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const fetchStandByRateTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: true,
      },
    }));
    const { data, error } = await SharedService.getstandByRateTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          standByRateTypes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      standByRateTypeList:
        data?.rows.filter((standByRateType) => standByRateType.is_active) ||
        defaultState.standByRateTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: false,
      },
    }));
  };

  const handleConfirmDialog = async (payload) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.generateSchedule(payload);

    if (error) {
      toast.error(
        Array.isArray(error) ? (
          <>
            {error.map((item) => (
              <>
                {item.message}
                <ul>
                  {(item.submittedDates || item.forcedSubmittedDates || []).map(
                    (date) => (
                      <li>
                        <b>{getUTCDateString(date)}</b>
                      </li>
                    )
                  )}
                </ul>
              </>
            ))}
          </>
        ) : (
          error.message
        )
      );
    } else {
      toast.success("Schedule updated successfully.");
      if (state.tabIndex === 1) {
        fetchEntries(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectedKAM,
          state.selectedLocation,
          state.pageFilters,
          state.rowBeingEdited,
          state.filters.status
        );
      } else if (state.tabIndex === 2 && !!state.selectOriginalKAM) {
        fetchTransferEntriesStatus(
          state.search,
          state.scheduleDate,
          state.endDate,
          state.searchColumns,
          state.selectOriginalKAM,
          state.selectedLocation,
          state.pageFilters,
          state.filters
        );
      }
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      confirmDialog: null,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleCalendarOpen = (type = "scheduleDate") => {
    setState((prevState) => ({
      ...prevState,
      isCalenderOpen: type !== "copyCalender",
      previousScheduleActiveDate:
        type === "scheduleDate" || type === "endDate"
          ? new Date(prevState[type])
          : new Date(),
    }));
  };

  const handleCalendarClose = () => {
    setState((prevState) => ({
      ...prevState,
      isCalenderOpen: false,
    }));
  };

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const kamNotesLookup = state.isKAMNotesPresent?.reduce((lookup, kamNote) => {
    lookup[kamNote.daily_schedule_id] = kamNote.review_reason;
    return lookup;
  }, {});

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const reviewColumnConfig = [
    {
      id: "customer_name",
      label: "Customer Name",
      field: "customer_name",
      render: (row) => {
        return (
          <Tooltip
            title={row.customer_branch?.customer?.name || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "customer_branch.location",
      label: "Location",
      field: "customer_branch.location",
      render: (row) => {
        return (
          <Tooltip
            title={row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "routeDetails.name",
      label: "Route",
      field: "routeDetails.name",
      render: (row, rowIndex) => {
        return (
          <Tooltip title={row?.routeDetails?.name ?? "-"} placement="top-start">
            <Typography noWrap variant="body2">
              {row?.routeDetails?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "individual_contractor_icid",
      label: "ICID/IC Name",
      field: "individual_contractor_icid",
      render: (row) => {
        const handleLabelClick = () => {
          if (!!row.individual_contractor?.icid) {
            fetchList(
              "IC",
              noop,
              `?filter[limit]=1&filter[where][icid]=${row.individual_contractor?.icid}`
            );
            setState((prevState) => ({
              ...prevState,
              showIcDetails: true,
            }));
          }
        };
        let title = !!row.individual_contractor?.icid
          ? `
      IC Settlement Company Name: ${
        row.individual_contractor?.settlementCompany?.value || "-"
      }, 
    
      ICID: ${row.individual_contractor?.icid || "-"},
      IC Name: ${row.individual_contractor?.last_name || ""} ${
              row.individual_contractor?.first_name || ""
            },
    Contractor Type: ${row.individual_contractor?.contractorType?.value || "-"}
    ${row.ic_inactive ? ", Status: Inactive ICID" : ""}
    `
          : "";

        if (
          row.individual_contractor_changed &&
          row.original_individual_contractor_id
        ) {
          const orginalIC = appData.icList.find(
            (contractor) =>
              contractor?.id === row.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }

        const label = row.individual_contractor?.icid
          ? `${row.individual_contractor?.icid || ""}/${
              row.individual_contractor?.last_name || ""
            } ${row.individual_contractor?.first_name || ""}`
          : "";

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );
        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;
        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title ?? ""} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    style={{
                      textDecoration:
                        !!row.individual_contractor?.icid && "underline",
                      cursor: !!row.individual_contractor?.icid && "pointer",
                    }}
                    onClick={
                      !!row.individual_contractor?.icid && handleLabelClick
                    }
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },

    {
      id: "review_reason",
      label: "Review Reason",
      field: "review_reason",
      render: (row) => {
        return (
          <Tooltip title={row.reviewReason || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.reviewReason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerClassName: classes.kamNotesTableHeaderCell,
      id: "kam_notes",
      label: "KAM Notes",
      field: "kam_notes",
      render: (row) => {
        let Bill_Pay_Notes_Value =
          (!!row?.dont_bill_notes
            ? `Do not bill reason : ${row?.dont_bill_notes}\n`
            : "") +
          (!!row?.dont_pay_notes
            ? `Do not pay reason : ${row?.dont_pay_notes}\n`
            : "") +
          (!!row?.dont_bill_miles_notes
            ? `Do not bill extra miles reason: ${row?.dont_bill_miles_notes}\n`
            : "") +
          (!!row?.dont_pay_miles_notes
            ? `Do not pay extra miles reason: ${row?.dont_pay_miles_notes}\n`
            : "") +
          (!!row?.dont_bill_tolls_notes
            ? `Do not bill toll reason: ${row?.dont_bill_tolls_notes}\n`
            : "") +
          (!!row?.dont_pay_tolls_notes
            ? `Do not pay toll reason: ${row?.dont_pay_tolls_notes}`
            : "");

        Bill_Pay_Notes_Value = Bill_Pay_Notes_Value.replace(/\n$/, "");

        return (
          <TextField
            required
            fullWidth
            variant="outlined"
            multiline
            size="small"
            name="kamNotes"
            label="Add KAM Note"
            value={
              row.temp_kam_notes !== undefined
                ? row.temp_kam_notes
                : row.kam_notes === null || row.kam_notes === ""
                ? Bill_Pay_Notes_Value
                : row.kam_notes || ""
            }
            error={!!state.errors[row.id]?.trim()}
            helperText={state.errors[row.id]}
            onChange={(evt) => {
              const field = evt.target.name;
              const value = evt.target.value;
              const errorMessage = validate(field, value) || "";
              setState((prevState) => {
                const updatedEntries = (
                  prevState.tabIndex === 1
                    ? prevState.fetchingDataList
                    : prevState.transferEntries
                ).map((entry) => {
                  if (entry.id === row.id) {
                    entry.temp_kam_notes = value || "";
                  }
                  return entry;
                });

                const newState = {
                  ...prevState,
                  errors: {
                    ...prevState.errors,
                    [row.id]: errorMessage,
                  },
                  kamNotes: value,
                };

                if (prevState.tabIndex === 1) {
                  newState.fetchingDataList = updatedEntries;
                } else {
                  newState.transferEntries = updatedEntries;
                }

                return newState;
              });
            }}
          />
        );
      },
    },
  ];

  const transferredErrorColumnConfig = [
    {
      id: "schedule_date",
      label: "Schedule Date",
      field: "schedule_date",
      render: (row) => {
        return (
          <Tooltip title={row.schedule_dates || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.schedule_dates || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "kam",
      label: "KAM",
      field: "kam",
      render: (row) => {
        const kamDetail = `${row.kam.first_name || ""} ${
          row.kam.last_name || ""
        }-${row.kam.email || ""}-(${row.kam.username || ""})`;
        return (
          <Tooltip title={kamDetail} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const columnConfig = [
    {
      id: "col-edit",
      noResize: true,
      headerClassName: classes.wrapTableTitle,
      render: (row) =>
        moreActions(
          row,
          state.scheduleSubmitted,
          state.transferScheduleSubmitted
        ),
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill",
      label: "Do Not Bill",
      field: "dont_bill",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill ? (
                row?.dont_bill_notes === null || row?.dont_bill_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay",
      label: "Do Not Pay",
      field: "dont_pay",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay ? (
                row?.dont_pay_notes === null || row?.dont_pay_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_miles?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill_miles",
      label: "Do Not Bill Extra Miles",
      field: "dont_bill_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill_miles ? (
                row?.dont_bill_miles_notes === null ||
                row?.dont_bill_miles_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill_miles && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill_miles}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_miles?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay_miles",
      label: "Do Not Pay Extra Miles",
      field: "dont_pay_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay_miles ? (
                row?.dont_pay_miles_notes === null ||
                row?.dont_pay_miles_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay_miles && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay_miles}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_tolls?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill_tolls",
      label: "Do Not Bill Toll",
      field: "dont_bill_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill_tolls ? (
                row?.dont_bill_tolls_notes === null ||
                row?.dont_bill_tolls_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill_tolls && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill_tolls}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_tolls?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay_tolls",
      label: "Do Not Pay Toll",
      field: "dont_pay_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay_tolls ? (
                row?.dont_pay_tolls_notes === null ||
                row?.dont_pay_tolls_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay_tolls && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay_tolls}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.schedule_date?.isSelected,
      id: "schedule_date",
      label: "Schedule Date",
      field: "schedule_date",
      headerClassName: classes.tableHeaderColumnwidth,
      canSort: true,
      render: (row) => {
        const scheduleDate = getUTCDateString(row.schedule_date);
        return (
          <Tooltip title={scheduleDate || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {scheduleDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "customer_branch.customer.name",
      label: "Customer Name",
      fieldName: "customer_branch.customer.name",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={row.customer_branch?.customer?.name || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.customer_branch?.customer?.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "customer_branch.location",
      label: "Location",
      field: "customer_branch.location",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        // const title = `State: ${
        //   row.customer_branch?.state?.name || "-"
        // }, PDX Company: ${row.customer_branch?.pdxCompany?.value || "-"}`;
        return (
          <Tooltip
            title={row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "routeDetails.name",
      label: "Route",
      field: "routeDetails.name",
      headerClassName: classes.tableHeaderRouteWidth,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip title={row?.routeDetails?.name ?? "-"} placement="top-start">
            <Typography noWrap variant="body2">
              {row?.routeDetails?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "customer_branch.state.name",
      label: "State",
      field: "customer_branch.state.name",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.state?.name ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.state?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "customer_branch.pdxCompany.value",
      label: "PDX Company",
      field: "customer_branch.pdxCompany.value",
      canSort: true,
      headerClassName: classes.pdxCompanyHeader,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "vehicleType.value",
      label: "Vehicle",
      field: "vehicleType.value",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => {
        const title = `${row.vehicleType?.value || "-"}`;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "individual_contractor.first_name",
      label: "ICID/IC Name",
      field: "individual_contractor.first_name",
      canSort: true,
      render: (row) => {
        const handleLabelClick = () => {
          if (!!row.individual_contractor?.icid) {
            fetchList(
              "IC",
              noop,
              `?filter[limit]=1&filter[where][icid]=${row.individual_contractor?.icid}`
            );
            setState((prevState) => ({
              ...prevState,
              showIcDetails: true,
            }));
          }
        };
        let title = !!row.individual_contractor?.icid
          ? `
          IC Settlement Company Name: ${
            row.individual_contractor?.settlementCompany?.value || "-"
          }, 
        
          ICID: ${row.individual_contractor?.icid || "-"},
          IC Name: ${row.individual_contractor?.last_name || ""} ${
              row.individual_contractor?.first_name || ""
            },
        Contractor Type: ${
          row.individual_contractor?.contractorType?.value || "-"
        }
        ${row.ic_inactive ? ", Status: Inactive ICID" : ""}
        `
          : "";

        if (
          row.individual_contractor_changed &&
          row.original_individual_contractor_id
        ) {
          const orginalIC = appData.icList.find(
            (contractor) =>
              contractor?.id === row.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }

        const label = row.individual_contractor?.icid
          ? `${row.individual_contractor?.icid || ""}/${
              row.individual_contractor?.last_name || ""
            } ${row.individual_contractor?.first_name || ""}`
          : "";

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );
        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;
        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    style={{
                      textDecoration:
                        !!row.individual_contractor?.icid && "underline",
                      cursor: !!row.individual_contractor?.icid && "pointer",
                    }}
                    onClick={
                      !!row.individual_contractor?.icid && handleLabelClick
                    }
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.WCP_Driver?.isSelected,
      id: "WCP_Driver",
      label: "WCP-Driver",
      field: "WCP_Driver",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const driver = row?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""} (#${
              driver?.id
            })`
          : null;
        return (
          <Tooltip title={driverName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {driverName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_type?.isSelected,
      headerClassName: classes.tableHeaderColumnwidth,
      id: "pay_rate_type",
      label: "Pay Rate Type",
      field: "pay_rate_type",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate?.isSelected,
      id: "pay_rate",
      label: "Pay Rate",
      field: "pay_rate",
      startAdornment: "$",
      headerClassName: classes.tableHeaderColumnwidth,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_quantity?.isSelected,
      id: "pay_quantity",
      label: "Pay Quantity",
      headerClassName: classes.tableHeaderColumnwidth,
      field: "pay_quantity",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_surcharge?.isSelected,
      id: "pay_fuel_surcharge",
      label: "Pay Fuel Surcharge%",
      field: "pay_fuel_surcharge",
      headerClassName: classes.tableHeaderColumnwidth,
      canSort: true,
      render: (row) => {
        const label = row.pay_fuel_surcharge
          ? `${row.pay_fuel_surcharge || ""}%`
          : "0";

        return (
          <Tooltip title={label} placement="top-start">
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_toll_amount?.isSelected,
      id: "pay_toll_amount",
      label: "Pay Toll Amount",
      field: "pay_toll_amount",
      headerClassName: classes.tableHeaderColumnwidth,
      startAdornment: "$",
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_miles?.isSelected,
      id: "pay_extra_miles",
      label: "Pay Extra Miles",
      field: "pay_extra_miles",
      headerClassName: classes.tableHeaderColumnwidth,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.reimbursement?.isSelected,
      id: "reimbursement",
      label: "Incentive",
      field: "reimbursement",
      headerClassName: classes.tableHeaderColumnwidth,
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.reimbursement || 0} placement="top-start">
          <Typography variant="body2">{`$${
            row.reimbursement || 0
          }`}</Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.kam_notes?.isSelected,
      id: "kam_notes",
      label: "KAM Notes",
      headerClassName: classes.tableHeaderNotesWidth,
      field: "kam_notes",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.kam_notes ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.kam_notes ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_notes?.isSelected,
      id: "review_notes",
      label: "Review Notes",
      field: "review_notes",
      headerClassName: classes.tableHeaderNotesWidth,
      canSort: true,
      render: (row) => {
        let finalNotes;
        if (row.review_notes !== null && !!row.review_notes?.length) {
          const splitNotes = row.review_notes?.split("||");

          finalNotes = splitNotes?.map((note) => {
            let str = note;

            const startIndexOfDate = str?.indexOf("-");
            const lastIndexOfDate = str?.indexOf(": ");
            const formattedDate = new Date(
                str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
              )?.toLocaleString(),
              str1 =
                str.substring(0, startIndexOfDate) +
                "-" +
                formattedDate +
                str.substring(lastIndexOfDate);
            return str1;
          });
        }

        const title =
          row.review_notes !== null && !!row.review_notes?.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html: (finalNotes || []).join("<br>"),
              }}
            />
          ) : (
            "-"
          );

        return (
          <Tooltip title={title || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {finalNotes || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_reason?.isSelected,
      id: "review_reason",
      label: "Review Reason",
      field: "review_reason",
      headerClassName: classes.tableHeaderNotesWidth,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.review_reason || "-"} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.review_reason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden:
        !isTransferScheduleEnable ||
        !state.dynamicColumns?.transfer_to?.isSelected ||
        state.tabIndex === 2,
      id: "transfer_to",
      label: "Transfer to KAM",
      field: "transfer_to",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const transferTo = appDataList.kamUsers.find(
          (data) => data?.id === row?.transfer_to
        );
        let kamDetail;
        if (transferTo) {
          kamDetail = `${transferTo.first_name} ${
            transferTo.last_name || ""
          } - ${transferTo.email}-(${transferTo.username})`;
        }
        return (
          <Tooltip title={kamDetail ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden:
        !isTransferScheduleEnable ||
        !state.dynamicColumns?.transfer_notes?.isSelected ||
        state.tabIndex === 2,
      id: "transfer_notes",
      label: "Transfer Notes",
      field: "transfer_notes",
      headerClassName: classes.tableHeaderNotesWidth,
      render: (row) => {
        const flagLabel = row?.transfer_notes?.length ? (
          <img src={TransferYellowIcon} alt="reports" height={20} width={20} />
        ) : (
          "-"
        );

        return (
          <Tooltip title={row?.transfer_notes ?? ""} placement="top-start">
            <Typography noWrap variant="body2">
              {flagLabel}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden:
        !isTransferScheduleEnable ||
        state.tabIndex === 2 ||
        !state.dynamicColumns?.is_accepted?.isSelected,
      id: "is_accepted",
      label: "Is Accepted",
      field: "is_accepted",
      headerClassName: classes.tableHeaderColumnwidth,
      render: (row) => {
        return (
          <Tooltip
            title={(row.is_accepted ? "Yes" : "No") ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {(row.is_accepted ? "Yes" : "No") || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden:
        !isTransferScheduleEnable ||
        state.tabIndex === 2 ||
        !state.dynamicColumns?.accepted_by?.isSelected,
      id: "accepted_by",
      label: "Accepted By",
      field: "accepted_by",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const acceptedBy = appData.users.find(
          (data) => data?.id === row?.accepted_by
        );
        let kamDetail;
        if (acceptedBy) {
          kamDetail = `${acceptedBy.first_name} ${
            acceptedBy.last_name || ""
          } - (${acceptedBy.username})`;
        }
        return (
          <Tooltip title={kamDetail ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden:
        !isTransferScheduleEnable ||
        state.tabIndex === 2 ||
        !state.dynamicColumns?.is_rejected?.isSelected,
      id: "is_rejected",
      label: "Is Rejected",
      field: "is_rejected",
      headerClassName: classes.tableHeaderColumnwidth,
      render: (row) => {
        return (
          <Tooltip
            title={(row.is_rejected ? "Yes" : "No") ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {(row.is_rejected ? "Yes" : "No") || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden:
        !isTransferScheduleEnable ||
        state.tabIndex === 2 ||
        !state.dynamicColumns?.rejected_by?.isSelected,
      id: "rejected_by",
      label: "Rejected By",
      field: "rejected_by",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const rejectedBy = appData.users.find(
          (data) => data?.id === row?.rejected_by
        );
        let kamDetail;
        if (rejectedBy) {
          kamDetail = `${rejectedBy.first_name} ${
            rejectedBy.last_name || ""
          } - (${rejectedBy.username})`;
        }
        return (
          <Tooltip title={kamDetail ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.submitted_by?.isSelected,
      id: "submitted_by",
      label: "Submitted By",
      field: "submitted_by",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const kam = appData.users.find(
          (ele) => ele?.id === row?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }
        return (
          <Tooltip title={submittedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submittedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "submitted_at",
      label: "Last Submitted At",
      isHidden: !state.dynamicColumns?.submitted_at?.isSelected,
      fieldName: "submitted_at",
      hasEllipses: true,
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const submitAt = `${
          !!row.submitted_at
            ? `${getDateString(row.submitted_at)} ${getFormattedTime(
                new Date(row.submitted_at)
              )}`
            : "-"
        }`;
        return (
          <Tooltip title={submitAt || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submitAt}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.reverted_by?.isSelected,
      id: "reverted_by",
      label: "Reverted By",
      field: "reverted_by",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const user = appData.users.find(
          (ele) => ele?.id === row?.reverted_by || null
        );
        let revertedBy = null;
        if (user) {
          revertedBy = `${user.first_name || ""} ${user.last_name || ""}-${
            user.email || ""
          }-(${user.username || ""})`;
        }
        return (
          <Tooltip title={revertedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {revertedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "reverted_at",
      label: "Reverted At",
      isHidden: !state.dynamicColumns?.reverted_at?.isSelected,
      fieldName: "reverted_at",
      hasEllipses: true,
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const revertedAt = `${
          !!row.reverted_at
            ? `${getDateString(row.reverted_at)} ${getFormattedTime(
                new Date(row.reverted_at)
              )}`
            : "-"
        }`;
        return (
          <Tooltip title={revertedAt || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {revertedAt}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  if (state.tabIndex === 2) {
    columnConfig.splice(
      1,
      0,
      {
        isHidden: !state.dynamicColumns?.original_kam?.isSelected,
        id: "KAM.full_name",
        label: "Original KAM",
        field: "KAM.full_name",
        canSort: true,
        render: (row) => {
          const originalKam = `${row?.KAM?.full_name || ""}`;
          return (
            <Tooltip title={originalKam} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalKam}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !state.dynamicColumns?.transfer_to?.isSelected,
        id: "transfer_to",
        label: "Transfer To KAM",
        field: "transfer_to",
        canSort: true,
        render: (row) => {
          const transferToKAM = appData.users.filter(
            (user) => user.id === row.transfer_to
          );

          let tooltip;
          let title;

          if (transferToKAM.length > 0) {
            tooltip = `${transferToKAM[0]?.first_name || ""} ${
              transferToKAM[0]?.last_name || ""
            } - (${transferToKAM[0]?.username || ""})`;
            title = `${transferToKAM[0]?.first_name || ""} ${
              transferToKAM[0]?.last_name || ""
            }`;
          }
          return (
            <Tooltip title={tooltip ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {title || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !state.dynamicColumns?.transfer_notes,
        id: "transfer_notes",
        label: "Transfer Notes",
        field: "transfer_notes",
        render: (row) => {
          const flagLabel = row?.transfer_notes?.length ? (
            <img
              src={TransferYellowIcon}
              alt="reports"
              height={20}
              width={20}
            />
          ) : (
            "-"
          );

          return (
            <Tooltip title={row?.transfer_notes ?? ""} placement="top-start">
              <Typography noWrap variant="body2">
                {flagLabel}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !state.dynamicColumns?.is_accepted?.isSelected,
        id: "is_accepted",
        label: "Is Accepted",
        field: "is_accepted",
        render: (row) => {
          return (
            <Tooltip
              title={(row.is_accepted ? "Yes" : "No") ?? ""}
              placement="top-start"
            >
              <Typography variant="body2" noWrap>
                {(row.is_accepted ? "Yes" : "No") || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !state.dynamicColumns?.accepted_by?.isSelected,
        id: "accepted_by",
        label: "Accepted By",
        field: "accepted_by",
        render: (row) => {
          const acceptedBy = appData.users.find(
            (data) => data?.id === row?.accepted_by
          );
          let kamDetail;
          if (acceptedBy) {
            kamDetail = `${acceptedBy.first_name} ${
              acceptedBy.last_name || ""
            } - (${acceptedBy.username})`;
          }
          return (
            <Tooltip title={kamDetail ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {kamDetail || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !state.dynamicColumns?.status?.isSelected,
        id: "status",
        label: "Status",
        field: "status",
        canSort: true,
        render: (row) => {
          const status = `${row?.status || ""}`;
          return (
            <Tooltip title={status} placement="top-start">
              <Typography variant="body2" noWrap>
                {status}
              </Typography>
            </Tooltip>
          );
        },
      }
    );
  }

  if (
    !state.hasRendered &&
    canViewAllSchedules &&
    state.isFetchingList.customers &&
    state.isFetchingList.users &&
    state.isFetchingList.IC
  ) {
    return <CircularProgress className={classes.pageLoader} />;
  }

  return (
    <>
      {appData.isTabletView ? (
        <ResponsiveView
          isRoleADMIN={isRoleADMIN}
          selectedRef={selectedRef}
          isRoleView={isRoleView}
          locationOPtions={locationOPtions}
          isTransferredScheduledReceived={
            viewData.isTransferredScheduledReceived
          }
          isScheduledTransferred={viewData.isScheduledTransferred}
          selectedLocation={state.selectedLocation}
          kamId={kamId}
          testId={state.testId}
          getDatesInRange={getDatesInRange}
          isRoleKAM={isRoleKAM}
          canViewAllSchedules={canViewAllSchedules}
          state={state}
          classes={classes}
          history={history}
          PAGE_KEYS={PAGE_KEYS}
          RECORD_STATUS={RECORD_STATUS}
          ROLE={ROLE}
          columnConfig={columnConfig}
          appDataList={appDataList}
          rosterValue={state.rosterValue}
          tabIndex={state.tabIndex}
          tableHeadRef={tableHeadRef}
          inputRef={inputRef}
          handleTabChange={handleTabChange}
          expandLocation={expandLocation}
          getPzriceMatrix={getPzriceMatrix}
          setState={setState}
          handleCalendarClose={handleCalendarClose}
          handleCalendarOpen={handleCalendarOpen}
          handleSearch={handleSearch}
          renderDayContents={renderDayContents}
          getDayClassName={getDayClassName}
          getDayClassNamePrevious={getDayClassNamePrevious}
          userData={isRoleKAM ? userData : null}
          handleSelectionChange={handleSelectionChange}
          handleGridHelper={(gridInstance) => (gridHelper = gridInstance)}
          handleTransferStatusDialog={handleTransferStatusDialog}
          wrapRef1={wrapRef1}
          wrapRef2={wrapRef2}
          gridRefs={gridRefs}
          handleRowOpen={handleRowOpen}
          handleMultiCollapsibleGridScroll={handleMultiCollapsibleGridScroll}
          handleCollapsibleGridScroll={handleCollapsibleGridScroll}
          syncChildGridsScroll={syncChildGridsScroll}
          clearAllEntries={clearAllEntries}
        />
      ) : (
        <DesktopView
          isRoleKAM={isRoleKAM}
          expandLocation={expandLocation}
          isRoleADMIN={isRoleADMIN}
          selectedRef={selectedRef}
          tableHeadRef={tableHeadRef}
          isRoleView={isRoleView}
          locationOPtions={locationOPtions}
          isTransferredScheduledReceived={
            viewData.isTransferredScheduledReceived
          }
          isScheduledTransferred={viewData.isScheduledTransferred}
          selectedLocation={state.selectedLocation}
          rosterValue={state.rosterValue}
          getPzriceMatrix={getPzriceMatrix}
          clearAllEntries={clearAllEntries}
          testId={state.testId}
          specificDateRosterValue={specificDateRosterValue}
          kamId={kamId}
          getDatesInRange={getDatesInRange}
          canViewAllSchedules={canViewAllSchedules}
          state={state}
          classes={classes}
          history={history}
          inputRef={inputRef}
          PAGE_KEYS={PAGE_KEYS}
          RECORD_STATUS={RECORD_STATUS}
          ROLE={ROLE}
          columnConfig={columnConfig}
          appDataList={appDataList}
          userData={isRoleKAM ? userData : null}
          setState={setState}
          tabIndex={state.tabIndex}
          handleTabChange={handleTabChange}
          handleCalendarClose={handleCalendarClose}
          handleCalendarOpen={handleCalendarOpen}
          handleSearch={handleSearch}
          getDayClassName={getDayClassName}
          getDayClassNamePrevious={getDayClassNamePrevious}
          renderDayContents={renderDayContents}
          handleSelectionChange={handleSelectionChange}
          handleGridHelper={(gridInstance) => (gridHelper = gridInstance)}
          handleTransferStatusDialog={handleTransferStatusDialog}
          wrapRef1={wrapRef1}
          wrapRef2={wrapRef2}
          handleRowOpen={handleRowOpen}
          handleMultiCollapsibleGridScroll={handleMultiCollapsibleGridScroll}
          handleCollapsibleGridScroll={handleCollapsibleGridScroll}
          syncChildGridsScroll={syncChildGridsScroll}
          gridRefs={gridRefs}
        />
      )}
      {isTransferScheduleEnable &&
        ((state.wantToTransfer && isTransfered.wantToTransfer) ||
          state.isTransferSchedule) && (
          <TransferSchedule
            entries={
              isTransfered.wantToTransfer
                ? state.entriesTotransfer
                : Object.values(state.rowEntries)
                    .flat()
                    ?.filter((data) =>
                      state.selectedRows?.some((id) => id === data.id)
                    )
            }
            transferRecordStatus={state.transferRecordStatus}
            appDataList={appDataList}
            selectedKAM={state.selectedKAM}
            tabIndex={state.tabIndex}
            isLoading={state.isTransfering}
            isFetchingEntries={state.isFetchingEntries}
            handleCancel={handleCancel}
            handleTransferSchedule={handleTransferSchedule}
            userData={isRoleKAM ? userData?.id : null}
            isTransferSchedule={
              isTransfered.wantToTransfer || state.isTransferSchedule
            }
          />
        )}
      {(state.isNew || state.rowBeingEdited) && (
        <Form
          open={state.isNew || state.rowBeingEdited}
          selectedKAMId={kamId}
          entry={state.rowBeingEdited}
          selectOriginalKAM={state.selectOriginalKAM}
          priceMatrix={state.priceMatrix}
          isPriceMatrixFetched={state.isPriceMatrixFetched}
          kamId={isRoleKAM ? userData?.id : null}
          tabIndex={state.tabIndex}
          vehicleTypeList={state.vehicleTypeList}
          formAlertFields={state.formAlertFields}
          isRoleKAM={state.userRole === ROLE.KAM}
          kam={state.selectedKAM}
          isNew={state.isNew}
          schedule_date={state.scheduleDate}
          end_date={state.endDate}
          handleUpdateEntries={handleUpdateEntries}
          isMultipleRecord={state.isMultiSchedule}
          customerList={appDataList.customers}
          contractorList={appDataList.icList}
          onClose={async (isSubmitted = false, customer = {}) => {
            setState((prevState) => ({
              ...prevState,
              rowBeingEdited: null,
              isNew: false,
              formAlertFields: {},
            }));
            if (typeof isSubmitted === "boolean" && isSubmitted) {
              await (() => {
                if (state.tabIndex === 1) {
                  fetchEntries(
                    state.search,
                    state.scheduleDate,
                    state.endDate,
                    state.searchColumns,
                    state.selectedKAM,
                    state.selectedLocation,
                    state.pageFilters,
                    state.rowBeingEdited,
                    state.filters.status,
                    false
                  );
                } else if (state.tabIndex === 2 && !!state.selectOriginalKAM) {
                  fetchTransferEntriesStatus(
                    state.search,
                    state.scheduleDate,
                    state.endDate,
                    state.searchColumns,
                    state.selectOriginalKAM,
                    state.selectedLocation,
                    state.pageFilters,
                    state.filters
                  );
                }
                fetchList();
              })();
              if (state.isNew && state.tabIndex === 1) {
                gridHelper && gridHelper.toggleAll(false);
              } else if (state.tabIndex === 2) {
                gridHelper && gridHelper.toggleAll(false);
                gridHelper && gridHelper.toggle(true, customer?.name);
              }
            }
          }}
          showFieldAlert={showFieldAlert}
        />
      )}
      {state.showIcDetails && (
        <IcForm
          open={!!state.showIcDetails}
          isTabletView={appData.isTabletView}
          isFetching={state.isIcFetching}
          entry={appDataList.IC[0]}
          usersList={appData.users}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              showIcDetails: false,
            }));
          }}
        />
      )}
      {!!state.confirmDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDialog}
          content={
            <Box>
              <Typography variant="body1">
                {state.confirmDialog.contentText}
              </Typography>
              {!state.confirmDialog?.payload?.isOfPreviousDate && (
                <Box className="d-flex f-align-center mt-2">
                  <FormControl>
                    <RadioGroup
                      row
                      name="roster_type"
                      value={state.rosterValue}
                      onChange={(evt) => {
                        const value = evt.target.value;

                        setState((prevState) => ({
                          ...prevState,
                          rosterValue: value,
                          confirmDialog: {
                            ...prevState.confirmDialog,
                            payload: {
                              ...prevState.confirmDialog.payload,
                              is_weekday: value === "weekdays" ? true : false,
                            },
                          },
                        }));
                      }}
                    >
                      <FormControlLabel
                        value="weekdays"
                        control={<Radio size="small" color="primary" />}
                        label="Weekdays"
                        disabled={
                          state.rosterValue === "weekend" &&
                          !state.isBothDateRange
                        }
                      />
                      <FormControlLabel
                        value="weekend"
                        control={<Radio size="small" color="primary" />}
                        label="Weekend"
                        disabled={
                          state.rosterValue === "weekdays" &&
                          !state.isBothDateRange
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
            </Box>
          }
          onConfirm={() => handleConfirmDialog(state.confirmDialog.payload)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              confirmDialog: null,
            }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              {state.confirmDialog.positiveActionLabel}
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.showTransferredErrorPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paperWidthSm: classes.paperWidthSm,
          }}
          open={!!state.showTransferredErrorPopup}
          contentText={
            <>
              <Typography variant="body1" className="mb-6">
                <strong>All records are not submitted for this date.</strong>
                <br />
                Please get in touch with the below-mentioned KAM(s) to submit
                their schedules:
              </Typography>
              <Grid
                columns={transferredErrorColumnConfig}
                rows={state.showTransferredErrorData.flatMap(
                  ({ schedule_dates, transfer_to, kam_id }) =>
                    schedule_dates.map((date) => ({
                      schedule_dates: getUTCDateString(date),
                      kam: appDataList.kamUsers.find(
                        (data) =>
                          data?.id === transfer_to || data?.id === kam_id
                      ),
                    }))
                )}
                actionBarConfig={null}
                isLoading={state.isFetchingTransferEntries}
                hasSelection={false}
                hasPagination={false}
                onReady={(instance) => (gridHelper = instance)}
              />
            </>
          }
          onConfirm={() =>
            setState((prevState) => ({
              ...prevState,
              showTransferredErrorPopup: false,
            }))
          }
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              showTransferredErrorPopup: false,
            }));
          }}
        />
      )}
      {state.deletingEntryId && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingEntryId}
          contentText="Are you sure you want to delete?"
          onConfirm={() => handleDelete(state.deletingEntryId)}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, deletingEntryId: null }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}

      {state.showDatesWithoutRecordsDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.showDatesWithoutRecordsDialog}
          contentText={
            <div>
              <Typography variant="body1">
                All records submitted except the given date(s):
                {state.dateWithoutRecords.map((date) => (
                  <li>{getUTCDateString(date)}</li>
                ))}
              </Typography>
            </div>
          }
          onConfirm={() =>
            setState((prevState) => ({
              ...prevState,
              showDatesWithoutRecordsDialog: false,
            }))
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              showDatesWithoutRecordsDialog: false,
            }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={null}
        />
      )}

      {state.isKAMNotesAdded && (
        <ActionDialog
          classes={{
            confirm: state.isLoading ? "" : "bg-primary",
            paperWidthSm: classes.paperWidthSm,
          }}
          open={!!state.isKAMNotesAdded}
          contentText={
            <>
              <Typography variant="body1" className="mt-2">
                <strong>
                  {" "}
                  Please add the KAM notes for the submitted record(s).
                </strong>
              </Typography>
              <br></br>
              <Grid
                columns={reviewColumnConfig}
                rows={(state.tabIndex === 1
                  ? state.fetchingDataList
                  : state.transferEntries
                )
                  ?.filter((entry) => kamNotesLookup[entry.id])
                  ?.map((entry) => ({
                    ...entry,
                    reviewReason: kamNotesLookup[entry.id],
                  }))}
                actionBarConfig={null}
                isLoading={state.isFetchingData}
                hasSelection={false}
                hasPagination={false}
                onReady={(instance) => (gridHelper = instance)}
              />
            </>
          }
          onConfirm={async () => {
            const filteredEntries = (
              state.tabIndex === 1
                ? state.fetchingDataList
                : state.transferEntries
            )?.filter((entry) =>
              state.isKAMNotesPresent?.some(
                (kamNote) => kamNote.daily_schedule_id === entry.id
              )
            );

            const kamNotesArray = filteredEntries?.map((entry) => {
              let kam_notes = entry.temp_kam_notes || entry.kam_notes || "";

              if (!entry.temp_kam_notes && !entry.kam_notes) {
                kam_notes +=
                  (!!entry.dont_bill_notes
                    ? `Do not bill reason : ${entry.dont_bill_notes}, `
                    : "") +
                  (!!entry.dont_pay_notes
                    ? `Do not pay reason : ${entry.dont_pay_notes}, `
                    : "") +
                  (!!entry.dont_bill_miles_notes
                    ? `Do not bill extra miles reason: ${entry.dont_bill_miles_notes}, `
                    : "") +
                  (!!entry.dont_pay_miles_notes
                    ? `Do not pay extra miles reason: ${entry.dont_pay_miles_notes}, `
                    : "") +
                  (!!entry.dont_bill_tolls_notes
                    ? `Do not bill toll reason: ${entry.dont_bill_tolls_notes}, `
                    : "") +
                  (!!entry.dont_pay_tolls_notes
                    ? `Do not pay toll reason: ${entry.dont_pay_tolls_notes}`
                    : "");
              }

              kam_notes = kam_notes.replace(/, \s*$/, "");

              return { id: entry.id, kam_notes };
            });

            await handleScheduleSubmit({
              ...pageConfig.confirmScheduleSubmit,
              kamNotesPresent: true,
              kamNotesArray: kamNotesArray || [],
            });

            setState((prevState) => ({
              ...prevState,
              isKAMNotesAdded: false,
            }));
            gridHelper.resetSelection();
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isKAMNotesAdded: false,
              errors: {
                kamNotes: " ",
              },
            }))
          }
          isConfirmDisabled={
            state.isLoading ||
            !(
              state.tabIndex === 1
                ? Object.values(state.rowEntries).flat()
                : state.transferEntries
            )
              ?.filter((entry) =>
                state.isKAMNotesPresent?.some(
                  (kamNote) => kamNote.daily_schedule_id === entry.id
                )
              )
              ?.every(
                (entry) =>
                  (entry.temp_kam_notes?.trim() ||
                    entry.kam_notes?.trim() ||
                    entry.dont_bill_notes?.trim() ||
                    entry.dont_bill_miles_notes?.trim() ||
                    entry.dont_pay_miles_notes?.trim() ||
                    entry.dont_bill_tolls_notes?.trim() ||
                    entry.dont_pay_tolls_notes?.trim() ||
                    entry.dont_pay_notes?.trim()) &&
                  !state.errors[entry.id]?.trim()
              )
          }
          positiveActionLabel={
            <>
              SUBMIT
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isUnderReview && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.isUnderReview}
          contentText="Submitted schedule will undergo for review process."
          onConfirm={() =>
            setState((prevState) => ({ ...prevState, isUnderReview: false }))
          }
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isUnderReview: false }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={null}
        />
      )}
      {!!state.confirmScheduleSubmit && (
        <ActionDialog
          classes={{
            confirm: state.isLoading ? "" : "bg-primary",
          }}
          open={!!state.confirmScheduleSubmit}
          contentText={
            <>
              <Typography variant="body1">
                {`Are you sure you want to ${
                  state.confirmScheduleSubmit?.payload?.isPartialSubmit
                    ? "partially"
                    : ""
                } submit?`}
              </Typography>
              <Typography variant="body2" className="mt-2">
                After submission, the submitted record(s) cannot be changed.
              </Typography>
            </>
          }
          onConfirm={() => {
            setPageConfig(PAGE_KEYS.DAILY_SCHEDULE, {
              confirmScheduleSubmit: state.confirmScheduleSubmit.payload,
            });
            handleScheduleSubmit({
              ...state.confirmScheduleSubmit.payload,
              kamNotesPresent: false,
            });
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              confirmScheduleSubmit: null,
            }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              Ok
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          forceSubmitReasonList={state.forceSubmitReasonList}
          onClose={handleClose}
          kamId={kamId}
          onForceSubmit={() => fetchList("pendingItems", noop, kamId)}
        />
      )}
      {state.fieldAlert.show && (
        <DynamicForm
          open={state.fieldAlert.show}
          title="Update Entry(s)"
          subHeading={state.fieldAlert.subHeading}
          positiveLabel="Save"
          fieldErrors={state.fieldAlert.errors}
          onChange={state.fieldAlert.onChange}
          isLoading={state.fieldAlert.isLoading}
          updateValidations={state.fieldAlert.updateValidations}
          configs={state.fieldAlert.configs}
          onSubmit={(data = {}, errors = {}) => {
            const payload = Object.entries(data).reduce((acc, [key, value]) => {
              acc[key] =
                key !== "bill_rate_type" &&
                key !== "pay_rate_type" &&
                !data[key]
                  ? "0"
                  : value;
              return acc;
            }, {});

            handleFieldAlertSubmit(
              state.fieldAlert.id,
              {
                ...payload,
                schedule_date: state.fieldAlert.scheduleDate,
              },
              errors
            );
          }}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              fieldAlert: defaultState.fieldAlert,
            }));
          }}
        />
      )}
      {state.openRevertForcedSubmitDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.openRevertForcedSubmitDialog}
          contentText={
            <div>
              <Typography variant="body1">
                Are you certain that you wish to undo the force submit action
                for this date({state.forcedSubmitDate})?
              </Typography>
            </div>
          }
          onConfirm={() => handleRevertForcedSubmit(state.forcedSubmitDate)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              openRevertForcedSubmitDialog: false,
            }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={<>OK</>}
          negativeActionLabel={null}
        />
      )}
      {state.showDetailedErrorPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={state.showDetailedErrorPopup}
          contentText={
            <div>
              <ul>
                {state.showDetailedErrorMessage?.map((message) => {
                  return <li>{message}</li>;
                })}
              </ul>
            </div>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              showDetailedErrorPopup: false,
            }));
          }}
          positiveActionLabel={"OK"}
          negativeActionLabel={null}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              showDetailedErrorPopup: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.isTransferStatusDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paperWidthSm: classes.paperWidthforTransferStatus,
          }}
          open={state.isTransferStatusDialog}
          contentText={
            state.isTransferType === "reject" ? (
              <>
                <Typography variant="body2" className="mt-2">
                  Do you want to reject this transfer schedule?
                </Typography>
                <br></br>
                <TextField
                  className="mb-1"
                  fullWidth
                  name="rejectedNotes"
                  label="Rejection Reason"
                  variant="outlined"
                  size="small"
                  value={state.rejectedNotes}
                  error={!!state.errors.rejectedNotes?.trim()}
                  helperText={state.errors.rejectedNotes}
                  onChange={(evt) => handleFieldChange(evt)}
                />
              </>
            ) : (
              <Typography variant="body2" className="mt-2">
                Do you want to approve this transfer schedule?
              </Typography>
            )
          }
          onConfirm={() => {
            if (state.isTransferType === "approve") {
              handleApproveTransferSchedule(state.selectedRows);
            } else if (state.isTransferType === "reject") {
              handleRejectTransferSchedule(
                state.selectedRows,
                state.rejectedNotes
              );
            }
          }}
          onCancel={() => {
            handleTransferStatusDialog(false);
            if (state.isTransferType === "reject") {
              setState((prevState) => ({
                ...prevState,
                errors: {
                  ...prevState.errors,
                  rejectedNotes: " ",
                },
                rejectedNotes: defaultState.rejectedNotes,
              }));
            }
          }}
          isConfirmDisabled={
            state.isTransferLoading ||
            (state.isTransferType === "reject" &&
              !!state.errors.rejectedNotes?.trim())
          }
          positiveActionLabel={
            <>
              OK
              {state.isTransferLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.payQuantityExceedDaily && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.quantityExceedpaperSize,
          }}
          open={state.payQuantityExceedDaily}
          contentText={
            <>
              <Typography variant="h6" color="error" className="mb-4 text-bold">
                Pay Quantity should not exceed 1.99 for Pay Rate Type 'Daily' in
                the following entrie(s):
              </Typography>
              <Grid
                columns={quantityExceedColumnConfig}
                rows={state.payQuantityExceedDaily}
                actionBarConfig={null}
                isLoading={false}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              payQuantityExceedDaily: defaultState.payQuantityExceedDaily,
            }));
          }}
          showPositiveActionLabel={false}
          hasClose={true}
          negativeActionLabel="Close"
        />
      )}
      {state.isRowOpen.length > 0 && (
        <div
          ref={wrapRef1}
          onScroll={
            state.tabIndex === 2
              ? handleCollapsibleGridScroll
              : handleMultiCollapsibleGridScroll
          }
          style={{
            overflowX: "auto",
            width: "calc(100vw - 110px)",
            height: "2%",
            bottom: 0,
            position: "fixed",
          }}
        >
          <div
            style={{
              width: state.dimensions,
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
    </>
  );
};

export default ViewDailySchedule;
