/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Tooltip, Typography } from "@material-ui/core";

import clsx from "clsx";
import { Grid, noop } from "shared";
import useStyles from "./style";
import { useCallback } from "react";
import DeleteIcon from "@material-ui/icons/Delete";

const MemorizeGrid = ({
  isFetchingData = false,
  entries = [],
  appData = {},
  handleSelection = noop,
  onReady = noop,
  handleDeleteDialog = noop,
}) => {
  const classes = useStyles();

  const getRouteNames = (route_ids, customer_branch_id, appDataObj) => {
    const customer = appDataObj?.customers?.find((cust) =>
      cust?.customer_branches?.some(
        (branch) => branch?.id === customer_branch_id
      )
    );

    if (!customer) return [];
    const branch = customer?.customer_branches?.find(
      (branch) => branch?.id === customer_branch_id
    );

    if (!branch || !branch.routes) return [];

    return (
      !route_ids
        ? branch.routes
        : branch.routes.filter((route) => route_ids.includes(route.id))
    )
      .map((route) => route.name)
      .join(", ");
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    handleSelection(selectedRows);
  }, []);

  const columnConfig = [
    {
      id: "col-edit",
      noResize: true,
      render: (row) => (
        <Tooltip title="Edit" placement="top-start">
          <DeleteIcon
            color="error"
            className="ml-4 c-pointer"
            onClick={() => handleDeleteDialog(row.id)}
          />
        </Tooltip>
      ),
    },
    {
      id: "name",
      label: "Name",
      field: "name",
      render: (row) => {
        const title = row?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "pdx_company",
      label: "Pdx Company",
      field: "pdx_company",
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "route",
      label: "Route",
      field: "route",
      render: (row) => {
        const title =
          getRouteNames(row?.route_ids, row?.customer_branch_id, appData) ||
          "-";

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {(!row?.route_ids ? "All" : title) || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Paper
        element={0}
        className={clsx(classes.invoice)}
        style={{ position: "relative" }}
      >
        <Grid
          columns={columnConfig}
          rows={entries}
          actionBarConfig={null}
          hasSelection={true}
          isLoading={isFetchingData}
          hasPagination={false}
          classes={classes.list}
          onSelectionChange={handleSelectionChange}
          onReady={onReady}
          compactScroll
        />
      </Paper>
    </>
  );
};

export default MemorizeGrid;
