import { createRef, useState } from "react";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import TableContainer from "@material-ui/core/TableContainer";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { noop, GRID_ACTION_VIEW } from "../../constants";
import ActionToolbar from "./action-toolbar";
import FilterToolbar from "./filter-toolbar";
// import globalStyles from "shared/constants/styles"
import Loader from "./loader";
import PaginationActions from "./pagination-actions";
import NoRecords from "assets/images/norecord.svg";
import NoRecordsDark from "assets/images/no-records-dark.svg";
import { useStyles } from "./style";
import { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";

const defaultState = {
  selectedRows: [],
  areAllSelected: false,
  showNoDataMessage: false,
};

const Grid = ({
  columns = [],
  rows = [],
  selectedCustomerRows = [],
  selectedICRows = [],
  order = null,
  orderBy = null,
  hasMoreEntries = 0,
  pageSize = 5,
  pageNumber = 1,
  hasLoader = true,
  isLoading = false,
  isDCNScreen = false,
  selectedRef = null,
  boxborder = null,
  hasSelection = true,
  hasPagination = true,
  disabled = false,
  hasSelectMultiple = true,
  hasSelectAll = true,
  filterConfig = {},
  actionBarConfig = {},
  containerRef,
  isInfiniteLoading = false,
  classes: passedClasses = {},
  totalRows = 0,
  paginationActions = [],
  isFormVisible = false,
  isLazyLoadingAdded = false,
  noRecordLabel = "",
  rowEvents = [],
  onSelectAll = noop,
  handleScroll = noop,
  onPageSizeChange = noop,
  onPageNumberChange = noop,
  onSortChange = noop,
  onSelectionChange = noop,
  onReady = noop,
  compactScroll = false,
}) => {
  const theme = useTheme();
  const [state, setState] = useState(defaultState);
  const columnRefs = [];

  const classes = useStyles();
  const handleSelectVisibleChange = (event) => {
    const isChecked = event.currentTarget.checked;
    let selectedRows = [];
    if (isChecked) {
      selectedRows = rows
        .filter((row) => !row.preventSelection)
        .map((row) => row.id);
    }

    onSelectionChange(selectedRows);
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  };

  const handleSortClick = (columnId) => {
    const newOrder = order === "ASC" ? "DESC" : "ASC";
    const column = columns.find((column) => column.id === columnId);
    onSortChange(column, newOrder);
  };

  useEffect(() => {
    let timer;
    if (!isInfiniteLoading && hasMoreEntries === 0 && isLazyLoadingAdded) {
      setState((prevState) => ({
        ...prevState,
        showNoDataMessage: true,
      }));
      timer = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          showNoDataMessage: false,
        }));
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isInfiniteLoading, hasMoreEntries]);

  const handleSelection = (rowId) => {
    setState((prevState) => {
      const existingSelectionIndex = prevState.selectedRows.indexOf(rowId);
      let selectedRows = prevState.selectedRows.slice();
      if (existingSelectionIndex === -1) {
        selectedRows.push(rowId);
      } else {
        selectedRows.splice(existingSelectionIndex, 1);
      }

      onSelectionChange(selectedRows);

      return {
        ...prevState,
        selectedRows,
      };
    });
  };

  useEffect(() => {
    if (state.selectedRows.length === 0 && selectedCustomerRows.length > 0) {
      setState((prevState) => ({
        ...prevState,
        selectedRows: selectedCustomerRows,
      }));
    } else if (state.selectedRows.length === 0 && selectedICRows.length > 0) {
      setState((prevState) => ({
        ...prevState,
        selectedRows: selectedICRows,
      }));
    }
  }, [selectedCustomerRows, selectedICRows, state.selectedRows]);

  const handlePageChange = (event, pageNumber) => {
    event.stopPropagation();
    onPageNumberChange(pageNumber + 1);
    // Reset selectedRows to empty array to clear selection when page changes
    setState((prevState) => ({
      ...prevState,
      selectedRows: [],
    }));
  };

  const handlePageSizeChange = (event) => {
    onPageSizeChange(event.target.value);
  };

  const createSelectionHandler = (rowId) => {
    return (event) => {
      handleSelection(rowId, event);
    };
  };

  if (hasLoader && isLoading) {
    return <Loader columns={columns.length} />;
  }

  if (!isLoading && rows.length === 0) {
    return (
      <div
        className="d-flex f-justify-center"
        style={{ height: compactScroll && 325 }}
      >
        {!!noRecordLabel ? (
          <div
            className={clsx("d-flex f-align-center", classes.noRecordsLable)}
          >
            <Typography variant="h6">{noRecordLabel}</Typography>
          </div>
        ) : (
          <img
            className={clsx(
              passedClasses.noRecordsImg,
              compactScroll ? classes.noRecordsImgCompact : classes.noRecordsImg
            )}
            src={
              theme && theme.palette.type === "dark" ? NoRecordsDark : NoRecords
            }
          />
        )}
      </div>
    );
  }

  const selectAllPaginationAction =
    hasSelection && hasSelectMultiple && hasSelectAll
      ? [
          <div
            className="d-flex f-align-center f-justify-start"
            key={"select-all"}
            // sx={{
            //   mt: 2,
            //   ...globalStyles.display.flex,
            //   ...globalStyles.flex.align.center,
            // }}
          >
            <Button
              variant={state.areAllSelected ? "contained" : "outlined"}
              color="primary"
              className="mr-2"
              // sx={{
              //   ml: 5,
              //   mr: 5,
              //   ...style.paginationItems,
              //   ...globalStyles.text.color.primary,
              //   ...(state.areAllSelected
              //     ? globalStyles.background.color.primary.main
              //     : {}),
              //   ...(state.areAllSelected
              //     ? globalStyles.text.color.white
              //     : {}),
              // }}
              disableRipple
              onClick={() => {
                onSelectAll(!state.areAllSelected);
                setState((prevState) => ({
                  ...prevState,
                  areAllSelected: !prevState.areAllSelected,
                  selectedRows: !state.areAllSelected
                    ? rows.map((row) => row.id)
                    : [],
                }));
              }}
              disabled={disabled}
            >
              Select All
            </Button>
            {(state.areAllSelected || state.selectedRows.length > 0) && (
              <Typography
                // sx={{
                //   ml: 3,
                //   ...globalStyles.text.color.primary,
                //   ...globalStyles.display.inlineBlock,
                // }}
                disabled={disabled}
                variant="body2"
              >
                {state.areAllSelected
                  ? "All Entries "
                  : state.selectedRows.length > 1
                  ? `${state.selectedRows.length} Entries `
                  : "1 Entry "}
                Selected
              </Typography>
            )}
          </div>,
        ]
      : [];

  onReady({
    resetAllSelection: () =>
      setState((prevState) => ({ ...prevState, areAllSelected: false })),
    resetSelection: () =>
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
      })),
    setSelection: (selectedRows) =>
      setState((prevState) => ({
        ...prevState,
        selectedRows,
      })),
    getSelection: () => state.selectedRows,
  });

  return (
    <div className={(classes.root, classes.tableBottonMargin)}>
      {filterConfig.isFiltering ? (
        <FilterToolbar
          {...filterConfig}
          numSelected={state.selectedRows.length}
          actions={{
            left: actionBarConfig
              ? actionBarConfig.left.filter(
                  (action) =>
                    action.view === GRID_ACTION_VIEW.FILTER ||
                    action.view === GRID_ACTION_VIEW.ALL ||
                    action.view === GRID_ACTION_VIEW.FILTER_SELECTION
                )
              : [],
            right: actionBarConfig
              ? actionBarConfig.right.filter(
                  (action) =>
                    action.view === GRID_ACTION_VIEW.FILTER ||
                    action.view === GRID_ACTION_VIEW.ALL ||
                    action.view === GRID_ACTION_VIEW.FILTER_SELECTION
                )
              : [],
          }}
        />
      ) : (
        actionBarConfig && (
          <ActionToolbar
            config={actionBarConfig}
            numSelected={state.selectedRows.length}
          />
        )
      )}
      <TableContainer
        ref={containerRef}
        onScroll={handleScroll}
        className={clsx(
          compactScroll ? classes.compactScroll : passedClasses.container,
          {
            [classes.container]: rows.length > 0,
          }
        )}
      >
        <Table stickyHeader className={classes.borderCollapse}>
          <TableHead>
            <TableRow className={clsx(classes.row, passedClasses.header)}>
              {hasSelection && (
                <TableCell padding="checkbox">
                  {hasSelectMultiple && (
                    <Checkbox
                      indeterminate={
                        state.selectedRows.length > 0 &&
                        state.selectedRows.length < rows.length
                      }
                      checked={
                        rows.length > 0 &&
                        ((state.selectedRows.length > 0 &&
                          state.selectedRows.length ===
                            rows.filter((row) => !row.preventSelection)
                              .length) ||
                          state.areAllSelected)
                      }
                      disabled={disabled}
                      onChange={handleSelectVisibleChange}
                      inputProps={{ "aria-label": "select visible" }}
                    />
                  )}
                </TableCell>
              )}
              {columns.map((column, columnIndex) => {
                if (column.isHidden) {
                  return null;
                }
                columnRefs[columnIndex] = createRef();
                let content = <span></span>;
                if (column.renderHeader) {
                  content = column.renderHeader();
                } else {
                  content = column.canSort ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      key={column.id}
                      direction={
                        orderBy === column.id
                          ? (order || "").toLowerCase()
                          : "asc"
                      }
                      disabled={isFormVisible}
                      onClick={() => handleSortClick(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <span>{column.label}</span>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? "right" : "left"}
                    padding={column.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === column.id ? order : false}
                    className={clsx(
                      column.showRightBorder && classes.showRightBorder,
                      classes.tableCell,
                      classes.tableHeadCell,
                      classes.tableHeadPadding,
                      column.headerClassName
                    )}
                    ref={columnRefs[columnIndex]}
                  >
                    <Tooltip
                      title={column.tooltip || column.label}
                      placement={column.tooltipPlacement || "top-start"}
                    >
                      <div className="d-flex f-justify-between">
                        {content}
                        {!column.noResize && (
                          <div
                            className={clsx(
                              "d-flex f-justify-between f-align-center p-absolute",
                              classes.resizeIconWrapper
                            )}
                            data-role="resize"
                            onMouseDown={(event) => {
                              if (
                                columnRefs[columnIndex] &&
                                columnRefs[columnIndex].current
                              ) {
                                const element = columnRefs[columnIndex].current;
                                document.onmouseup = () => {
                                  document.onmousemove = null;
                                };

                                document.onmousemove = (() => {
                                  const initialCursorX = event.pageX;
                                  const initialWidth = element.offsetWidth;
                                  return (event) => {
                                    if (initialCursorX) {
                                      const diffX =
                                        event.pageX - initialCursorX;
                                      element.style.minWidth = `${
                                        initialWidth + diffX
                                      }px`;
                                      element.style.maxWidth = `${
                                        initialWidth + diffX
                                      }px`;
                                    }
                                  };
                                })();
                              }
                            }}
                            onMouseUp={() => {
                              console.log("Fired local");
                              document.onmousemove = null;
                            }}
                          >
                            <div className={classes.resizeIcon} />
                          </div>
                        )}
                      </div>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && rows.length === 0 && (
              <TableCell colSpan={columns.length}>
                <div className="d-flex f-justify-center">
                  <img
                    className={classes.noRecordsImg}
                    src={
                      theme && theme.palette.type === "dark"
                        ? NoRecordsDark
                        : NoRecords
                    }
                  />
                </div>
              </TableCell>
            )}
            {rows.map((row, rowIndex) => {
              if (row.renderer) {
                return row.renderer(row, rowIndex);
              }
              let content = <></>;
              const selectionHandler = createSelectionHandler(row.id);
              if (rows.render) {
                content = rows.render();
              } else {
                const labelId = `table-checkbox-${rowIndex}`;
                content = (
                  <>
                    {hasSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            state.selectedRows.indexOf(row.id) !== -1 ||
                            state.areAllSelected
                          }
                          inputProps={{ "aria-labelledby": labelId }}
                          onChange={selectionHandler}
                          disabled={isFormVisible || row.preventSelection}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, columnIndex) => {
                      if (column.isHidden) {
                        return null;
                      }
                      let content = (
                        <Typography
                          className="d-inline-block w-100 d-flex f-align-center"
                          variant="body2"
                          component="span"
                          noWrap={column.hasEllipses}
                        >
                          {`${
                            row[column.field] === null ||
                            row[column.field] === undefined
                              ? ""
                              : column.startAdornment ?? ""
                          }${row[column.field] || column.placeholder || "-"}${
                            row[column.field] === null ||
                            row[column.field] === undefined
                              ? ""
                              : column.endAdornment ?? ""
                          }`}
                        </Typography>
                      );
                      if (column.render) {
                        content = column.render(row, rowIndex);
                      }

                      return (
                        <TableCell
                          className={clsx(
                            column.showRightBorder && classes.showRightBorder,
                            classes.tableCell,
                            classes.tableHeadPadding,
                            column.className,
                            row.classes && row.classes[column.field]
                          )}
                          key={`${
                            column.label || "grid-column"
                          }-${columnIndex}${row.id}`}
                        >
                          {column.render ? (
                            <>{content}</>
                          ) : (
                            <Tooltip
                              title={row[column.field] || ""}
                              placement={
                                column.dataTooltipPlacement || "top-start"
                              }
                            >
                              {content}
                            </Tooltip>
                          )}
                        </TableCell>
                      );
                    })}
                  </>
                );
              }
              return (
                <TableRow
                  hover
                  key={row.id}
                  ref={
                    (!isDCNScreen &&
                      row.id === boxborder &&
                      row.id !== null &&
                      boxborder !== null) ||
                    (isDCNScreen &&
                      boxborder === row?.ICID &&
                      row.id !== null &&
                      boxborder !== null)
                      ? selectedRef
                      : null
                  }
                  className={clsx(
                    classes.row,
                    passedClasses.row,
                    row.className,
                    {
                      [classes.boxborder]: isDCNScreen
                        ? boxborder === row?.ICID &&
                          row.id !== null &&
                          boxborder !== null
                        : row.id === boxborder &&
                          row.id !== null &&
                          boxborder !== null,
                    }
                  )}
                  {...rowEvents.reduce(
                    (acc, { type, handler }) => ({
                      ...acc,
                      [type]: () => handler(row, rowIndex),
                    }),
                    {}
                  )}
                >
                  {content}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {isLazyLoadingAdded && isInfiniteLoading && (
          <Box className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8">
            <Typography color="primary" variant="body1">
              Loading more data...
            </Typography>
            <CircularProgress />
          </Box>
        )}

        {isLazyLoadingAdded &&
          state.showNoDataMessage &&
          hasMoreEntries === 0 && (
            <Box className="d-flex f-align-center f-justify-center pl-2 pr-4 pt-8 pb-8">
              <Typography color="primary" variant="body1">
                No data found...
              </Typography>
            </Box>
          )}
      </TableContainer>
      {hasPagination && !!rows.length && (
        <div className="d-flex">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={pageSize}
            colSpan={3}
            page={pageNumber - 1}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            ActionsComponent={PaginationActions}
            classes={{
              root: clsx({
                disabled: isFormVisible,
              }),
              select: "d-flex f-align-center f-justify-end",
            }}
          />
          {[...selectAllPaginationAction, ...paginationActions].map(
            (action) => action
          )}
        </div>
      )}
    </div>
  );
};

export default Grid;
