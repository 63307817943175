import React, { useEffect, useState } from "react";
import { ActionDialog, Dialog } from "shared/components";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import useStyles from "./style";
import { Autocomplete } from "@material-ui/lab";
import { getDateString, getFormattedTime, getUTCDateString } from "utils";
import { APX_COMPANY, noop, VALIDATIONS } from "shared/constants";
import { preventInputKeyCodes, validator } from "utils";
import clsx from "clsx";
import PostAddIcon from "@material-ui/icons/PostAdd";
import WarningIcon from "@material-ui/icons/Warning";
import FlagIcon from "@material-ui/icons/Flag";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { RATE_TYPES } from "modules/shared/constants";

const defaultState = {
  billRateTypeValue: {},
  billRateValue: 0,
  billQuantityValue: 0,
  billAmountValue: 0,
  billFuelAmountValue: 0,
  billTollAmountValue: 0,
  billExtraMilesValue: 0,
  billExtraMileAmountValue: 0,
  payRateTypeValue: {},
  payRateValue: 0,
  payQuantityValue: 0,
  payAmountValue: 0,
  payFuelAmountValue: 0,
  payTollAmountValue: 0,
  payExtraMileAmountValue: 0,
  payExtraMilesValue: 0,
  incentiveValue: 0,
  reviewNotesValue: "",
  saveInfoWarning: false,
  errors: {
    billRateValue: " ",
    billQuantityValue: " ",
    billTollAmountValue: " ",
    payRateValue: " ",
    payQuantityValue: " ",
    payTollAmountValue: " ",
    incentiveValue: " ",
  },
  dontBill: {
    value: false,
    notes: null,
  },
  dontPay: {
    value: false,
    notes: null,
  },
  dontBillMiles: {
    value: false,
    notes: null,
  },
  dontPayMiles: {
    value: false,
    notes: null,
  },
  dontBillTolls: {
    value: false,
    notes: null,
  },
  dontPayTolls: {
    value: false,
    notes: null,
  },
  confirmDontBill: false,
  confirmDontBillValue: false,
  confirmDontBillExtraMiles: false,
  confirmDontBillExtraMilesValue: false,
  confirmDontBillToll: false,
  confirmDontBillTollValue: false,
  confirmDontPay: false,
  confirmDontPayValue: false,
  confirmDontPayExtraMiles: false,
  confirmDontPayExtraMilesValue: false,
  confirmDontPayToll: false,
  confirmDontPayTollValue: false,
};

let isFieldChange = false;

const Form = ({
  open,
  onClose = noop,
  row = null,
  appData,
  appDataList,
  isRoleView,
  isRoleMGR,
  handleLabelClick = noop,
  userInfo = noop,
  userFlagInfo = noop,
  handleShowAddLookupDialog = noop,
  reviewNotesOptions = [],
  renderGridActions = noop,
  onConfirm = noop,
  events = {},
  priceMatrix = [],
  formEvents = {},
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const updatedRow = row.filter((entry) => entry.keyType === "Updated")[0];
  const originalRow = row.filter((entry) => entry.keyType === "original")[0];

  const handleClose = () => {
    isFieldChange = false;
    onClose();
  };

  useEffect(() => {
    if (row) {
      setState((prevState) => ({
        ...prevState,

        billRateTypeValue:
          updatedRow.billing_and_settlement?.bill_rate_type || "-",
        billRateValue: updatedRow.billing_and_settlement?.bill_rate,
        billQuantityValue: updatedRow.billing_and_settlement?.bill_quantity,
        billAmountValue: updatedRow.billing_and_settlement?.bill_amount,
        billFuelAmountValue:
          updatedRow.billing_and_settlement?.bill_fuel_amount,
        billTollAmountValue:
          updatedRow.billing_and_settlement?.bill_toll_amount,
        billExtraMilesValue: updatedRow.billing_and_settlement.bill_extra_miles,
        billExtraMileAmountValue:
          updatedRow.billing_and_settlement?.bill_extra_mile_amount,
        payRateTypeValue: updatedRow.billing_and_settlement?.pay_rate_type,
        payRateValue: updatedRow.billing_and_settlement?.pay_rate,
        payQuantityValue: updatedRow.billing_and_settlement?.pay_quantity,
        payAmountValue: updatedRow.billing_and_settlement?.pay_amount,
        payFuelAmountValue: updatedRow.billing_and_settlement?.pay_fuel_amount,
        payTollAmountValue: updatedRow.billing_and_settlement?.pay_toll_amount,
        payExtraMilesValue: updatedRow.billing_and_settlement.pay_extra_miles,
        payExtraMileAmountValue:
          updatedRow.billing_and_settlement.pay_extra_mile_amount,
        incentiveValue: updatedRow.billing_and_settlement?.reimbursement
          ? (+updatedRow.billing_and_settlement.reimbursement).toFixed(2)
          : defaultState.incentiveValue,
        reviewNotesValue: updatedRow.billing_and_settlement?.sharedNotes,
        dontBill: {
          ...prevState.dontBill,
          value: updatedRow.billing_and_settlement?.dont_bill,
          notes: !!updatedRow.billing_and_settlement?.dont_bill ? (
            updatedRow.billing_and_settlement?.dont_bill_notes === null ||
            updatedRow.billing_and_settlement?.dont_bill_notes === "" ? (
              "This record will not be included in billing"
            ) : (
              <>
                This record will not be included in billing.
                <br />
                Reason : {updatedRow.billing_and_settlement?.dont_bill_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontPay: {
          ...prevState.dontPay,
          value: updatedRow.billing_and_settlement?.dont_pay,
          notes: !!updatedRow.billing_and_settlement?.dont_pay ? (
            updatedRow.billing_and_settlement?.dont_pay_notes === null ||
            updatedRow.billing_and_settlement?.dont_pay_notes === "" ? (
              "This record will not be included in settlement"
            ) : (
              <>
                This record will not be included in settlement.
                <br />
                Reason : {updatedRow.billing_and_settlement?.dont_pay_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontBillMiles: {
          ...prevState.dontBillMiles,
          value: updatedRow.billing_and_settlement?.dont_bill_miles,
          notes: !!updatedRow.billing_and_settlement?.dont_bill_miles ? (
            updatedRow.billing_and_settlement?.dont_bill_miles_notes === null ||
            updatedRow.billing_and_settlement?.dont_bill_miles_notes === "" ? (
              "This record will not be included in billing."
            ) : (
              <>
                This record will not be included in billing.
                <br />
                Reason :{" "}
                {updatedRow.billing_and_settlement.dont_bill_miles_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontPayMiles: {
          ...prevState.dontPayMiles,
          value: updatedRow.billing_and_settlement.dont_pay_miles,
          notes: !!updatedRow.billing_and_settlement.dont_pay_miles ? (
            updatedRow.billing_and_settlement?.dont_pay_miles_notes === null ||
            updatedRow.billing_and_settlement?.dont_pay_miles_notes === "" ? (
              "This record will not be included in settlement."
            ) : (
              <>
                This record will not be included in settlement.
                <br />
                Reason :{" "}
                {updatedRow.billing_and_settlement.dont_pay_miles_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontBillTolls: {
          ...prevState.dontBillTolls,
          value: updatedRow.billing_and_settlement.dont_bill_tolls,
          notes: !!updatedRow.billing_and_settlement.dont_bill_tolls ? (
            updatedRow.billing_and_settlement?.dont_bill_tolls_notes === null ||
            updatedRow.billing_and_settlement?.dont_bill_tolls_notes === "" ? (
              "This record will not be included in billing."
            ) : (
              <>
                This record will not be included in billing.
                <br />
                Reason :{" "}
                {updatedRow.billing_and_settlement.dont_bill_tolls_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontPayTolls: {
          ...prevState.dontPayTolls,
          value: updatedRow.billing_and_settlement.dont_pay_tolls,
          notes: !!updatedRow.billing_and_settlement.dont_pay_tolls ? (
            updatedRow.billing_and_settlement?.dont_pay_tolls_notes === null ||
            updatedRow.billing_and_settlement?.dont_pay_tolls_notes === "" ? (
              "This record will not be included in settlement."
            ) : (
              <>
                This record will not be included in settlement.
                <br />
                Reason :{" "}
                {updatedRow.billing_and_settlement.dont_pay_tolls_notes}
              </>
            )
          ) : (
            ""
          ),
        },
      }));
    }
  }, [row]);

  useEffect(() => {
    const isAPXCompany =
      updatedRow.billing_and_settlement &&
      updatedRow.billing_and_settlement?.customer_branch?.pdxCompany?.value ===
        APX_COMPANY;
    const maxQuantity = isAPXCompany ? 10000 : 24;
    if (state.payRateTypeValue === "Daily" && +state.payQuantityValue > 1.99) {
      const errorMessage = validate(
        "payQuantityValue",
        +state.payQuantityValue
      );
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            payQuantityValue: errorMessage,
          },
        }));
      }
    } else if (
      state.payRateTypeValue === "Hourly" &&
      +state.payQuantityValue > maxQuantity
    ) {
      const errorMessage = validate(
        "payQuantityValue",
        +state.payQuantityValue
      );
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            payQuantityValue: errorMessage,
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          payQuantityValue: " ",
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.payRateTypeValue, state.payQuantityValue]);

  useEffect(() => {
    const isAPXCompany =
      updatedRow.billing_and_settlement &&
      updatedRow.billing_and_settlement?.customer_branch?.pdxCompany?.value ===
        APX_COMPANY;
    const maxQuantity = isAPXCompany ? 10000 : 24;
    if (
      state.billRateTypeValue === "Daily" &&
      +state.billQuantityValue > 1.99
    ) {
      const errorMessage = validate(
        "billQuantityValue",
        +state.billQuantityValue
      );
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            billQuantityValue: errorMessage,
          },
        }));
      }
    } else if (
      state.billRateTypeValue === "Hourly" &&
      +state.billQuantityValue > maxQuantity
    ) {
      const errorMessage = validate(
        "billQuantityValue",
        +state.billQuantityValue
      );
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            billQuantityValue: errorMessage,
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          billQuantityValue: " ",
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.billRateTypeValue, state.billQuantityValue]);

  const getFieldValidatorMap = (state, field) => {
    const { billRateTypeValue, payRateTypeValue } = state;
    const fieldValidatorMap = {
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],

      billRateValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      billQuantityValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      billTollAmountValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      payRateValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      payQuantityValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      payTollAmountValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      incentiveValue: [{ type: VALIDATIONS.MIN, value: 0 }],
    };

    if (billRateTypeValue === "Hourly") {
      const isAPXCompany =
        updatedRow.billing_and_settlement &&
        updatedRow.billing_and_settlement?.customer_branch?.pdxCompany
          ?.value === APX_COMPANY;
      fieldValidatorMap["billQuantityValue"] = [
        {
          type: VALIDATIONS.MAX,
          value: isAPXCompany ? 10000 : 24,
        },
        ...fieldValidatorMap["billQuantityValue"],
      ];
    }

    if (billRateTypeValue === "Daily") {
      fieldValidatorMap["billQuantityValue"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["billQuantityValue"] || []),
      ];
    }

    if (payRateTypeValue === "Hourly") {
      const isAPXCompany =
        updatedRow.billing_and_settlement &&
        updatedRow.billing_and_settlement?.customer_branch?.pdxCompany
          ?.value === APX_COMPANY;
      fieldValidatorMap["payQuantityValue"] = [
        {
          type: VALIDATIONS.MAX,
          value: isAPXCompany ? 10000 : 24,
        },
        ...fieldValidatorMap["payQuantityValue"],
      ];
    }

    if (payRateTypeValue === "Daily") {
      fieldValidatorMap["payQuantityValue"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["payQuantityValue"] || []),
      ];
    }

    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }

    if (field === "billQuantityValue") {
      setState((prevState) => ({
        ...prevState,
        billQuantityValue: value,
        billAmountValue: (value * state.billRateValue).toFixed(2),
        billFuelAmountValue: (
          state.billRateValue *
          (updatedRow.billing_and_settlement.bill_fuel_surcharge / 100)
        ).toFixed(2),
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    } else if (field === "payQuantityValue") {
      setState((prevState) => ({
        ...prevState,

        payQuantityValue: value,
        payAmountValue: (value * state.payRateValue).toFixed(2),
        payFuelAmountValue: (
          state.billRateValue *
          (updatedRow.billing_and_settlement.bill_fuel_surcharge / 100)
        ).toFixed(2),
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    } else if (field === "billRateValue") {
      setState((prevState) => ({
        ...prevState,

        billRateValue: value,
        billAmountValue: (value * state.billQuantityValue).toFixed(2),
        billFuelAmountValue: (
          state.billAmountValue *
          (updatedRow.billing_and_settlement.bill_fuel_surcharge / 100)
        ).toFixed(2),
      }));
    } else if (field === "payRateValue") {
      setState((prevState) => ({
        ...prevState,
        payRateValue: value,
        payAmountValue: (value * state.payQuantityValue).toFixed(2),
        payFuelAmountValue: (
          state.payAmountValue *
          (updatedRow.billing_and_settlement.pay_fuel_surcharge / 100)
        ).toFixed(2),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    }
  };

  const formatNumber = (value) => {
    const num = +value;

    if (isNaN(num)) return value;

    return num.toFixed(2);
  };

  const handleSubmit = () => {
    isFieldChange = false;
    if (
      state.billRateTypeValue !==
      updatedRow.billing_and_settlement?.bill_rate_type
    ) {
      formEvents.handleBillRateTypeUpdate(updatedRow, state.billRateTypeValue);
    }

    if (state.billRateValue !== updatedRow.billing_and_settlement?.bill_rate) {
      setState((prevState) => ({
        ...prevState,
        billRateValue: formatNumber(prevState.billRateValue),
      }));
      if (row.billing_and_settlement?.routeDetails?.price_type === "Matrix") {
        formEvents.handleBillRateUpdateMatrix(
          updatedRow,
          formatNumber(state.billRateValue)
        );
      } else {
        formEvents.handleBillRateUpdate(
          updatedRow,
          formatNumber(state.billRateValue)
        );
      }
    }

    if (
      state.billQuantityValue !==
      updatedRow.billing_and_settlement?.bill_quantity
    ) {
      setState((prevState) => ({
        ...prevState,
        billQuantityValue: formatNumber(prevState.billQuantityValue),
      }));
      formEvents.handleBillQuantityUpdate(
        updatedRow,
        formatNumber(state.billQuantityValue)
      );
    }

    if (
      state.billTollAmountValue !==
      updatedRow.billing_and_settlement?.bill_toll_amount
    ) {
      setState((prevState) => ({
        ...prevState,
        billTollAmountValue: formatNumber(prevState.billTollAmountValue),
      }));
      formEvents.handleBillTollAmountUpdate(
        updatedRow,
        formatNumber(state.billTollAmountValue)
      );
    }

    if (
      state.payRateTypeValue !==
      updatedRow.billing_and_settlement?.pay_rate_type
    ) {
      formEvents.handlePayRateTypeUpdate(
        updatedRow,
        state.payRateTypeValue,
        state.payRateValue !== updatedRow.billing_and_settlement?.pay_rate
          ? state.payRateValue
          : null
      );
    }

    if (state.payRateValue !== updatedRow.billing_and_settlement?.pay_rate) {
      setState((prevState) => ({
        ...prevState,
        payRateValue: formatNumber(prevState.payRateValue),
      }));
      if (row.billing_and_settlement?.routeDetails?.price_type === "Matrix") {
        formEvents.handlePayRateUpdateMatrix(
          updatedRow,
          formatNumber(state.payRateValue)
        );
      } else {
        formEvents.handlePayRateUpdate(
          updatedRow,
          formatNumber(state.payRateValue)
        );
      }
    }

    if (
      state.payQuantityValue !== updatedRow.billing_and_settlement?.pay_quantity
    ) {
      setState((prevState) => ({
        ...prevState,
        payQuantityValue: formatNumber(prevState.payQuantityValue),
      }));
      formEvents.handlePayQuantityUpdate(
        updatedRow,
        formatNumber(state.payQuantityValue)
      );
    }

    if (
      state.payTollAmountValue !==
      updatedRow.billing_and_settlement.pay_toll_amount
    ) {
      setState((prevState) => ({
        ...prevState,
        payTollAmountValue: formatNumber(prevState.payTollAmountValue),
      }));
      formEvents.handlePayTollAmountUpdate(
        updatedRow,
        formatNumber(state.payTollAmountValue)
      );
    }

    if (
      state.incentiveValue !== updatedRow.billing_and_settlement?.reimbursement
    ) {
      setState((prevState) => ({
        ...prevState,
        incentiveValue: formatNumber(prevState.incentiveValue),
      }));
      formEvents.handleIncentiveUpdate(
        updatedRow,
        formatNumber(state.incentiveValue)
      );
    }
    if (
      state.reviewNotesValue !== updatedRow.billing_and_settlement?.sharedNotes
    ) {
      formEvents.handleReviewNotesChange(updatedRow, state.reviewNotesValue);
    }

    if (state.dontBill.value !== updatedRow.billing_and_settlement?.dont_bill) {
      onConfirm(
        state.dontBill.value,
        updatedRow.id,
        updatedRow.keyType,
        events.calculateBill
      );
    }
    if (state.dontPay.value !== updatedRow.billing_and_settlement?.dont_pay) {
      onConfirm(
        state.dontPay.value,
        updatedRow.id,
        updatedRow.keyType,
        events.calculatePay
      );
    }
    if (
      state.dontBillMiles.value !==
      updatedRow.billing_and_settlement?.dont_bill_miles
    ) {
      onConfirm(
        state.dontBillMiles.value,
        updatedRow.id,
        updatedRow.keyType,
        events.calculateBillMiles
      );
    }
    if (
      state.dontPayMiles.value !==
      updatedRow.billing_and_settlement?.dont_pay_miles
    ) {
      onConfirm(
        state.dontPayMiles.value,
        updatedRow.id,
        updatedRow.keyType,
        events.calculatePayMiles
      );
    }
    if (
      state.dontBillTolls.value !==
      updatedRow.billing_and_settlement?.dont_bill_tolls
    ) {
      onConfirm(
        state.dontBillTolls.value,
        updatedRow.id,
        updatedRow.keyType,
        events.calculateBillToll
      );
    }
    if (
      state.dontPayTolls.value !==
      updatedRow.billing_and_settlement?.dont_pay_tolls
    ) {
      onConfirm(
        state.dontPayTolls.value,
        updatedRow.id,
        updatedRow.keyType,
        events.calculatePayToll
      );
    }
  };

  const handleNoteChange = () => {
    isFieldChange = false;
    if (
      state.reviewNotesValue !== updatedRow.billing_and_settlement?.sharedNotes
    ) {
      formEvents.handleReviewNotesChange(updatedRow, state.reviewNotesValue);
    }
  };

  const IcidContent = ({ rowData }) => {
    let title = !!rowData.billing_and_settlement?.individual_contractor
      ? `
          IC Settlement Company Name: ${
            rowData.billing_and_settlement?.settlementCoName || "-"
          }, 
       
          ICID: ${
            rowData.billing_and_settlement?.individual_contractor?.icid || "-"
          },
          IC Name: ${
            rowData.billing_and_settlement?.individual_contractor?.last_name ||
            ""
          } ${
          rowData.billing_and_settlement?.individual_contractor?.first_name ||
          ""
        },Contractor Type: ${
          rowData.billing_and_settlement?.individual_contractor
            ?.contractorType || ""
        }
        `
      : "";
    if (
      rowData.billing_and_settlement?.individual_contractor_changed &&
      rowData.billing_and_settlement?.original_individual_contractor_id
    ) {
      const orginalIC = appData.icList.find(
        (contractor) =>
          contractor?.id ===
          rowData.billing_and_settlement?.original_individual_contractor_id
      );
      if (orginalIC) {
        title += `, Original IC Name: ${orginalIC.last_name || ""} ${
          orginalIC.first_name || ""
        }`;
      }
    }
    const label = !!rowData.billing_and_settlement?.individual_contractor
      ? `${rowData.billing_and_settlement?.individual_contractor?.icid || ""}/${
          rowData.billing_and_settlement?.individual_contractor?.last_name || ""
        } ${
          rowData.billing_and_settlement?.individual_contractor?.first_name ||
          ""
        }`
      : "-";

    const flagLabel =
      (!!rowData.billing_and_settlement?.individual_contractor?.is_flagged &&
        !!rowData.billing_and_settlement?.individual_contractor
          ?.is_cautious_flagged) ||
      !!rowData.billing_and_settlement?.individual_contractor?.is_flagged ? (
        <FlagIcon style={{ color: "red" }} />
      ) : rowData.billing_and_settlement?.individual_contractor
          ?.is_cautious_flagged ? (
        <div className={classes.warningIconHeight}>
          <WarningIcon className={classes.warningIcon} />
          <div className={classes.exclamationMark}></div>
        </div>
      ) : (
        ""
      );

    const cautionFlaggedBy = userInfo(
      rowData.billing_and_settlement?.individual_contractor?.ctn_flag_by
    );
    const cautionFlaggedReason = userFlagInfo(
      rowData.billing_and_settlement?.individual_contractor?.ctn_flag_rsn
    );

    const flagTitles = !rowData.billing_and_settlement
      ? "-"
      : `Flagged Reason: ${
          rowData.billing_and_settlement?.individual_contractor?.flagReason
            ?.value || cautionFlaggedReason?.value
        }, Flagged By: ${
          rowData.billing_and_settlement?.individual_contractor?.flaggedBy
            ?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          rowData.billing_and_settlement?.individual_contractor?.flaggedBy
            ?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          rowData.billing_and_settlement?.individual_contractor?.flaggedBy
            ?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          rowData.billing_and_settlement?.individual_contractor?.flaggedBy
            ?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          rowData.billing_and_settlement?.individual_contractor?.flagged_at ||
            rowData?.individual_contractor?.ctn_flagged_at
        )}`;
    return (
      <>
        <TextField
          value={label || "-"}
          className={classes.field}
          label="ICID/IC Name"
          variant={rowData.keyType === "original" ? "filled" : "outlined"}
          size="small"
          helperText=" "
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={flagTitles} placement="top-start">
                  <IconButton edge="start" size="small">
                    {flagLabel}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="View Info" placement="top-start">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => {
                      !!rowData.billing_and_settlement?.individual_contractor &&
                        handleLabelClick(rowData);
                    }}
                    style={{
                      cursor: !!rowData.billing_and_settlement
                        ?.individual_contractor
                        ? "pointer"
                        : "default",
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            inputComponent: ({ inputRef, ...props }) => (
              <Tooltip title={title ?? ""} placement="top-start">
                <div
                  ref={inputRef}
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    textOverflow: "ellipsis",
                    cursor: "default",
                    color: "rgba(0, 0, 0, 0.4)",
                  }}
                >
                  {label || "-"}
                </div>
              </Tooltip>
            ),
          }}
        />
      </>
    );
  };

  const ReviewNotesContent = ({ rowData }) => {
    let finalNotes;

    if (rowData.notesRow?.review_notes !== null) {
      let splitNotes = rowData.notesRow?.review_notes?.split("||");

      finalNotes = splitNotes?.map((note) => {
        let str = note;

        const startIndexOfDate = str?.indexOf("-");
        const lastIndexOfDate = str?.indexOf(": ");
        const formattedDate = new Date(
            str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
          )?.toLocaleString(),
          str1 =
            str.substring(0, startIndexOfDate) +
            "-" +
            formattedDate +
            str.substring(lastIndexOfDate);
        return str1;
      });
    }

    const title = (
      <div
        dangerouslySetInnerHTML={{
          __html: (finalNotes || []).join("<br>"),
        }}
      />
    );

    let lastNote = !!finalNotes ? finalNotes[0] : "";
    let x = lastNote.split(": ");
    const userName = x[0].split("-")[0];
    const noteTime = x[0].split("-")[1]?.split(",");
    delete x[0];
    const message = x.join(": ");

    return (
      <div>
        {rowData.keyType !== "original" ? (
          <div>
            {!isRoleView && (
              <div
                className="d-flex f-align-center mt-1"
                style={{ width: 290, gap: "4px" }}
              >
                <Autocomplete
                  required
                  fullWidth
                  size="small"
                  freeSolo
                  // className={classes.addNewReviewNote}
                  className={classes.field}
                  options={reviewNotesOptions}
                  getOptionLabel={(option) => {
                    if (option.value) {
                      return option.value;
                    }
                    return option;
                  }}
                  disableClearable
                  value={
                    (rowData.keyType !== "original"
                      ? state.reviewNotesValue
                      : state.original.reviewNotesValue) || ""
                  }
                  onChange={(event, value) => {
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,

                      reviewNotesValue:
                        (typeof value === "string" ? value : value?.value) ||
                        "",
                    }));
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      setState((prevState) => ({
                        ...prevState,

                        reviewNotesValue: newInputValue,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      // margin="normal"
                      variant="outlined"
                      label="Add New Review Note"
                      helperText=" "
                    />
                  )}
                />

                {!isRoleMGR && (
                  <Tooltip
                    title="Add To Lookup(Review Notes)"
                    placement="top-end"
                  >
                    <Button
                      type="submit"
                      onClick={() => handleShowAddLookupDialog(rowData.id)}
                      variant="contained"
                      color="primary"
                      disabled={!state.reviewNotesValue}
                      className={classes.buttonStyle}
                    >
                      <PostAddIcon />
                    </Button>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ) : !finalNotes || !userName ? (
          <TextField
            value="-"
            className={clsx("mb-3", classes.field)}
            variant={rowData.keyType === "original" ? "filled" : "outlined"}
            size="small"
            helperText=" "
            label="Review Notes"
            InputProps={{
              classes: { disabled: classes.disabledText },
            }}
            fullWidth
          />
        ) : (
          <Tooltip title={title || "-"} placement="top-start">
            <TextField
              value={`${userName} ${noteTime[0]}, ${noteTime[1]}: ${
                message.length > 50
                  ? message.slice(2, 50) + "..."
                  : message.slice(2, 100)
              }`}
              label="Review Notes"
              InputProps={{
                classes: { disabled: classes.disabledText },
              }}
              fullWidth
              className={clsx("mb-3", classes.field)}
              variant={rowData.keyType === "original" ? "filled" : "outlined"}
              size="small"
              helperText=" "
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const BillRateContent = ({ rowData, fieldValue }) => {
    let billPayPrice = [];

    if (rowData.billing_and_settlement?.routeDetails?.price_type === "Matrix") {
      billPayPrice = priceMatrix?.filter(
        (item) =>
          rowData.billing_and_settlement?.customer_branch?.id ===
            item?.customer_branch_id &&
          item.name ===
            rowData?.billing_and_settlement?.routeDetails?.matrix_name
      );
    }

    const options = billPayPrice?.map((item) => ({
      value: item.bill_price,
      label: `$ ${item.bill_price} (${item.quantity})`,
    }));

    const billRateValue =
      rowData.keyType === "original"
        ? rowData.billing_and_settlement?.bill_rate
        : state.billRateValue;

    const label = rowData.billing_and_settlement?.bill_rate
      ? (() => {
          const matchingBillOption = billPayPrice?.find(
            (billOption) => billOption?.bill_price == billRateValue
          );

          if (matchingBillOption) {
            return `$ ${billRateValue} (${matchingBillOption.quantity})`;
          } else {
            return `$ ${billRateValue}`;
          }
        })()
      : "-";

    return rowData.billing_and_settlement?.routeDetails?.price_type ===
      "Matrix" ? (
      <Autocomplete
        disableClearable
        // className="mr-2 w-50 mb-1"
        className={classes.field}
        classes={{
          root: classes.autocompletePaper,
          listbox: classes.autocompletePaper,
          inputRoot: clsx(
            rowData.original_billing_settlement !== null &&
              rowData.billing_and_settlement?.bill_rate !=
                rowData.original_billing_settlement?.bill_rate &&
              "color-error"
          ),
        }}
        size="small"
        value={{ label }}
        disabled={
          !!isRoleView ||
          rowData.keyType === "original" ||
          !!state.dontBill.value
        }
        options={options || []}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Billing Rate"
            variant={rowData.keyType === "original" ? "filled" : "outlined"}
            helperText=" "
          />
        )}
        onChange={(e, value) => {
          isFieldChange = true;

          const newValue = value?.value;

          const correspondingSettlement = rowData.billing_and_settlement
            ?.dont_pay
            ? { pay_price: "0.00" }
            : billPayPrice.find((item) => item.bill_price === newValue);
          setState((prevState) => ({
            ...prevState,

            billRateValue: newValue,
            billAmountValue: (newValue * state.billQuantityValue).toFixed(2),
            billFuelAmountValue: (
              state.billAmountValue *
              (rowData.billing_and_settlement.bill_fuel_surcharge / 100)
            ).toFixed(2),
            payRateValue: correspondingSettlement.pay_price,
            payAmountValue: (
              correspondingSettlement.pay_price * state.payQuantityValue
            ).toFixed(2),
            payFuelAmount: (
              state.payAmount *
              (updatedRow.billing_and_settlement.pay_fuel_surcharge / 100)
            ).toFixed(2),
          }));
        }}
      />
    ) : (
      <TextField
        label="Billing Rate"
        value={fieldValue}
        disabled={
          !!isRoleView ||
          !!rowData.billing_and_settlement?.dont_bill ||
          rowData.keyType === "original"
        }
        name="billRateValue"
        type="number"
        variant={rowData.keyType === "original" ? "filled" : "outlined"}
        size="small"
        helperText=" "
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          classes: {
            input:
              rowData.keyType === "Updated"
                ? rowData.original_billing_settlement !== null &&
                  rowData.billing_and_settlement?.bill_rate !==
                    rowData.original_billing_settlement?.bill_rate &&
                  "color-error"
                : null,
          },
        }}
        className={classes.field}
        onChange={handleFieldChange}
      />
    );
  };

  const PayRateContent = ({ rowData }) => {
    let billPayPrice = [];

    if (rowData.billing_and_settlement?.routeDetails?.price_type === "Matrix") {
      billPayPrice = priceMatrix?.filter(
        (item) =>
          rowData.billing_and_settlement?.customer_branch?.id ===
            item?.customer_branch_id &&
          item.name ===
            rowData?.billing_and_settlement?.routeDetails?.matrix_name
      );
    }

    const options = billPayPrice?.map((item) => ({
      value: item.pay_price,
      label: `$ ${item.pay_price} (${item.quantity})`,
    }));

    const payRateValue =
      rowData.keyType === "original"
        ? rowData.billing_and_settlement?.pay_rate
        : state.payRateValue;

    const label = rowData.billing_and_settlement?.pay_rate
      ? (() => {
          const matchingBillOption = billPayPrice?.find(
            (billOption) => billOption?.pay_price == payRateValue
          );

          if (matchingBillOption) {
            return `$ ${payRateValue} (${matchingBillOption.quantity})`;
          } else {
            return `$ ${payRateValue}`;
          }
        })()
      : "-";

    return rowData.billing_and_settlement?.routeDetails?.price_type ===
      "Matrix" ? (
      <Autocomplete
        disableClearable
        // className="mr-2 w-50 mb-1"
        className={classes.field}
        classes={{
          root: classes.autocompletePaper,
          listbox: classes.autocompletePaper,
          inputRoot: clsx(
            rowData.keyType === "Updated"
              ? rowData.original_billing_settlement !== null &&
                  rowData.billing_and_settlement?.pay_rate !=
                    rowData.original_billing_settlement?.pay_rate &&
                  "color-error"
              : null
          ),
        }}
        size="small"
        value={{ label }}
        disabled={
          isRoleView ||
          rowData.keyType === "original" ||
          rowData.billing_and_settlement?.individual_contractor
            ?.contractorType === "Stand-by" ||
          !!state.dontPay.value
        }
        options={options || []}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Pay Rate"
            variant={rowData.keyType === "original" ? "filled" : "outlined"}
            helperText=" "
          />
        )}
        onChange={(evt, value) => {
          const newValue = value?.value;

          const correspondingBilling = rowData.billing_and_settlement?.dont_bill
            ? { bill_price: "0.00" }
            : billPayPrice?.find((item) => item.pay_price === newValue);

          setState((prevState) => ({
            ...prevState,

            payRateValue: newValue,
            payAmountValue: (newValue * state.payQuantityValue).toFixed(2),
            payFuelAmountValue: (
              state.payAmountValue *
              (rowData.billing_and_settlement.pay_fuel_surcharge / 100)
            ).toFixed(2),
            billRateValue: correspondingBilling.bill_price,
            billAmountValue: (
              correspondingBilling.bill_price * state.billQuantityValue
            ).toFixed(2),
            billFuelAmountValue: (
              state.billAmountValue *
              (updatedRow.billing_and_settlement.bill_fuel_surcharge / 100)
            ).toFixed(2),
          }));
        }}
      />
    ) : (
      <TextField
        label="Pay Rate"
        value={
          rowData === "original"
            ? rowData.billing_and_settlement?.bill_rate || "-"
            : state.payRateValue
        }
        disabled={
          !!isRoleView ||
          !!rowData.billing_and_settlement?.dont_bill ||
          rowData.keyType === "original" ||
          rowData.billing_and_settlement?.individual_contractor
            ?.contractorType === "Stand-by"
        }
        type="number"
        onWheel={(event) => event.target.blur()}
        onKeyDown={preventInputKeyCodes}
        variant={rowData.keyType === "original" ? "filled" : "outlined"}
        size="small"
        helperText=" "
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          classes: {
            input:
              rowData.keyType === "Updated"
                ? rowData.original_billing_settlement !== null &&
                  rowData.billing_and_settlement?.pay_rate !==
                    rowData.original_billing_settlement?.pay_rate &&
                  "color-error"
                : null,
          },
        }}
        className={classes.field}
        onChange={handleFieldChange}
        name="payRateValue"
      />
    );
  };

  const isValidate = !!validate();

  const handleConfirmDontBill = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBill: true,
      confirmDontBillValue: isCheckBoxChecked,
    }));
  };

  const calculateBill = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,

      billRateValue: "0.00",
      billTollAmountValue: 0,
      billAmountValue: 0,
      billFuelAmountValue: 0,
      billExtraMilesValue: "0",
      billExtraMileAmountValue: "0",
      dontBill: {
        ...prevState.dontBill,
        value: isCheckBoxChecked,
        notes: null,
      },
      dontBillMiles: {
        ...prevState.dontBillMiles,
        value: false,
        notes: null,
      },
      dontBillTolls: {
        ...prevState.dontBillTolls,
        value: false,
        notes: null,
      },
    }));
  };

  const handleConfirmDontPay = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPay: true,
      confirmDontPayValue: isCheckBoxChecked,
    }));
  };

  const calculatePay = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      payRateValue: "0.00",
      payAmountValue: 0,
      payFuelAmountValue: 0,
      payTollAmountValue: 0,
      payExtraMilesValue: "0",
      payExtraMileAmountValue: "0",
      dontPay: {
        ...prevState.dontPay,
        value: isCheckBoxChecked,
        notes: null,
      },
      dontPayMiles: {
        ...prevState.dontPayMiles,
        value: false,
        notes: null,
      },
      dontPayTolls: {
        ...prevState.dontPayTolls,
        value: false,
        notes: null,
      },
    }));
  };

  const handleConfirmDontBillExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillExtraMiles: isCheckBoxChecked,
      confirmDontBillExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const calculateBillMiles = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,

      billExtraMilesValue: "0",
      billExtraMileAmountValue: "0",
      dontBillMiles: {
        ...prevState.dontBillMiles,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const handleConfirmDontPayExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayExtraMiles: isCheckBoxChecked,
      confirmDontPayExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const calculatePayMiles = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,

      payExtraMilesValue: "0",
      payExtraMileAmountValue: "0",
      dontPayMiles: {
        ...prevState.dontPayMiles,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const handleConfirmDontBillToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillToll: isCheckBoxChecked,
      confirmDontBillTollValue: isCheckBoxChecked,
    }));
  };

  const calculateBillToll = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,

      billTollAmountValue: 0,
      dontBillTolls: {
        ...prevState.dontBillTolls,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const handleConfirmDontPayToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayToll: isCheckBoxChecked,
      confirmDontPayTollValue: isCheckBoxChecked,
    }));
  };

  const calculatePayToll = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,

      payTollAmountValue: 0,
      dontPayTolls: {
        ...prevState.dontPayTolls,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const formNotes = [
    {
      label: "Price Type",
      updatedValue: (() => {
        const routeDetails = updatedRow.billing_and_settlement?.routeDetails;
        const priceType = routeDetails?.price_type;

        return priceType === "Matrix"
          ? "Price Matrix"
          : priceType === "Standard"
          ? "Standard"
          : null;
      })(),
      originalValue: (() => {
        const routeDetails = originalRow.billing_and_settlement?.routeDetails;
        const priceType = routeDetails?.price_type;

        return priceType === "Matrix"
          ? "Price Matrix"
          : priceType === "Standard"
          ? "Standard"
          : null;
      })(),
      updatedTooltip: (() => {
        const routeDetails = updatedRow.billing_and_settlement?.routeDetails;
        const priceType = routeDetails?.price_type;
        const matrixName = routeDetails?.matrix_name;

        return priceType === "Matrix"
          ? `Matrix Name: ${matrixName}`
          : priceType === "Standard"
          ? "Standard"
          : null;
      })(),
      originalTooltip: (() => {
        const routeDetails = originalRow.billing_and_settlement?.routeDetails;
        const priceType = routeDetails?.price_type;
        const matrixName = routeDetails?.matrix_name;

        return priceType === "Matrix"
          ? `Matrix Name: ${matrixName}`
          : priceType === "Standard"
          ? "Standard"
          : null;
      })(),
    },
    {
      label: "Review Reason",
      updatedValue: updatedRow.review_reason,
      updatedTooltip: updatedRow.review_reason,
    },
    {
      label: "AA/MGR Notes",
      updatedValue: updatedRow.billing_and_settlement?.change_notes,
      originalValue: originalRow.billing_and_settlement?.change_notes,
      updatedTooltip: updatedRow.billing_and_settlement?.change_notes,
      originalTooltip: originalRow.billing_and_settlement?.change_notes,
    },
    {
      label: "KAM Notes",
      updatedValue:
        updatedRow.billing_and_settlement !== null
          ? updatedRow.billing_and_settlement?.kam_notes
          : "-",
      originalValue:
        originalRow.billing_and_settlement !== null
          ? originalRow.billing_and_settlement?.kam_notes
          : "-",
      updatedTooltip: updatedRow.billing_and_settlement?.kam_notes,
      originalTooltip: originalRow.billing_and_settlement?.kam_notes,
    },
  ];

  const formFields = [
    {
      label: "Schedule Date",
      updatedValue: !!updatedRow.billing_and_settlement?.schedule_date
        ? getUTCDateString(updatedRow.billing_and_settlement?.schedule_date)
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.schedule_date
        ? getUTCDateString(originalRow.billing_and_settlement?.schedule_date)
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement?.schedule_date
        ? getUTCDateString(updatedRow.billing_and_settlement?.schedule_date)
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement?.schedule_date
        ? getUTCDateString(originalRow.billing_and_settlement?.schedule_date)
        : "-",
    },
    {
      label: "Customer Name",
      updatedValue: (() => {
        const customerName = `${
          updatedRow.billing_and_settlement?.customer_branch?.customer?.name ||
          ""
        }`;

        return customerName;
      })(),
      originalValue: (() => {
        const customerName = `${
          originalRow.billing_and_settlement?.customer_branch?.customer?.name ||
          ""
        }`;

        return customerName;
      })(),
      updatedTooltip: (() => {
        const customerName = `${
          updatedRow.billing_and_settlement?.customer_branch?.customer?.name ||
          ""
        }`;

        return customerName;
      })(),
      originalTooltipTooltip: (() => {
        const customerName = `${
          originalRow.billing_and_settlement?.customer_branch?.customer?.name ||
          ""
        }`;

        return customerName;
      })(),
    },
    {
      label: "KAM",
      updatedValue: (() => {
        const kamName = `${
          updatedRow.billing_and_settlement?.kamUser?.first_name || ""
        } ${updatedRow.billing_and_settlement?.kamUser?.last_name || ""}`;

        const value = !!updatedRow.billing_and_settlement?.kamUser
          ? kamName || "-"
          : "-";

        return value;
      })(),
      originalValue: (() => {
        const kamName = `${
          originalRow.billing_and_settlement?.kamUser?.first_name || ""
        } ${originalRow.billing_and_settlement?.kamUser?.last_name || ""}`;

        const value = !!originalRow.billing_and_settlement?.kamUser
          ? kamName || "-"
          : "-";

        return value;
      })(),
      updatedTooltip: (() => {
        const kamName = `${
          updatedRow.billing_and_settlement?.kamUser?.first_name || ""
        } ${updatedRow.billing_and_settlement?.kamUser?.last_name || ""}`;

        const title = updatedRow.billing_and_settlement?.kamUser?.username
          ? `${kamName}-(${updatedRow.billing_and_settlement?.kamUser?.username})`
          : "";

        return title;
      })(),
      originalTooltip: (() => {
        const kamName = `${
          originalRow.billing_and_settlement?.kamUser?.first_name || ""
        } ${originalRow.billing_and_settlement?.kamUser?.last_name || ""}`;

        const title = originalRow.billing_and_settlement?.kamUser?.username
          ? `${kamName}-(${originalRow.billing_and_settlement?.kamUser?.username})`
          : "";

        return title;
      })(),
    },
    {
      label: "Location",
      updatedValue: (() => {
        const value = !!updatedRow.billing_and_settlement?.customer_branch_id
          ? updatedRow.billing_and_settlement?.customer_branch?.location || "-"
          : "-";

        return value;
      })(),
      originalValue: (() => {
        const value = !!originalRow.billing_and_settlement?.customer_branch_id
          ? originalRow.billing_and_settlement?.customer_branch?.location || "-"
          : "-";

        return value;
      })(),
      updatedTooltip: (() => {
        const title = !!updatedRow.billing_and_settlement?.customer_branch_id
          ? updatedRow.billing_and_settlement?.customer_branch?.location
          : "-";

        return title;
      })(),
      originalTooltip: (() => {
        const title = !!originalRow.billing_and_settlement?.customer_branch_id
          ? originalRow.billing_and_settlement?.customer_branch?.location
          : "-";

        return title;
      })(),
    },
    {
      label: "Route",
      updatedValue: updatedRow.billing_and_settlement?.routeDetails?.name,
      originalValue: originalRow.billing_and_settlement?.routeDetails?.name,
      updatedTooltip: updatedRow.billing_and_settlement?.routeDetails?.name,
      originalTooltip: originalRow.billing_and_settlement?.routeDetails?.name,
    },
    {
      label: "State",
      updatedValue: (() => {
        const value = !!updatedRow.billing_and_settlement?.customer_branch_id
          ? updatedRow.billing_and_settlement?.customer_branch?.state?.name ||
            "-"
          : "-";

        return value;
      })(),
      originalValue: (() => {
        const value = !!originalRow.billing_and_settlement?.customer_branch_id
          ? originalRow.billing_and_settlement?.customer_branch?.state?.name ||
            "-"
          : "-";

        return value;
      })(),
      updatedTooltip: (() => {
        const title = !!updatedRow.billing_and_settlement?.customer_branch_id
          ? updatedRow.billing_and_settlement?.customer_branch?.state?.name
          : "-";

        return title;
      })(),
      originalTooltip: (() => {
        const title = !!originalRow.billing_and_settlement?.customer_branch_id
          ? originalRow.billing_and_settlement?.customer_branch?.state?.name
          : "-";

        return title;
      })(),
    },
    {
      label: "PDX Company",
      updatedValue: (() => {
        const value = !!updatedRow.billing_and_settlement?.customer_branch_id
          ? updatedRow.billing_and_settlement?.customer_branch?.pdxCompany
              ?.value || "-"
          : "-";

        return value;
      })(),
      originalValue: (() => {
        const value = !!originalRow.billing_and_settlement?.customer_branch_id
          ? originalRow.billing_and_settlement?.customer_branch?.pdxCompany
              ?.value || "-"
          : "-";

        return value;
      })(),
      updatedTooltip: (() => {
        const title = !!updatedRow.billing_and_settlement?.customer_branch_id
          ? updatedRow.billing_and_settlement?.customer_branch?.pdxCompany
              ?.value
          : "-";

        return title;
      })(),
      originalTooltip: (() => {
        const title = !!originalRow.billing_and_settlement?.customer_branch_id
          ? originalRow.billing_and_settlement?.customer_branch?.pdxCompany
              ?.value
          : "-";

        return title;
      })(),
    },
    {
      label: "Vehicle",
      updatedValue: updatedRow.billing_and_settlement?.vehicleType?.value,
      originalValue: originalRow.billing_and_settlement?.vehicleType?.value,
      updatedTooltip: updatedRow.billing_and_settlement?.vehicleType?.value,
      originalTooltip: originalRow.billing_and_settlement?.vehicleType?.value,
    },
  ];

  const formFields1 = [
    {
      label: "WCP-Driver",
      updatedValue: (() => {
        const billingRow = updatedRow.billing_and_settlement;
        const driver = billingRow?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""} (#${
              driver?.id
            })`
          : null;

        return driverName;
      })(),
      originalValue: (() => {
        const billingRow = originalRow.original_billing_settlement;
        const driver = billingRow?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""} (#${
              driver?.id
            })`
          : null;

        return driverName;
      })(),
      updatedTooltip: (() => {
        const billingRow = updatedRow.billing_and_settlement;
        const driver = billingRow?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""} (#${
              driver?.id
            })`
          : null;

        return driverName;
      })(),
      originalTooltip: (() => {
        const billingRow = originalRow.original_billing_settlement;
        const driver = billingRow?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""} (#${
              driver?.id
            })`
          : null;

        return driverName;
      })(),
    },
    {
      label: "Settlement Company",
      updatedValue: updatedRow.billing_and_settlement?.settlementCoName,
      originalValue: originalRow.billing_and_settlement?.settlementCoName,
      updatedTooltip: updatedRow.billing_and_settlement?.settlementCoName,
      originalTooltipTooltip:
        originalRow.billing_and_settlement?.settlementCoName,
    },
  ];

  const billingFormFields = [
    {
      label: "Billing Rate Type",
      updatedValue: {
        label: state.billRateTypeValue || "-",
      },
      originalValue: originalRow.billing_and_settlement?.bill_rate_type,
      originalTooltip: originalRow.billing_and_settlement?.bill_rate_type,
      disabled:
        !!isRoleView ||
        updatedRow.billing_and_settlement?.routeDetails?.price_type ===
          "Matrix",
      classes: clsx(
        updatedRow.original_billing_settlement !== null &&
          updatedRow.billing_and_settlement?.bill_rate_type !=
            updatedRow.original_billing_settlement?.bill_rate_type &&
          "color-error"
      ),
      options: RATE_TYPES.map((rateType) => ({
        value: rateType,
        label: rateType,
      })),
      isAutocomplete: true,
      onChange: (e, value) => {
        isFieldChange = true;
        setState((prevState) => ({
          ...prevState,
          billRateTypeValue: value.value,
        }));
      },
    },
  ];

  const billingFormFields2 = [
    {
      label: "Billing Quantity",
      updatedValue: state.billQuantityValue,
      originalValue: originalRow.billing_and_settlement?.bill_quantity,
      disabled:
        isRoleView ||
        updatedRow.billing_and_settlement?.routeDetails?.price_type ===
          "Matrix",
      classes:
        updatedRow.original_billing_settlement !== null &&
        updatedRow.billing_and_settlement?.bill_quantity !=
          updatedRow.original_billing_settlement?.bill_quantity &&
        "color-error",
      canEdit: true,
      helperText: state.errors.billQuantityValue,
      error: !!state.errors?.billQuantityValue?.trim(),
      name: "billQuantityValue",
      inputType: "number",
    },
    {
      label: "Billing Amount",
      updatedValue: !!state.billAmountValue
        ? `$${state.billAmountValue}`
        : "$0",
      originalValue: !!originalRow.billing_and_settlement?.bill_amount
        ? `$${originalRow.billing_and_settlement?.bill_amount}`
        : "$0",
      updatedTooltip: !!state.billAmountValue
        ? state.billAmountValue || ""
        : "$0",
      originalTooltip: !!originalRow.billing_and_settlement?.bill_amount
        ? originalRow.billing_and_settlement?.bill_amount || ""
        : "$0",
    },
    {
      label: "Billing Fuel surcharge%",
      updatedValue: !!updatedRow.billing_and_settlement?.bill_fuel_surcharge
        ? `${updatedRow.billing_and_settlement?.bill_fuel_surcharge}%`
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.bill_fuel_surcharge
        ? `${originalRow.billing_and_settlement?.bill_fuel_surcharge}%`
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement?.bill_fuel_surcharge
        ? `${updatedRow.billing_and_settlement?.bill_fuel_surcharge}%` || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement?.bill_fuel_surcharge
        ? `${originalRow.billing_and_settlement?.bill_fuel_surcharge}%` || ""
        : "-",
    },
    {
      label: "Billing Fuel Amount",
      updatedValue: !!state.billFuelAmountValue
        ? `$${state.billFuelAmountValue}`
        : "$0",
      originalValue: !!originalRow.billing_and_settlement?.bill_fuel_amount
        ? `$${originalRow.billing_and_settlement?.bill_fuel_amount}`
        : "$0",
      updatedTooltip: !!state.billFuelAmountValue
        ? state.billFuelAmountValue || ""
        : "$0",
      originalTooltip: !!originalRow.billing_and_settlement?.bill_fuel_amount
        ? originalRow.billing_and_settlement?.bill_fuel_amount || ""
        : "$0",
    },
    {
      label: "Billing Toll Amount",
      updatedValue: state.billTollAmountValue,
      originalValue: originalRow.billing_and_settlement?.bill_toll_amount,
      originalTooltip: originalRow.billing_and_settlement?.bill_toll_amount,
      disabled:
        !!updatedRow.billing_and_settlement?.dont_bill_tolls ||
        !!updatedRow.billing_and_settlement?.dont_bill,
      classes:
        updatedRow.original_billing_settlement !== null &&
        (updatedRow.billing_and_settlement?.bill_toll_amount > 999.99 ||
          +updatedRow.billing_and_settlement?.bill_toll_amount !==
            +updatedRow.original_billing_settlement?.bill_toll_amount) &&
        "color-error",
      canEdit: true,
      helperText: state.errors.billTollAmountValue,
      error: !!state.errors?.billTollAmountValue?.trim(),
      name: "billTollAmountValue",
      inputType: "number",
    },
    {
      label: "Billing Extra Miles",
      updatedValue: !!state.billExtraMilesValue
        ? state.billExtraMilesValue
        : "-",
      originalValue: updatedRow.billing_and_settlement?.bill_extra_miles
        ? updatedRow.billing_and_settlement?.bill_extra_miles
        : "-",
      updatedTooltip: !!state.billExtraMilesValue
        ? state.billExtraMilesValue || ""
        : "-",
      originalTooltip: !!updatedRow.billing_and_settlement?.bill_extra_miles
        ? updatedRow.billing_and_settlement?.bill_extra_miles || ""
        : "-",
    },
    {
      label: "Billing Rate Per Extra Mile",
      updatedValue: !!updatedRow.billing_and_settlement
        ?.bill_rate_per_extra_mile
        ? `$${updatedRow.billing_and_settlement?.bill_rate_per_extra_mile}`
        : "-",
      originalValue: !!originalRow.billing_and_settlement
        ?.bill_rate_per_extra_mile
        ? `$${originalRow.billing_and_settlement?.bill_rate_per_extra_mile}`
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement
        ?.bill_rate_per_extra_mile
        ? updatedRow.billing_and_settlement?.bill_rate_per_extra_mile || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement
        ?.bill_rate_per_extra_mile
        ? originalRow.billing_and_settlement?.bill_rate_per_extra_mile || ""
        : "-",
    },
    {
      label: "Billing Extra Miles Amount",
      updatedValue: !!state.billExtraMileAmountValue
        ? `$${state.billExtraMileAmountValue}`
        : "-",
      originalValue: !!originalRow.billing_and_settlement
        ?.bill_extra_mile_amount
        ? `$${originalRow.billing_and_settlement?.bill_extra_mile_amount}`
        : "-",
      updatedTooltip: !!state.billExtraMileAmountValue
        ? state.billExtraMileAmountValue || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement
        ?.bill_extra_mile_amount
        ? originalRow.billing_and_settlement?.bill_extra_mile_amount || ""
        : "-",
    },
  ];

  const payFormFields = [
    {
      label: "Pay Rate Type",
      updatedValue: {
        label: state.payRateTypeValue || "-",
      },
      originalValue: originalRow.billing_and_settlement?.pay_rate_type,
      originalTooltip: originalRow.billing_and_settlement?.pay_rate_type,
      disabled:
        !!isRoleView ||
        updatedRow.billing_and_settlement?.routeDetails?.price_type ===
          "Matrix",
      classes: clsx(
        updatedRow.original_billing_settlement !== null &&
          updatedRow.billing_and_settlement?.pay_rate_type !=
            updatedRow.original_billing_settlement?.pay_rate_type &&
          "color-error"
      ),
      options:
        updatedRow.billing_and_settlement?.individual_contractor
          ?.contractorType === "Stand-by"
          ? appDataList.icList
              ?.find(
                (ele) =>
                  ele.id ===
                  updatedRow?.billing_and_settlement?.individual_contractor?.id
              )
              ?.StandByRates?.map((rate) => ({
                value: rate,
                label: rate?.rateType?.value,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          : RATE_TYPES.map((rateType) => ({
              value: rateType,
              label: rateType,
            })),
      isAutocomplete: true,
      onChange: (e, value) => {
        isFieldChange = true;
        setState((prevState) => ({
          ...prevState,
          payRateTypeValue: value?.value?.rateType?.value || value?.value,
          payRateValue: value?.value?.rate_value
            ? value?.value?.rate_value
            : prevState.payRateValue,
          payAmountValue:
            updatedRow.billing_and_settlement?.individual_contractor
              ?.contractorType === "Stand-by"
              ? (
                  (value?.value?.rate_value
                    ? value?.value?.rate_value
                    : prevState.payRateValue) * state.payQuantityValue
                ).toFixed(2)
              : prevState.payAmountValue,
        }));
      },
    },
  ];

  const payFormFields1 = [
    {
      label: "Pay Quantity",
      updatedValue: state.payQuantityValue,
      originalValue: originalRow.billing_and_settlement?.pay_quantity,
      disabled:
        isRoleView ||
        updatedRow.billing_and_settlement?.routeDetails?.price_type ===
          "Matrix",
      classes:
        updatedRow.keyType === "Updated"
          ? updatedRow.original_billing_settlement !== null &&
            updatedRow.billing_and_settlement?.pay_quantity !=
              updatedRow.original_billing_settlement?.pay_quantity &&
            "color-error"
          : null,
      helperText: state.errors.payQuantityValue,
      error: !!state.errors?.payQuantityValue?.trim(),
      name: "payQuantityValue",
      canEdit: true,
      inputType: "number",
    },
    {
      label: "Pay Amount",
      updatedValue: !!state.payAmountValue ? `$${state.payAmountValue}` : "$0",
      originalValue: !!originalRow.billing_and_settlement?.pay_amount
        ? `$${originalRow.billing_and_settlement?.pay_amount}`
        : "$0",
      updatedTooltip: !!state.payAmountValue
        ? state.payAmountValue || ""
        : "$0",
      originalTooltip: !!originalRow.billing_and_settlement?.pay_amount
        ? originalRow.billing_and_settlement?.pay_amount || ""
        : "$0",
    },
    {
      label: "Pay Fuel surcharge%",
      updatedValue: !!updatedRow.billing_and_settlement?.pay_fuel_surcharge
        ? `${updatedRow.billing_and_settlement?.pay_fuel_surcharge}%`
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.pay_fuel_surcharge
        ? `${originalRow.billing_and_settlement?.pay_fuel_surcharge}%`
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement?.bill_fuel_surcharge
        ? `${updatedRow.billing_and_settlement?.pay_fuel_surcharge}%` || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement?.pay_fuel_surcharge
        ? `${originalRow.billing_and_settlement?.pay_fuel_surcharge}%` || ""
        : "-",
    },
    {
      label: "Pay Fuel Amount",
      updatedValue: !!state.payFuelAmountValue
        ? `$${state.payFuelAmountValue}`
        : "$0",
      originalValue: !!originalRow.billing_and_settlement?.pay_fuel_amount
        ? `$${originalRow.billing_and_settlement?.pay_fuel_amount}`
        : "$0",
      updatedTooltip: !!state.payFuelAmountValue
        ? state.payFuelAmountValue || ""
        : "$0",
      originalTooltip: !!originalRow.billing_and_settlement?.pay_fuel_amount
        ? originalRow.billing_and_settlement?.pay_fuel_amount || ""
        : "$0",
    },
    {
      label: "Pay Toll Amount",
      updatedValue: state.payTollAmountValue,
      originalValue: originalRow.billing_and_settlement?.pay_toll_amount,
      originalTooltip: originalRow.billing_and_settlement?.pay_toll_amount,
      disabled:
        !!updatedRow.billing_and_settlement?.dont_bill_tolls ||
        !!updatedRow.billing_and_settlement?.dont_bill,
      classes:
        updatedRow.original_billing_settlement !== null &&
        (updatedRow.billing_and_settlement?.pay_toll_amount > 999.99 ||
          +updatedRow.billing_and_settlement?.pay_toll_amount !==
            +updatedRow.original_billing_settlement?.pay_toll_amount) &&
        "color-error",
      canEdit: true,
      helperText: state.errors.payTollAmountValue,
      error: !!state.errors?.payTollAmountValue?.trim(),
      name: "payTollAmountValue",
      inputType: "number",
    },
    {
      label: "Pay Extra Miles",
      updatedValue: !!state.payExtraMilesValue ? state.payExtraMilesValue : "-",
      originalValue: updatedRow.billing_and_settlement?.pay_extra_miles
        ? updatedRow.billing_and_settlement?.pay_extra_miles
        : "-",
      updatedTooltip: !!state.payExtraMilesValue
        ? state.payExtraMilesValue || ""
        : "-",
      originalTooltip: !!updatedRow.billing_and_settlement?.pay_extra_miles
        ? updatedRow.billing_and_settlement?.pay_extra_miles || ""
        : "-",
    },
    {
      label: "Pay Rate Per Extra Mile",
      updatedValue: !!updatedRow.billing_and_settlement?.pay_rate_per_extra_mile
        ? `$${updatedRow.billing_and_settlement?.pay_rate_per_extra_mile}`
        : "-",
      originalValue: !!originalRow.billing_and_settlement
        ?.pay_rate_per_extra_mile
        ? `$${originalRow.billing_and_settlement?.pay_rate_per_extra_mile}`
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement
        ?.pay_rate_per_extra_mile
        ? updatedRow.billing_and_settlement?.pay_rate_per_extra_mile || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement
        ?.pay_rate_per_extra_mile
        ? originalRow.billing_and_settlement?.pay_rate_per_extra_mile || ""
        : "-",
    },
    {
      label: "Pay Extra Miles Amount",
      updatedValue: !!state.payExtraMileAmountValue
        ? `$${state.payExtraMileAmountValue}`
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.pay_extra_mile_amount
        ? `$${originalRow.billing_and_settlement?.pay_extra_mile_amount}`
        : "-",
      updatedTooltip: !!state.payExtraMileAmountValue
        ? state.payExtraMileAmountValue || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement
        ?.pay_extra_mile_amount
        ? originalRow.billing_and_settlement?.pay_extra_mile_amount || ""
        : "-",
    },
  ];

  const formFields3 = [
    {
      label: "Incentive",
      updatedValue: state.incentiveValue,
      originalValue: originalRow.billing_and_settlement?.reimbursement
        ? (+originalRow.billing_and_settlement.reimbursement).toFixed(2)
        : "-",
      originalTooltip: originalRow.billing_and_settlement?.reimbursement
        ? (+originalRow.billing_and_settlement.reimbursement).toFixed(2)
        : "",
      helperText: state.errors.incentiveValue,
      error: !!state.errors?.incentiveValue?.trim(),
      name: "incentiveValue",
      classes:
        !!updatedRow.original_billing_settlement &&
        +updatedRow.billing_and_settlement?.reimbursement !==
          +updatedRow.original_billing_settlement?.reimbursement &&
        "color-error",
      canEdit: true,
      inputType: "number",
      updatedStartAdornment: !!updatedRow.billing_and_settlement
        ?.reimbursement ? (
        <InputAdornment position="start">$</InputAdornment>
      ) : (
        ""
      ),
      originalStartAdornment: !!originalRow.billing_and_settlement
        ?.reimbursement ? (
        <InputAdornment position="start">$</InputAdornment>
      ) : (
        ""
      ),
    },
    {
      label: "Deduction",
      updatedValue: !!updatedRow.billing_and_settlement?.deduction
        ? `$${updatedRow.billing_and_settlement?.deduction}`
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.deduction
        ? `$${originalRow.billing_and_settlement?.deduction}`
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement?.deduction
        ? updatedRow.billing_and_settlement?.deduction || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement?.deduction
        ? originalRow.billing_and_settlement?.deduction || ""
        : "-",
    },
    {
      label: "Deduction Reason",
      updatedValue: !!updatedRow.billing_and_settlement?.deduction_reason
        ? updatedRow.billing_and_settlement?.deduction_reason
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.deduction_reason
        ? originalRow.billing_and_settlement?.deduction_reason
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement?.deduction_reason
        ? updatedRow.billing_and_settlement?.deduction_reason || ""
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement?.deduction_reason
        ? originalRow.billing_and_settlement?.deduction_reason || ""
        : "-",
    },
    {
      label: "Office",
      updatedValue: (() => {
        const officeUser = !!updatedRow.billing_and_settlement?.aaUser
          ? `${updatedRow.billing_and_settlement?.aaUser?.first_name || ""} ${
              updatedRow.billing_and_settlement?.aaUser?.last_name || ""
            }`
          : "-";

        return officeUser;
      })(),
      originalValue: (() => {
        const officeUser = !!originalRow.billing_and_settlement?.aaUser
          ? `${originalRow.billing_and_settlement?.aaUser?.first_name || ""} ${
              originalRow.billing_and_settlement?.aaUser?.last_name || ""
            }`
          : "-";

        return officeUser;
      })(),
      updatedTooltip: (() => {
        const officeUser = !!updatedRow.billing_and_settlement?.aaUser
          ? `${updatedRow.billing_and_settlement?.aaUser?.first_name || ""} ${
              updatedRow.billing_and_settlement?.aaUser?.last_name || ""
            }`
          : "-";
        const title = updatedRow.billing_and_settlement?.aaUser?.username
          ? `${officeUser}-(${updatedRow.billing_and_settlement?.aaUser?.username})`
          : "-";

        return title;
      })(),
      originalTooltip: (() => {
        const officeUser = !!originalRow.billing_and_settlement?.aaUser
          ? `${originalRow.billing_and_settlement?.aaUser?.first_name || ""} ${
              originalRow.billing_and_settlement?.aaUser?.last_name || ""
            }`
          : "-";
        const title = originalRow.billing_and_settlement?.aaUser?.username
          ? `${officeUser}-(${originalRow.billing_and_settlement?.aaUser?.username})`
          : "-";

        return title;
      })(),
    },
    {
      label: "Manager",
      updatedValue: (() => {
        const officeUser = !!updatedRow.billing_and_settlement?.mgrUser
          ? `${updatedRow.billing_and_settlement?.aaUser?.first_name || ""} ${
              updatedRow.billing_and_settlement?.aaUser?.last_name || ""
            }`
          : "-";

        return officeUser;
      })(),
      originalValue: (() => {
        const officeUser = !!originalRow.billing_and_settlement?.mgrUser
          ? `${originalRow.billing_and_settlement?.mgrUser?.first_name || ""} ${
              originalRow.billing_and_settlement?.mgrUser?.last_name || ""
            }`
          : "-";

        return officeUser;
      })(),
      updatedTooltip: (() => {
        const officeUser = !!updatedRow.billing_and_settlement?.mgrUser
          ? `${updatedRow.billing_and_settlement?.mgrUser?.first_name || ""} ${
              updatedRow.billing_and_settlement?.mgrUser?.last_name || ""
            }`
          : "-";
        const title = updatedRow.billing_and_settlement?.mgrUser?.username
          ? `${officeUser}-(${updatedRow.billing_and_settlement?.mgrUser?.username})`
          : "-";

        return title;
      })(),
      originalTooltip: (() => {
        const officeUser = !!originalRow.billing_and_settlement?.mgrUser
          ? `${originalRow.billing_and_settlement?.mgrUser?.first_name || ""} ${
              originalRow.billing_and_settlement?.mgrUser?.last_name || ""
            }`
          : "-";
        const title = originalRow.billing_and_settlement?.mgrUser?.username
          ? `${officeUser}-(${originalRow.billing_and_settlement?.mgrUser?.username})`
          : "-";

        return title;
      })(),
    },
    {
      label: "Profit Percentage",
      updatedValue: !!updatedRow.billing_and_settlement?.profit_percentage
        ? `${updatedRow.billing_and_settlement?.profit_percentage}%`
        : "-",
      originalValue: !!originalRow.billing_and_settlement?.profit_percentage
        ? `${originalRow.billing_and_settlement?.profit_percentage}%`
        : "-",
      updatedTooltip: !!updatedRow.billing_and_settlement?.profit_percentage
        ? `${updatedRow.billing_and_settlement?.profit_percentage}%`
        : "-",
      originalTooltip: !!originalRow.billing_and_settlement?.profit_percentage
        ? `${originalRow.billing_and_settlement?.profit_percentage}%`
        : "-",
    },
    {
      label: "Submitted By",
      updatedValue: (() => {
        const kam = appData.users.find(
          (ele) =>
            ele?.id ===
              updatedRow.billing_and_settlement?.daily_schedule?.submitted_by ||
            null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }

        return submittedBy;
      })(),
      originalValue: (() => {
        const kam = appData.users.find(
          (ele) =>
            ele?.id ===
              originalRow.billing_and_settlement?.daily_schedule
                ?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }

        return submittedBy;
      })(),
      updatedTooltip: (() => {
        const kam = appData.users.find(
          (ele) =>
            ele?.id ===
              updatedRow.billing_and_settlement?.daily_schedule?.submitted_by ||
            null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }

        return submittedBy;
      })(),
      originalTooltip: (() => {
        const kam = appData.users.find(
          (ele) =>
            ele?.id ===
              originalRow.billing_and_settlement?.daily_schedule
                ?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }

        return submittedBy;
      })(),
    },
    {
      label: "Last Updated By",
      updatedValue: (() => {
        const updatedBy = appData.users.find(
          (ele) =>
            ele?.id === updatedRow?.billing_and_settlement?.updated_by || null
        );
        let user = "";
        if (updatedBy) {
          user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
            updatedBy.username
          })`;
        }

        return user;
      })(),
      originalValue: (() => {
        const updatedBy = appData.users.find(
          (ele) =>
            ele?.id === originalRow?.billing_and_settlement?.updated_by || null
        );
        let user = "";
        if (updatedBy) {
          user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
            updatedBy.username
          })`;
        }

        return user;
      })(),
      updatedTooltip: (() => {
        const updatedBy = appData.users.find(
          (ele) =>
            ele?.id === updatedRow?.billing_and_settlement?.updated_by || null
        );
        let user = "";
        if (updatedBy) {
          user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
            updatedBy.username
          })`;
        }

        return user;
      })(),
      originalTooltip: (() => {
        const updatedBy = appData.users.find(
          (ele) =>
            ele?.id === originalRow?.billing_and_settlement?.updated_by || null
        );
        let user = "";
        if (updatedBy) {
          user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
            updatedBy.username
          })`;
        }

        return user;
      })(),
    },
    {
      label: "Last Updated At",
      updatedValue: (() => {
        const updatedAt = !!updatedRow.billing_and_settlement.updated_at
          ? `${getDateString(
              updatedRow.billing_and_settlement.updated_at
            )} ${getFormattedTime(
              new Date(updatedRow.billing_and_settlement.updated_at)
            )}`
          : "";

        return updatedAt;
      })(),
      originalValue: (() => {
        const updatedAt = !!originalRow.billing_and_settlement.updated_at
          ? `${getDateString(
              originalRow.billing_and_settlement.updated_at
            )} ${getFormattedTime(
              new Date(originalRow.billing_and_settlement.updated_at)
            )}`
          : "";

        return updatedAt;
      })(),
      updatedTooltip: (() => {
        const updatedAt = !!updatedRow.billing_and_settlement.updated_at
          ? `${getDateString(
              updatedRow.billing_and_settlement.updated_at
            )} ${getFormattedTime(
              new Date(updatedRow.billing_and_settlement.updated_at)
            )}`
          : "";

        return updatedAt;
      })(),
      originalTooltip: (() => {
        const updatedAt = !!originalRow.billing_and_settlement.updated_at
          ? `${getDateString(
              originalRow.billing_and_settlement.updated_at
            )} ${getFormattedTime(
              new Date(originalRow.billing_and_settlement.updated_at)
            )}`
          : "";

        return updatedAt;
      })(),
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>Edit Row</Dialog.Title>
        <Dialog.Content>
          <div>
            <div className="d-flex f-align-center f-wrap mb-6">
              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip title="Updated" placement="top-start">
                      <div style={{ cursor: "default" }}>
                        <Checkbox
                          className="ml-1"
                          disableRipple
                          color="default"
                          icon={<span className={classes.iconUpdated} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Updated"
                />

                <FormControlLabel
                  control={
                    <Tooltip title="Original" placement="top-start">
                      <div style={{ cursor: "default" }}>
                        <Checkbox
                          disableRipple
                          className="no-events"
                          style={{ cursor: "default" }}
                          color="default"
                          icon={<span className={classes.iconOriginal} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Original"
                />
              </div>
              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip title={state.dontBill.notes} placement="top-start">
                      <div>
                        <Checkbox
                          color="primary"
                          className={clsx(`${isRoleView ? "no-events" : ""}`)}
                          checked={state.dontBill.value}
                          disabled={
                            updatedRow.keyType === "original" &&
                            updatedRow.original_billing_settlement === null
                          }
                          onChange={(evt) => {
                            if (evt.target.checked === true) {
                              handleConfirmDontBill(evt.target.checked);
                            } else {
                              calculateBill(evt.target.checked);
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Bill"
                />

                <FormControlLabel
                  disabled
                  control={
                    <Tooltip
                      title={
                        !!originalRow.billing_and_settlement?.dont_bill ? (
                          originalRow.billing_and_settlement
                            ?.dont_bill_notes === null ||
                          originalRow.billing_and_settlement
                            ?.dont_bill_notes === "" ? (
                            "This record will not be included in billing"
                          ) : (
                            <>
                              This record will not be included in billing.
                              <br />
                              Reason :{" "}
                              {
                                originalRow.billing_and_settlement
                                  ?.dont_bill_notes
                              }
                            </>
                          )
                        ) : (
                          ""
                        )
                      }
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="secondary"
                          className={`${isRoleView ? "no-events" : ""}`}
                          checked={
                            originalRow.billing_and_settlement?.dont_bill
                          }
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Bill"
                />
              </div>

              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip title={state.dontPay.notes} placement="top-start">
                      <div>
                        <Checkbox
                          color="primary"
                          className={`${isRoleView ? "no-events" : ""}`}
                          checked={state.dontPay.value}
                          disabled={
                            updatedRow.keyType === "original" &&
                            updatedRow.original_billing_settlement === null
                          }
                          onChange={(evt) => {
                            if (evt.target.checked === true) {
                              handleConfirmDontPay(evt.target.checked);
                            } else {
                              calculatePay(evt.target.checked);
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Pay"
                />
                <FormControlLabel
                  disabled
                  control={
                    <Tooltip
                      title={
                        !!originalRow.billing_and_settlement?.dont_pay ? (
                          originalRow.billing_and_settlement?.dont_pay_notes ===
                            null ||
                          originalRow.billing_and_settlement?.dont_pay_notes ===
                            "" ? (
                            "This record will not be included in settlement"
                          ) : (
                            <>
                              This record will not be included in settlement.
                              <br />
                              Reason :{" "}
                              {
                                originalRow.billing_and_settlement
                                  ?.dont_pay_notes
                              }
                            </>
                          )
                        ) : (
                          ""
                        )
                      }
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="secondary"
                          className={`${isRoleView ? "no-events" : ""}`}
                          checked={originalRow.billing_and_settlement?.dont_pay}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Pay"
                />
              </div>

              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip
                      title={state.dontBillMiles.notes}
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="primary"
                          checked={state.dontBillMiles.value}
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled={
                            !!isRoleView ||
                            !!updatedRow.billing_and_settlement?.dont_bill ||
                            (updatedRow.keyType === "original" &&
                              updatedRow.original_billing_settlement === null)
                          }
                          onChange={(evt) => {
                            if (evt.target.checked === true) {
                              handleConfirmDontBillExtraMiles(
                                evt.target.checked
                              );
                            } else {
                              calculateBillMiles(evt.target.checked);
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Bill Extra Miles"
                />
                <FormControlLabel
                  disabled
                  control={
                    <Tooltip
                      title={
                        !!originalRow.billing_and_settlement
                          ?.dont_bill_miles ? (
                          originalRow.billing_and_settlement
                            ?.dont_bill_miles_notes === null ||
                          originalRow.billing_and_settlement
                            ?.dont_bill_miles_notes === "" ? (
                            "This record will not be included in billing."
                          ) : (
                            <>
                              This record will not be included in billing.
                              <br />
                              Reason :{" "}
                              {
                                originalRow.billing_and_settlement
                                  .dont_bill_miles_notes
                              }
                            </>
                          )
                        ) : (
                          ""
                        )
                      }
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="secondary"
                          checked={
                            originalRow.billing_and_settlement?.dont_bill_miles
                          }
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Bill Extra Miles"
                />
              </div>

              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip
                      title={state.dontPayMiles.notes}
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="primary"
                          checked={state.dontPayMiles.value}
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled={
                            !!updatedRow.billing_and_settlement?.dont_pay ||
                            (updatedRow.keyType === "original" &&
                              updatedRow.original_billing_settlement === null)
                          }
                          onChange={(evt) => {
                            if (evt.target.checked === true) {
                              handleConfirmDontPayExtraMiles(
                                evt.target.checked
                              );
                            } else {
                              calculatePayMiles(evt.target.checked);
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Pay Extra Miles"
                />

                <FormControlLabel
                  disabled
                  control={
                    <Tooltip
                      title={
                        !!originalRow.billing_and_settlement.dont_pay_miles ? (
                          originalRow.billing_and_settlement
                            ?.dont_pay_miles_notes === null ||
                          originalRow.billing_and_settlement
                            ?.dont_pay_miles_notes === "" ? (
                            "This record will not be included in settlement."
                          ) : (
                            <>
                              This record will not be included in settlement.
                              <br />
                              Reason :{" "}
                              {
                                originalRow.billing_and_settlement
                                  .dont_pay_miles_notes
                              }
                            </>
                          )
                        ) : (
                          ""
                        )
                      }
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="secondary"
                          checked={
                            originalRow.billing_and_settlement.dont_pay_miles
                          }
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Pay Extra Miles"
                />
              </div>

              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip
                      title={state.dontBillTolls.notes}
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="primary"
                          checked={state.dontBillTolls.value}
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled={
                            !!isRoleView ||
                            !!updatedRow.billing_and_settlement?.dont_bill ||
                            (updatedRow.keyType === "original" &&
                              updatedRow.original_billing_settlement === null)
                          }
                          onChange={(evt) => {
                            if (evt.target.checked === true) {
                              handleConfirmDontBillToll(evt.target.checked);
                            } else {
                              calculateBillToll(evt.target.checked);
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Bill Toll"
                />
                <FormControlLabel
                  disabled
                  control={
                    <Tooltip
                      title={
                        !!originalRow.billing_and_settlement.dont_bill_tolls ? (
                          originalRow.billing_and_settlement
                            ?.dont_bill_tolls_notes === null ||
                          originalRow.billing_and_settlement
                            ?.dont_bill_tolls_notes === "" ? (
                            "This record will not be included in billing."
                          ) : (
                            <>
                              This record will not be included in billing.
                              <br />
                              Reason :{" "}
                              {
                                originalRow.billing_and_settlement
                                  .dont_bill_tolls_notes
                              }
                            </>
                          )
                        ) : (
                          ""
                        )
                      }
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="secondary"
                          checked={
                            originalRow.billing_and_settlement.dont_bill_tolls
                          }
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Bill Toll"
                />
              </div>

              <div className="d-flex f-align-center flex-column">
                <FormControlLabel
                  control={
                    <Tooltip
                      title={state.dontPayTolls.notes}
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="primary"
                          checked={state.dontPayTolls.value}
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled={
                            !!updatedRow.billing_and_settlement?.dont_pay ||
                            (updatedRow.keyType === "original" &&
                              updatedRow.original_billing_settlement === null)
                          }
                          onChange={(evt) => {
                            if (evt.target.checked === true) {
                              handleConfirmDontPayToll(evt.target.checked);
                            } else {
                              calculatePayToll(evt.target.checked);
                            }
                          }}
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Pay Toll"
                />
                <FormControlLabel
                  disabled
                  control={
                    <Tooltip
                      title={
                        !!originalRow.billing_and_settlement.dont_pay_tolls ? (
                          originalRow.billing_and_settlement
                            ?.dont_pay_tolls_notes === null ||
                          originalRow.billing_and_settlement
                            ?.dont_pay_tolls_notes === "" ? (
                            "This record will not be included in settlement."
                          ) : (
                            <>
                              This record will not be included in settlement.
                              <br />
                              Reason :{" "}
                              {
                                originalRow.billing_and_settlement
                                  .dont_pay_tolls_notes
                              }
                            </>
                          )
                        ) : (
                          ""
                        )
                      }
                      placement="top-start"
                    >
                      <div>
                        <Checkbox
                          color="secondary"
                          checked={
                            originalRow.billing_and_settlement.dont_pay_tolls
                          }
                          className={`${isRoleView ? "no-events" : ""}`}
                          disabled
                        />
                      </div>
                    </Tooltip>
                  }
                  label="Do Not Pay Toll"
                />
              </div>
            </div>

            <div className="d-flex f-align-center f-wrap">
              {formNotes.map((field) => (
                <div className="d-flex f-align-center flex-column">
                  <Tooltip
                    title={field.updatedTooltip ?? ""}
                    placement="top-start"
                    disableFocusListener
                  >
                    <TextField
                      className={classes.field}
                      disabled
                      label={field.label}
                      variant="outlined"
                      size="small"
                      value={field.updatedValue || "-"}
                      helperText=" "
                      InputProps={{
                        classes: { disabled: classes.disabledText },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={field.originalTooltip ?? ""}
                    placement="top-start"
                    disableFocusListener
                  >
                    <TextField
                      className={classes.originalField}
                      disabled
                      label={field.label}
                      variant="filled"
                      size="small"
                      value={field.originalValue || "-"}
                      helperText=" "
                      InputProps={{
                        classes: { disabled: classes.disabledText },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Tooltip>
                </div>
              ))}
              <div className="d-flex f-align-center f-justify-start f-wrap">
                <div className="d-flex f-justify-center flex-column">
                  {ReviewNotesContent({ rowData: updatedRow })}
                  {ReviewNotesContent({ rowData: originalRow })}
                </div>
              </div>
            </div>

            <div className="d-flex f-align-center f-wrap">
              {formFields.map((field) => (
                <div className="d-flex f-align-center flex-column">
                  <Tooltip
                    title={field.updatedTooltip ?? ""}
                    placement="top-start"
                    disableFocusListener
                  >
                    <TextField
                      className={classes.field}
                      disabled
                      label={field.label}
                      variant="outlined"
                      size="small"
                      value={field.updatedValue || "-"}
                      helperText=" "
                      InputProps={{
                        classes: { disabled: classes.disabledText },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={field.originalTooltip ?? ""}
                    placement="top-start"
                    disableFocusListener
                  >
                    <TextField
                      className={classes.originalField}
                      disabled
                      label={field.label}
                      variant="filled"
                      size="small"
                      value={field.originalValue || "-"}
                      helperText=" "
                      InputProps={{
                        classes: { disabled: classes.disabledText },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Tooltip>
                </div>
              ))}

              <div className="d-flex f-justify-center flex-column">
                {IcidContent({ rowData: updatedRow })}
                {IcidContent({ rowData: originalRow })}
              </div>

              {formFields1.map((field) => (
                <div className="d-flex f-align-center flex-column">
                  {field.canEdit ? (
                    <TextField
                      className={classes.field}
                      label={field?.label}
                      name={field?.name}
                      disabled={field?.disabled}
                      variant="outlined"
                      size="small"
                      type={field?.inputType}
                      value={field?.updatedValue}
                      error={field?.error}
                      helperText={field.helperText}
                      onChange={field?.onChange}
                      onWheel={(event) => event.target.blur()}
                      onKeyDown={preventInputKeyCodes}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          input: field?.classes,
                        },
                      }}
                    />
                  ) : field.isAutocomplete ? (
                    <Autocomplete
                      disableClearable
                      // className="mr-2 w-50 mb-1"
                      className={classes.field}
                      classes={{
                        root: classes.autocompletePaper,
                        listbox: classes.autocompletePaper,
                        inputRoot: field?.classes,
                      }}
                      size="small"
                      disabled={field?.disabled}
                      value={field?.updatedValue}
                      options={field?.options}
                      getOptionLabel={(option) => option.label}
                      onChange={field.onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={field?.label}
                          variant="outlined"
                          helperText=" "
                        />
                      )}
                    />
                  ) : (
                    <Tooltip
                      title={field.updatedTooltip ?? ""}
                      placement="top-start"
                      disableFocusListener
                    >
                      <TextField
                        className={classes.field}
                        disabled
                        label={field.label}
                        variant="outlined"
                        size="small"
                        value={field.updatedValue || "-"}
                        helperText=" "
                        InputProps={{
                          classes: { disabled: classes.disabledText },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={field.originalTooltip ?? ""}
                    placement="top-start"
                    disableFocusListener
                  >
                    <TextField
                      className={classes.originalField}
                      disabled
                      label={field.label}
                      variant="filled"
                      size="small"
                      value={field.originalValue || "-"}
                      helperText=" "
                      InputProps={{
                        classes: { disabled: classes.disabledText },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Tooltip>
                </div>
              ))}
              <div>
                <Typography variant="h6" className="mb-3">
                  Billing
                </Typography>
                <div className="d-flex f-align-center f-wrap mb-6">
                  {updatedRow.billing_and_settlement?.routeDetails
                    ?.price_type === "Matrix" && (
                    <div className="d-flex f-align-center flex-column">
                      <Tooltip
                        title={
                          updatedRow.billing_and_settlement?.routeDetails
                            ?.matrix_name ?? ""
                        }
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.field}
                          disabled
                          label="Price Matrix Name"
                          variant="outlined"
                          size="small"
                          value={
                            updatedRow.billing_and_settlement?.routeDetails
                              ?.matrix_name || "-"
                          }
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          originalRow.billing_and_settlement?.routeDetails
                            ?.matrix_name ?? ""
                        }
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label="Price Matrix Name"
                          variant="filled"
                          size="small"
                          value={
                            originalRow.billing_and_settlement?.routeDetails
                              ?.matrix_name || "-"
                          }
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {billingFormFields.map((field) => (
                    <div className="d-flex f-align-center flex-column">
                      <Autocomplete
                        disableClearable
                        // className="mr-2 w-50 mb-1"
                        className={classes.field}
                        classes={{
                          root: classes.autocompletePaper,
                          listbox: classes.autocompletePaper,
                          inputRoot: field?.classes,
                        }}
                        size="small"
                        disabled={field?.disabled}
                        value={field?.updatedValue}
                        options={field?.options}
                        getOptionLabel={(option) => option.label}
                        onChange={field.onChange}
                        name={field.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={field?.label}
                            variant="outlined"
                            helperText=" "
                          />
                        )}
                      />
                      <Tooltip
                        title={field.originalTooltip ?? ""}
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label={field.label}
                          variant="filled"
                          size="small"
                          value={field.originalValue || "-"}
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  ))}

                  <div className="d-flex f-justify-center flex-column">
                    {BillRateContent({
                      rowData: updatedRow,
                      fieldValue: state.billRateValue,
                    })}
                    {BillRateContent({
                      rowData: originalRow,
                      fieldValue:
                        originalRow.billing_and_settlement?.bill_rate || "-",
                    })}
                  </div>
                  {billingFormFields2.map((field) => (
                    <div className="d-flex f-align-center flex-column">
                      {field.canEdit ? (
                        <TextField
                          className={classes.field}
                          label={field.label}
                          name={field.name}
                          disabled={field.disabled}
                          variant="outlined"
                          size="small"
                          type={field?.inputType}
                          value={field.updatedValue}
                          error={field.error}
                          helperText={field.helperText || " "}
                          onChange={handleFieldChange}
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={preventInputKeyCodes}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            classes: {
                              input: field.classes,
                            },
                          }}
                        />
                      ) : field.isAutocomplete ? (
                        <Autocomplete
                          disableClearable
                          // className="mr-2 w-50 mb-1"
                          className={classes.field}
                          classes={{
                            root: classes.autocompletePaper,
                            listbox: classes.autocompletePaper,
                            inputRoot: field.classes,
                          }}
                          size="small"
                          disabled={field.disabled}
                          value={field.updatedValue}
                          options={field.options}
                          getOptionLabel={(option) => option.label}
                          onChange={field.onChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label={field.label}
                              variant="outlined"
                              helperText=" "
                            />
                          )}
                        />
                      ) : (
                        <Tooltip
                          title={field.updatedTooltip ?? ""}
                          placement="top-start"
                          disableFocusListener
                        >
                          <TextField
                            className={classes.field}
                            disabled
                            label={field.label}
                            variant="outlined"
                            size="small"
                            value={field.updatedValue || "-"}
                            helperText=" "
                            InputProps={{
                              classes: { disabled: classes.disabledText },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        title={field.originalTooltip ?? ""}
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label={field.label}
                          variant="filled"
                          size="small"
                          value={field.originalValue || "-"}
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <Typography variant="h6" className="mb-3">
                  Pay
                </Typography>

                <div className="d-flex f-align-center f-wrap mb-6">
                  {updatedRow.billing_and_settlement?.routeDetails
                    ?.price_type === "Matrix" && (
                    <div className="d-flex f-align-center flex-column">
                      <Tooltip
                        title={
                          updatedRow.billing_and_settlement?.routeDetails
                            ?.matrix_name ?? ""
                        }
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.field}
                          disabled
                          label="Price Matrix Name"
                          variant="outlined"
                          size="small"
                          value={
                            updatedRow.billing_and_settlement?.routeDetails
                              ?.matrix_name || "-"
                          }
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          originalRow.billing_and_settlement?.routeDetails
                            ?.matrix_name ?? ""
                        }
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label="Price Matrix Name"
                          variant="filled"
                          size="small"
                          value={
                            originalRow.billing_and_settlement?.routeDetails
                              ?.matrix_name || "-"
                          }
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {payFormFields.map((field) => (
                    <div className="d-flex f-align-center flex-column">
                      <Autocomplete
                        disableClearable
                        // className="mr-2 w-50 mb-1"
                        className={classes.field}
                        classes={{
                          root: classes.autocompletePaper,
                          listbox: classes.autocompletePaper,
                          inputRoot: field?.classes,
                        }}
                        size="small"
                        disabled={field?.disabled}
                        value={field?.updatedValue}
                        options={field?.options}
                        getOptionLabel={(option) => option.label}
                        onChange={field.onChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={field?.label}
                            variant="outlined"
                            helperText=" "
                          />
                        )}
                      />
                      <Tooltip
                        title={field.originalTooltip ?? ""}
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label={field.label}
                          variant="filled"
                          size="small"
                          value={field.originalValue || "-"}
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  ))}

                  <div className="d-flex f-justify-center flex-column">
                    {PayRateContent({
                      rowData: updatedRow,
                    })}
                    {PayRateContent({
                      rowData: originalRow,
                    })}
                  </div>
                  {payFormFields1.map((field) => (
                    <div className="d-flex f-align-center flex-column">
                      {field.canEdit ? (
                        <TextField
                          className={classes.field}
                          label={field.label}
                          name={field.name}
                          disabled={field.disabled}
                          variant="outlined"
                          size="small"
                          type={field?.inputType}
                          value={field.updatedValue}
                          error={field.error}
                          helperText={field.helperText || " "}
                          onChange={handleFieldChange}
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={preventInputKeyCodes}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            classes: {
                              input: field.classes,
                            },
                          }}
                        />
                      ) : field.isAutocomplete ? (
                        <Autocomplete
                          disableClearable
                          // className="mr-2 w-50 mb-1"
                          className={classes.field}
                          classes={{
                            root: classes.autocompletePaper,
                            listbox: classes.autocompletePaper,
                            inputRoot: field.classes,
                          }}
                          size="small"
                          disabled={field.disabled}
                          value={field.updatedValue}
                          options={field.options}
                          getOptionLabel={(option) => option.label}
                          onChange={field.onChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label={field.label}
                              variant="outlined"
                              helperText=" "
                            />
                          )}
                        />
                      ) : (
                        <Tooltip
                          title={field.updatedTooltip ?? ""}
                          placement="top-start"
                          disableFocusListener
                        >
                          <TextField
                            className={classes.field}
                            disabled
                            label={field.label}
                            variant="outlined"
                            size="small"
                            value={field.updatedValue || "-"}
                            helperText=" "
                            InputProps={{
                              classes: { disabled: classes.disabledText },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        title={field.originalTooltip ?? ""}
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label={field.label}
                          variant="filled"
                          size="small"
                          value={field.originalValue || "-"}
                          helperText=" "
                          InputProps={{
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <Typography variant="h6" className="mb-3">
                  Misc.
                </Typography>

                <div className="d-flex f-align-center f-wrap mb-6">
                  {formFields3.map((field) => (
                    <div className="d-flex f-align-center flex-column">
                      {field.canEdit ? (
                        <TextField
                          className={classes.field}
                          label={field.label}
                          name={field.name}
                          disabled={field.disabled}
                          variant="outlined"
                          size="small"
                          type={field?.inputType}
                          value={field.updatedValue}
                          error={field.error}
                          helperText={field.helperText}
                          onChange={handleFieldChange}
                          onWheel={(event) => event.target.blur()}
                          onKeyDown={preventInputKeyCodes}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: field.updatedStartAdornment,
                            classes: {
                              input: field.classes,
                            },
                          }}
                        />
                      ) : field.isAutocomplete ? (
                        <Autocomplete
                          disableClearable
                          // className="mr-2 w-50 mb-1"
                          className={classes.field}
                          classes={{
                            root: classes.autocompletePaper,
                            listbox: classes.autocompletePaper,
                            inputRoot: field.classes,
                          }}
                          size="small"
                          disabled={field.disabled}
                          value={field.updatedValue}
                          options={field.options}
                          getOptionLabel={(option) => option.label}
                          onChange={field.onChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label={field.label}
                              variant="outlined"
                              helperText=" "
                            />
                          )}
                        />
                      ) : (
                        <Tooltip
                          title={field.updatedTooltip ?? ""}
                          placement="top-start"
                          disableFocusListener
                        >
                          <TextField
                            className={classes.field}
                            disabled
                            label={field.label}
                            variant="outlined"
                            size="small"
                            value={field.updatedValue || "-"}
                            helperText=" "
                            InputProps={{
                              classes: { disabled: classes.disabledText },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        title={field.originalTooltip ?? ""}
                        placement="top-start"
                        disableFocusListener
                      >
                        <TextField
                          className={classes.originalField}
                          disabled
                          label={field.label}
                          variant="filled"
                          size="small"
                          value={field.originalValue || "-"}
                          helperText=" "
                          InputProps={{
                            startAdornment: field.originalStartAdornment,
                            classes: { disabled: classes.disabledText },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="d-flex f-align-center f-justify-end p-4">
            <Button onClick={handleClose} className="ml-2 mr-2" color="primary">
              Close
            </Button>
            <Button
              className="ml-2 mr-2"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  saveInfoWarning: true,
                }));
              }}
              color="primary"
              variant="contained"
              disabled={!isFieldChange || validate()}
            >
              Save
            </Button>
            {renderGridActions(
              handleSubmit,
              isValidate,
              Boolean(state.reviewNotesValue?.trim()),
              handleNoteChange
            )}
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.saveInfoWarning && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.saveInfoWarning}
          contentText="Changes are temporarily saved for approval but will be lost on page reload or navigation."
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              saveInfoWarning: defaultState.saveInfoWarning,
              billRateTypeValue:
                updatedRow.billing_and_settlement?.bill_rate_type || "-",
              billRateValue: updatedRow.billing_and_settlement?.bill_rate,
              billQuantityValue:
                updatedRow.billing_and_settlement?.bill_quantity,
              billAmountValue: updatedRow.billing_and_settlement?.bill_amount,
              billFuelAmountValue:
                updatedRow.billing_and_settlement?.bill_fuel_amount,
              billTollAmountValue:
                updatedRow.billing_and_settlement?.bill_toll_amount,
              billExtraMilesValue:
                updatedRow.billing_and_settlement.bill_extra_miles,
              billExtraMileAmountValue:
                updatedRow.billing_and_settlement?.bill_extra_mile_amount,
              payRateTypeValue:
                updatedRow.billing_and_settlement?.pay_rate_type,
              payRateValue: updatedRow.billing_and_settlement?.pay_rate,
              payQuantityValue: updatedRow.billing_and_settlement?.pay_quantity,
              payAmountValue: updatedRow.billing_and_settlement?.pay_amount,
              payFuelAmountValue:
                updatedRow.billing_and_settlement?.pay_fuel_amount,
              payTollAmountValue:
                updatedRow.billing_and_settlement?.pay_toll_amount,
              payExtraMilesValue:
                updatedRow.billing_and_settlement.pay_extra_miles,
              payExtraMileAmountValue:
                updatedRow.billing_and_settlement.pay_extra_mile_amount,
              incentiveValue: updatedRow.billing_and_settlement?.reimbursement
                ? (+updatedRow.billing_and_settlement.reimbursement).toFixed(2)
                : "-",
              reviewNotesValue: updatedRow.billing_and_settlement?.sharedNotes,
              dontBill: {
                ...prevState.dontBill,
                value: updatedRow.billing_and_settlement?.dont_bill,
                notes: !!updatedRow.billing_and_settlement?.dont_bill ? (
                  updatedRow.billing_and_settlement?.dont_bill_notes === null ||
                  updatedRow.billing_and_settlement?.dont_bill_notes === "" ? (
                    "This record will not be included in billing"
                  ) : (
                    <>
                      This record will not be included in billing.
                      <br />
                      Reason :{" "}
                      {updatedRow.billing_and_settlement?.dont_bill_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontPay: {
                ...prevState.dontPay,
                value: updatedRow.billing_and_settlement?.dont_pay,
                notes: !!updatedRow.billing_and_settlement?.dont_pay ? (
                  updatedRow.billing_and_settlement?.dont_pay_notes === null ||
                  updatedRow.billing_and_settlement?.dont_pay_notes === "" ? (
                    "This record will not be included in settlement"
                  ) : (
                    <>
                      This record will not be included in settlement.
                      <br />
                      Reason :{" "}
                      {updatedRow.billing_and_settlement?.dont_pay_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontBillMiles: {
                ...prevState.dontBillMiles,
                value: updatedRow.billing_and_settlement?.dont_bill_miles,
                notes: !!updatedRow.billing_and_settlement?.dont_bill_miles ? (
                  updatedRow.billing_and_settlement?.dont_bill_miles_notes ===
                    null ||
                  updatedRow.billing_and_settlement?.dont_bill_miles_notes ===
                    "" ? (
                    "This record will not be included in billing."
                  ) : (
                    <>
                      This record will not be included in billing.
                      <br />
                      Reason :{" "}
                      {updatedRow.billing_and_settlement.dont_bill_miles_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontPayMiles: {
                ...prevState.dontPayMiles,
                value: updatedRow.billing_and_settlement.dont_pay_miles,
                notes: !!updatedRow.billing_and_settlement.dont_pay_miles ? (
                  updatedRow.billing_and_settlement?.dont_pay_miles_notes ===
                    null ||
                  updatedRow.billing_and_settlement?.dont_pay_miles_notes ===
                    "" ? (
                    "This record will not be included in settlement."
                  ) : (
                    <>
                      This record will not be included in settlement.
                      <br />
                      Reason :{" "}
                      {updatedRow.billing_and_settlement.dont_pay_miles_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontBillTolls: {
                ...prevState.dontBillTolls,
                value: updatedRow.billing_and_settlement.dont_bill_tolls,
                notes: !!updatedRow.billing_and_settlement.dont_bill_tolls ? (
                  updatedRow.billing_and_settlement?.dont_bill_tolls_notes ===
                    null ||
                  updatedRow.billing_and_settlement?.dont_bill_tolls_notes ===
                    "" ? (
                    "This record will not be included in billing."
                  ) : (
                    <>
                      This record will not be included in billing.
                      <br />
                      Reason :{" "}
                      {updatedRow.billing_and_settlement.dont_bill_tolls_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontPayTolls: {
                ...prevState.dontPayTolls,
                value: updatedRow.billing_and_settlement.dont_pay_tolls,
                notes: !!updatedRow.billing_and_settlement.dont_pay_tolls ? (
                  updatedRow.billing_and_settlement?.dont_pay_tolls_notes ===
                    null ||
                  updatedRow.billing_and_settlement?.dont_pay_tolls_notes ===
                    "" ? (
                    "This record will not be included in settlement."
                  ) : (
                    <>
                      This record will not be included in settlement.
                      <br />
                      Reason :{" "}
                      {updatedRow.billing_and_settlement.dont_pay_tolls_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
            }));
          }}
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              saveInfoWarning: defaultState.saveInfoWarning,
            }));
            handleSubmit();
          }}
          showPositiveActionLabel={true}
          hasClose={true}
        />
      )}
      {state.confirmDontBill && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBill}
          contentText="By Checking, bill rate will become 0. This action will not be reverted once done"
          onConfirm={() => {
            calculateBill(state.confirmDontBillValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPay && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPay}
          contentText="By Checking, pay rate will become 0. This action will not be reverted once done"
          onConfirm={() => {
            calculatePay(state.confirmDontPayValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontPay: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPay: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontBillExtraMiles && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBillExtraMiles}
          contentText=" By Checking, bill extra miles will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculateBillMiles(state.confirmDontBillExtraMilesValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontBillExtraMiles: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillExtraMiles: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPayExtraMiles && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPayExtraMiles}
          contentText="By Checking, pay extra miles will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculatePayMiles(state.confirmDontPayExtraMilesValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontPayExtraMiles: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayExtraMiles: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontBillToll && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBillToll}
          contentText=" By Checking, bill toll amount will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculateBillToll(state.confirmDontBillTollValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontBillToll: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillToll: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPayToll && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPayToll}
          contentText=" By Checking, pay toll amount will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculatePayToll(state.confirmDontPayTollValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontPayToll: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayToll: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
    </>
  );
};

export default Form;
