import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    maxWidth: props.isTabletView ? "98%" : "89%",
    minWidth: props.isTabletView ? "98%" : "89%",
    maxHeight: props.isTabletView ? "95%" : "90%",
  }),

  disabledText: {
    // color: "rgba(0, 0, 0, 0.87)",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  },
  autocompletePaper: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  field: (props) => ({
    width: props.isTabletView ? 264 : 200,
    marginRight: 24,
    marginBottom: 5,
  }),
  originalField: (props) => ({
    width: props.isTabletView ? 264 : 200,
    marginRight: 24,
    marginBottom: 5,
    color: "rgb(71 0 71)",
  }),
  originalCheckBox: (props) => ({
    color: "rgb(71 0 71)",
  }),
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "20px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  addNewReviewNote: {
    minWidth: "200px",
  },
  paperSize: {
    minWidth: "30%",
  },
  iconOriginal: {
    borderRadius: 3,
    width: 16,
    height: 16,
    // boxShadow:
    //   "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    // // backgroundColor: '#f5f8fa',
    // backgroundImage:
    //   "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgb(224 224 224)",
    },
  },
  iconUpdated: {
    borderRadius: 3,
    width: 16,
    height: 16,

    "input:disabled ~ &": {
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      background: "none",
    },
  },
}));

export default useStyles;
