import { getUTCDateString, getFormattedTime } from "utils";
import jsPDF from "jspdf";
import "jspdf-autotable";
import newOrgLogo from "shared/assets/images/pdx-logo.png";
import isFlagged from "../../../assets/icons/flag.png";
import isCautionFlagged from "../../../assets/icons/caution.png";
import { HEADERS, PDF_COLUMN_STYLES } from "shared";

const GenerateDCNPdf = ({
  dcnDetails = [],
  dcnDetailedHistory = [],
  metadata = "",
}) => {
  const doc = new jsPDF({
    orientation: "landscape",
    format: "a3",
  });

  doc.setFontSize(14);

  const unsettledIndices = dcnDetails
    .map((row, index) => (!!row.isUnsettledRecord ? index : null))
    .filter((index) => index !== null);

  const rows = dcnDetails.map((item, index) => {
    const originalData = [
      item.postData.paydata[0]["hold"] == 0 ? "No" : "Yes",
      item.originalData.isFlagged === "True"
        ? "true"
        : item.originalData.isFlagged === "True" &&
          item.originalData.isCautiousFlagged === "True"
        ? "true"
        : item.originalData.isCautiousFlagged === "True"
        ? "false"
        : "-",
      item.originalData.ICID,
      item.originalData.DeptNum,
      item.originalData.LastName || "-",
      item.originalData.FirstName,
      item.originalData.SSN
        ? item.originalData.SSN.substring(item.originalData.SSN.length - 4)
        : "-",
      item.originalData.EIN || "-",
      item.originalData.CoName || "-",
      item.originalData.paydata[0]["Settlement"],
      item.originalData.paydata[0]["FuelReimbursement"],
      item.originalData.paydata[0]["TollReimbursement"],
      item.originalData.paydata[0]["MileageReimbursement"],
      item.originalData.paydata[0]["Reimbursement"],
      item.originalData.paydata[0]["MiscDeduction"],
      item.originalData.paydata[0]["Misc2Deduction"],
      item.originalData.paydata[0]["FuelAdvanceDeduction"],
      item.originalData.paydata[0]["FuelAdvance2"],
      item.originalData.paydata[0]["CashAdvanceDeduction"],
      item.originalData.paydata[0]["CashAdvance2Deduction"],
      item.originalData.paydata[0]["ClaimDeduction"],
      item.originalData.paydata[0]["Claim2Deduction"],
      item.originalData.paydata[0]["Claim3Deduction"],
      item.originalData.paydata[0]["Rental1Deduction"],
      item.originalData.paydata[0]["Rental2Deduction"],
      item.originalData.paydata[0]["Rental3Deduction"],
      item.originalData.paydata[0]["Rental4Deduction"],
      item.originalData.paydata[0]["EOR"],
    ];

    const postDate = [
      item.postData.paydata[0]["hold"] == 0 ? "No" : "Yes",
      item.originalData.isFlagged === "True"
        ? "true"
        : item.originalData.isFlagged === "True" &&
          item.originalData.isCautiousFlagged === "True"
        ? "true"
        : item.originalData.isCautiousFlagged === "True"
        ? "false"
        : "-",
      item.postData.ICID,
      item.postData.DeptNum,
      item.postData.LastName || "-",
      item.postData.FirstName,
      item.postData.SSN
        ? item.postData.SSN.substring(item.postData.SSN.length - 4)
        : "-",
      item.postData.EIN || "-",
      item.postData.CoName || "-",
      item.postData.paydata[0]["Settlement"],
      item.postData.paydata[0]["FuelReimbursement"],
      item.postData.paydata[0]["TollReimbursement"],
      item.postData.paydata[0]["MileageReimbursement"],
      item.postData.paydata[0]["Reimbursement"],
      item.postData.paydata[0]["MiscDeduction"],
      item.postData.paydata[0]["Misc2Deduction"],
      item.postData.paydata[0]["FuelAdvanceDeduction"],
      item.postData.paydata[0]["FuelAdvance2"],
      item.postData.paydata[0]["CashAdvanceDeduction"],
      item.postData.paydata[0]["CashAdvance2Deduction"],
      item.postData.paydata[0]["ClaimDeduction"],
      item.postData.paydata[0]["Claim2Deduction"],
      item.postData.paydata[0]["Claim3Deduction"],
      item.postData.paydata[0]["Rental1Deduction"],
      item.postData.paydata[0]["Rental2Deduction"],
      item.postData.paydata[0]["Rental3Deduction"],
      item.postData.paydata[0]["Rental4Deduction"],
      item.postData.paydata[0]["EOR"],
    ];

    const combinedData = originalData.map((original, i) => {
      const updated = postDate[i];
      if (original !== updated) {
        return original + "\n\n" + updated;
      } else {
        return original;
      }
    });

    return combinedData;
  });

  const heading = "DCN DETAILED HISTORY";

  const headerText = `Settlement Company : ${
    dcnDetailedHistory?.settlementCompany?.value
  }\nSent Date & Time : ${getUTCDateString(
    dcnDetailedHistory?.sent_at
  )} ${getFormattedTime(new Date(dcnDetailedHistory?.sent_at))}\nSent By : ${
    dcnDetailedHistory?.sentBy?.first_name
  } ${dcnDetailedHistory?.sentBy?.last_name} (${
    dcnDetailedHistory?.sentBy?.username
  })\nPay Date : ${getUTCDateString(
    dcnDetailedHistory?.pay_date
  )}\nStart Date : ${metadata?.fromDate}\nEnd Date : ${metadata?.toDate}`;

  const pdxLogo = {
    text: "Powered by",
    image: newOrgLogo,
  };

  const subheading =
    "Abbreviations : Settlement (Sett.), Company (Co.), Deduction (Ded.), Advance (Adv.), Reimbursement/Incentive (Reimb. / Inc.)";

  doc.setFont("helvetica", "bold");
  doc.setTextColor("#004700");

  // Calculate the width of the heading
  const headingWidth = doc.getTextWidth(heading);

  // Calculate the x-coordinate for the heading
  const headingX = (doc.internal.pageSize.width - headingWidth) / 2;

  // Calculate the y-coordinate for the heading
  const headingY = 16;

  // Calculate the x-coordinate for the logo
  const logoX = doc.internal.pageSize.width - 50;

  // Calculate the y-coordinate for the logo
  const logoY = 20;

  // Display the heading
  doc.setFontSize(16);
  doc.text(heading, headingX, headingY);

  // Display the headerText
  doc.setFontSize(12);
  doc.text(headerText, 11, 20);

  // Display the "Powered by" text
  doc.setFontSize(8);
  doc.setTextColor("#9e9e9e");
  doc.text(pdxLogo.text, logoX + 16, logoY - 3);

  doc.addImage(pdxLogo.image, "JPEG", logoX + 7, logoY, 35, 15);

  doc.setFont("helvetica", "bold");
  doc.setTextColor("#004700");

  // Display the subheading
  doc.setFontSize(12);
  doc.text(subheading, 11, 56);

  doc.autoTable({
    head: [HEADERS],
    body: rows,
    startY: 58,
    theme: "grid",
    margin: { left: 11 },
    styles: { fontSize: 9 },
    headStyles: { fontSize: 7 },
    columnStyles: PDF_COLUMN_STYLES,
    didDrawCell: function (data) {
      if (data.cell.raw.includes("\n\n")) {
        const parts = data.cell.raw.split("\n\n");
        if (parts[1] !== parts[0]) {
          doc.setTextColor("#f44438");
          doc.text(parts[1], data.cell.x + 2, data.cell.y + 12, {
            baseline: "bottom",
          });
        }
      }
      if (data.column.index === 1 && data.cell.raw === "true") {
        doc.addImage(isFlagged, "png", data.cell.x + 3, data.cell.y + 2, 4, 4);
      } else if (data.column.index === 1 && data.cell.raw === "false") {
        doc.addImage(
          isCautionFlagged,
          "png",
          data.cell.x + 2,
          data.cell.y + 1,
          5,
          5
        );
      }
    },
    willDrawCell: function (data) {
      if (data.cell.raw.includes("\n\n")) {
        const parts = data.cell.raw.split("\n\n");
        if (parts[1] !== parts[0]) {
          const emptyStringIndex = data.cell.text.indexOf("");
          if (emptyStringIndex !== -1) {
            data.cell.text.splice(emptyStringIndex);
          }
        }
      }
      if (
        data.column.index === 1 &&
        (data.cell.raw === "true" || data.cell.raw === "false")
      ) {
        data.cell.text = [];
      }
    },
    didParseCell: function (data) {
      if (
        data.section === "body" &&
        unsettledIndices.includes(data.row.index)
      ) {
        data.cell.styles.fillColor = [247, 246, 229];
      }
    },
  });

  for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
    doc.setPage(i);
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000000");
    doc.setFontSize(10);
    doc.text(
      `Page ${i} of ${doc.internal.getNumberOfPages()}`,
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      { align: "center" }
    );
  }

  return doc;
};

export default GenerateDCNPdf;
