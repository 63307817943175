import React, { useEffect, useState } from "react";
import { ActionDialog, Dialog } from "shared/components";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./style";
import { Autocomplete } from "@material-ui/lab";
import { getDateString, getFormattedTime, getUTCDateString } from "utils";
import { APX_COMPANY, noop, VALIDATIONS } from "shared/constants";
import { preventInputKeyCodes, validator } from "utils";
import clsx from "clsx";
import PostAddIcon from "@material-ui/icons/PostAdd";
import WarningIcon from "@material-ui/icons/Warning";
import FlagIcon from "@material-ui/icons/Flag";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { RATE_TYPES } from "modules/shared/constants";

const defaultState = {
  dsPayRateTypeValue: {},
  dsPayRateValue: 0,
  dsPayQuantityValue: 0,
  updatedBilltollAmountValue: 0,
  dsPayTollAmountValue: 0,
  dsPayExtraMilesValue: 0,
  incentiveValue: 0,
  reviewNotesValue: "",
  saveInfoWarning: false,
  dsRateTypeOptions: [],
  errors: {
    dsPayRateTypeValue: " ",
    dsPayRateValue: " ",
    dsPayQuantityValue: " ",
    updatedBilltollAmountValue: " ",
    dsPayTollAmountValue: " ",
    incentiveValue: " ",
    reviewNotesValue: " ",
  },
  confirmDontBill: false,
  confirmDontBillValue: false,
  confirmDontPay: false,
  confirmDontPayValue: false,
  confirmDontBillExtraMiles: false,
  confirmDontBillExtraMilesValue: false,
  confirmDontPayExtraMiles: false,
  confirmDontPayExtraMilesValue: false,
  confirmDontBillToll: false,
  confirmDontBillTollValue: false,
  confirmDontPayToll: false,
  confirmDontPayTollValue: false,

  dontBill: {
    value: false,
    notes: null,
  },
  dontPay: {
    value: false,
    notes: null,
  },
  dontBillMiles: {
    value: false,
    notes: null,
  },
  dontPayMiles: {
    value: false,
    notes: null,
  },
  dontBillTolls: {
    value: false,
    notes: null,
  },
  dontPayTolls: {
    value: false,
    notes: null,
  },
};

let isFieldChange = false;

const Form = ({
  open,
  onClose = noop,
  row = null,
  onSave = noop,
  appData,
  appDataList,
  isRoleView,
  isRoleMGR,
  handleLabelClick = noop,
  userInfo = noop,
  userFlagInfo = noop,
  handleShowAddLookupDialog = noop,
  reviewNotesOptions = [],
  renderGridActions = noop,
  onConfirm = noop,
  events = {},
  priceMatrix = [],
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const handleClose = () => {
    isFieldChange = false;
    onClose();
  };

  useEffect(() => {
    if (row) {
      setState((prevState) => ({
        ...prevState,
        dsPayRateTypeValue:
          row.daily_schedule.pay_rate_type || defaultState.dsPayRateTypeValue,
        dsPayRateValue:
          row.daily_schedule.pay_rate || defaultState.dsPayRateValue,
        dsPayQuantityValue:
          row.daily_schedule.pay_quantity || defaultState.dsPayQuantityValue,
        updatedBilltollAmountValue:
          row.bill_toll_amount || defaultState.updatedBilltollAmountValue,
        dsPayTollAmountValue:
          row.daily_schedule.pay_toll_amount ||
          defaultState.dsPayTollAmountValue,
        dsPayExtraMilesValue:
          row.daily_schedule !== null
            ? row.daily_schedule?.pay_extra_miles
            : defaultState.dsPayExtraMilesValue,
        incentiveValue:
          row.daily_schedule?.reimbursement || defaultState.incentiveValue,
        reviewNotesValue:
          row.daily_schedule?.sharedNotes || defaultState.reviewNotesValue,
        dsRateTypeOptions:
          row.daily_schedule?.individual_contractor?.contractorType?.value ===
          "Stand-by"
            ? appDataList.icList
                ?.find(
                  (ele) =>
                    ele.id === row?.daily_schedule?.individual_contractor?.id
                )
                ?.StandByRates?.map((rate) => ({
                  value: rate,
                  label: rate?.rateType?.value,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
            : RATE_TYPES.map((rateType) => ({
                value: rateType,
                label: rateType,
              })),

        dontBill: {
          ...prevState.dontBill,
          value: row.daily_schedule?.dont_bill,
          notes: !!row.daily_schedule?.dont_bill ? (
            row.daily_schedule?.dont_bill_notes === null ||
            row.daily_schedule?.dont_bill_notes === "" ? (
              "This record will not be included in billing"
            ) : (
              <>
                This record will not be included in billing.
                <br />
                Reason : {row.daily_schedule?.dont_bill_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontPay: {
          ...prevState.dontPay,
          value: row.daily_schedule?.dont_pay,
          notes: !!row.daily_schedule?.dont_pay ? (
            row.daily_schedule?.dont_pay_notes === null ||
            row.daily_schedule?.dont_pay_notes === "" ? (
              "This record will not be included in settlement"
            ) : (
              <>
                This record will not be included in settlement.
                <br />
                Reason : {row.daily_schedule?.dont_pay_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontBillMiles: {
          ...prevState.dontBillMiles,
          value: row.daily_schedule?.dont_bill_miles,
          notes: !!row.daily_schedule.dont_bill_miles ? (
            row.daily_schedule?.dont_bill_miles_notes === null ||
            row.daily_schedule?.dont_bill_miles_notes === "" ? (
              "This record will not be included in billing."
            ) : (
              <>
                This record will not be included in billing.
                <br />
                Reason : {row.daily_schedule.dont_bill_miles_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontPayMiles: {
          ...prevState.dontPayMiles,
          value: row.daily_schedule.dont_pay_miles,
          notes: !!row.daily_schedule.dont_pay_miles ? (
            row.daily_schedule?.dont_pay_miles_notes === null ||
            row.daily_schedule?.dont_pay_miles_notes === "" ? (
              "This record will not be included in settlement."
            ) : (
              <>
                This record will not be included in settlement.
                <br />
                Reason : {row.daily_schedule.dont_pay_miles_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontBillTolls: {
          ...prevState.dontBillTolls,
          value: row.daily_schedule.dont_bill_tolls,
          notes: !!row.daily_schedule.dont_bill_tolls ? (
            row.daily_schedule?.dont_bill_tolls_notes === null ||
            row.daily_schedule?.dont_bill_tolls_notes === "" ? (
              "This record will not be included in billing."
            ) : (
              <>
                This record will not be included in billing.
                <br />
                Reason : {row.daily_schedule.dont_bill_tolls_notes}
              </>
            )
          ) : (
            ""
          ),
        },
        dontPayTolls: {
          ...prevState.dontPayTolls,
          value: row.daily_schedule.dont_pay_tolls,
          notes: !!row.daily_schedule.dont_pay_tolls ? (
            row.daily_schedule?.dont_pay_tolls_notes === null ||
            row.daily_schedule?.dont_pay_tolls_notes === "" ? (
              "This record will not be included in settlement."
            ) : (
              <>
                This record will not be included in settlement.
                <br />
                Reason : {row.daily_schedule.dont_pay_tolls_notes}
              </>
            )
          ) : (
            ""
          ),
        },
      }));
    }
  }, [row]);

  useEffect(() => {
    const isAPXCompany =
      row?.daily_schedule &&
      row?.daily_schedule?.customer_branch?.pdxCompany?.value === APX_COMPANY;
    const maxQuantity = isAPXCompany ? 10000 : 24;
    if (
      state.dsPayRateTypeValue === "Daily" &&
      +state.dsPayQuantityValue > 1.99
    ) {
      const errorMessage = validate(
        "dsPayQuantityValue",
        +state.dsPayQuantityValue
      );
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            dsPayQuantityValue: errorMessage,
          },
        }));
      }
    } else if (
      state.dsPayRateTypeValue === "Hourly" &&
      +state.dsPayQuantityValue > maxQuantity
    ) {
      const errorMessage = validate(
        "dsPayQuantityValue",
        +state.dsPayQuantityValue
      );
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            dsPayQuantityValue: errorMessage,
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          dsPayQuantityValue: " ",
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dsPayRateTypeValue, state.dsPayQuantityValue]);

  const getFieldValidatorMap = (state, field) => {
    const { dsPayRateTypeValue } = state;
    const fieldValidatorMap = {
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],

      dsPayRateValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      dsPayQuantityValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      dsPayTollAmountValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],

      dsPayRateTypeValue:
        (
          row.daily_schedule?.individual_contractor?.contractorType?.value || ""
        ).toLowerCase() === "stand-by"
          ? [{ type: VALIDATIONS.REQUIRED, value: true }]
          : [],
      updatedBilltollAmountValue: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      incentiveValue: [{ type: VALIDATIONS.MIN, value: 0 }],
    };

    if (dsPayRateTypeValue === "Hourly") {
      const isAPXCompany =
        row?.daily_schedule &&
        row?.daily_schedule?.customer_branch?.pdxCompany?.value === APX_COMPANY;
      fieldValidatorMap["dsPayQuantityValue"] = [
        {
          type: VALIDATIONS.MAX,
          value: isAPXCompany ? 10000 : 24,
        },
        ...fieldValidatorMap["dsPayQuantityValue"],
      ];
    }

    if (dsPayRateTypeValue === "Daily") {
      fieldValidatorMap["dsPayQuantityValue"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["dsPayQuantityValue"] || []),
      ];
    }

    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const formatNumber = (value) => {
    const num = +value;

    if (isNaN(num)) return value;

    return num.toFixed(2);
  };

  const handleSubmit = () => {
    isFieldChange = false;
    if (state.dsPayRateTypeValue !== row.daily_schedule.pay_rate_type) {
      onSave(row.id, "daily_schedule.pay_rate_type", state.dsPayRateTypeValue);
    }

    if (state.dsPayRateValue !== row.daily_schedule.pay_rate) {
      setState((prevState) => ({
        ...prevState,
        dsPayRateValue: formatNumber(prevState.dsPayRateValue),
      }));
      onSave(
        row.id,
        "daily_schedule.pay_rate",
        formatNumber(state.dsPayRateValue)
      );
    }

    if (state.dsPayQuantityValue !== row.daily_schedule.pay_quantity) {
      setState((prevState) => ({
        ...prevState,
        dsPayQuantityValue: formatNumber(prevState.dsPayQuantityValue),
      }));
      onSave(
        row.id,
        "daily_schedule.pay_quantity",
        formatNumber(state.dsPayQuantityValue)
      );
    }

    if (state.updatedBilltollAmountValue !== row.bill_toll_amount) {
      setState((prevState) => ({
        ...prevState,
        updatedBilltollAmountValue: formatNumber(
          prevState.updatedBilltollAmountValue
        ),
      }));
      onSave(
        row.id,
        "bill_toll_amount",
        formatNumber(state.updatedBilltollAmountValue)
      );
    }

    if (state.dsPayTollAmountValue !== row.daily_schedule.pay_toll_amount) {
      setState((prevState) => ({
        ...prevState,
        dsPayTollAmountValue: formatNumber(prevState.dsPayTollAmountValue),
      }));
      onSave(
        row.id,
        "daily_schedule.pay_toll_amount",
        formatNumber(state.dsPayTollAmountValue)
      );
    }

    if (state.incentiveValue !== row.daily_schedule?.reimbursement) {
      setState((prevState) => ({
        ...prevState,
        incentiveValue: formatNumber(prevState.incentiveValue),
      }));
      onSave(
        row.id,
        "daily_schedule.reimbursement",
        formatNumber(state.incentiveValue)
      );
    }
    if (state.reviewNotesValue !== row.daily_schedule?.sharedNotes) {
      onSave(row.id, "daily_schedule.sharedNotes", state.reviewNotesValue);
      onSave(row.id, "daily_schedule.review_notes", state.reviewNotesValue);
    }

    if (state.dontBill.value !== row.daily_schedule?.dont_bill) {
      onConfirm(state.dontBill.value, row.id, events.calculateBill);
    }
    if (state.dontPay.value !== row.daily_schedule?.dont_pay) {
      onConfirm(state.dontPay.value, row.id, events.calculatePay);
    }
    if (state.dontBillMiles.value !== row.daily_schedule?.dont_bill_miles) {
      onConfirm(state.dontBillMiles.value, row.id, events.calculateBillMiles);
    }
    if (state.dontPayMiles.value !== row.daily_schedule.dont_pay_miles) {
      onConfirm(state.dontPayMiles.value, row.id, events.calculatePayMiles);
    }
    if (state.dontBillTolls.value !== row.daily_schedule.dont_bill_tolls) {
      onConfirm(state.dontBillTolls.value, row.id, events.calculateBillToll);
    }
    if (state.dontPayTolls.value !== row.daily_schedule.dont_pay_tolls) {
      onConfirm(state.dontPayTolls.value, row.id, events.calculatePayToll);
    }
  };

  const handleNoteChange = () => {
    isFieldChange = false;
    if (state.reviewNotesValue !== row.daily_schedule?.sharedNotes) {
      onSave(row.id, "daily_schedule.sharedNotes", state.reviewNotesValue);
      onSave(row.id, "daily_schedule.review_notes", state.reviewNotesValue);
    }
  };

  const UpdatedByContent = () => {
    const updatedBy = appData.users.find(
      (ele) => ele?.id === row?.daily_schedule?.updated_by || null
    );
    let user = "";
    if (updatedBy) {
      user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
        updatedBy.username
      })`;
    }

    return (
      <Tooltip title={user ?? ""} placement="top-start">
        <TextField
          className={classes.field}
          disabled={true}
          label="Last Updated By"
          variant="outlined"
          size="small"
          value={user || "-"}
          helperText=" "
          InputProps={{ classes: { disabled: classes.disabledText } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Tooltip>
    );
  };

  const SubmittedByContent = () => {
    const kam = appData.users.find(
      (ele) => ele?.id === row.daily_schedule?.submitted_by || null
    );
    let submittedBy = null;
    if (kam) {
      submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
        kam.email || ""
      }-(${kam.username || ""})`;
    }

    return (
      <Tooltip title={submittedBy ?? ""} placement="top-start">
        <TextField
          className={classes.field}
          disabled={true}
          label="Submitted By"
          variant="outlined"
          size="small"
          value={submittedBy || "-"}
          helperText=" "
          InputProps={{ classes: { disabled: classes.disabledText } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Tooltip>
    );
  };

  const WCPDriverContent = () => {
    const dailyScheduleRow = row?.daily_schedule;
    const driver = dailyScheduleRow?.driverContractor;
    const driverName = driver
      ? `${driver?.first_name || ""} ${driver?.last_name || ""} (#${
          driver?.id
        })`
      : null;

    return (
      <Tooltip title={driverName ?? ""} placement="top-start">
        <TextField
          className={classes.field}
          disabled={true}
          label="WCP Driver"
          variant="outlined"
          size="small"
          value={driverName || "-"}
          helperText=" "
          InputProps={{ classes: { disabled: classes.disabledText } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Tooltip>
    );
  };

  const IcidContent = () => {
    let title = !!row.daily_schedule?.individual_contractor?.icid
      ? `
      IC Settlement Company Name: ${
        row.daily_schedule.individual_contractor?.settlementCompany?.value ||
        "-"
      }, 
      IC Name:${
        (row.daily_schedule?.individual_contractor?.first_name || "") +
        " " +
        (row.daily_schedule?.individual_contractor?.last_name || "")
      },
       ICID:${row.daily_schedule?.individual_contractor?.icid || ""},
      Contractor Type:${
        row.daily_schedule?.individual_contractor?.contractorType?.value || ""
      },
     
    `
      : "";
    const label = row.daily_schedule.individual_contractor?.icid
      ? `${row.daily_schedule.individual_contractor?.icid || ""}/${
          row.daily_schedule.individual_contractor?.last_name || ""
        } ${row.daily_schedule.individual_contractor?.first_name || ""}`
      : "";

    const flagLabel =
      (!!row.daily_schedule?.individual_contractor?.is_flagged &&
        !!row.daily_schedule?.individual_contractor?.is_cautious_flagged) ||
      !!row.daily_schedule?.individual_contractor?.is_flagged ? (
        <FlagIcon style={{ color: "red" }} />
      ) : row.daily_schedule?.individual_contractor?.is_cautious_flagged ? (
        <div className={classes.warningIconHeight}>
          <WarningIcon className={classes.warningIcon} />
          <div className={classes.exclamationMark}></div>
        </div>
      ) : (
        ""
      );
    const cautionFlaggedBy = userInfo(
      row.daily_schedule?.individual_contractor?.ctn_flag_by
    );
    const cautionFlaggedReason = userFlagInfo(
      row.daily_schedule?.individual_contractor?.ctn_flag_rsn
    );
    const flagTitles = `Flagged Reason: ${
      row.daily_schedule.individual_contractor?.flagReason?.value ||
      cautionFlaggedReason?.value
    }, Flagged By: ${
      row.daily_schedule.individual_contractor?.flaggedBy?.first_name ||
      cautionFlaggedBy?.first_name ||
      ""
    } ${
      row.daily_schedule.individual_contractor?.flaggedBy?.last_name ||
      cautionFlaggedBy?.last_name ||
      ""
    }, USERNAME: ${
      row.daily_schedule.individual_contractor?.flaggedBy?.username ||
      cautionFlaggedBy?.username ||
      ""
    }, ROLE: ${
      row.daily_schedule.individual_contractor?.flaggedBy?.role ||
      cautionFlaggedBy?.role ||
      ""
    }, Flagged At: ${getDateString(
      row.daily_schedule.individual_contractor?.flagged_at ||
        row?.individual_contractor?.ctn_flagged_at
    )}`;
    return (
      <>
        <TextField
          value={label || "-"}
          className={classes.field}
          label="ICID/IC Name"
          variant="outlined"
          size="small"
          helperText=" "
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={flagTitles} placement="top-start">
                  <IconButton edge="start" size="small">
                    {flagLabel}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="View Info" placement="top-start">
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => {
                      !!row.daily_schedule?.individual_contractor?.icid &&
                        handleLabelClick(row);
                    }}
                    style={{
                      cursor: !!row.daily_schedule?.individual_contractor?.icid
                        ? "pointer"
                        : "default",
                    }}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            inputComponent: ({ inputRef, ...props }) => (
              <Tooltip title={title ?? ""} placement="top-start">
                <div
                  ref={inputRef}
                  {...props}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    textOverflow: "ellipsis",
                    cursor: "default",
                    color: "rgba(0, 0, 0, 0.4)",
                  }}
                >
                  {label || "-"}
                </div>
              </Tooltip>
            ),
          }}
        />
      </>
    );
  };

  const PayRateContent = () => {
    let billPayPrice = [];

    if (row.daily_schedule?.routeDetails?.price_type === "Matrix") {
      billPayPrice = priceMatrix?.filter(
        (item) =>
          row.daily_schedule?.customer_branch?.id ===
            item?.customer_branch_id &&
          item.name === row?.daily_schedule?.routeDetails?.matrix_name
      );
    }

    const options = billPayPrice?.map((item) => ({
      value: item.pay_price,
      label: `$ ${item.pay_price} (${item.quantity})`,
    }));

    const label = row.daily_schedule?.pay_rate
      ? (() => {
          const matchingBillOption = billPayPrice?.find(
            (billOption) => billOption?.pay_price == state.dsPayRateValue
          );

          if (matchingBillOption) {
            return `$ ${state.dsPayRateValue} (${matchingBillOption.quantity})`;
          } else {
            return `$ ${state.dsPayRateValue}`;
          }
        })()
      : "-";

    return row.daily_schedule?.routeDetails?.price_type === "Matrix" ? (
      <Autocomplete
        disableClearable
        // className="mr-2 w-50 mb-1"
        className={classes.field}
        classes={{
          root: classes.autocompletePaper,
          listbox: classes.autocompletePaper,
          inputRoot: clsx(
            row.base_roster !== null &&
              row.daily_schedule.pay_rate !== row.base_roster?.pay_rate &&
              "color-error"
          ),
        }}
        size="small"
        value={{ label }}
        disabled={
          isRoleView ||
          row.daily_schedule?.individual_contractor?.contractorType ===
            "Stand-by" ||
          !!state.dontPay.value
        }
        options={options || []}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="DS Pay Rate"
            variant="outlined"
            helperText=" "
          />
        )}
        onChange={(evt, value) => {
          isFieldChange = true;
          const newValue = value?.value;

          setState((prevState) => ({
            ...prevState,

            dsPayRateValue: newValue,
          }));
        }}
      />
    ) : (
      <TextField
        className={classes.field}
        label="DS Pay Rate"
        disabled={
          isRoleView ||
          row.daily_schedule?.individual_contractor?.contractorType?.value ===
            "Stand-by" ||
          !!row.daily_schedule?.dont_pay
        }
        name="dsPayRateValue"
        variant="outlined"
        size="small"
        type="number"
        value={state.dsPayRateValue}
        error={!!state.errors.dsPayRateValue.trim()}
        helperText={state.errors.dsPayRateValue}
        onChange={handleFieldChange}
        onWheel={(event) => event.target.blur()}
        onKeyDown={preventInputKeyCodes}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          classes: {
            input: clsx(
              row.base_roster !== null &&
                row.daily_schedule.pay_rate !== row.base_roster?.pay_rate &&
                "color-error"
            ),
          },
        }}
      />
    );
  };

  const ReviewNotesContent = () => {
    let finalNotes;

    if (row.initialReviewNotes !== null && !row.review_notes?.length) {
      let splitNotes = row.initialReviewNotes?.split("||");

      finalNotes = splitNotes?.map((note) => {
        let str = note;

        const startIndexOfDate = str?.indexOf("-");
        const lastIndexOfDate = str?.indexOf(": ");
        const formattedDate = new Date(
            str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
          )?.toLocaleString(),
          str1 =
            str.substring(0, startIndexOfDate) +
            "-" +
            formattedDate +
            str.substring(lastIndexOfDate);
        return str1;
      });
    }

    const title = (
      <div
        dangerouslySetInnerHTML={{
          __html: (finalNotes || []).join("<br>"),
        }}
      />
    );

    let lastNote = !!finalNotes ? finalNotes[0] : "";
    let x = lastNote.split(": ");
    const userName = x[0].split("-")[0];
    const noteTime = x[0].split("-")[1]?.split(",");
    delete x[0];
    const message = x.join(": ");

    return (
      <div className="d-flex f-align-center">
        {!finalNotes || !userName ? (
          <TextField
            value="-"
            disabled
            className={classes.field}
            variant="outlined"
            size="small"
            helperText=" "
            label="Review Notes"
            InputProps={{
              readOnly: true,
              classes: { disabled: classes.disabledText },
            }}
            fullWidth
          />
        ) : (
          <Tooltip title={title || "-"} placement="top-start">
            <TextField
              value={`${userName} ${noteTime[0]}, ${noteTime[1]}: ${
                message.length > 50
                  ? message.slice(2, 50) + "..."
                  : message.slice(2, 100)
              }`}
              InputProps={{
                readOnly: true,
                classes: { disabled: classes.disabledText },
              }}
              disabled
              fullWidth
              className={classes.field}
              variant="outlined"
              size="small"
              helperText=" "
              label="Review Notes"
            />
          </Tooltip>
        )}
        {!isRoleView && (
          <div className="d-flex " style={{ width: 290, gap: "4px" }}>
            <Autocomplete
              required
              fullWidth
              size="small"
              freeSolo
              // className={classes.addNewReviewNote}
              className={clsx("mb-3", classes.field)}
              options={reviewNotesOptions}
              getOptionLabel={(option) => {
                if (option.value) {
                  return option.value;
                }
                return option;
              }}
              disableClearable
              value={state.reviewNotesValue || ""}
              onChange={(event, value) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  reviewNotesValue:
                    (typeof value === "string" ? value : value?.value) || "",
                }));
              }}
              onInputChange={(event, newInputValue, reason) => {
                isFieldChange = true;
                if (reason === "input") {
                  setState((prevState) => ({
                    ...prevState,
                    reviewNotesValue: newInputValue,
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Add New Review Note"
                  helperText={" "}
                />
              )}
            />

            {!isRoleMGR && (
              <Tooltip title="Add To Lookup(Review Notes)" placement="top-end">
                <Button
                  type="submit"
                  onClick={() => handleShowAddLookupDialog(row.id)}
                  variant="contained"
                  color="primary"
                  disabled={!state.reviewNotesValue}
                  className={classes.buttonStyle}
                >
                  <PostAddIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  };

  const isValidate = !!validate();

  const handleConfirmDontBill = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBill: true,
      confirmDontBillValue: isCheckBoxChecked,
    }));
  };

  const calculateBill = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      updatedBilltollAmountValue: 0,
      dontBill: {
        ...prevState.dontBill,
        value: isCheckBoxChecked,
        notes: null,
      },
      dontBillMiles: {
        ...prevState.dontBillMiles,
        value: false,
        notes: null,
      },
      dontBillTolls: {
        ...prevState.dontBillTolls,
        value: false,
        notes: null,
      },
    }));
  };

  const handleConfirmDontPay = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPay: true,
      confirmDontPayValue: isCheckBoxChecked,
    }));
  };

  const calculatePay = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      dsPayRateValue: "0.00",
      dsPayTollAmountValue: 0,
      dsPayExtraMilesValue: 0,
      dontPay: {
        ...prevState.dontPay,
        value: isCheckBoxChecked,
        notes: null,
      },
      dontPayMiles: {
        ...prevState.dontPayMiles,
        value: false,
        notes: null,
      },
      dontPayTolls: {
        ...prevState.dontPayTolls,
        value: false,
        notes: null,
      },
    }));
  };

  const handleConfirmDontBillExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillExtraMiles: isCheckBoxChecked,
      confirmDontBillExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const calculateBillMiles = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      dontBillMiles: {
        ...prevState.dontBillMiles,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const handleConfirmDontPayExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayExtraMiles: isCheckBoxChecked,
      confirmDontPayExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const calculatePayMiles = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      dsPayExtraMilesValue: 0,
      dontPayMiles: {
        ...prevState.dontPayMiles,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const handleConfirmDontBillToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillToll: isCheckBoxChecked,
      confirmDontBillTollValue: isCheckBoxChecked,
    }));
  };

  const calculateBillToll = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      updatedBilltollAmountValue: 0,
      dontBillTolls: {
        ...prevState.dontBillTolls,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  const handleConfirmDontPayToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayToll: isCheckBoxChecked,
      confirmDontPayTollValue: isCheckBoxChecked,
    }));
  };

  const calculatePayToll = (isCheckBoxChecked = false) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      dsPayTollAmountValue: 0,
      dontPayTolls: {
        ...prevState.dontPayTolls,
        value: isCheckBoxChecked,
        notes: null,
      },
    }));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>Edit Row</Dialog.Title>
        <Dialog.Content>
          <div>
            <div className="d-flex f-align-center f-wrap mb-6">
              {/* Do Not Bill */}
              <FormControlLabel
                control={
                  <Tooltip title={state.dontBill.notes} placement="top-start">
                    <div>
                      <Checkbox
                        color="primary"
                        checked={state.dontBill.value}
                        onChange={(evt) => {
                          if (evt.target.checked === true) {
                            handleConfirmDontBill(evt.target.checked);
                          } else {
                            calculateBill(evt.target.checked);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                label="Do Not Bill"
              />

              <FormControlLabel
                control={
                  <Tooltip title={state.dontPay.notes} placement="top-start">
                    <div>
                      <Checkbox
                        color="primary"
                        checked={state.dontPay.value}
                        onChange={(evt) => {
                          if (evt.target.checked === true) {
                            handleConfirmDontPay(evt.target.checked);
                          } else {
                            calculatePay(row.id, evt.target.checked);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                label="Do Not Pay"
              />

              <FormControlLabel
                control={
                  <Tooltip
                    title={state.dontBillMiles.notes}
                    placement="top-start"
                  >
                    <div>
                      <Checkbox
                        color="primary"
                        checked={state.dontBillMiles.value}
                        disabled={!!row.daily_schedule?.dont_bill}
                        onChange={(evt) => {
                          if (evt.target.checked === true) {
                            handleConfirmDontBillExtraMiles(evt.target.checked);
                          } else {
                            calculateBillMiles(row.id, evt.target.checked);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                label="Do Not Bill Extra Miles"
              />
              <FormControlLabel
                control={
                  <Tooltip
                    title={state.dontPayMiles.notes}
                    placement="top-start"
                  >
                    <div>
                      <Checkbox
                        color="primary"
                        checked={state.dontPayMiles.value}
                        disabled={!!row.daily_schedule?.dont_pay}
                        onChange={(evt) => {
                          if (evt.target.checked === true) {
                            handleConfirmDontPayExtraMiles(evt.target.checked);
                          } else {
                            calculatePayMiles(row.id, evt.target.checked);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                label="Do Not Pay Extra Miles"
              />

              <FormControlLabel
                control={
                  <Tooltip
                    title={state.dontBillTolls.notes}
                    placement="top-start"
                  >
                    <div>
                      <Checkbox
                        color="primary"
                        checked={state.dontBillTolls.value}
                        disabled={!!row.daily_schedule?.dont_bill}
                        onChange={(evt) => {
                          if (evt.target.checked === true) {
                            handleConfirmDontBillToll(evt.target.checked);
                          } else {
                            calculateBillToll(row.id, evt.target.checked);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                label="Do Not Bill Toll"
              />
              {/* Do Not Pay Toll */}
              <FormControlLabel
                control={
                  <Tooltip
                    title={state.dontPayTolls.notes}
                    placement="top-start"
                  >
                    <div>
                      <Checkbox
                        color="primary"
                        checked={state.dontPayTolls.value}
                        disabled={!!row.daily_schedule?.dont_pay}
                        onChange={(evt) => {
                          if (evt.target.checked === true) {
                            handleConfirmDontPayToll(evt.target.checked);
                          } else {
                            calculatePayToll(row.id, evt.target.checked);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                }
                label="Do Not Pay Toll"
              />
            </div>

            <div className="d-flex f-align-center f-wrap">
              <Tooltip title={row.review_reason || ""} placement="top-start">
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Review Reason"
                  variant="outlined"
                  size="small"
                  value={row.review_reason || "-"}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={row.daily_schedule.kam_notes || "-"}
                placement="top-start"
                disableFocusListener
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Kam Notes"
                  variant="outlined"
                  size="small"
                  value={row.daily_schedule.kam_notes || "-"}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>
              <div className="d-flex f-align-center f-wrap">
                {ReviewNotesContent()}
              </div>
            </div>

            <div className="d-flex f-align-center f-wrap">
              <Tooltip
                title={getUTCDateString(row.schedule_date) || ""}
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Schedule Date"
                  variant="outlined"
                  size="small"
                  value={getUTCDateString(row.schedule_date) || "-"}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={
                  row.kamUser?.username
                    ? `${
                        row.kamUser.first_name +
                        " " +
                        (row.kamUser.last_name || "")
                      }-(${row.kamUser?.username})`
                    : ""
                }
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Kam"
                  variant="outlined"
                  size="small"
                  helperText=" "
                  value={
                    row.kamUser.first_name +
                      " " +
                      (row.kamUser.last_name || "") || "-"
                  }
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={row.daily_schedule.customer_branch?.customer?.name || ""}
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Customer Name"
                  variant="outlined"
                  size="small"
                  helperText=" "
                  value={
                    row.daily_schedule.customer_branch?.customer?.name || "-"
                  }
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={row.daily_schedule.customer_branch?.location || "-"}
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Location"
                  variant="outlined"
                  size="small"
                  helperText=" "
                  value={row.daily_schedule.customer_branch?.location || "-"}
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={row.daily_schedule?.routeDetails?.name || "-"}
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Route"
                  variant="outlined"
                  size="small"
                  value={row.daily_schedule?.routeDetails?.name || "-"}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={row.daily_schedule.customer_branch?.state?.name ?? ""}
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="State"
                  variant="outlined"
                  size="small"
                  value={row.daily_schedule.customer_branch?.state?.name ?? "-"}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={
                  row.daily_schedule.customer_branch?.pdxCompany?.value ?? ""
                }
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="PDX Company"
                  variant="outlined"
                  size="small"
                  value={
                    row.daily_schedule.customer_branch?.pdxCompany?.value ?? "-"
                  }
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              <Tooltip
                title={row.daily_schedule.vehicleType?.value || "-"}
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Vehicle"
                  variant="outlined"
                  size="small"
                  value={row.daily_schedule.vehicleType?.value || "-"}
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>

              {IcidContent()}
              {WCPDriverContent()}

              {row.daily_schedule?.routeDetails?.price_type === "Matrix" && (
                <Tooltip
                  title={row.daily_schedule?.routeDetails?.matrix_name || "-"}
                  placement="top-start"
                >
                  <TextField
                    className={classes.field}
                    disabled={true}
                    label="Price Matrix Name"
                    variant="outlined"
                    size="small"
                    value={row.daily_schedule?.routeDetails?.matrix_name || "-"}
                    helperText=" "
                    InputProps={{ classes: { disabled: classes.disabledText } }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Tooltip>
              )}

              <Autocomplete
                disableClearable
                // className="mr-2 w-50 mb-1"
                className={classes.field}
                classes={{
                  root: classes.autocompletePaper,
                  listbox: classes.autocompletePaper,
                  inputRoot: clsx(
                    row.base_roster !== null &&
                      row.daily_schedule?.pay_rate_type !==
                        row.base_roster?.pay_rate_type &&
                      ((row.daily_schedule?.pay_rate_type === "Daily" &&
                        row.daily_schedule?.pay_quantity > 1.99) ||
                        row.daily_schedule?.pay_rate_type !== "Daily") &&
                      "color-error"
                  ),
                }}
                size="small"
                disabled={
                  isRoleView ||
                  row.daily_schedule?.routeDetails?.price_type === "Matrix"
                }
                value={{ label: state.dsPayRateTypeValue || "" }}
                options={state.dsRateTypeOptions || []}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => {
                  isFieldChange = true;
                  setState((prevState) => {
                    return {
                      ...prevState,
                      dsPayRateTypeValue:
                        value?.value?.rateType?.value || value?.value,
                      dsPayRateValue: !!value?.value?.rate_value
                        ? value?.value?.rate_value
                        : prevState.dsPayRateValue,
                    };
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="DS Pay Rate Type"
                    variant="outlined"
                    helperText=" "
                  />
                )}
              />

              <TextField
                className={classes.field}
                label="DS Pay Quantity"
                name="dsPayQuantityValue"
                disabled={
                  isRoleView ||
                  row.daily_schedule?.routeDetails?.price_type === "Matrix"
                }
                variant="outlined"
                size="small"
                type="number"
                value={state.dsPayQuantityValue}
                error={!!state.errors.dsPayQuantityValue.trim()}
                helperText={state.errors.dsPayQuantityValue}
                onChange={handleFieldChange}
                onWheel={(event) => event.target.blur()}
                onKeyDown={preventInputKeyCodes}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    input: clsx(
                      row.base_roster !== null &&
                        row.daily_schedule?.pay_rate_type !== "Per Tire" &&
                        ((row.daily_schedule?.pay_rate_type === "Daily" &&
                          row.daily_schedule?.pay_quantity > 1.99) ||
                          (row.daily_schedule?.pay_rate_type !== "Daily" &&
                            row.daily_schedule?.pay_quantity !==
                              row.base_roster?.pay_quantity)) &&
                        "color-error"
                    ),
                  },
                }}
              />

              {PayRateContent()}

              <TextField
                className={classes.field}
                label="DS Pay Toll Amount"
                name="dsPayTollAmountValue"
                disabled={
                  !!row.daily_schedule?.dont_pay ||
                  !!row.daily_schedule?.dont_pay_tolls
                }
                variant="outlined"
                size="small"
                type="number"
                value={state.dsPayTollAmountValue}
                error={!!state.errors.dsPayTollAmountValue.trim()}
                helperText={state.errors.dsPayTollAmountValue}
                onChange={handleFieldChange}
                onWheel={(event) => event.target.blur()}
                onKeyDown={preventInputKeyCodes}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    input: clsx(
                      row.base_roster !== null &&
                        row.daily_schedule.pay_toll_amount > 999 &&
                        "color-error"
                    ),
                  },
                }}
              />
              <TextField
                className={classes.field}
                label="DS Pay Extra Miles"
                disabled
                variant="outlined"
                size="small"
                value={state.dsPayExtraMilesValue}
                helperText=" "
                InputProps={{ classes: { disabled: classes.disabledText } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                className={classes.field}
                disabled={true}
                label="BR Pay Rate Type"
                variant="outlined"
                size="small"
                value={
                  row.base_roster !== null
                    ? row.base_roster?.pay_rate_type
                    : "-"
                }
                helperText=" "
                InputProps={{ classes: { disabled: classes.disabledText } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                className={classes.field}
                disabled
                label="BR Pay Rate"
                variant="outlined"
                size="small"
                value={
                  row.base_roster !== null
                    ? "$" + row.base_roster?.pay_rate
                    : "-"
                }
                helperText=" "
                InputProps={{ classes: { disabled: classes.disabledText } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                className={classes.field}
                disabled
                label="BR Pay Quantity"
                variant="outlined"
                size="small"
                value={
                  row.base_roster !== null ? row.base_roster?.pay_quantity : "-"
                }
                helperText=" "
                InputProps={{ classes: { disabled: classes.disabledText } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                className={classes.field}
                disabled
                label="Original Bill Toll Amount"
                variant="outlined"
                size="small"
                value={`$${row.org_bill_toll_amount}` || "-"}
                helperText=" "
                InputProps={{ classes: { disabled: classes.disabledText } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                className={classes.field}
                label="Updated Bill Toll Amount"
                name="updatedBilltollAmountValue"
                disabled={
                  !!row.daily_schedule?.dont_bill ||
                  !!row.daily_schedule?.dont_bill_tolls
                }
                variant="outlined"
                size="small"
                value={state.updatedBilltollAmountValue}
                type="number"
                error={!!state.errors.updatedBilltollAmountValue.trim()}
                helperText={state.errors.updatedBilltollAmountValue}
                onChange={handleFieldChange}
                onWheel={(event) => event.target.blur()}
                onKeyDown={preventInputKeyCodes}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    input: clsx(
                      row.bill_toll_amount != row.org_bill_toll_amount &&
                        "color-error"
                    ),
                  },
                }}
              />
              <TextField
                className={classes.field}
                disabled
                label="BR Pay Toll Amount"
                variant="outlined"
                size="small"
                value={
                  row.base_roster !== null
                    ? "$" + row.base_roster?.pay_toll_amount
                    : "-"
                }
                helperText=" "
                InputProps={{ classes: { disabled: classes.disabledText } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                className={classes.field}
                name="incentiveValue"
                label="Incentives"
                variant="outlined"
                size="small"
                type="number"
                value={state.incentiveValue}
                error={!!state.errors.incentiveValue.trim()}
                helperText={state.errors.incentiveValue}
                onChange={handleFieldChange}
                onWheel={(event) => event.target.blur()}
                onKeyDown={preventInputKeyCodes}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    input: clsx(
                      !!row.daily_schedule?.reimbursement &&
                        row.daily_schedule?.reimbursement != 0 &&
                        "color-error"
                    ),
                  },
                }}
              />

              {UpdatedByContent()}
              {SubmittedByContent()}

              <Tooltip
                title={
                  !!row.daily_schedule.updated_at
                    ? `${getDateString(
                        row.daily_schedule.updated_at
                      )} ${getFormattedTime(
                        new Date(row.daily_schedule.updated_at)
                      )}`
                    : "" ?? ""
                }
                placement="top-start"
              >
                <TextField
                  className={classes.field}
                  disabled={true}
                  label="Last Updated At"
                  variant="outlined"
                  size="small"
                  value={
                    !!row.daily_schedule.updated_at
                      ? `${getDateString(
                          row.daily_schedule.updated_at
                        )} ${getFormattedTime(
                          new Date(row.daily_schedule.updated_at)
                        )}`
                      : ""
                  }
                  helperText=" "
                  InputProps={{ classes: { disabled: classes.disabledText } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="d-flex f-align-center f-justify-end p-4">
            <Button onClick={handleClose} className="ml-2 mr-2" color="primary">
              Close
            </Button>
            <Button
              className="ml-2 mr-2"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  saveInfoWarning: true,
                }));
              }}
              color="primary"
              variant="contained"
              disabled={!isFieldChange || validate()}
            >
              Save
            </Button>
            {renderGridActions(
              handleSubmit,
              isValidate,
              Boolean(state.reviewNotesValue?.trim()),
              handleNoteChange
            )}
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.saveInfoWarning && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.saveInfoWarning}
          contentText="Changes are temporarily saved for approval but will be lost on page reload or navigation."
          onCancel={() => {
            isFieldChange = false;
            setState((prevState) => ({
              ...prevState,
              saveInfoWarning: defaultState.saveInfoWarning,
              dsPayRateTypeValue:
                row.daily_schedule.pay_rate_type ||
                defaultState.dsPayRateTypeValue,
              dsPayRateValue:
                row.daily_schedule.pay_rate || defaultState.dsPayRateValue,
              dsPayQuantityValue:
                row.daily_schedule.pay_quantity ||
                defaultState.dsPayQuantityValue,
              updatedBilltollAmountValue:
                row.bill_toll_amount || defaultState.updatedBilltollAmountValue,
              dsPayTollAmountValue:
                row.daily_schedule.pay_toll_amount ||
                defaultState.dsPayTollAmountValue,
              dsPayExtraMilesValue:
                row.daily_schedule !== null
                  ? row.daily_schedule?.pay_extra_miles
                  : "-",
              incentiveValue:
                row.daily_schedule?.reimbursement ||
                defaultState.incentiveValue,
              reviewNotesValue:
                row.daily_schedule?.review_notes ||
                defaultState.reviewNotesValue,
              dontBill: {
                ...prevState.dontBill,
                value: row.daily_schedule?.dont_bill,
                notes: !!row.daily_schedule?.dont_bill ? (
                  row.daily_schedule?.dont_bill_notes === null ||
                  row.daily_schedule?.dont_bill_notes === "" ? (
                    "This record will not be included in billing"
                  ) : (
                    <>
                      This record will not be included in billing.
                      <br />
                      Reason : {row.daily_schedule?.dont_bill_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontPay: {
                ...prevState.dontPay,
                value: row.daily_schedule?.dont_pay,
                notes: !!row.daily_schedule?.dont_pay ? (
                  row.daily_schedule?.dont_pay_notes === null ||
                  row.daily_schedule?.dont_pay_notes === "" ? (
                    "This record will not be included in settlement"
                  ) : (
                    <>
                      This record will not be included in settlement.
                      <br />
                      Reason : {row.daily_schedule?.dont_pay_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontBillMiles: {
                ...prevState.dontBillMiles,
                value: row.daily_schedule?.dont_bill_miles,
                notes: !!row.daily_schedule.dont_bill_miles ? (
                  row.daily_schedule?.dont_bill_miles_notes === null ||
                  row.daily_schedule?.dont_bill_miles_notes === "" ? (
                    "This record will not be included in billing."
                  ) : (
                    <>
                      This record will not be included in billing.
                      <br />
                      Reason : {row.daily_schedule.dont_bill_miles_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontPayMiles: {
                ...prevState.dontPayMiles,
                value: row.daily_schedule.dont_pay_miles,
                notes: !!row.daily_schedule.dont_pay_miles ? (
                  row.daily_schedule?.dont_pay_miles_notes === null ||
                  row.daily_schedule?.dont_pay_miles_notes === "" ? (
                    "This record will not be included in settlement."
                  ) : (
                    <>
                      This record will not be included in settlement.
                      <br />
                      Reason : {row.daily_schedule.dont_pay_miles_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontBillTolls: {
                ...prevState.dontBillTolls,
                value: row.daily_schedule.dont_bill_tolls,
                notes: !!row.daily_schedule.dont_bill_tolls ? (
                  row.daily_schedule?.dont_bill_tolls_notes === null ||
                  row.daily_schedule?.dont_bill_tolls_notes === "" ? (
                    "This record will not be included in billing."
                  ) : (
                    <>
                      This record will not be included in billing.
                      <br />
                      Reason : {row.daily_schedule.dont_bill_tolls_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
              dontPayTolls: {
                ...prevState.dontPayTolls,
                value: row.daily_schedule.dont_pay_tolls,
                notes: !!row.daily_schedule.dont_pay_tolls ? (
                  row.daily_schedule?.dont_pay_tolls_notes === null ||
                  row.daily_schedule?.dont_pay_tolls_notes === "" ? (
                    "This record will not be included in settlement."
                  ) : (
                    <>
                      This record will not be included in settlement.
                      <br />
                      Reason : {row.daily_schedule.dont_pay_tolls_notes}
                    </>
                  )
                ) : (
                  ""
                ),
              },
            }));
          }}
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              saveInfoWarning: defaultState.saveInfoWarning,
            }));
            handleSubmit();
          }}
          showPositiveActionLabel={true}
          hasClose={true}
        />
      )}
      {state.confirmDontBill && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBill}
          contentText="By Checking, bill rate will become 0. This action will not be reverted once done"
          onConfirm={() => {
            calculateBill(state.confirmDontBillValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPay && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPay}
          contentText="By Checking, pay rate will become 0. This action will not be reverted once done"
          onConfirm={() => {
            calculatePay(state.confirmDontPayValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontPay: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPay: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontBillExtraMiles && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBillExtraMiles}
          contentText=" By Checking, bill extra miles will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculateBillMiles(state.confirmDontBillExtraMilesValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontBillExtraMiles: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillExtraMiles: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPayExtraMiles && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPayExtraMiles}
          contentText="By Checking, pay extra miles will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculatePayMiles(state.confirmDontPayExtraMilesValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontPayExtraMiles: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayExtraMiles: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontBillToll && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBillToll}
          contentText=" By Checking, bill toll amount will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculateBillToll(state.confirmDontBillTollValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontBillToll: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBillToll: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.confirmDontPayToll && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontPayToll}
          contentText=" By Checking, pay toll amount will become 0. This action will not be reverted once done."
          onConfirm={() => {
            calculatePayToll(state.confirmDontPayTollValue);
            setState((prevState) => ({
              ...prevState,
              confirmDontPayToll: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontPayToll: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="OK"
          negativeActionLabel={<>Cancel</>}
        />
      )}
    </>
  );
};

export default Form;
