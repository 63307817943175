/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Chip,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useStyles from "./style";
import {
  ActionDialog,
  Dropdown,
  FilterComponent,
  Grid,
} from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../../service";
import {
  debounce,
  getDateString,
  getUTCDateString,
  queryStringBuilderNew,
  setPageConfig,
  updateGridLayout,
  updatePageConfig,
  updatePagination,
  validator,
} from "utils";
import { PAGE_KEYS, VALIDATIONS, noop } from "shared";
import TuneIcon from "@material-ui/icons/Tune";
import FilterListIcon from "@material-ui/icons/FilterList";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PDFDocument } from "pdf-lib";
import SendIcon from "@material-ui/icons/Send";
import MailIcon from "@material-ui/icons/Mail";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CreditMemoSummary from "../credit-memo-summary";
import UploadFiles from "modules/invoice/file-upload";
import GenerateCreditMemoPDF from "../generate-credit-pdf";
import { CREDIT_MEMO_STATUS } from "modules/shared/constants";
import { Skeleton } from "@material-ui/lab";

let gridHelper = null;
let isConfirmDisabled = false;

const ViewCreditMemo = ({
  history,
  defaultState = {},
  state = {},
  setState = noop,
  filters = {},
  dateFilterType,
  appDataList,
  setInitialFetch = noop,
  creditSearch,
  getSearchArray = noop,
}) => {
  const classes = useStyles();

  const statusKeys = Object.keys(defaultState.dynamicStatus);

  const getCreditMemo = async (row, preview) => {
    setState((prevState) => ({ ...prevState, isCreditMemoLoading: true }));

    let queryString = `?filter[where][id]=${row?.id}`;

    const { data, error } = await Service.getCreditMemo(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isCreditMemoLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const credit = data?.rows[0];

    if (!credit) {
      setState((prevState) => ({ ...prevState, isCreditMemoLoading: false }));
      return null;
    }

    const updatedData = credit.CreditMemoItemRecords.map((item) => {
      if (item.route_name && item.route_id) {
        return {
          ...item,
          credit_description: `${item.route_name} - ${
            item.credit_description || ""
          }`,
        };
      }
      return item;
    });

    const blob = GenerateCreditMemoPDF({
      creditMemoDetails: updatedData,
      creditNo: credit.credit_no,
      creditDate: getUTCDateString(credit.credit_memo_date),
      customerNotes: credit.customer_notes,
      pdxCompany: credit.pdx_company,
      status: credit.status,
      address: {
        customerName: credit.customer_branch.customer?.name,
        branch: credit.customer_branch.location,
        primary_contact: credit.bill_to.primary_contact,
        streetAddress: credit.bill_to.street_address,
        city: credit.bill_to.city,
        state: credit.bill_to.state,
        zipCode: credit.bill_to.zip_code,
        country: credit.bill_to.country,
      },
      invoices: credit.invoice_amount,
      fromDate: getUTCDateString(credit.from_date),
      toDate: getUTCDateString(credit.to_date),
      isPreview: preview,
    });

    setState((prevState) => ({
      ...prevState,
      isCreditMemoLoading: false,
      pdfUrl: blob,
      entry: credit || defaultState.entry,
    }));
    return credit;
  };

  const fetchCreditMemo = async (row, loading) => {
    setState((prevState) => ({ ...prevState, [loading]: true }));

    let queryString = `?filter[where][id]=${row?.id}`;

    const { data, error } = await Service.getCreditMemo(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, [loading]: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const creditMemoEntries = data?.rows?.[0];

    fetchEntries(
      creditSearch,
      { ...filters, ...state.filters },
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
          entry: creditMemoEntries || defaultState.entry,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      });
  };

  const handleMarkCreditMemoSent = async (id, isSingleCreditMemo = false) => {
    setState((prevState) => ({ ...prevState, isMarkAsSentLoading: true }));

    const idList = Array.isArray(id) ? id.join(",") : id;
    const query = `?creditMemoIds=${idList}`;
    const { error } = await Service.markCreditMemoSent(query);

    if (error) {
      setState((prevState) => ({ ...prevState, isMarkAsSentLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Credit memo(s) marked as sent successfully");
    setState((prevState) => ({
      ...prevState,
      isMarkAsSentLoading: false,
      isEmailSent: false,
    }));
    fetchEntries(
      creditSearch,
      { ...filters, ...state.filters },
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    if (isSingleCreditMemo) {
      getCreditMemo({ id: id || isSingleCreditMemo }, true);
    }
  };

  const handleMarkCreditMemoSentDialog = () =>
    setState((prevState) => ({
      ...prevState,
      isEmailSent: true,
    }));

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      creditMemoPopup = false
    ) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const searchArray = getSearchArray(search);
      const sortObj = { field: orderBy, order };

      let filtersArr = [];

      if (pageFilters.length > 0) {
        filtersArr = [
          ...pageFilters?.map((filter) => ({
            ...filter,
            value: filter?.value?.value || filter?.value,
          })),
        ];
      }

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.creditMemoStatus.length && filters.creditMemoStatus) {
        filtersArr = [...filtersArr, ...filters.creditMemoStatus];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (
        filters.selectedLocation.length &&
        filters.selectedLocation[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.selectedLocation];
      }
      if (
        filters.creditMemoFilter.length &&
        filters.creditMemoFilter[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.creditMemoFilter];
      }
      if (filters.invoiceSentDate.length && filters.invoiceSentDate[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceSentDate];
      }

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        sortObj,
        search && search.length > 0
      );

      if (
        dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][credit_memo_date]=${filters.startDate[0].value}`;
      }

      if (
        dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][credit_memo_date][gte]=${filters.startDate[0].value}`;
      }
      if (
        dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][credit_memo_date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }
      queryString += `&is_deleted=false`;
      const { data, error } = await Service.getCreditMemo(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          selectedRows: defaultState.selectedRows,
        }));
        gridHelper && gridHelper.resetSelection();
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        selectedRows: !creditMemoPopup
          ? defaultState.selectedRows
          : prevState.selectedRows,
        totalEntries: data.count,
        hasFetched: true,
      }));

      if (!creditMemoPopup) {
        gridHelper && gridHelper.resetSelection();
      }
      return data;
    },
    [dateFilterType]
  );

  const fetchAllCreditMemoEntries = useCallback(
    async (selectedIds = [], loading) => {
      if (!selectedIds.length) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        [loading]: true,
      }));

      const idString = selectedIds.join(",");
      let queryString = selectedIds.length ? `?creditMemoIds=${idString}` : "";
      const { data, error } = await Service.getAllCreditMemo(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        [loading]: false,
      }));
      return data?.CreditMemoRecords;
    },
    [state.selectedRows]
  );

  const handleCreditMemoFilter = (option) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        creditMemoFilter: [
          {
            field: "status",
            type: "=",
            value: option.value,
          },
        ],
        invoiceLatePaymentFilter: [],
      },
    }));
  };

  const filterConfig = useMemo(
    () => [
      {
        field: "kam_name",
        fieldToDisplay: "KAM",
        operatorType: "string",
        options: appDataList?.users?.kam?.map((kam) => ({
          ...kam,
          label: `${kam.first_name} ${kam.last_name}-(${kam.username})  `,
          value: `${kam.first_name} ${kam.last_name}`,
        })),
      },
      {
        field: "aa_name",
        fieldToDisplay: "Office",
        operatorType: "string",
        options: appDataList?.users?.aa?.map((office) => ({
          ...office,
          label: `${office.first_name || ""} ${office.last_name || ""}-(${
            office.username
          })`,
          value: `${office.first_name || ""} ${office.last_name || ""}`,
        })),
      },
      {
        field: "invoice_no",
        fieldToDisplay: "Invoice No.",
        operatorType: "number",
      },
      {
        field: "payment_no",
        fieldToDisplay: "Payment No.",
        operatorType: "number",
      },
      {
        field: "total_amount",
        fieldToDisplay: "Total Amount",
        operatorType: "number",
      },
      {
        field: "invoice_amount",
        fieldToDisplay: "Invoice Amount",
        operatorType: "number",
      },
      {
        field: "balance_credit_amount",
        fieldToDisplay: "Balance Credit Amount",
        operatorType: "number",
      },
    ],
    [appDataList.users]
  );

  useEffect(() => {
    if (!state.hasFetched) {
      fetchEntries(
        creditSearch,
        { ...filters, ...state.filters },
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  }, [
    fetchEntries,
    creditSearch,
    filters,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.viewCreditMemoSummary,
    state.pageFilters,
  ]);

  useEffect(() => {
    const handleResize = debounce(() => {
      window.requestAnimationFrame(updateGridLayout);
    }, 100);

    updateGridLayout();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [state.entries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    setInitialFetch();
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    setInitialFetch();
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback(async (selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    setInitialFetch();
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApprovalDialog = (id, type, value) => {
    setState((prevState) => ({
      ...prevState,
      isApprovedDialog: value,
      invoiceId: id,
      approvalType: type,
    }));
  };

  const handleApproval = async (id, type) => {
    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: true,
    }));

    let idArray = [];
    idArray.push(id);

    let serviceMethod, status;

    switch (type) {
      case "approve":
        serviceMethod = "approveCreditMemo";
        status = "approved";

        break;
      case "reject":
        serviceMethod = "rejectCreditMemo";
        status = "rejected";
        break;
      default:
        serviceMethod = null;
    }

    const { error } = await Service[serviceMethod]({ creditMemoIds: idArray });

    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: false,
      isApprovedDialog: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success(`Credit memo has been ${status} successfully.`);
      fetchEntries(
        creditSearch,
        { ...filters, ...state.filters },
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
      getCreditMemo({ id: id }, true);
    }
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    setInitialFetch();
    setPageConfig(PAGE_KEYS.VIEW_CREDIT_MEMO, {
      filters: pageFilters,
    });
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleItemClick = (creditMemo) => {
    setState((prevState) => ({
      ...prevState,
      selectedCreditMemo: creditMemo,
    }));
  };

  const handleFetchEmail = (loading) => {
    setState((prevState) => ({
      ...prevState,
      [loading]: true,
    }));
    fetchEntries(
      creditSearch,
      { ...filters, ...state.filters },
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters,
      state.showSendCreditMemoPopup
    )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      });
  };

  const handleMultiplePdf = async (
    selectedCreditIds = [],
    loading,
    isPreview = false
  ) => {
    const creditMemoData = await fetchAllCreditMemoEntries(
      selectedCreditIds,
      loading
    );

    const pdfArrayBuffers = await Promise.all(
      creditMemoData?.map(async (credit) => {
        const updatedData = credit.CreditMemoItemRecords.map((item) => {
          if (item.route_name && item.route_id) {
            return {
              ...item,
              credit_description: `${item.route_name} - ${
                item.credit_description || ""
              }`,
            };
          }
          return item;
        });
        const creditMemoData = {
          creditMemoDetails: updatedData,
          creditNo: credit.credit_no,
          creditDate: getUTCDateString(credit.credit_memo_date),
          customerNotes: credit.customer_notes,
          pdxCompany: credit.pdx_company,
          status: credit.status,
          address: {
            customerName: credit.customer_branch.customer?.name,
            branch: credit.customer_branch.location,
            primary_contact: credit.bill_to.primary_contact,
            streetAddress: credit.bill_to.street_address,
            city: credit.bill_to.city,
            state: credit.bill_to.state,
            zipCode: credit.bill_to.zip_code,
            country: credit.bill_to.country,
          },
          invoices: credit.invoice_amount,
          fromDate: getUTCDateString(credit.from_date),
          toDate: getUTCDateString(credit.to_date),
          isArrayPrinting: true,
        };

        const pdfBytes = await GenerateCreditMemoPDF(creditMemoData);
        return pdfBytes;
      })
    );

    const mergedPdf = await PDFDocument.create();

    for (const pdfArrayBuffer of pdfArrayBuffers) {
      const pdf = await PDFDocument.load(pdfArrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();

    const mergedPdfBlob = new Blob([mergedPdfBytes], {
      type: "application/pdf",
    });

    if (isPreview) {
      const pdfUrl = URL.createObjectURL(mergedPdfBlob);
      window.open(pdfUrl);
    } else {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(mergedPdfBlob);
      link.download = `multiple_credit_memos${
        state.pdxCompany
          ? `-${state.pdxCompany?.value.replace(/\s+/g, "_")}`
          : ""
      }-${
        dateFilterType === "single"
          ? getDateString(filters.startDate[0]?.value)
          : `${getDateString(filters.startDate[0]?.value)}-${getDateString(
              filters.endDate[0]?.value
            )}`
      }.pdf`;

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.credit_no?.isSelected,
      id: "credit_no",
      label: "Credit Memo No.",
      field: "credit_no",
      canSort: true,
      render: (row) => {
        const title = row.credit_no;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography
                noWrap
                variant="body2"
                onClick={() => {
                  getCreditMemo(row, true);
                  setState((prevState) => ({
                    ...prevState,
                    viewCreditMemoSummary: true,
                    selectedCreditMemo: row,
                  }));
                }}
                style={{
                  color: "#775edc",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
            {!!row?.is_sent_to_customer && (
              <Tooltip title={"Credit Memo Sent"} placement="top-start">
                <MailIcon color="primary" className="ml-2" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title = CREDIT_MEMO_STATUS.find(
          (item) => item.value === row?.status
        );
        return (
          <Tooltip title={title?.label ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title?.label || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.credit_memo_date?.isSelected,
      id: "credit_memo_date",
      label: "Credit Memo Date",
      field: "credit_memo_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.credit_memo_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.initial_sent_date?.isSelected,
      id: "initial_sent_date",
      label: "Credit Memo Sent Date",
      field: "initial_sent_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.initial_sent_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_number?.isSelected,
      id: "invoice_no",
      label: "Invoice No.",
      field: "invoice_no",
      canSort: true,
      render: (row) => {
        const title = row?.invoicePaymentRecords?.invoice_number;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.payment_number?.isSelected,
      id: "payment_no",
      label: "Payment No.",
      field: "payment_no",
      canSort: true,
      render: (row) => {
        const title = row?.invoicePaymentRecords?.payment_number;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdxCompany?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer?.isSelected,
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        const title = row?.customer_branch?.customer?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam_name?.isSelected,
      id: "kam_name",
      label: "KAM",
      field: "kam_name",
      canSort: true,
      render: (row) => {
        const kamUser = row?.customer_branch?.kamUser;
        const title = kamUser
          ? `${kamUser.first_name ?? ""} ${kamUser.last_name ?? ""} - (${
              kamUser.username ?? ""
            })`
          : "";
        const kamName = kamUser
          ? `${kamUser.first_name ?? ""} ${kamUser.last_name ?? ""}`
          : "-";
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.aa_name?.isSelected,
      id: "aa_name",
      label: "Office",
      field: "aa_name",
      canSort: true,
      render: (row) => {
        const aaUser = row?.customer_branch?.aaUser;
        const title = aaUser
          ? `${aaUser.first_name ?? ""} ${aaUser.last_name ?? ""} - (${
              aaUser.username ?? ""
            })`
          : "";
        const aaName = aaUser
          ? `${aaUser.first_name ?? ""} ${aaUser.last_name ?? ""}`
          : "-";

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {aaName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount?.isSelected,
      id: "total_amount",
      label: "Total Amount",
      field: "total_amount",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_amount?.isSelected,
      id: "invoice_amount",
      label: "Invoice Amount",
      field: "invoice_amount",
      canSort: true,
      render: (row) => {
        const title = `$${row.invoice_amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.balance_credit_amount?.isSelected,
      id: "balance_credit_amount",
      label: "Balance Credit Amount",
      field: "balance_credit_amount",
      canSort: true,
      render: (row) => {
        const title = row.balance_credit_amount;
        return (
          <Tooltip title={`$${title || "0.00"}`} placement="top-start">
            <Typography variant="body2" noWrap>{`$${
              title || "0.00"
            }`}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleClose = (isClose) => {
    setState((prevState) => ({
      ...prevState,
      viewCreditMemoSummary: isClose,
      filters: {
        ...prevState.filters,
        creditMemoFilter: defaultState.filters.creditMemoFilter,
      },
    }));
  };

  const handleDelete = async (ids) => {
    setState((prevState) => ({
      ...prevState,
      isCreditMemoDeleteLoading: true,
    }));

    const queryString = `?creditMemoIds=${ids}`;

    const { error } = await Service.deleteCreditMemo(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isCreditMemoDeleteLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else {
      toast.success("Deleted successfully.");
      setState((prevState) => ({
        ...prevState,
        isCreditMemoDeleteLoading: false,
        deletingEntryIds: null,
        selectedRows: defaultState.selectedRows,
      }));
      gridHelper && gridHelper.resetSelection();
      fetchEntries(
        creditSearch,
        { ...filters, ...state.filters },
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
    }
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      from: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
      emailSubject: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      emailBody: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = " ";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const uploadedAttachment = (attachment) => {
    setState((prevState) => ({
      ...prevState,
      uploadedAttachment: attachment,
    }));
  };

  const handleSubmit = async (
    ids = [],
    tempEmailArray,
    permEmailArray,
    subject,
    body,
    attachment
  ) => {
    setState((prevState) => ({ ...prevState, isCreditMemoSending: true }));
    const emailArray =
      !state.selectedRows.length || state.selectedRows.length === 1
        ? [...tempEmailArray, ...permEmailArray]
        : [...tempEmailArray];

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email_body", body);

    ids.forEach((id, index) => formData.append(`creditMemoIds[${index}]`, id));
    emailArray.forEach((email, index) =>
      formData.append(`emailIds[${index}]`, email)
    );

    attachment.forEach((file, index) => {
      formData.append(`uploadedAttachment`, file);
    });

    const { error } = await Service.sendCreditMemoEmail(formData);

    if (error) {
      setState((prevState) => ({ ...prevState, isCreditMemoSending: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Email sent successfully.");
    setState((prevState) => ({
      ...prevState,
      isCreditMemoSending: false,
      showSendCreditMemoPopup: false,
      temporaryEmail: defaultState.temporaryEmail,
      permanentEmail: defaultState.permanentEmail,
      from: defaultState.from,
      emailSubject: defaultState.emailSubject,
      emailBody: defaultState.emailBody,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
    handleFetchEmail("creditMemoLoading");
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.temporaryEmail.some((obj) => obj === value.trim())) {
      errorMessage = "This email has already been entered.";
    }
    if (state.permanentEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }

    isConfirmDisabled = !!errorMessage && errorMessage !== " ";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddEmail = (type) => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        [type]: [...(prevState[type] || []), state?.email?.trim()],

        ...(type === "temporaryEmail" && {
          showAddPermanentEmailDialog: false,
          email: defaultState.email,
        }),
      }));
    }
  };

  const handleDeleteEmail = (id, type) => {
    setState((prevState) => {
      const filteredEmails = prevState[type].filter((email) => email !== id);
      const emailExists = filteredEmails.some(
        (item) => item === prevState.email
      );

      return {
        ...prevState,
        [type]: filteredEmails,
        errors: {
          ...prevState.errors,
          email: emailExists ? prevState.errors.email : " ",
          from: prevState.errors.from,
          emailSubject: prevState.errors.emailSubject,
          emailBody: prevState.errors.emailBody,
        },
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (validate() === " " && state.errors?.email === " ") {
        event.preventDefault();
        handleAddEmail("temporaryEmail");
      }
    }
  };

  const handleBlur = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("temporaryEmail");
    }
  };

  const handleAddPermEmailDialog = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("permanentEmail");
    }

    setState((prevState) => ({
      ...prevState,
      showAddPermanentEmailDialog: true,
    }));
  };

  const addPermananentEmaiil = async (data, email) => {
    setState((prevState) => ({ ...prevState, isPermEmailLoading: true }));

    const payload = {
      id: data.id || null,
      invoice_type: data.invoiceType?.id || null,
      pdx_company_id: data.pdx_company_id || null,
      customer_id: data.customer_id,
      state: data.state || null,
      location: data.location || null,
      aa_id: data.aa_id || null,
      kam_id: data.kam_id || null,
      mgr_id: data.mgr_id || null,
      email_addresses: {
        emailAddresses: email,
      },
    };

    const { error } = await Service.addEmailInBranch(payload);

    if (error) {
      handleAddEmail("temporaryEmail");
      setState((prevState) => ({ ...prevState, isPermEmailLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Customer Email has been succesfully added.`);
    setState((prevState) => ({
      ...prevState,
      isPermEmailLoading: false,
      showAddPermanentEmailDialog: false,
      email: defaultState.email,
    }));
    handleFetchEmail("emailLoading");
  };

  useEffect(() => {
    const selectedCompanies =
      state.selectedRows.length > 0
        ? [
            ...new Set(
              state.entries
                .filter((obj) => state.selectedRows.includes(obj.id))
                .map((ele) => ele.pdx_company)
            ),
          ].join(", ")
        : null;

    const displayCompanyName = () => {
      if (selectedCompanies && selectedCompanies.split(",").length > 1) {
        return "Parts Distribution Xpress";
      } else {
        return selectedCompanies;
      }
    };
    setState((prevState) => ({
      ...prevState,
      emailSubject: `${
        state.selectedRows.length === 1
          ? `Credit Memo ${
              state.entries.filter((obj) =>
                state.selectedRows.includes(obj.id)
              )[0]?.credit_no
            }`
          : "Credit Memos"
      } from ${selectedCompanies || "PDX"}`,
      emailBody: `Dear ${
        state.selectedRows.length === 1
          ? state.entries.filter((obj) =>
              state.selectedRows.includes(obj.id)
            )[0]?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour ${
        state.selectedRows.length > 1 ? "Credit Memos are" : "credit memo is"
      } attached. Please review the details of the credit applied to your account. If you have any questions or need further clarification, feel free to reach out to us. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
    }));
  }, [state.selectedCreditMemo, state.selectedRows, state.entries]);

  const dataEntry =
    state.entries.filter((obj) => state.selectedRows.includes(obj.id))[0] || [];

  const emailAddresses =
    state.selectedRows.length === 1 &&
    (dataEntry?.customer_branch?.email_addresses?.emailAddresses || []);

  useEffect(() => {
    if (state.showSendCreditMemoPopup && emailAddresses.length) {
      setState((prevState) => ({
        ...prevState,
        permanentEmail: emailAddresses,
      }));
    }
  }, [state.showSendCreditMemoPopup, dataEntry, emailAddresses]);

  return (
    <>
      <div className="mr-5" id="numbers-page-wrapper">
        {!state.viewCreditMemoSummary && (
          <Paper elevation={2} className={clsx("p-4", classes.paperSpacing)}>
            <div
              className={clsx(
                "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                classes.actionsWrapper
              )}
            >
              <div>
                {!!state.selectedRows.length && (
                  <>
                    <Button
                      startIcon={<DeleteForeverOutlinedIcon color="error" />}
                      classes={{
                        root: "border-error ml-4",
                        label: "color-error",
                      }}
                      variant="outlined"
                      onClick={() => {
                        const selectedCreditsWithPayments = state.entries
                          .filter(
                            (ele) =>
                              state.selectedRows.includes(ele.id) &&
                              !!ele.payment_id
                          )
                          .map((ele) => ele.credit_no);
                        if (
                          state.entries.some(
                            (ele) =>
                              state.selectedRows.includes(ele.id) &&
                              ele.status !== "draft"
                          )
                        ) {
                          return toast.error(
                            "Deletion is not allowed for credit memo unless they are in draft status."
                          );
                        }
                        if (selectedCreditsWithPayments.length > 0) {
                          return toast.error(
                            `Deletion is not allowed for credit memo(s) ${selectedCreditsWithPayments.join(
                              ", "
                            )} because a payment is associated with it.`
                          );
                        }
                        setState((prevState) => ({
                          ...prevState,
                          deletingEntryIds: state.selectedRows.join(","),
                        }));
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<SendIcon color="primary" />}
                      className="ml-2"
                      variant="outlined"
                      onClick={() => {
                        if (
                          state.entries.some(
                            (ele) =>
                              state.selectedRows.includes(ele.id) &&
                              (ele.status === "draft" ||
                                ele.status === "pending-approval")
                          )
                        ) {
                          return toast.error(
                            "Credit memo(s) can only be sent to customers whose status is approved."
                          );
                        }
                        setState((prevState) => ({
                          ...prevState,
                          showSendCreditMemoPopup: true,
                        }));
                      }}
                    >
                      Send Credit Memo
                    </Button>
                    <Button
                      color="primary"
                      className="ml-2"
                      variant="outlined"
                      onClick={() => {
                        if (
                          state.entries.some(
                            (ele) =>
                              state.selectedRows.includes(ele.id) &&
                              (ele.status === "draft" ||
                                ele.status === "pending-approval")
                          )
                        ) {
                          return toast.error(
                            "Credit memo(s) can only be marked as sent for customers whose status is approved."
                          );
                        }
                        handleMarkCreditMemoSentDialog();
                      }}
                    >
                      Mark as Sent
                    </Button>
                  </>
                )}
              </div>

              <div className="d-flex f-align-center">
                <div className="mr-4">
                  <Button
                    startIcon={<GetAppIcon />}
                    variant="contained"
                    color="primary"
                    disabled={
                      state.isCreditMemoDetailsLoading ||
                      !state.selectedRows?.length
                    }
                    onClick={() =>
                      handleMultiplePdf(
                        state.selectedRows,
                        "isCreditMemoDetailsLoading",
                        false
                      )
                    }
                  >
                    Download
                    {state.isCreditMemoDetailsLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </Button>
                </div>
                <Dropdown
                  classes={{
                    paper: classes.dropdownPaper,
                    wrapper:
                      !!state.filters.creditMemoStatus.length &&
                      classes.dropdownBackground,
                  }}
                  disablePortal={false}
                  isMultiSelect
                  remainOpen
                  placement="bottom-end"
                  options={statusKeys.map((key) => state.dynamicStatus[key])}
                  labelEllipses
                  hasEllipses
                  label={"Status :"}
                  onChange={(selectedOptions) => {
                    setInitialFetch();
                    const isSelectAll = selectedOptions.some(
                      (item) => item.value === "all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicStatus?.all?.isSelected;
                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;
                    const isAllOptionSelected =
                      statusKeys.length - 1 ===
                      selectedOptions.filter((item) => item.value !== "all")
                        .length;

                    const updatedDynamicStatus = statusKeys.reduce(
                      (acc, key) => {
                        const isSelected = isClickedOnSelectAll
                          ? isSelectAll
                          : key === "all"
                          ? isAllOptionSelected
                          : selectedOptions.some(
                              (item) =>
                                item.label === state.dynamicStatus[key].label
                            );

                        return {
                          ...acc,
                          [key]: { ...state.dynamicStatus[key], isSelected },
                        };
                      },
                      {}
                    );

                    const tempFilters = [];

                    if (!updatedDynamicStatus["all"].isSelected) {
                      statusKeys.forEach((key) => {
                        if (key !== "all") {
                          if (updatedDynamicStatus[key].isSelected) {
                            tempFilters.push({
                              field: "status",
                              type: "=",
                              value: key.replace(/_/g, "-"),
                            });
                          }
                        }
                      });
                    }

                    setState((prevState) => ({
                      ...prevState,
                      dynamicStatus: {
                        ...prevState.dynamicStatus,
                        ...updatedDynamicStatus,
                      },
                      filters: {
                        ...prevState.filters,
                        creditMemoStatus: tempFilters,
                      },
                      pageNumber: defaultState.pageNumber,
                    }));
                  }}
                />
                <Dropdown
                  classes={{
                    paper: classes.tunePaper,
                  }}
                  disablePortal={false}
                  remainOpen
                  isMultiSelect
                  placement="bottom-end"
                  options={Object.keys(state.dynamicColumns).map(
                    (key) => state.dynamicColumns[key]
                  )}
                  customToggle={({ anchorRef, onClick }) => (
                    <Tooltip title="Show/Hide column(s)" placement="top-start">
                      <IconButton
                        color="primary"
                        ref={anchorRef}
                        onClick={onClick}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={(options) => {
                    const isSelectAll = options.some(
                      (item) => item.value === "select_all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicColumns?.select_all?.isSelected;

                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;

                    const isAllOptionSelected =
                      Object.keys(state.dynamicColumns).length - 1 ===
                      options.filter((item) => item.value !== "select_all")
                        .length;
                    const updatedDynamicColumns = Object.keys(
                      state.dynamicColumns
                    ).reduce((acc, key) => {
                      const isSelected = isClickedOnSelectAll
                        ? isSelectAll
                        : key === "select_all"
                        ? isAllOptionSelected
                        : !!options.some((item) => item.value === key);

                      return {
                        ...acc,
                        [key]: {
                          ...state.dynamicColumns[key],
                          isSelected,
                        },
                      };
                    }, {});
                    setPageConfig(PAGE_KEYS.VIEW_CREDIT_MEMO, {
                      dynamicColumns: updatedDynamicColumns,
                    });
                    setState((prevState) => ({
                      ...prevState,
                      dynamicColumns: {
                        ...prevState.dynamicColumns,
                        ...updatedDynamicColumns,
                      },
                    }));
                  }}
                />
                <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    className={clsx("ml-2", {
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      updatePageConfig("view-credit-memo");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Grid
              columns={columnConfig}
              rows={state.entries}
              actionBarConfig={null}
              hasSelectAll={false}
              isLoading={state.isLoading}
              totalRows={state.totalEntries}
              onReady={(instance) => (gridHelper = instance)}
              pageSize={state.pageSize}
              pageNumber={state.pageNumber}
              order={state.order}
              orderBy={state.orderBy}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              onSelectionChange={handleSelectionChange}
              onSortChange={handleSortChange}
              classes={{
                container: classes.addressGridridPaper,
              }}
            />
          </Paper>
        )}
        {state.viewCreditMemoSummary && (
          <CreditMemoSummary
            rows={state.entries}
            selectedCreditMemo={state.selectedCreditMemo}
            usersList={appDataList.usersList}
            isLoading={state.isLoading}
            totalEntries={state.totalEntries}
            pageSize={state.pageSize}
            isCreditMemoLoading={state.isCreditMemoLoading}
            pageNumber={state.pageNumber}
            isMarkAsSentLoading={state.isMarkAsSentLoading}
            pdfUrl={state.pdfUrl}
            entry={state.entry}
            history={history}
            creditMemoLoading={state.creditMemoLoading}
            isPdfPreviewLoading={state.isPdfPreviewLoading}
            isPdfDownloadLoading={state.isPdfDownloadLoading}
            emailLoading={state.emailLoading}
            onPageNumberChange={handlePageNumberChange}
            getCreditMemo={getCreditMemo}
            handleMarkCreditMemoSent={handleMarkCreditMemoSent}
            onPageSizeChange={handlePageSizeChange}
            handleClose={handleClose}
            handleItemClick={handleItemClick}
            handleFetch={handleFetchEmail}
            handleCreditMemoFilter={handleCreditMemoFilter}
            handleMultiplePdf={handleMultiplePdf}
            fetchCreditMemo={fetchCreditMemo}
            creditMemoFilterStatus={state.filters.creditMemoStatus}
            handleApprovalDialog={handleApprovalDialog}
          />
        )}
      </div>

      {state.isApprovedDialog && (
        <ActionDialog
          classes={{
            confirm: `${
              state.approvalType === "reject" ? "bg-danger" : "bg-primary"
            }`,
            paper: classes.paperSize,
          }}
          open={state.isApprovedDialog}
          contentText={`Are you sure you want to ${state.approvalType} ?`}
          onConfirm={() => handleApproval(state.invoiceId, state.approvalType)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isApprovedDialog: false,
            }))
          }
          isConfirmDisabled={state.isApprovalLoading}
          positiveActionLabel={
            <>
              {state.approvalType}
              {state.isApprovalLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.deletingEntryIds && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingEntryIds}
          contentText="Are you sure you want to delete?"
          onConfirm={() => handleDelete(state.deletingEntryIds)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingEntryIds: null,
            }))
          }
          isConfirmDisabled={state.isCreditMemoDeleteLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isCreditMemoDeleteLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isEmailSent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={state.isEmailSent}
          contentText={`Are you sure you want to mark credit memo(s) as sent?`}
          onConfirm={() => {
            handleMarkCreditMemoSent(
              state.selectedRows.length && state.selectedRows,
              false
            );
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isEmailSent: false,
            }))
          }
          isConfirmDisabled={state.isMarkAsSentLoading}
          positiveActionLabel={
            <>
              SEND
              {state.isMarkAsSentLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.showSendCreditMemoPopup && (
        <ActionDialog
          classes={{
            confirm:
              state.isCreditMemoSending || isConfirmDisabled
                ? "black"
                : "bg-primary",
            paper: classes.invoiceSentPaperSize,
          }}
          open={!!state.showSendCreditMemoPopup}
          contentText={
            <div style={{ overflow: "hidden" }}>
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                {`Send ${
                  state.selectedRows.length === 1
                    ? `Credit Memo ${
                        state.entries.filter((obj) =>
                          state.selectedRows.includes(obj.id)
                        )[0]?.credit_no
                      }`
                    : "Credit Memos"
                } `}
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value={"noreply@pdxdelivers.com"}
                  className="mt-3"
                  helperText=" "
                />
              </div>
              {!state.selectedRows.length || state.selectedRows.length === 1 ? (
                <>
                  <div className="d-flex">
                    <Typography
                      variant="h6"
                      className="text-bold mr-8 mt-4"
                      style={{ width: "8rem" }}
                    >
                      To
                    </Typography>

                    <div
                      className="d-flex"
                      style={{ gap: "6px", width: "100%" }}
                    >
                      <TextField
                        label="Add Email ID(s)"
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="email"
                        value={state.email}
                        disabled={state.isCreditMemoSending}
                        required
                        className="mt-3"
                        onChange={handleFieldChange}
                        error={!!state.errors.email?.trim()}
                        helperText={state.errors.email}
                      />

                      <div>
                        <Tooltip
                          title="Add Email Temporarily"
                          placement="top-end"
                        >
                          <Button
                            type="submit"
                            onClick={() => {
                              if (
                                validate() === " " &&
                                state.errors?.email === " "
                              ) {
                                handleAddEmail("temporaryEmail");
                              }
                            }}
                            variant="contained"
                            color="primary"
                            disabled={
                              isConfirmDisabled ||
                              !state.email ||
                              state.isCreditMemoSending
                            }
                            className={classes.buttonStyle1}
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip
                          title="Add Email To Customer"
                          placement="top-end"
                        >
                          <Button
                            type="submit"
                            onClick={handleAddPermEmailDialog}
                            variant="contained"
                            color="primary"
                            disabled={
                              isConfirmDisabled ||
                              !state.email ||
                              state.isCreditMemoSending
                            }
                            className={classes.buttonStyle1}
                          >
                            <PostAddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  {(state.temporaryEmail.length > 0 ||
                    state.permanentEmail.length > 0) && (
                    <div className="d-flex mb-4">
                      <div
                        variant="h6"
                        className="mr-8 mt-4"
                        style={{ width: "8rem" }}
                      ></div>
                      <div
                        className="d-flex f-justify-between flex-wrap"
                        style={{ width: "100%", gap: 20 }}
                      >
                        {state.permanentEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Customer Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.emailLoading ? (
                                      <ListItem>
                                        <Skeleton
                                          className="mr-2"
                                          variant="circle"
                                          width={15}
                                          height={15}
                                        />
                                        <ListItemText
                                          primary={
                                            <Skeleton
                                              variant="rect"
                                              width="50%"
                                              height="20px"
                                            />
                                          }
                                        />
                                      </ListItem>
                                    ) : (
                                      state.permanentEmail.map((item) => (
                                        <ListItem key={item}>
                                          <div className="mr-2">
                                            <FiberManualRecordIcon
                                              style={{ fontSize: 10 }}
                                            />
                                          </div>
                                          <ListItemText primary={item} />
                                          {!state.isCreditMemoSending && (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                onClick={() =>
                                                  handleDeleteEmail(
                                                    item,
                                                    "permanentEmail"
                                                  )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          )}
                                        </ListItem>
                                      ))
                                    )}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                        {state.temporaryEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Temporary Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.temporaryEmail.map((item) => (
                                      <ListItem key={item}>
                                        <ListItemText primary={item} />
                                        {!state.isCreditMemoSending && (
                                          <ListItemSecondaryAction>
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteEmail(
                                                  item,
                                                  "temporaryEmail"
                                                )
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <CancelIcon fontSize="small" />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        )}
                                      </ListItem>
                                    ))}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-4"
                    style={{ width: "8rem" }}
                  >
                    To
                  </Typography>
                  <TextField
                    label="Add Email ID(s)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={state.email}
                    required
                    className="mt-3"
                    onChange={handleFieldChange}
                    error={!!state.errors.email?.trim()}
                    helperText={state.errors.email}
                    disabled={state.isCreditMemoSending}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <div className={classes.chipsContainer}>
                          {state.temporaryEmail.map((email) => (
                            <Chip
                              key={email}
                              disabled={state.isCreditMemoSending}
                              label={email}
                              onDelete={() =>
                                handleDeleteEmail(email, "temporaryEmail")
                              }
                              className={classes.emailChip}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  label="Add Subject"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  disabled={state.isCreditMemoSending}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailSubject?.trim()}
                  helperText={state.errors.emailSubject}
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  minRows={10}
                  maxRows={10}
                  multiline
                  disabled={state.isCreditMemoSending}
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailBody?.trim()}
                  helperText={state.errors.emailBody}
                />
              </div>
              <UploadFiles
                uploadedAttachment={uploadedAttachment}
                isLoading={state.isCreditMemoSending}
                attach="credit-memo"
              />
            </div>
          }
          onConfirm={() =>
            handleSubmit(
              state.selectedRows,
              state.temporaryEmail,
              state.permanentEmail,
              state.emailSubject,
              state.emailBody,
              state.uploadedAttachment
            )
          }
          onCancel={() => {
            isConfirmDisabled = false;
            const selectedCompanies =
              state.selectedRows.length > 0
                ? [
                    ...new Set(
                      state.entries
                        .filter((obj) => state.selectedRows.includes(obj.id))
                        .map((ele) => ele.pdx_company)
                    ),
                  ].join(", ")
                : null;

            const displayCompanyName = () => {
              if (
                selectedCompanies &&
                selectedCompanies.split(",").length > 1
              ) {
                return "Parts Distribution Xpress";
              } else {
                return selectedCompanies;
              }
            };
            setState((prevState) => ({
              ...prevState,
              showSendCreditMemoPopup: false,
              email: defaultState.email,
              temporaryEmail: defaultState.temporaryEmail,
              permanentEmail: defaultState.permanentEmail,
              emailSubject: `${
                state.selectedRows.length === 1
                  ? `Credit Memo ${
                      state.entries.filter((obj) =>
                        state.selectedRows.includes(obj.id)
                      )[0]?.credit_no
                    }`
                  : "Credit Memos"
              } from ${selectedCompanies || "PDX"}`,
              emailBody: `Dear ${
                state.selectedRows.length === 1
                  ? state.entries.filter((obj) =>
                      state.selectedRows.includes(obj.id)
                    )[0]?.customer_branch?.customer?.name
                  : "Customers"
              },\n\nYour ${
                state.selectedRows.length > 1
                  ? "Credit Memos are"
                  : "credit memo is"
              } attached. Please review the details of the credit applied to your account. If you have any questions or need further clarification, feel free to reach out to us. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
              errors: {
                email: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          isConfirmDisabled={
            state.isCreditMemoSending ||
            state.emailLoading ||
            isConfirmDisabled ||
            !!state.email ||
            (state.temporaryEmail.length === 0 &&
              state.permanentEmail.length === 0) ||
            (validate() && validate() !== " ") ||
            (state.selectedRows.length &&
              state.selectedRows.length !== 1 &&
              state.temporaryEmail.length === 0)
          }
          positiveActionLabel={
            <>
              SEND CREDIT MEMO
              {state.isCreditMemoSending && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.showAddPermanentEmailDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={!!state.showAddPermanentEmailDialog}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                {`Would you like to add this email address `}
                <span style={{ color: "Black" }}>{state.email}</span>
                {` to customer emails?`}
              </Typography>
            </>
          }
          onConfirm={() =>
            addPermananentEmaiil(
              state.entries.filter((obj) =>
                state.selectedRows.includes(obj.id)
              )[0]?.customer_branch,
              state.permanentEmail
            )
          }
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showAddPermanentEmailDialog: false,
                permanentEmail: emailAddresses,
              };
            });
          }}
          isConfirmDisabled={state.isPermEmailLoading}
          positiveActionLabel={
            <>
              Add To Customer Email
              {state.isPermEmailLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>CANCEL</>}
        />
      )}
    </>
  );
};

export default ViewCreditMemo;
