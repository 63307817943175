/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Chip,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Accordion,
  AccordionSummary,
  Radio,
  FormControl,
  RadioGroup,
  ButtonGroup,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import useStyles from "./style";
import {
  ActionDialog,
  Datepicker,
  Dropdown,
  FilterComponent,
  Grid,
} from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import { parseISO, differenceInSeconds } from "date-fns";
import {
  debounce,
  exportBase64ToFile,
  getDateString,
  getPageConfig,
  getUTCDateString,
  LoadingOverlay,
  queryStringBuilderNew,
  setPageConfig,
  updateGridLayout,
  updatePageConfig,
  updatePagination,
  validator,
} from "utils";
import { AppContext, PAGE_KEYS, VALIDATIONS, noop } from "shared";
import TuneIcon from "@material-ui/icons/Tune";
import { SharedService } from "modules/shared";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InvoiceSummary from "./invoice-summary";
import GenerateInvoicePDF from "../generateInvoicePdf";
import { INVOICE_STATUS } from "modules/shared/constants";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PDFDocument } from "pdf-lib";
import SendIcon from "@material-ui/icons/Send";
import MailIcon from "@material-ui/icons/Mail";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import UploadFiles from "../file-upload";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CreditMemo from "../credit-memo/credit-memo-form";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import ViewCreditMemo from "../credit-memo/view-credit-memo";

let gridHelper = null,
  timeout = null;
let isConfirmDisabled = false;

const defaultState = {
  entries: [],
  tabIndex: 1,
  scheduleDate: null,
  deletingEntryIds: null,
  entry: {},
  isInvoiceLoading: false,
  pdfUrl: "",
  pdxCompanyList: [],
  customerlocationList: [],
  uploadedAttachment: null,
  customer: null,
  isOverdue: false,
  isLatePayment: false,
  selectedInvoice: null,
  pdxCompany: null,
  route: null,
  viewInvoiceSummary: false,
  isMarkAsSentLoading: false,
  selectedLocation: null,
  invoiceSentDate: null,
  search: "",
  pageFilters: [],
  isInvoiceDetailsLoading: false,
  isExportingForQB: false,
  isPdfPreviewLoading: false,
  isPdfDownloadLoading: false,
  isPollingStarted: false,
  isInvoiceSent: false,
  email: "",
  from: "",
  emailSubject: "Invoices from Parts Distribution Xpress (PDX)",
  emailBody:
    "Dear Customers,\n\nYour invoice is attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\nParts Distribution Xpress",
  temporaryEmail: [],
  permanentEmail: [],
  showAddPermanentEmailDialog: false,
  isPermEmailLoading: false,
  errors: {
    email: " ",
    from: " ",
    emailSubject: " ",
    emailBody: " ",
  },
  showSendInvoicePopup: false,
  isInvoiceSending: false,
  emailLoading: false,
  filters: {
    customer: [],
    isOverdue: [],
    isLatePayment: [],
    endDate: [],
    startDate: [],
    invoiceStatus: [],
    pdxCompany: [],
    route: [],
    selectedLocation: [],
    invoiceFilter: [],
    invoiceLatePaymentFilter: [],
    invoiceSentDate: [],
  },
  dynamicStatus: {
    all: { label: "All", value: "all", isSelected: true },
    draft: {
      label: "Draft",
      value: "draft",
      isSelected: true,
    },
    approved: {
      label: "Approved",
      value: "approved",
      isSelected: true,
    },
    pending_approval: {
      label: "Pending Approval",
      value: "pending-approval",
      isSelected: true,
    },
    paid: {
      label: "Paid",
      value: "paid",
      isSelected: true,
    },
    partially_paid: {
      label: "Partially Paid",
      value: "partially-paid",
      isSelected: true,
    },
  },
  totalEntries: 0,
  dateFilterType: "single",
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  selectedRows: [],
  isLoading: false,
  isInvoiceDeleteLoading: false,
  showCreditMemo: false,
  isFetchingCreditMemoData: false,
  creditMemoDetails: null,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
    locations: false,
    route: false,
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    serial_no: { label: "Invoice No.", value: "serial_no", isSelected: true },
    status: { label: "Status", value: "status", isSelected: true },
    invoice_date: {
      label: "Invoice Date",
      value: "invoice_date",
      isSelected: true,
    },
    initial_sent_date: {
      label: "Invoice Sent Date",
      value: "initial_sent_date",
      isSelected: true,
    },
    aa_name: { label: "AA", value: "aa_name", isSelected: true },
    kam_name: { label: "KAM", value: "kam_name", isSelected: true },
    pdxCompany: { label: "PDX Company", value: "pdxCompany", isSelected: true },
    customer: { label: "Customer", value: "customer", isSelected: true },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    terms: {
      label: "Net Terms Days",
      value: "terms",
      isSelected: true,
    },
    total_amount: {
      label: "Total Amount",
      value: "total_amount",
      isSelected: true,
    },
    discount_amt: {
      label: "Discount Amount",
      value: "discount_amt",
      isSelected: true,
    },
    used_credit_amt: {
      label: "Used Credit Amount",
      value: "used_credit_amt",
      isSelected: true,
    },
    total_amount_finalized: {
      label: "Finalized Amount",
      value: "total_amount_finalized",
      isSelected: true,
    },
    balance_amt: {
      label: "Balance Amount",
      value: "balance_amt",
      isSelected: true,
    },
  },
  isApprovalLoading: false,
  isApprovedDialog: false,
  approvalType: "",
  invoiceLoading: false,
  isPulsingEffect: false,
};

const creditDefaultState = {
  hasFetched: false,
  entries: [],
  scheduleDate: null,
  deletingEntryIds: null,
  entry: {},
  isCreditMemoLoading: false,
  pdfUrl: "",
  uploadedAttachment: null,
  selectedCreditMemo: null,
  viewCreditMemoSummary: false,
  isMarkAsSentLoading: false,
  pageFilters: [],
  isCreditMemoDetailsLoading: false,
  isPdfPreviewLoading: false,
  isPdfDownloadLoading: false,
  isEmailSent: false,
  email: "",
  from: "",
  emailSubject: "Credit memo from Parts Distribution Xpress (PDX)",
  emailBody:
    "Dear Customers,\n\nYour credit memo is attached. Please review the details of the credit applied to your account. If you have any questions or need further clarification, feel free to reach out to us. Thank you for your business - we appreciate it very much.\n\nSincerely,\nParts Distribution Xpress",
  temporaryEmail: [],
  permanentEmail: [],
  showAddPermanentEmailDialog: false,
  isPermEmailLoading: false,
  errors: {
    email: " ",
    from: " ",
    emailSubject: " ",
    emailBody: " ",
  },
  showSendCreditMemoPopup: false,
  isCreditMemoSending: false,
  emailLoading: false,
  filters: {
    creditMemoStatus: [],
    creditMemoFilter: [],
  },
  totalEntries: 0,
  dateFilterType: "single",
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  selectedRows: [],
  isLoading: false,
  isCreditMemoDeleteLoading: false,
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    credit_no: {
      label: "Credit Memo No.",
      value: "credit_no",
      isSelected: true,
    },
    status: {
      label: "Status",
      value: "status",
      isSelected: true,
    },
    credit_memo_date: {
      label: "Credit Memo Date",
      value: "credit_memo_date",
      isSelected: true,
    },
    initial_sent_date: {
      label: "Credit Memo Sent Date",
      value: "initial_sent_date",
      isSelected: true,
    },
    invoice_number: {
      label: "Invoice No.",
      value: "invoice_number",
      isSelected: true,
    },
    payment_number: {
      label: "Payment No.",
      value: "payment_number",
      isSelected: true,
    },
    pdxCompany: { label: "PDX Company", value: "pdxCompany", isSelected: true },
    customer: { label: "Customer", value: "customer", isSelected: true },
    location: { label: "Location", value: "location", isSelected: true },
    kam_name: { label: "KAM", value: "kam_name", isSelected: true },
    aa_name: { label: "Office", value: "aa_name", isSelected: true },

    total_amount: {
      label: "Total Amount",
      value: "total_amount",
      isSelected: true,
    },
    invoice_amount: {
      label: "Invoice Amount",
      value: "invoice_amount",
      isSelected: true,
    },
    balance_credit_amount: {
      label: "Balance Credit Amount",
      value: "balance_credit_amount",
      isSelected: true,
    },
  },
  dynamicStatus: {
    all: { label: "All", value: "all", isSelected: true },
    draft: {
      label: "Draft",
      value: "draft",
      isSelected: true,
    },
    approved: {
      label: "Approved",
      value: "approved",
      isSelected: true,
    },
    pending_approval: {
      label: "Pending Approval",
      value: "pending-approval",
      isSelected: true,
    },
  },
  isApprovalLoading: false,
  isApprovedDialog: false,
  approvalType: "",
  invoiceId: null,
  creditMemoLoading: false,
};

const ViewInvoice = ({ history, location }) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const data = location.state || {};
  const pageConfig = getPageConfig(PAGE_KEYS.VIEW_INVOICE);
  const creditPageConfig = getPageConfig(PAGE_KEYS.VIEW_CREDIT_MEMO);
  const startDate = (() => {
    const hasSelectedInvoice =
      data.selectedInvoice && Object.keys(data.selectedInvoice).length > 0;
    const useSelectedInvoiceDate =
      hasSelectedInvoice && pageConfig && !pageConfig?.startDateFilter;

    if (useSelectedInvoiceDate) {
      return [
        {
          field: "invoice_date",
          type: "=",
          value: getUTCDateString(data.selectedInvoice.invoice_date),
        },
      ];
    }

    if (pageConfig?.startDateFilter) {
      return [
        {
          field: "invoice_date",
          type: "=",
          value: pageConfig.startDateFilter,
        },
      ];
    }

    return [];
  })();

  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    tabIndex: (pageConfig && pageConfig?.tabIndex) || defaultState.tabIndex,
    viewInvoiceSummary:
      data?.viewInvoiceSummary || defaultState.viewInvoiceSummary,
    selectedInvoice: data?.selectedInvoice || defaultState.selectedInvoice,
    isPulsingEffect: data?.pulsingEffect || defaultState.isPulsingEffect,
    dateFilterType:
      (pageConfig && pageConfig?.dateFilterType) || defaultState.dateFilterType,
    pdxCompany:
      (pageConfig && pageConfig?.pdxCompanyFilter?.value) ||
      defaultState.pdxCompany,
    customer:
      (pageConfig && pageConfig?.customerFilter?.value) ||
      defaultState.customer,
    selectedLocation:
      (pageConfig && pageConfig?.selectedLocationFilter?.value) ||
      defaultState.selectedLocation,
    route: (pageConfig && pageConfig?.routeFilter?.value) || defaultState.route,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    invoiceSentDate:
      (pageConfig && pageConfig?.invoiceSentDateFilter?.invoiceSentDateValue) ||
      defaultState.invoiceSentDate,
    filters: {
      ...defaultState.filters,
      pdxCompany:
        (pageConfig && pageConfig?.pdxCompanyFilter?.pdxCompany) || [],
      customer: (pageConfig && pageConfig?.customerFilter?.customer) || [],
      selectedLocation:
        (pageConfig && pageConfig?.selectedLocationFilter?.selectedLocation) ||
        [],
      route: (pageConfig && pageConfig?.routeFilter?.route) || [],
      invoiceSentDate:
        (pageConfig && pageConfig?.invoiceSentDateFilter?.invoiceSentDate) ||
        [],
      startDate,
      endDate:
        (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
    },
  });

  const [creditState, creditSetState] = useState({
    ...creditDefaultState,
    dynamicColumns:
      creditPageConfig?.dynamicColumns || creditDefaultState.dynamicColumns,
    pageFilters:
      (creditPageConfig && creditPageConfig?.filters) ||
      creditDefaultState.pageFilters,
  });

  const appDataList = useMemo(() => {
    const usersList = appData.users;
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        mgr: [],
        aa: [],
      }
    );
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
      users,
      usersList,
    };
  }, [appData]);

  const setInitialFetch = () => {
    creditSetState((prevState) => ({
      ...prevState,
      hasFetched: false,
    }));
  };

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchCustomerLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: true,
      },
    }));
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          locations: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList: data?.rows || defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const handleExportForQB = async (selectedIds) => {
    setState((prevState) => ({
      ...prevState,
      isExportingForQB: true,
    }));

    const selectedInvoiceIds = selectedIds.join(",");

    const query = `?invoiceIds=${selectedInvoiceIds}`;

    const { data, error } = await Service.exportForQB(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isExportingForQB: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else {
      toast.success("Invoice(s) exported successfully.");
      exportBase64ToFile(data, "Invoices");
      setState((prevState) => ({
        ...prevState,
        isExportingForQB: false,
      }));
    }
  };

  const getCustomerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany, appDataList]);

  const getLocationList = Object.entries(state.customerlocationList)
    .map(([stateName, locations]) => {
      const filteredLocations = !state.customer
        ? locations
        : locations.filter((location) =>
            state.customer?.customer_branches?.some(
              (branch) => branch.location === location
            )
          );
      return filteredLocations.map((location) => ({
        label: `${location}-(${stateName})`,
        value: location,
      }));
    })
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const getRouteList = useMemo(() => {
    const routes =
      state.customer && state.selectedLocation
        ? state.customer?.customer_branches?.filter(
            (item) => state.selectedLocation?.value === item.location
          )
        : appDataList.customers
            ?.filter((customer) => customer.is_active)
            ?.map((item) => item.customer_branches)
            .flat();

    const allRoutes = routes
      ?.map((branch) => branch.routes)
      .flat()
      ?.map((route) => ({
        id: route.id,
        name: route.name,
        is_active: route.is_active,
      }));

    const activeRoutes = allRoutes?.filter((route) => route.is_active);
    const inactiveRoutes = allRoutes?.filter((route) => !route.is_active);

    const sortedActiveRoutes = activeRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );
    const sortedInactiveRoutes = inactiveRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    return [...sortedActiveRoutes, ...sortedInactiveRoutes];
  }, [appDataList.customers, state.customer, state.selectedLocation]);

  const handleTitleClick = (row) => {
    history.push({
      pathname: "/invoice/draft_invoice",
      state: {
        invoiceId: row?.id,
        customerList: appDataList.customers || [],
        status: row?.status,
      },
    });
  };

  useEffect(() => {
    if (!!data.selectedInvoice && Object.keys(data.selectedInvoice).length > 0)
      getInvoice(state.selectedInvoice, true);
  }, [data.selectedInvoice]);

  const getInvoice = async (row, preview) => {
    setState((prevState) => ({ ...prevState, isInvoiceLoading: true }));

    let queryString = `?invoiceIds=${row?.id}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const invoiceEntries = data?.invoiceDataArray?.[0];

    const blob = GenerateInvoicePDF({
      invoiceDetails: invoiceEntries?.lineItems,
      invoiceDescriptionDetails: invoiceEntries?.lineItemNotes,
      invoiceAdditionalLineItems: invoiceEntries?.additionalLineItemRecords,
      invoiceNo: invoiceEntries?.invoiceDetails?.serial_no,
      terms: invoiceEntries?.invoiceDetails?.terms,
      termsAndCondition: invoiceEntries?.invoiceDetails?.terms_and_condition,
      invoiceDate: invoiceEntries?.invoiceDetails?.invoice_date,
      fromDate: invoiceEntries.invoiceDetails.from_date,
      toDate: invoiceEntries.invoiceDetails.to_date,
      customerNotes: invoiceEntries?.invoiceDetails?.customer_notes,
      invoiceStatus: invoiceEntries?.invoiceDetails?.status,
      discount: invoiceEntries?.invoiceDetails?.discount_amt,
      usedCreditAmount: invoiceEntries?.invoiceDetails?.used_credit_amt,
      pdxCompany:
        invoiceEntries?.invoiceDetails?.customer_branch?.pdxCompany?.value,
      balanceAmount: invoiceEntries?.invoiceDetails?.balance_amt,
      paidAmount: invoiceEntries?.invoiceDetails?.paid_amt,
      isOverdue: invoiceEntries?.invoiceDetails?.is_overdue,
      completePaymentDoneOn:
        invoiceEntries?.invoiceDetails?.complete_payment_done_on,
      isLatePayment:
        getUTCDateString(
          invoiceEntries?.invoiceDetails?.payment_completed_date
        ) > getUTCDateString(invoiceEntries?.invoiceDetails?.payment_due_date)
          ? true
          : false,
      billTo: {
        customerName: invoiceEntries?.invoiceDetails?.bill_to?.customerName,
        branch: invoiceEntries?.invoiceDetails?.bill_to?.branch,
        streetAddress: invoiceEntries?.invoiceDetails?.bill_to?.street_address,
        state: invoiceEntries?.invoiceDetails?.bill_to?.state,
        city: invoiceEntries?.invoiceDetails?.bill_to?.city,
        country: invoiceEntries?.invoiceDetails?.bill_to?.country,
        zipCode: invoiceEntries?.invoiceDetails?.bill_to?.zip_code,
        phone: invoiceEntries?.invoiceDetails?.bill_to?.phone,
        primary_contact:
          invoiceEntries?.invoiceDetails?.bill_to?.primary_contact ||
          invoiceEntries?.invoiceDetails?.bill_to?.contact,
      },
      isPreview: preview,
    });

    setState((prevState) => ({
      ...prevState,
      isInvoiceLoading: false,
      pdfUrl: blob,
      entry: invoiceEntries || defaultState.entry,
    }));
    history.replace({ ...history.location, state: {} });
    return invoiceEntries;
  };

  const fetchInvoice = async (row, loading) => {
    setState((prevState) => ({ ...prevState, [loading]: true }));

    let queryString = `?invoiceIds=${row?.id}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    if (error) {
      setState((prevState) => ({ ...prevState, [loading]: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const invoiceEntries = data?.invoiceDataArray?.[0];

    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
          entry: invoiceEntries || defaultState.entry,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      });
  };

  const handleMarkInvoiceSent = async (id, isSingleInvoice = false) => {
    setState((prevState) => ({ ...prevState, isMarkAsSentLoading: true }));

    const idArray = [];
    if (Array.isArray(id)) {
      idArray.push(...id);
    } else {
      idArray.push(id);
    }

    const { error } = await Service.markInvoiceSent({ invoiceIds: idArray });

    if (error) {
      setState((prevState) => ({ ...prevState, isMarkAsSentLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice(s) marked as sent successfully");
    setState((prevState) => ({
      ...prevState,
      isMarkAsSentLoading: false,
      isInvoiceSent: false,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    if (isSingleInvoice) {
      getInvoice({ id: id || isSingleInvoice }, true);
    }
  };

  const handleMarkInvoiceSentDialog = () =>
    setState((prevState) => ({
      ...prevState,
      isInvoiceSent: true,
    }));

  const getSearchArray = (search) => {
    const columns = ["serial_no"];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const getCreditSearchArray = (search) => {
    const columns = ["credit_no"];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      invoicePopup = false,
      isDataFetched = false
    ) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isPollingStarted: isDataFetched,
      }));
      const searchArray = getSearchArray(search);
      const sortObj = { field: orderBy, order };

      let filtersArr = [];

      if (pageFilters.length > 0) {
        filtersArr = [
          ...pageFilters?.map((filter) => ({
            ...filter,
            value: filter?.value?.value || filter?.value,
          })),
        ];
      }

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (filters.invoiceStatus.length && filters.invoiceStatus) {
        filtersArr = [...filtersArr, ...filters.invoiceStatus];
      }
      if (filters.isOverdue.length && filters.isOverdue) {
        filtersArr = [...filtersArr, ...filters.isOverdue];
      }
      if (
        filters.selectedLocation.length &&
        filters.selectedLocation[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.selectedLocation];
      }
      if (filters.invoiceSentDate.length && filters.invoiceSentDate[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceSentDate];
      }
      if (filters.invoiceFilter.length && filters.invoiceFilter[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceFilter];
      }

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        sortObj,
        search && search.length > 0
      );
      if (filters.route.length && filters.route[0]) {
        let routeArray = [];
        routeArray.push(filters.route);
        queryString += `&filter[where][and][0][or][0][route_ids][contains]=${JSON.stringify(
          filters.route
        )}`;
      }
      if (filters.isLatePayment.length && filters.isLatePayment) {
        queryString += `&filter[isLatePayment]=${filters.isLatePayment[0].value}`;
      }
      if (
        filters.invoiceLatePaymentFilter.length &&
        filters.invoiceLatePaymentFilter[0].value
      ) {
        queryString += `&filter[isLatePayment]=${filters.invoiceLatePaymentFilter[0].value}`;
      }
      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][invoice_date]=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][invoice_date][gte]=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][invoice_date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }
      const { data, error } = await Service.getAllInvoice(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          selectedRows: defaultState.selectedRows,
          isPollingStarted: false,
        }));
        gridHelper && gridHelper.resetSelection();
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        selectedRows: !invoicePopup
          ? defaultState.selectedRows
          : prevState.selectedRows,
        totalEntries: data.count,
      }));

      if (!invoicePopup) {
        gridHelper && gridHelper.resetSelection();
      }
      history.replace({ ...history.location, state: {} });
      return data;
    },
    [state.dateFilterType]
  );

  const handleGetInvoice = useCallback(
    (search, filters, pageSize, pageNumber, order, orderBy, pageFilters) => {
      let retryCount = 0;
      const maxRetries = 20;

      const stopPolling = (intervalId) => {
        clearInterval(intervalId);
        setState((prevState) => ({
          ...prevState,
          isPollingStarted: false,
        }));
      };

      const pollDraftInvoice = async () => {
        try {
          const data = await fetchEntries(
            search,
            filters,
            pageSize,
            pageNumber,
            order,
            orderBy,
            pageFilters,
            false,
            true
          );

          const shouldContinuePolling = data.rows.some((row) =>
            ["NOT_STARTED", "IN_PROGRESS"].includes(
              row?.invoice_generation_status
            )
          );

          if (!shouldContinuePolling) {
            stopPolling(intervalId);
          }

          retryCount++;
          if (retryCount >= maxRetries) {
            stopPolling(intervalId);
          }
        } catch (error) {
          stopPolling(intervalId);
        }
      };

      const intervalId = setInterval(pollDraftInvoice, 1000);

      pollDraftInvoice();

      return () => stopPolling(intervalId);
    },
    []
  );

  useEffect(() => {
    handleGetInvoice(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  }, []);

  const fetchAllInvoiceEntries = useCallback(
    async (selectedIds = [], loading) => {
      if (!selectedIds.length) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        [loading]: true,
      }));

      const idString = selectedIds.join(",");
      let queryString = selectedIds.length ? `?invoiceIds=${idString}` : "";
      const { data, error } = await Service.getAllInvoiceDetails(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        [loading]: false,
      }));
      return data?.invoiceDataArray;
    },
    [state.selectedRows]
  );

  const filterConfig = useMemo(
    () => [
      {
        field: "kam_name",
        fieldToDisplay: "KAM",
        operatorType: "string",
        options: appDataList?.users?.kam?.map((kam) => ({
          ...kam,
          label: `${kam.first_name} ${kam.last_name}-(${kam.username})  `,
          value: `${kam.first_name} ${kam.last_name}`,
        })),
      },
      {
        field: "aa_name",
        fieldToDisplay: "Office",
        operatorType: "string",
        options: appDataList?.users?.aa?.map((office) => ({
          ...office,
          label: `${office.first_name || ""} ${office.last_name || ""}-(${
            office.username
          })`,
          value: `${office.first_name || ""} ${office.last_name || ""}`,
        })),
      },
      {
        field: "terms",
        fieldToDisplay: "Terms",
        operatorType: "number",
      },
      {
        field: "total_amount_calculated",
        fieldToDisplay: "Total Amount",
        operatorType: "number",
      },
      {
        field: "discount_amt",
        fieldToDisplay: "Discount Amount",
        operatorType: "number",
      },
      {
        field: "used_credit_amt",
        fieldToDisplay: "Used Credit Amount",
        operatorType: "number",
      },
      {
        field: "total_amount_finalized",
        fieldToDisplay: "Finalized Amount",
        operatorType: "number",
      },
      {
        field: "balance_amt",
        fieldToDisplay: "Balance Amount",
        operatorType: "number",
      },
    ],
    [appDataList.users]
  );

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.viewInvoiceSummary,
    state.pageFilters,
  ]);

  useEffect(() => {
    fetchList("customers", noop);
    fetchList("users", noop);
    fetchPDXCompanyList();
    fetchCustomerLocation();
  }, [state.viewInvoiceSummary]);

  useEffect(() => {
    if (state.tabIndex === 1) {
      const handleResize = debounce(() => {
        window.requestAnimationFrame(updateGridLayout);
      }, 100);

      updateGridLayout();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [state.entries, state.tabIndex]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback(async (selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSearch = useCallback((search) => {
    setInitialFetch();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        pageNumber: defaultState.pageNumber,
        selectedRows: defaultState.selectedRows,
      }));

      creditSetState((prevState) => ({
        ...prevState,
        pageNumber: creditDefaultState.pageNumber,
        selectedRows: creditDefaultState.selectedRows,
      }));
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleInvoiceFilter = (option) => {
    if (option.value === "overdue") {
      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          invoiceFilter: [
            {
              field: "is_overdue",
              type: "=",
              value: true,
            },
          ],
          invoiceLatePaymentFilter: [],
        },
      }));
    } else if (option.value === "isLatePayment") {
      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          invoiceLatePaymentFilter: [
            {
              field: "isLatePayment",
              type: "=",
              value: true,
            },
          ],
          invoiceFilter: [],
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          invoiceFilter: [
            {
              field: "status",
              type: "=",
              value: option.value,
            },
          ],
          invoiceLatePaymentFilter: [],
        },
      }));
    }
  };

  const handleTabChange = useCallback((tabIndex) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex,
      selectedRows: defaultState.selectedRows,
    }));
    setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
      tabIndex,
    });
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApproval = async (id, type) => {
    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: true,
    }));

    let idArray = [];
    idArray.push(id);

    let serviceMethod, status;

    switch (type) {
      case "approve":
        serviceMethod = "approveInvoice";
        status = "approved";

        break;
      case "reject":
        serviceMethod = "rejectInvoice";
        status = "rejected";
        break;
      default:
        serviceMethod = null;
    }

    const { error } = await Service[serviceMethod]({ invoiceIds: idArray });

    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: false,
      isApprovedDialog: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success(`Invoice has been ${status} successfully.`);
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
      getInvoice({ id: id }, true);
    }
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
      filters: pageFilters,
    });
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApprovalDialog = (id, type, value) => {
    setState((prevState) => ({
      ...prevState,
      isApprovedDialog: value,
      invoiceId: id,
      approvalType: type,
    }));
  };

  const handleItemClick = (invoice) => {
    setState((prevState) => ({
      ...prevState,
      selectedInvoice: invoice,
    }));
  };

  const handleFetchEmail = (loading) => {
    setState((prevState) => ({
      ...prevState,
      [loading]: true,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters,
      state.showSendInvoicePopup
    )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      });
  };

  const handleMultiplePdf = async (
    selectedInvoiceIds = [],
    loading,
    isPreview = false
  ) => {
    const invoiceData = await fetchAllInvoiceEntries(
      selectedInvoiceIds,
      loading
    );

    const pdfArrayBuffers = await Promise.all(
      invoiceData?.map(async (invoice) => {
        const invoiceData = {
          invoiceDetails: invoice?.lineItems,
          invoiceDescriptionDetails: invoice?.lineItemNotes,
          invoiceAdditionalLineItems: invoice?.additionalLineItemRecords,
          invoiceNo: invoice.invoiceDetails.serial_no,
          terms: invoice.invoiceDetails.terms,
          termsAndCondition: invoice.invoiceDetails.terms_and_condition,
          invoiceDate: invoice.invoiceDetails.invoice_date,
          fromDate: invoice.invoiceDetails.from_date,
          toDate: invoice.invoiceDetails.to_date,
          customerNotes: invoice.invoiceDetails.customer_notes,
          invoiceStatus: invoice.invoiceDetails.status,
          discount: invoice.invoiceDetails.discount_amt,
          usedCreditAmount: invoice.invoiceDetails?.used_credit_amt,
          pdxCompany: invoice.invoiceDetails.customer_branch?.pdxCompany?.value,
          balanceAmount: invoice?.invoiceDetails?.balance_amt,
          paidAmount: invoice?.invoiceDetails?.paid_amt,
          isOverdue: invoice?.invoiceDetails?.is_overdue,
          completePaymentDoneOn:
            invoice?.invoiceDetails?.complete_payment_done_on,
          isLatePayment:
            getUTCDateString(invoice?.invoiceDetails?.payment_completed_date) >
            getUTCDateString(invoice?.invoiceDetails?.payment_due_date)
              ? true
              : false,
          billTo: {
            customerName: invoice.invoiceDetails.bill_to?.customerName,
            branch: invoice.invoiceDetails.bill_to?.branch,
            streetAddress: invoice.invoiceDetails.bill_to?.street_address,
            state: invoice.invoiceDetails.bill_to?.state,
            city: invoice.invoiceDetails.bill_to?.city,
            country: invoice.invoiceDetails.bill_to?.country,
            zipCode: invoice.invoiceDetails.bill_to?.zip_code,
            phone: invoice.invoiceDetails.bill_to?.phone,
            primary_contact:
              invoice.invoiceDetails.bill_to?.primary_contact ||
              invoice.invoiceDetails.bill_to?.contact,
          },
          isArrayPrinting: true,
        };

        const pdfBytes = await GenerateInvoicePDF(invoiceData);
        return pdfBytes;
      })
    );

    const mergedPdf = await PDFDocument.create();

    for (const pdfArrayBuffer of pdfArrayBuffers) {
      const pdf = await PDFDocument.load(pdfArrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();

    const mergedPdfBlob = new Blob([mergedPdfBytes], {
      type: "application/pdf",
    });

    if (isPreview) {
      const pdfUrl = URL.createObjectURL(mergedPdfBlob);
      window.open(pdfUrl);
    } else {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(mergedPdfBlob);
      link.download = `multiple_invoices${
        state.pdxCompany
          ? `-${state.pdxCompany?.value.replace(/\s+/g, "_")}`
          : ""
      }-${
        state.dateFilterType === "single"
          ? getDateString(state.filters.startDate[0]?.value)
          : `${getDateString(
              state.filters.startDate[0]?.value
            )}-${getDateString(state.filters.endDate[0]?.value)}`
      }.pdf`;

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.serial_no?.isSelected,
      id: "serial_no",
      label: "Invoice No.",
      field: "serial_no",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title = row.serial_no;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography
                noWrap
                variant="body2"
                onClick={() => {
                  getInvoice(row, true);
                  setState((prevState) => ({
                    ...prevState,
                    viewInvoiceSummary: true,
                    selectedInvoice: row,
                  }));
                }}
                style={{
                  color: "#775edc",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
            {!!row?.is_sent_to_customer && (
              <Tooltip title={"Invoice Sent"} placement="top-start">
                <MailIcon color="primary" className="ml-2" />
              </Tooltip>
            )}
            {row?.creditMemoRecord?.id && (
              <Tooltip
                title={
                  <Fragment>
                    <p>Credit Memo No. - {row.creditMemoRecord?.credit_no}</p>
                    <p>
                      Credit Memo Date -{" "}
                      {getUTCDateString(row.creditMemoRecord?.credit_memo_date)}
                    </p>
                  </Fragment>
                }
                placement="top-start"
              >
                <span
                  onClick={() => getCreditMemo(row.creditMemoRecord.id)}
                  style={{ cursor: "pointer" }}
                >
                  <ReceiptIcon color="primary" className="ml-2 mt-1" />
                </span>
              </Tooltip>
            )}
            {row?.is_auto_approve && (
              <Tooltip title={"Auto Approved"} placement="top-start">
                <BeenhereIcon color="primary" className="ml-2" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title = INVOICE_STATUS.find((item) => item.value === row?.status);
        return (
          <Tooltip
            title={
              !!row.is_overdue
                ? `${title?.label} / Overdue`
                : !!(
                    getUTCDateString(row?.payment_completed_date) >
                    getUTCDateString(row?.payment_due_date)
                  )
                ? `${title?.label} / Late Payment Received`
                : title?.label ?? ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {!!row.is_overdue
                ? `${title?.label} / Overdue`
                : !!(
                    getUTCDateString(row?.payment_completed_date) >
                    getUTCDateString(row?.payment_due_date)
                  )
                ? `${title?.label} / Late Payment Received`
                : title?.label || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_date?.isSelected,
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.initial_sent_date?.isSelected,
      id: "initial_sent_date",
      label: "Invoice Sent Date",
      field: "initial_sent_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.initial_sent_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdxCompany?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer?.isSelected,
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        const title = row?.customer_branch?.customer?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam_name?.isSelected,
      id: "kam_name",
      label: "KAM",
      field: "kam_name",
      canSort: true,
      render: (row) => {
        const kamUser = row?.customer_branch?.kamUser;
        const title = kamUser
          ? `${kamUser.first_name ?? ""} ${kamUser.last_name ?? ""} - (${
              kamUser.username ?? ""
            })`
          : "";
        const kamName = kamUser
          ? `${kamUser.first_name ?? ""} ${kamUser.last_name ?? ""}`
          : "-";
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.aa_name?.isSelected,
      id: "aa_name",
      label: "Office",
      field: "aa_name",
      canSort: true,
      render: (row) => {
        const aaUser = row?.customer_branch?.aaUser;
        const title = aaUser
          ? `${aaUser.first_name ?? ""} ${aaUser.last_name ?? ""} - (${
              aaUser.username ?? ""
            })`
          : "";
        const aaName = aaUser
          ? `${aaUser.first_name ?? ""} ${aaUser.last_name ?? ""}`
          : "-";

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {aaName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "route",
      label: "Route(s)",
      field: "route",
      render: (row) => {
        const routes = row?.RoutesDetails ?? [];
        const title = routes.map((ele) => ele?.name)?.join(", ");
        return (
          <Tooltip title={title || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.terms?.isSelected,
      id: "terms",
      label: "Net Terms Days",
      field: "terms",
      canSort: true,
      render: (row) => {
        const title = row.terms;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount?.isSelected,
      id: "total_amount_calculated",
      label: "Total Amount",
      field: "total_amount_calculated",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount_calculated || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.discount_amt?.isSelected,
      id: "discount_amt",
      label: "Discount Amount",
      field: "discount_amt",
      canSort: true,
      render: (row) => {
        const title = `$${row.discount_amt || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.used_credit_amt?.isSelected,
      id: "used_credit_amt",
      label: "Used Credit Amount",
      field: "used_credit_amt",
      canSort: true,
      render: (row) => {
        const title = !!row.used_credit_amt ? `$${row.used_credit_amt}` : "";
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount_finalized?.isSelected,
      id: "total_amount_finalized",
      label: "Finalized Amount",
      field: "total_amount_finalized",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount_finalized || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.balance_amt?.isSelected,
      id: "balance_amt",
      label: "Balance Amount",
      field: "balance_amt",
      canSort: true,
      render: (row) => {
        const title = row.balance_amt;
        return (
          <Tooltip title={`$${title || "0.00"}`} placement="top-start">
            <Typography variant="body2" noWrap>{`$${
              title || "0.00"
            }`}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleClose = (isClose) => {
    setState((prevState) => ({
      ...prevState,
      viewInvoiceSummary: false,
      filters: {
        ...prevState.filters,
        invoiceFilter: defaultState.filters.invoiceFilter,
      },
    }));
  };

  const handleDelete = async (ids) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: true,
    }));

    const queryString = `?invoiceIds=${ids}`;

    const { error } = await Service.deleteInvoices(queryString);

    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      gridHelper && gridHelper.resetSelection();
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
    }
  };

  const statusKeys = Object.keys(defaultState.dynamicStatus);

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      from: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
      emailSubject: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      emailBody: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = " ";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const uploadedAttachment = (attachment) => {
    setState((prevState) => ({
      ...prevState,
      uploadedAttachment: attachment,
    }));
  };

  const handleSubmit = async (
    ids = [],
    tempEmailArray,
    permEmailArray,
    subject,
    body,
    attachment
  ) => {
    setState((prevState) => ({ ...prevState, isInvoiceSending: true }));
    const emailArray =
      !state.selectedRows.length || state.selectedRows.length === 1
        ? [...tempEmailArray, ...permEmailArray]
        : [...tempEmailArray];

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email_body", body);

    ids.forEach((id, index) => formData.append(`invoiceIds[${index}]`, id));
    emailArray.forEach((email, index) =>
      formData.append(`emailIds[${index}]`, email)
    );

    attachment.forEach((file, index) => {
      formData.append(`uploadedAttachment`, file);
    });

    const { error } = await Service.sendEmail(formData);

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceSending: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Email sent successfully.");
    setState((prevState) => ({
      ...prevState,
      isInvoiceSending: false,
      showSendInvoicePopup: false,
      temporaryEmail: defaultState.temporaryEmail,
      permanentEmail: defaultState.permanentEmail,
      from: defaultState.from,
      emailSubject: defaultState.emailSubject,
      emailBody: defaultState.emailBody,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
    handleFetchEmail("invoiceLoading");
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.temporaryEmail.some((obj) => obj === value.trim())) {
      errorMessage = "This email has already been entered.";
    }
    if (state.permanentEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }

    isConfirmDisabled = !!errorMessage && errorMessage !== " ";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddEmail = (type) => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        [type]: [...(prevState[type] || []), state?.email?.trim()],

        ...(type === "temporaryEmail" && {
          showAddPermanentEmailDialog: false,
          email: defaultState.email,
        }),
      }));
    }
  };

  const handleDeleteEmail = (id, type) => {
    setState((prevState) => {
      const filteredEmails = prevState[type].filter((email) => email !== id);
      const emailExists = filteredEmails.some(
        (item) => item === prevState.email
      );

      return {
        ...prevState,
        [type]: filteredEmails,
        errors: {
          ...prevState.errors,
          email: emailExists ? prevState.errors.email : " ",
          from: prevState.errors.from,
          emailSubject: prevState.errors.emailSubject,
          emailBody: prevState.errors.emailBody,
        },
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (validate() === " " && state.errors?.email === " ") {
        event.preventDefault();
        handleAddEmail("temporaryEmail");
      }
    }
  };

  const handleBlur = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("temporaryEmail");
    }
  };

  const handleAddPermEmailDialog = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("permanentEmail");
    }

    setState((prevState) => ({
      ...prevState,
      showAddPermanentEmailDialog: true,
    }));
  };

  const addPermananentEmaiil = async (data, email) => {
    setState((prevState) => ({ ...prevState, isPermEmailLoading: true }));

    const payload = {
      id: data.id || null,
      invoice_type: data.invoiceType?.id || null,
      pdx_company_id: data.pdx_company_id || null,
      customer_id: data.customer_id,
      state: data.state || null,
      location: data.location || null,
      aa_id: data.aa_id || null,
      kam_id: data.kam_id || null,
      mgr_id: data.mgr_id || null,
      email_addresses: {
        emailAddresses: email,
      },
    };

    const { error } = await Service.addEmailInBranch(payload);

    if (error) {
      handleAddEmail("temporaryEmail");
      setState((prevState) => ({ ...prevState, isPermEmailLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Customer Email has been succesfully added.`);
    setState((prevState) => ({
      ...prevState,
      isPermEmailLoading: false,
      showAddPermanentEmailDialog: false,
      email: defaultState.email,
    }));
    handleFetchEmail("emailLoading");
  };

  useEffect(() => {
    const selectedCompanies =
      state.selectedRows.length > 0
        ? [
            ...new Set(
              state.entries
                .filter((obj) => state.selectedRows.includes(obj.id))
                .map((invoice) => invoice.pdx_company)
            ),
          ].join(", ")
        : null;

    const displayCompanyName = () => {
      if (selectedCompanies && selectedCompanies.split(",").length > 1) {
        return "Parts Distribution Xpress";
      } else {
        return selectedCompanies;
      }
    };
    setState((prevState) => ({
      ...prevState,
      emailSubject: `${
        state.selectedRows.length === 1
          ? `Invoice #${
              state.entries.filter((obj) =>
                state.selectedRows.includes(obj.id)
              )[0]?.serial_no
            }`
          : "Invoices"
      } from ${selectedCompanies || "PDX"}`,
      emailBody: `Dear ${
        state.selectedRows.length === 1
          ? state.entries.filter((obj) =>
              state.selectedRows.includes(obj.id)
            )[0]?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour ${
        state.selectedRows.length > 1 ? "invoices are" : "invoice is"
      } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
    }));
  }, [state.selectedInvoice, state.selectedRows, state.entries]);

  const dataEntry =
    state.entries.filter((obj) => state.selectedRows.includes(obj.id))[0] || [];

  const emailAddresses =
    state.selectedRows.length === 1 &&
    (dataEntry?.customer_branch?.email_addresses?.emailAddresses || []);

  useEffect(() => {
    if (state.showSendInvoicePopup && emailAddresses.length) {
      setState((prevState) => ({
        ...prevState,
        permanentEmail: emailAddresses,
      }));
    }
  }, [state.showSendInvoicePopup, dataEntry, emailAddresses]);

  const getCreditMemo = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCreditMemoData: true,
    }));

    const query = `?filter[where][id]=${id}`;

    const { data, error } = await Service.getCreditMemo(query);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCreditMemoData: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCreditMemoData: false,
      showCreditMemo: true,
      creditMemoDetails: data.rows[0] || defaultState.creditMemoDetails,
    }));
  };

  const handleCloseCreditMemo = (close) => {
    setState((prevState) => ({
      ...prevState,
      showCreditMemo: close,
    }));
  };

  return (
    <>
      {state.isFetchingCreditMemoData && (
        <LoadingOverlay message="Processing, please wait..." />
      )}
      <div
        className={clsx("mr-5", state.isFetchingCreditMemoData && classes.blur)}
      >
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            View Invoices & Memos
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/invoice")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Invoice
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            View All Invoices & Credit Memos.
          </Typography>
        </div>
        <>
          {(state.tabIndex === 1
            ? !state.viewInvoiceSummary
            : !creditState.viewCreditMemoSummary) && (
            <div className="d-flex f-justify-between f-align-center">
              <div className="d-flex f-wrap">
                <div
                  className={clsx({
                    "d-flex f-align-center mt-1": !appData.isTabletView,
                  })}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      value={state.dateFilterType}
                      onChange={(evt) => {
                        const { value } = evt.target;
                        setState((prevState) => ({
                          ...prevState,
                          dateFilterType: value,
                          selectedRows: [],
                          filters: {
                            ...prevState.filters,
                            startDate: [
                              {
                                field: "invoice_date",
                                type: value === "single" ? "=" : "gte",
                                value:
                                  (pageConfig && pageConfig?.startDateFilter) ||
                                  getDateString(new Date()),
                              },
                            ],
                            endDate:
                              value === "single"
                                ? []
                                : [
                                    {
                                      field: "invoice_date",
                                      type: "lte",
                                      value:
                                        (pageConfig &&
                                          pageConfig?.endDateFilter) ||
                                        getDateString(new Date()),
                                    },
                                  ],
                          },
                          pageNumber: defaultState.pageNumber,
                        }));
                        creditSetState((prevState) => ({
                          ...prevState,
                          pageNumber: creditDefaultState.pageNumber,
                        }));
                        setInitialFetch();
                        setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                          dateFilterType: value,
                          endDateFilter:
                            (pageConfig && pageConfig?.startDateFilter) ||
                            getDateString(new Date()),
                          startDateFilter:
                            (pageConfig && pageConfig?.startDateFilter) ||
                            getDateString(new Date()),
                        });

                        if (gridHelper) {
                          gridHelper.resetSelection();
                          gridHelper.resetAllSelection();
                        }
                      }}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio size="small" color="primary" />}
                        label="Specific date"
                      />
                      <FormControlLabel
                        value="multiple"
                        control={<Radio size="small" color="primary" />}
                        label="Date range"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="d-flex mt-3">
                    <Datepicker
                      mask
                      isClearable={state.dateFilterType === "single"}
                      preventClear
                      selected={
                        state.filters.startDate.length &&
                        state.filters.startDate[0]?.value?.length
                          ? state.filters.startDate[0]?.value
                          : null
                      }
                      label={
                        state.dateFilterType === "single"
                          ? "Date"
                          : "Start date"
                      }
                      classes={{
                        datepickerWrapper: classes.scheduleDateWrapper,
                        input: {
                          root: clsx("mr-4", classes.datepickerWrapper),
                        },
                      }}
                      maxDate={
                        state.filters.endDate[0]?.value
                          ? new Date(state.filters.endDate[0]?.value)
                          : pageConfig &&
                            pageConfig?.dateFilterType === "multiple" &&
                            pageConfig?.endDateFilter
                          ? new Date(pageConfig.endDateFilter)
                          : new Date()
                      }
                      onChange={(startDate) => {
                        setState((prevState) => ({
                          ...prevState,
                          selectedRows: [],
                          filters: {
                            ...prevState.filters,
                            startDate: [
                              {
                                field: "invoice_date",
                                type:
                                  state.dateFilterType === "single"
                                    ? "="
                                    : "gte",
                                value: getDateString(startDate),
                              },
                            ],
                          },
                        }));
                        setInitialFetch();
                        setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                          startDateFilter: getDateString(startDate),
                          endDateFilter:
                            state.dateFilterType === "single"
                              ? getDateString(startDate)
                              : (pageConfig && pageConfig?.endDateFilter) ||
                                getDateString(new Date()),
                        });
                        if (gridHelper) {
                          gridHelper.resetSelection();
                          gridHelper.resetAllSelection();
                        }
                      }}
                    />
                    {state.dateFilterType === "multiple" && (
                      <Datepicker
                        mask
                        preventClear
                        selected={
                          state.filters?.endDate.length &&
                          (state.filters?.endDate[0]?.value ||
                            state.filters?.endDate)
                        }
                        label="End Date"
                        classes={{
                          datepickerWrapper: classes.scheduleDateWrapper,
                          input: {
                            root: clsx("mr-4", classes.datepickerWrapper),
                          },
                        }}
                        maxDate={new Date()}
                        minDate={new Date(state.filters.startDate[0]?.value)}
                        onChange={(endDate) => {
                          setState((prevState) => ({
                            ...prevState,
                            selectedRows: [],
                            filters: {
                              ...prevState.filters,
                              endDate: [
                                {
                                  field: "invoice_date",
                                  type: "lte",
                                  value: getDateString(endDate),
                                },
                              ],
                            },
                          }));
                          setInitialFetch();
                          setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                            endDateFilter: getDateString(endDate),
                          });
                          if (gridHelper) {
                            gridHelper.resetSelection();
                            gridHelper.resetAllSelection();
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-4 mr-4">
                  <Datepicker
                    mask
                    label="Sent Date"
                    selected={state.invoiceSentDate}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: clsx("mr-4", classes.datepickerWrapper),
                      },
                    }}
                    isClearable
                    onChange={(value) => {
                      setState((prevState) => ({
                        ...prevState,
                        invoiceSentDate: value,
                        filters: {
                          ...prevState.filters,
                          invoiceSentDate: [
                            {
                              field: "initial_sent_date",
                              type: "=",
                              value: getDateString(value),
                            },
                          ],
                        },
                      }));
                      setInitialFetch();
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        invoiceSentDateFilter: {
                          invoiceSentDateValue: getDateString(value),
                          invoiceSentDate: [
                            {
                              field: "initial_sent_date",
                              type: "=",
                              value: getDateString(value),
                            },
                          ],
                        },
                      });
                    }}
                  />
                </div>
                <Autocomplete
                  fullWidth
                  className={clsx("mr-4 mt-4", classes.customerDropdown)}
                  size="small"
                  value={state.pdxCompany}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={state.pdxCompanyList}
                  getOptionLabel={(option) => option.value || ""}
                  renderInput={(params) =>
                    state.isFetchingList.pdxCompany ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField
                        {...params}
                        label="PDX Company"
                        variant="outlined"
                      />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      pdxCompany: value,
                      filters: {
                        ...prevState.filters,
                        pdxCompany: [
                          {
                            field: "pdx_company_name",
                            type: "=",
                            value: value?.value,
                          },
                        ],
                      },
                    }));
                    setInitialFetch();
                    setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                      pdxCompanyFilter: {
                        value,
                        pdxCompany: [
                          {
                            field: "pdx_company_name",
                            type: "=",
                            value: value?.value,
                          },
                        ],
                      },
                    });
                    if (
                      state.customer ||
                      state.selectedLocation ||
                      state.route
                    ) {
                      setState((prevState) => ({
                        ...prevState,
                        customer: null,
                        selectedLocation: null,
                        route: null,
                        filters: {
                          ...prevState.filters,
                          customer: [],
                          selectedLocation: [],
                          route: [],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        customerFilter: null,
                        selectedLocationFilter: null,
                        routeFilter: null,
                      });
                    }
                  }}
                />
                <Autocomplete
                  fullWidth
                  className={clsx("mr-4 mt-4", classes.customerDropdown)}
                  size="small"
                  value={state.customer}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={
                    !state.pdxCompany ? appData.customers : getCustomerList
                  }
                  getOptionLabel={({ name }) => name}
                  renderInput={(params) =>
                    state.isFetchingList.customers ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField
                        {...params}
                        label="Customer"
                        variant="outlined"
                      />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      customer: value,
                      filters: {
                        ...prevState.filters,
                        customer: [
                          {
                            field: "customer_name",
                            type: "=",
                            value: value?.name,
                          },
                        ],
                      },
                    }));
                    setInitialFetch();
                    setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                      customerFilter: {
                        value,
                        customer: [
                          {
                            field: "customer_name",
                            type: "=",
                            value: value?.name,
                          },
                        ],
                      },
                    });
                    if (state.selectedLocation || state.route) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedLocation: null,
                        route: null,
                        filters: {
                          ...prevState.filters,
                          selectedLocation: [],
                          route: [],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        selectedLocationFilter: null,
                        routeFilter: null,
                      });
                    }
                  }}
                />
                <Autocomplete
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  size="small"
                  fullWidth
                  className={clsx("mr-4 mt-4", classes.customerDropdown)}
                  value={state.selectedLocation}
                  options={getLocationList}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) =>
                    state.isFetchingList.locations ? (
                      <Skeleton variant="rect" width="100%" height="42px" />
                    ) : (
                      <TextField
                        {...params}
                        label="Location"
                        variant="outlined"
                      />
                    )
                  }
                  getOptionSelected={(option, value) =>
                    option.label === value.label
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      selectedLocation: value,
                      filters: {
                        ...prevState.filters,
                        selectedLocation: [
                          {
                            field: "location",
                            type: "=",
                            value: value?.value,
                          },
                        ],
                      },
                    }));
                    setInitialFetch();
                    setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                      selectedLocationFilter: {
                        value,
                        selectedLocation: [
                          {
                            field: "location",
                            type: "=",
                            value: value?.value,
                          },
                        ],
                      },
                    });
                  }}
                />
                {state.tabIndex === 1 && (
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    value={state.route}
                    options={getRouteList}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          {option?.name || option || ""}
                          {!option?.is_active && (
                            <Tooltip
                              title="Inactive Route"
                              placement="top-start"
                            >
                              <ToggleOffIcon
                                style={{ color: "red", fontSize: 30 }}
                              />
                            </Tooltip>
                          )}
                        </span>
                      </React.Fragment>
                    )}
                    getOptionLabel={(option) => option?.name || option || ""}
                    renderInput={(params) =>
                      state.isFetchingList.customers ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Route"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        route: value,
                        filters: {
                          ...prevState.filters,
                          route: [value?.id],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        routeFilter: {
                          value,
                          route: [value?.id],
                        },
                      });
                    }}
                  />
                )}
                <TextField
                  type="text"
                  variant="outlined"
                  className="mt-4"
                  size="small"
                  defaultValue={state.search}
                  placeholder={`search by: ${
                    state.tabIndex === 1 ? "Invoice" : "Credit"
                  } No.`}
                  InputProps={{ endAdornment: <SearchIcon /> }}
                  onChange={(evt) => {
                    handleSearch((evt.target.value || "").trim());
                    setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                      search: evt.target.value,
                    });
                  }}
                />
              </div>
            </div>
          )}
          {(state.tabIndex === 1
            ? !state.viewInvoiceSummary
            : !creditState.viewCreditMemoSummary) && (
            <ButtonGroup
              size="small"
              aria-label="medium button group"
              className={classes.buttonGroup}
            >
              <Button
                size="medium"
                className={`${
                  state.tabIndex === 1 ? classes.selectedButton : ""
                } ${classes.button}`}
                onClick={() => handleTabChange(1)}
              >
                INVOICES
              </Button>
              <Button
                size="medium"
                className={`${
                  state.tabIndex === 2 ? classes.selectedButton : ""
                } ${classes.button}`}
                onClick={() => handleTabChange(2)}
              >
                CREDIT MEMOS
              </Button>
            </ButtonGroup>
          )}
          {state.tabIndex === 1 && !state.viewInvoiceSummary && (
            <Paper elevation={2} className={clsx("p-4 ", classes.paperSpacing)}>
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                <div>
                  {!!state.selectedRows.length && (
                    <>
                      <Button
                        startIcon={<DeleteForeverOutlinedIcon color="error" />}
                        classes={{
                          root: "border-error ml-4",
                          label: "color-error",
                        }}
                        variant="outlined"
                        onClick={() => {
                          if (
                            state.entries.some(
                              (ele) =>
                                state.selectedRows.includes(ele.id) &&
                                ele.status !== "draft"
                            )
                          ) {
                            return toast.error(
                              "Deletion is not allowed for invoices unless they are in draft status."
                            );
                          }
                          setState((prevState) => ({
                            ...prevState,
                            deletingEntryIds: state.selectedRows.join(","),
                          }));
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        color="primary"
                        startIcon={<SendIcon color="primary" />}
                        className="ml-2"
                        variant="outlined"
                        onClick={() => {
                          if (
                            state.entries.some(
                              (ele) =>
                                state.selectedRows.includes(ele.id) &&
                                (ele.status === "draft" ||
                                  ele.status === "pending-approval")
                            )
                          ) {
                            return toast.error(
                              "Invoice(s) can only be sent to customers whose status is approved, paid, or partially paid."
                            );
                          }
                          setState((prevState) => ({
                            ...prevState,
                            showSendInvoicePopup: true,
                          }));
                        }}
                      >
                        Send Invoice
                      </Button>
                      <Button
                        color="primary"
                        className="ml-2"
                        variant="outlined"
                        onClick={() => {
                          if (
                            state.entries.some(
                              (ele) =>
                                state.selectedRows.includes(ele.id) &&
                                (ele.status === "draft" ||
                                  ele.status === "pending-approval")
                            )
                          ) {
                            return toast.error(
                              "Invoice(s) can only be marked as sent for customers whose status is approved, paid, or partially paid."
                            );
                          }
                          handleMarkInvoiceSentDialog();
                        }}
                      >
                        Mark as Sent
                      </Button>
                    </>
                  )}
                </div>

                <div className="d-flex f-align-center">
                  <div className="mr-4">
                    <Button
                      endIcon={<SendIcon />}
                      variant="contained"
                      color="primary"
                      disabled={
                        state.isExportingForQB || !state.selectedRows?.length
                      }
                      onClick={() => handleExportForQB(state.selectedRows)}
                    >
                      Export
                      {state.isExportingForQB && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                  </div>
                  <div className="mr-4">
                    <Button
                      startIcon={<GetAppIcon />}
                      variant="contained"
                      color="primary"
                      disabled={
                        state.isInvoiceDetailsLoading ||
                        !state.selectedRows?.length
                      }
                      onClick={() =>
                        handleMultiplePdf(
                          state.selectedRows,
                          "isInvoiceDetailsLoading",
                          false
                        )
                      }
                    >
                      Download
                      {state.isInvoiceDetailsLoading && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                  </div>
                  <FormControlLabel
                    label="Is Overdue"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.isOverdue}
                        disabled={!!state.isLatePayment}
                        onChange={(evt) => {
                          const overdue = evt.target.checked;
                          setState((prevState) => ({
                            ...prevState,
                            isOverdue: overdue,
                            filters: {
                              ...prevState.filters,
                              isOverdue: overdue
                                ? [
                                    {
                                      field: "is_overdue",
                                      type: "=",
                                      value: overdue,
                                    },
                                  ]
                                : [],
                            },
                          }));
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Late Payment Received"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.isLatePayment}
                        disabled={!!state.isOverdue}
                        onChange={(evt) => {
                          const latePayment = evt.target.checked;
                          setState((prevState) => ({
                            ...prevState,
                            isLatePayment: latePayment,
                            filters: {
                              ...prevState.filters,
                              isLatePayment: latePayment
                                ? [
                                    {
                                      field: "isLatePayment",
                                      type: "=",
                                      value: latePayment,
                                    },
                                  ]
                                : [],
                            },
                          }));
                        }}
                      />
                    }
                  />
                  <Dropdown
                    classes={{
                      paper: classes.dropdownPaper,
                      wrapper:
                        !!state.filters.invoiceStatus.length &&
                        classes.dropdownBackground,
                    }}
                    disablePortal={false}
                    isMultiSelect
                    remainOpen
                    placement="bottom-end"
                    options={statusKeys.map((key) => state.dynamicStatus[key])}
                    labelEllipses
                    hasEllipses
                    label={"Status :"}
                    onChange={(selectedOptions) => {
                      const isSelectAll = selectedOptions.some(
                        (item) => item.value === "all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicStatus?.all?.isSelected;
                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;
                      const isAllOptionSelected =
                        statusKeys.length - 1 ===
                        selectedOptions.filter((item) => item.value !== "all")
                          .length;

                      const updatedDynamicStatus = statusKeys.reduce(
                        (acc, key) => {
                          const isSelected = isClickedOnSelectAll
                            ? isSelectAll
                            : key === "all"
                            ? isAllOptionSelected
                            : selectedOptions.some(
                                (item) =>
                                  item.label === state.dynamicStatus[key].label
                              );

                          return {
                            ...acc,
                            [key]: {
                              ...state.dynamicStatus[key],
                              isSelected,
                            },
                          };
                        },
                        {}
                      );

                      const tempFilters = [];

                      if (!updatedDynamicStatus["all"].isSelected) {
                        statusKeys.forEach((key) => {
                          if (key !== "all") {
                            if (updatedDynamicStatus[key].isSelected) {
                              tempFilters.push({
                                field: "status",
                                type: "=",
                                value: key.replace(/_/g, "-"),
                              });
                            }
                          }
                        });
                      }

                      setState((prevState) => ({
                        ...prevState,
                        dynamicStatus: {
                          ...prevState.dynamicStatus,
                          ...updatedDynamicStatus,
                        },
                        filters: {
                          ...prevState.filters,
                          invoiceStatus: tempFilters,
                        },
                        pageNumber: defaultState.pageNumber,
                      }));
                    }}
                  />
                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(state.dynamicColumns).map(
                      (key) => state.dynamicColumns[key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      const isSelectAll = options.some(
                        (item) => item.value === "select_all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicColumns?.select_all?.isSelected;

                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;

                      const isAllOptionSelected =
                        Object.keys(state.dynamicColumns).length - 1 ===
                        options.filter((item) => item.value !== "select_all")
                          .length;
                      const updatedDynamicColumns = Object.keys(
                        state.dynamicColumns
                      ).reduce((acc, key) => {
                        const isSelected = isClickedOnSelectAll
                          ? isSelectAll
                          : key === "select_all"
                          ? isAllOptionSelected
                          : !!options.some((item) => item.value === key);

                        return {
                          ...acc,
                          [key]: {
                            ...state.dynamicColumns[key],
                            isSelected,
                          },
                        };
                      }, {});
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        dynamicColumns: updatedDynamicColumns,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        dynamicColumns: {
                          ...prevState.dynamicColumns,
                          ...updatedDynamicColumns,
                        },
                      }));
                    }}
                  />
                  <Tooltip placement="top-start" title="Filter">
                    <IconButton
                      color="primary"
                      className={clsx("ml-2", {
                        "bg-primary": !!state.pageFilters?.length,
                      })}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isFiltering: true,
                        }));
                      }}
                    >
                      <FilterListIcon
                        className={clsx({
                          "color-white": !!state.pageFilters?.length,
                        })}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top-end" title="Reset All Filters">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        updatePageConfig("view-invoice");
                        window.location.reload();
                      }}
                    >
                      <RotateLeftIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Grid
                columns={columnConfig}
                rows={state.entries.map((entry, rowIndex) => {
                  const createdAt = parseISO(entry.created_at);
                  const now = new Date();
                  const isRecent = differenceInSeconds(now, createdAt) <= 20;

                  return {
                    ...entry,
                    className: clsx({
                      [classes.overdue]: !!entry.is_overdue,
                      [classes.latePayemnt]: !!(
                        getUTCDateString(entry?.payment_completed_date) >
                        getUTCDateString(entry?.payment_due_date)
                      ),
                      [classes.paid]: entry.status === "paid",
                      [classes.pulseRow]: state.isPulsingEffect && isRecent,
                    }),
                  };
                })}
                actionBarConfig={null}
                hasSelectAll={false}
                isLoading={state.isLoading || state.isPollingStarted}
                totalRows={state.totalEntries}
                onReady={(instance) => (gridHelper = instance)}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
                classes={{
                  container: classes.addressGridridPaper,
                }}
              />
            </Paper>
          )}

          {state.tabIndex === 2 && (
            <ViewCreditMemo
              defaultState={creditDefaultState}
              state={creditState}
              setState={creditSetState}
              filters={state.filters}
              dateFilterType={state.dateFilterType}
              setInitialFetch={setInitialFetch}
              appDataList={appDataList}
              history={history}
              creditSearch={state.search}
              getSearchArray={getCreditSearchArray}
            />
          )}
        </>

        {state.viewInvoiceSummary && (
          <InvoiceSummary
            rows={state.entries}
            selectedInvoice={state.selectedInvoice}
            customerList={appDataList.customers}
            usersList={appDataList.usersList}
            isLoading={state.isLoading}
            totalEntries={state.totalEntries}
            pageSize={state.pageSize}
            isInvoiceLoading={state.isInvoiceLoading}
            pageNumber={state.pageNumber}
            isMarkAsSentLoading={state.isMarkAsSentLoading}
            onPageNumberChange={handlePageNumberChange}
            getInvoice={getInvoice}
            handleMarkInvoiceSent={handleMarkInvoiceSent}
            onPageSizeChange={handlePageSizeChange}
            handleClose={handleClose}
            handleTitleClick={handleTitleClick}
            handleInvoiceFilter={handleInvoiceFilter}
            handleItemClick={handleItemClick}
            handleApprovalDialog={handleApprovalDialog}
            pdfUrl={state.pdfUrl}
            entry={state.entry}
            history={history}
            handleFetch={handleFetchEmail}
            invoiceLoading={state.invoiceLoading}
            handleMultiplePdf={handleMultiplePdf}
            isPdfPreviewLoading={state.isPdfPreviewLoading}
            isPdfDownloadLoading={state.isPdfDownloadLoading}
            invoiceFilterStatus={state.filters.invoiceStatus}
            fetchInvoice={fetchInvoice}
            emailLoading={state.emailLoading}
          />
        )}
        {state.isApprovedDialog && (
          <ActionDialog
            classes={{
              confirm: `${
                state.approvalType === "reject" ? "bg-danger" : "bg-primary"
              }`,
              paper: classes.paperSize,
            }}
            open={state.isApprovedDialog}
            contentText={`Are you sure you want to ${state.approvalType} ?`}
            onConfirm={() =>
              handleApproval(state.invoiceId, state.approvalType)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                isApprovedDialog: false,
              }))
            }
            isConfirmDisabled={state.isApprovalLoading}
            positiveActionLabel={
              <>
                {state.approvalType}
                {state.isApprovalLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
      </div>
      {state.deletingEntryIds && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingEntryIds}
          contentText="Are you sure you want to delete?"
          onConfirm={() => handleDelete(state.deletingEntryIds)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingEntryIds: null,
            }))
          }
          isConfirmDisabled={state.isInvoiceDeleteLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isInvoiceDeleteLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isInvoiceSent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={state.isInvoiceSent}
          contentText={`Are you sure you want to mark this invoice as sent?`}
          onConfirm={() => {
            handleMarkInvoiceSent(
              state.selectedRows.length && state.selectedRows,
              false
            );
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isInvoiceSent: false,
            }))
          }
          isConfirmDisabled={state.isMarkAsSentLoading}
          positiveActionLabel={
            <>
              SEND
              {state.isMarkAsSentLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.showSendInvoicePopup && (
        <ActionDialog
          classes={{
            confirm:
              state.isInvoiceSending || isConfirmDisabled
                ? "black"
                : "bg-primary",
            paper: classes.invoiceSentPaperSize,
          }}
          open={!!state.showSendInvoicePopup}
          contentText={
            <div style={{ overflow: "hidden" }}>
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                {`Send ${
                  state.selectedRows.length === 1
                    ? `Invoice #${
                        state.entries.filter((obj) =>
                          state.selectedRows.includes(obj.id)
                        )[0]?.serial_no
                      }`
                    : "Invoices"
                } `}
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value={"noreply@pdxdelivers.com"}
                  className="mt-3"
                  helperText=" "
                />
              </div>
              {!state.selectedRows.length || state.selectedRows.length === 1 ? (
                <>
                  <div className="d-flex">
                    <Typography
                      variant="h6"
                      className="text-bold mr-8 mt-4"
                      style={{ width: "8rem" }}
                    >
                      To
                    </Typography>

                    <div
                      className="d-flex"
                      style={{ gap: "6px", width: "100%" }}
                    >
                      <TextField
                        label="Add Email ID(s)"
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="email"
                        value={state.email}
                        disabled={state.isInvoiceSending}
                        required
                        className="mt-3"
                        onChange={handleFieldChange}
                        error={!!state.errors.email?.trim()}
                        helperText={state.errors.email}
                      />

                      <div>
                        <Tooltip
                          title="Add Email Temporarily"
                          placement="top-end"
                        >
                          <Button
                            type="submit"
                            onClick={() => {
                              if (
                                validate() === " " &&
                                state.errors?.email === " "
                              ) {
                                handleAddEmail("temporaryEmail");
                              }
                            }}
                            variant="contained"
                            color="primary"
                            disabled={
                              isConfirmDisabled ||
                              !state.email ||
                              state.isInvoiceSending
                            }
                            className={classes.buttonStyle1}
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip
                          title="Add Email To Customer"
                          placement="top-end"
                        >
                          <Button
                            type="submit"
                            onClick={handleAddPermEmailDialog}
                            variant="contained"
                            color="primary"
                            disabled={
                              isConfirmDisabled ||
                              !state.email ||
                              state.isInvoiceSending
                            }
                            className={classes.buttonStyle1}
                          >
                            <PostAddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  {(state.temporaryEmail.length > 0 ||
                    state.permanentEmail.length > 0) && (
                    <div className="d-flex mb-4">
                      <div
                        variant="h6"
                        className="mr-8 mt-4"
                        style={{ width: "8rem" }}
                      ></div>
                      <div
                        className="d-flex f-justify-between flex-wrap"
                        style={{ width: "100%", gap: 20 }}
                      >
                        {state.permanentEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Customer Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.emailLoading ? (
                                      <ListItem>
                                        <Skeleton
                                          className="mr-2"
                                          variant="circle"
                                          width={15}
                                          height={15}
                                        />
                                        <ListItemText
                                          primary={
                                            <Skeleton
                                              variant="rect"
                                              width="50%"
                                              height="20px"
                                            />
                                          }
                                        />
                                      </ListItem>
                                    ) : (
                                      state.permanentEmail.map((item) => (
                                        <ListItem key={item}>
                                          <div className="mr-2">
                                            <FiberManualRecordIcon
                                              style={{ fontSize: 10 }}
                                            />
                                          </div>
                                          <ListItemText primary={item} />
                                          {!state.isInvoiceSending && (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                onClick={() =>
                                                  handleDeleteEmail(
                                                    item,
                                                    "permanentEmail"
                                                  )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          )}
                                        </ListItem>
                                      ))
                                    )}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                        {state.temporaryEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Temporary Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.temporaryEmail.map((item) => (
                                      <ListItem key={item}>
                                        <ListItemText primary={item} />
                                        {!state.isInvoiceSending && (
                                          <ListItemSecondaryAction>
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteEmail(
                                                  item,
                                                  "temporaryEmail"
                                                )
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <CancelIcon fontSize="small" />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        )}
                                      </ListItem>
                                    ))}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-4"
                    style={{ width: "8rem" }}
                  >
                    To
                  </Typography>
                  <TextField
                    label="Add Email ID(s)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={state.email}
                    required
                    className="mt-3"
                    onChange={handleFieldChange}
                    error={!!state.errors.email?.trim()}
                    helperText={state.errors.email}
                    disabled={state.isInvoiceSending}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <div className={classes.chipsContainer}>
                          {state.temporaryEmail.map((email) => (
                            <Chip
                              key={email}
                              disabled={state.isInvoiceSending}
                              label={email}
                              onDelete={() =>
                                handleDeleteEmail(email, "temporaryEmail")
                              }
                              className={classes.emailChip}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  label="Add Subject"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  disabled={state.isInvoiceSending}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailSubject?.trim()}
                  helperText={state.errors.emailSubject}
                />
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  minRows={10}
                  maxRows={10}
                  multiline
                  disabled={state.isInvoiceSending}
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailBody?.trim()}
                  helperText={state.errors.emailBody}
                />
              </div>
              <UploadFiles
                uploadedAttachment={uploadedAttachment}
                isLoading={state.isInvoiceSending}
              />
            </div>
          }
          onConfirm={() =>
            handleSubmit(
              state.selectedRows,
              state.temporaryEmail,
              state.permanentEmail,
              state.emailSubject,
              state.emailBody,
              state.uploadedAttachment
            )
          }
          onCancel={() => {
            isConfirmDisabled = false;
            const selectedCompanies =
              state.selectedRows.length > 0
                ? [
                    ...new Set(
                      state.entries
                        .filter((obj) => state.selectedRows.includes(obj.id))
                        .map((invoice) => invoice.pdx_company)
                    ),
                  ].join(", ")
                : null;

            const displayCompanyName = () => {
              if (
                selectedCompanies &&
                selectedCompanies.split(",").length > 1
              ) {
                return "Parts Distribution Xpress";
              } else {
                return selectedCompanies;
              }
            };
            setState((prevState) => ({
              ...prevState,
              showSendInvoicePopup: false,
              email: defaultState.email,
              temporaryEmail: defaultState.temporaryEmail,
              permanentEmail: defaultState.permanentEmail,
              emailSubject: `${
                state.selectedRows.length === 1
                  ? `Invoice #${
                      state.entries.filter((obj) =>
                        state.selectedRows.includes(obj.id)
                      )[0]?.serial_no
                    }`
                  : "Invoices"
              } from ${selectedCompanies || "PDX"}`,
              emailBody: `Dear ${
                state.selectedRows.length === 1
                  ? state.entries.filter((obj) =>
                      state.selectedRows.includes(obj.id)
                    )[0]?.customer_branch?.customer?.name
                  : "Customers"
              },\n\nYour ${
                state.selectedRows.length > 1 ? "invoices are" : "invoice is"
              } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\n${displayCompanyName()}`,
              errors: {
                email: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          isConfirmDisabled={
            state.isInvoiceSending ||
            isConfirmDisabled ||
            !!state.email ||
            (state.temporaryEmail.length === 0 &&
              state.permanentEmail.length === 0) ||
            (validate() && validate() !== " ") ||
            (state.selectedRows.length &&
              state.selectedRows.length !== 1 &&
              state.temporaryEmail.length === 0)
          }
          positiveActionLabel={
            <>
              SEND INVOICE
              {state.isInvoiceSending && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.showAddPermanentEmailDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={!!state.showAddPermanentEmailDialog}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                {`Would you like to add this email address `}
                <span style={{ color: "Black" }}>{state.email}</span>
                {` to customer emails?`}
              </Typography>
            </>
          }
          onConfirm={() =>
            addPermananentEmaiil(
              state.entries.filter((obj) =>
                state.selectedRows.includes(obj.id)
              )[0]?.customer_branch,
              state.permanentEmail
            )
          }
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showAddPermanentEmailDialog: false,
                permanentEmail: emailAddresses,
              };
            });
          }}
          isConfirmDisabled={state.isPermEmailLoading}
          positiveActionLabel={
            <>
              Add To Customer Email
              {state.isPermEmailLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>CANCEL</>}
        />
      )}
      {state.showCreditMemo && (
        <CreditMemo
          open={state.showCreditMemo}
          onClose={handleCloseCreditMemo}
          viewOnlyCreditMemo={state.showCreditMemo}
          creditDetails={state.creditMemoDetails}
          heading="View"
        />
      )}
    </>
  );
};

export default ViewInvoice;
