import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  unreadBackground: {
    background: "#e5ebe5",
  },
  readBackground: {
    background: "#fafafa",
  },
  summary: {
    flexDirection: "row-reverse",
    "& .MuiExpansionPanelSummary-expandIcon": {
      marginRight: 0,
    },
  },
  badge: {
    paddingTop: "2px",
  },
  readIndicator: {
    width: 8,
    height: 8,
    borderRadius: "50%",
  },
  relativeTimeWrapper: {
    minWidth: 64,
  },
  boxWidth: {
    maxWidth: "500px",
    minWidth: "404px",
  },
  expansionPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "30px",
  },
  Grid: { maxHeight: "35rem", overflowY: "auto" },
}));

export default useStyles;
