import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/review-billing-settlement/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const approve = (payload) => {
  return responseFormatter(
    http.put(`/approve-billing-settlement`, payload, {
      setAuthHeader: true,
    })
  );
};

const reject = (payload) => {
  return responseFormatter(
    http.put(`/reject-billing-settlement`, payload, {
      setAuthHeader: true,
    })
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/reviewBillingSettlement/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const edit = (payload) => {
  return responseFormatter(
    http.put(`/review-billing-settlement`, payload, {
      setAuthHeader: true,
    })
  );
};

const patch = (payload) => {
  return responseFormatter(
    http.patch(`/review-billing-settlement`, payload, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const addNotesInLookup = (payload) => {
  return responseFormatter(
    http.post(`/lookup`, payload, {
      setAuthHeader: true,
    })
  );
};

const priceMatrix = (query) => {
  return responseFormatter(
    http.get(`/price-matrix/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getCustomerList = (query) => {
  return responseFormatter(
    http.get(`/review-billing-settlement/customer-list${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const BillingSettlementReviewService = {
  get,
  approve,
  priceMatrix,
  reject,
  edit,
  getCustomerLocation,
  patch,
  getLocalResources,
  addNotesInLookup,
  download,
  getCustomerList,
};

export default BillingSettlementReviewService;
