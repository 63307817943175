import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom/";
import Link from "@material-ui/core/Link";
import ViewInvoice from "../../../assets/icons/viewInvoice.svg";
import ApproveInvoice from "../../../assets/icons/approveInvoice.svg";
import paymentHistory from "../../../assets/icons/paymentHistory.svg";
import GenerateInvoice from "../../../assets/icons/generateInvoice.svg";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import GenerateInvoiceDialog from "../generateInvoice";
import { ROLE } from "modules/shared/constants";
import { getTokenData } from "utils";
import CreditMemo from "../credit-memo/credit-memo-form";
import ReceiptIcon from "@material-ui/icons/Receipt";

const defaultState = {
  open: false,
  isNew: false,
  selectedTab: "generateView",
};

const InvoiceHomePage = ({ history }) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const currentUser = getTokenData() || {};
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;

  const handleOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleOpenCreditMemo = () => {
    setState((prevState) => ({
      ...prevState,
      isNew: true,
    }));
  };

  const handleClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      open: close,
    }));
  };

  return (
    <div>
      <div>
        <Typography variant="h4" color="primary" className=" ml-2">
          Invoices & Memos
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          Select to view, edit and generate Invoices, Payments & Credit Memo
        </Typography>
      </div>
      {state.selectedTab === "generateView" && (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="center"
          className={classes.wrapper}
        >
          <Grid item sm={3} md={4} className={classes.griddddd}>
            <div
              className="d-flex f-align-center flex-column"
              style={{ paddingTop: "98px" }}
            >
              <Button
                variant="outlined"
                size="large"
                className={classes.iconButton}
                onClick={handleOpen}
              >
                <img
                  src={GenerateInvoice}
                  alt="generateInvoice"
                  className={classes.icon}
                />
              </Button>
              <Typography
                variant={"body1"}
                className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
              >
                Generate Invoice
              </Typography>
            </div>
          </Grid>
          <Grid item sm={3} md={4} className={classes.griddddd}>
            <div
              className="d-flex f-align-center flex-column"
              style={{ paddingTop: "98px" }}
            >
              <Link to="/invoice/view_invoice" component={NavLink}>
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.iconButton}
                  onClick={() => history.push("/invoice/view_invoice")}
                >
                  <img
                    src={ViewInvoice}
                    alt="viewInvoice"
                    className={classes.icon}
                  />
                </Button>
              </Link>
              <Typography
                variant={"body1"}
                className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
              >
                View Invoices & Memos
              </Typography>
            </div>
          </Grid>
          <Grid item sm={3} md={4} className={classes.griddddd}>
            <div
              className="d-flex f-align-center flex-column"
              style={{ paddingTop: "98px" }}
            >
              <Link to="/invoice/record_payment" component={NavLink}>
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.iconButton}
                  onClick={() => history.push("/invoice/record_payment")}
                >
                  <img
                    src={paymentHistory}
                    alt="paymentHistory"
                    className={clsx("ml-2", classes.icon)}
                  />
                </Button>
              </Link>
              <Typography
                variant={"body1"}
                className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
              >
                Payments
              </Typography>
            </div>
          </Grid>
          {!isRoleAA && (
            <Grid item sm={3} md={4} className={classes.griddddd}>
              <div
                className="d-flex f-align-center flex-column"
                style={{ paddingTop: "98px" }}
              >
                <Link to="/invoice/approve_reject" component={NavLink}>
                  <Button
                    variant="outlined"
                    size="large"
                    className={classes.iconButton}
                    onClick={() => history.push("/invoice/approve_reject")}
                  >
                    <img
                      src={ApproveInvoice}
                      alt="Approve/Reject"
                      className={clsx("mr-2", classes.icon)}
                    />
                  </Button>
                </Link>
                <Typography
                  variant={"body1"}
                  className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
                >
                  Approve/Reject
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item sm={3} md={4} className={classes.griddddd}>
            <div
              className="d-flex f-align-center flex-column"
              style={{ paddingTop: "98px" }}
            >
              <Button
                variant="outlined"
                size="large"
                className={classes.iconButton}
                onClick={handleOpenCreditMemo}
              >
                <CreditCardIcon className={classes.creditIcon} />
              </Button>
              <Typography
                variant={"body1"}
                className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
              >
                Create Credit Memo
              </Typography>
            </div>
          </Grid>
          {/* <Grid item sm={3} md={4} className={classes.griddddd}>
            <div
              className="d-flex f-align-center flex-column"
              style={{ paddingTop: "98px" }}
            >
              <Link to="/invoice/view_credit_memo" component={NavLink}>
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.iconButton}
                  onClick={() => history.push("/invoice/view_credit_memo")}
                >
                  <ReceiptIcon className={classes.creditIcon} />
                </Button>
              </Link>
              <Typography
                variant={"body1"}
                className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
              >
                View Credit Memos
              </Typography>
            </div>
          </Grid> */}
        </Grid>
      )}
      <GenerateInvoiceDialog
        open={state.open}
        handleClose={handleClose}
        history={history}
      />
      {state.isNew && (
        <CreditMemo
          open={state.isNew}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isNew: false,
            }));
          }}
        />
      )}
    </div>
  );
};

export default InvoiceHomePage;
