import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  holdPaddingLeft: {
    paddingLeft: "21px !important",
  },
  icidFlagTitle: {
    paddingLeft: "48px !important",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  total: {
    backgroundColor: "#D3D3D3",
  },
  tunePaper: {
    minWidth: 232,
  },
  permExcludedRecords: {
    background: "#ececec",
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
}));

export default useStyles;
