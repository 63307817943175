/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { toast } from "react-toastify";
import { APX_COMPANY, noop, VALIDATIONS } from "shared/constants";
import { Dialog, ActionDialog } from "shared/components";
import { RATE_TYPES, ROLE, VEHICLE_TYPES } from "modules/shared/constants";
import Service from "../service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";

import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "./style";
import { preventInputKeyCodes, validator } from "utils";
import { Checkbox, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const NUMBER_TYPE_FIELDS = [
  "bill_rate",
  "bill_quantity",
  "pay_rate",
  "pay_quantity",
  "pay_toll_amount",
];

let shouldShowAlert = {
  pay_rate: false,
  pay_quantity: false,
};
let isFieldChange = false;
let isRouteChanged = false;
let isRosterTypeChanged = false;
let isTollAmountChanged = false;
let isPayQuantityChanged = false;
let isPayRateChanged = false;
let isPayRateTypeChanged = false;
let isVehicleChanged = false;
const defaultState = {
  customer: null,
  individual_contractor: null,
  location: null,
  state: null,
  stanbyRateTypeArray: [],
  route: null,
  vehicle_type: null,
  isFetchingDriverList: false,
  representativeDriverList: [],
  representativeDriver: null,
  pay_rate_type: RATE_TYPES[0],
  pay_rate: "0",
  pay_quantity: "1",
  pay_toll_amount: "0",
  notes: null,
  viewAllIC: false,
  defaultICList: [],
  isLoading: false,
  kamUser: null,
  is_weekday: true,
  openReviewNotificationDialog: false,
  rosterValue: "weekdays",
  errors: {
    customer: " ",
    kamUser: " ",
    location: " ",
    notes: " ",
    route: " ",
    pay_rate: " ",
    pay_quantity: " ",
    pay_toll_amount: " ",
    vehicle_type: " ",
    pay_rate_type: " ",
  },
};

const Form = ({
  selectedKam = [],
  vehicleTypeList = [],
  priceMatrix = [],
  kamList = [],
  open = false,
  isPriceMatrixFetched = false,
  entry = null,
  selectedKAMId = null,
  isNew = false,
  isRoleKAM = false,
  contractorList = [],
  customerList = [],
  currentUser = {},
  kam = {},
  formAlertFields = {},
  onClose = noop,
  showFieldAlert = noop,
  handleUpdateEntries = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const isKAMUser = (currentUser?.role || "").toLowerCase() === ROLE.KAM;

  const fetchDriverList = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingDriverList: true,
    }));
    let queryString = `?filter[where][and][0][or][0][ic_id]=${id}&filter[where][is_deleted]=false`;

    const { data, error } = await Service.getDriverList(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingDriverList: defaultState.isFetchingDriverList,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      representativeDriverList:
        data.rows || defaultState.representativeDriverList,
      isFetchingDriverList: defaultState.isFetchingDriverList,
    }));
  };

  useEffect(() => {
    if (entry && !isNew) {
      const contractorDetails =
        contractorList.find(
          (item) => item.id === entry.individual_contractor?.id
        ) || {};

      const defaultICList = contractorList.filter(
        (item) => item.default_kam_id === entry.kamUser?.id
      );

      const { contractorTypeValue, stand_by_rate_type, stand_by_rate } =
        contractorDetails;
      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        customer: entry.customer_branch?.customer || defaultState.customer,
        individual_contractor: entry?.individual_contractor
          ? {
              ...entry.individual_contractor,
              contractorTypeValue,
              stand_by_rate_type,
              stand_by_rate,
            }
          : defaultState.individual_contractor,
        location: entry.customer_branch || defaultState.location,
        route: entry?.routeDetails
          ? {
              name: entry?.routeDetails?.name,
              id: entry?.routeDetails?.id,
              is_weekday: entry?.routeDetails?.is_weekday,
              price_type: entry?.routeDetails?.price_type,
              matrix_name: entry?.routeDetails?.matrix_name,
              default_pay_quantity: entry.routeDetails?.default_pay_quantity,
              default_pay_rate: entry.routeDetails?.default_pay_rate,
              default_pay_rate_type: entry.routeDetails?.default_pay_rate_type,
              pay_toll_amount: entry.routeDetails?.pay_toll_amount,
              vehicle_type: entry?.routeDetails?.vehicleType,
            }
          : defaultState.route,
        rosterValue:
          (entry.is_weekday === true ? "weekdays" : "weekend") ||
          defaultState.rosterValue,
        vehicle_type: entry.vehicleType || defaultState.vehicle_type,
        pay_rate_type: entry.pay_rate_type || defaultState.pay_rate_type,
        pay_rate: entry.pay_rate || defaultState.pay_rate,
        pay_quantity: entry.pay_quantity || defaultState.pay_quantity,
        pay_toll_amount: entry.pay_toll_amount || defaultState.pay_toll_amount,
        notes: entry.notes || defaultState.notes,
        state: entry.state || defaultState.state,
        kamUser: entry.kamUser || defaultState.kam,
        is_weekday: entry.is_weekday ?? defaultState.is_weekday,
        representativeDriver:
          entry.driverContractor || defaultState.representativeDriver,
        viewAllIC:
          !defaultICList.some(
            (item) => item?.id === entry.individual_contractor?.id
          ) || defaultState.viewAllIC,
      }));
    }
  }, [entry]);

  useEffect(() => {
    if (!!Object.keys(formAlertFields).length) {
      setState((prevState) => ({
        ...prevState,
        ...formAlertFields,
        errors: {
          ...prevState.errors,
          ...formAlertFields.errors,
        },
      }));
    }
  }, [formAlertFields]);

  useEffect(() => {
    if (
      state?.individual_contractor?.contractorTypeValue ===
      "WCP Master Contractor"
    ) {
      fetchDriverList(state.individual_contractor?.id);
    }
  }, [state.individual_contractor]);

  const getFieldValidatorMap = (state, field) => {
    const { pay_rate_type } = state;
    const fieldValidatorMap = {
      customer: [{ type: VALIDATIONS.REQUIRED, value: true }],
      // individual_contractor: [{ type: VALIDATIONS.REQUIRED, value: true }],
      location: [{ type: VALIDATIONS.REQUIRED, value: true }],
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      // dont_bill_notes: [
      //   { type: VALIDATIONS.MIN_LENGTH, value: 3 },
      //   { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      // ],
      route: [{ type: VALIDATIONS.MAX_LENGTH, value: 30 }],

      pay_rate:
        (
          state.individual_contractor?.contractorTypeValue || ""
        ).toLowerCase() === "stand-by"
          ? [
              { type: VALIDATIONS.MIN, value: 0 },
              { type: VALIDATIONS.LIMIT, value: 10.2 },
            ]
          : [
              { type: VALIDATIONS.MIN, value: 0 },
              { type: VALIDATIONS.LIMIT, value: 10.2 },
            ],
      pay_quantity: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      pay_toll_amount: [
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.LIMIT, value: 10.2 },
      ],
      vehicle_type: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],

      pay_rate_type:
        (
          state.individual_contractor?.contractorTypeValue || ""
        ).toLowerCase() === "stand-by"
          ? [{ type: VALIDATIONS.REQUIRED, value: true }]
          : [],
    };

    if (pay_rate_type === "Hourly") {
      fieldValidatorMap["pay_quantity"] = [
        {
          type: VALIDATIONS.MAX,
          value:
            state.location && state.location?.pdxCompany?.value === APX_COMPANY
              ? 10000
              : 24,
        },
        ...fieldValidatorMap["pay_quantity"],
      ];
    }

    if (pay_rate_type === "Daily") {
      fieldValidatorMap["pay_quantity"] = [
        { type: VALIDATIONS.DAILY_QUANTITY_LIMIT, value: 1.99 },
        ...(fieldValidatorMap["pay_quantity"] || []),
      ];
    }

    // if (bill_rate_type === "Hourly") {
    //   fieldValidatorMap["bill_quantity"] = [
    //     {
    //       type: VALIDATIONS.MAX,
    //       value: 24,
    //     },
    //     ...fieldValidatorMap["bill_quantity"],
    //   ];
    // }

    if (!isKAMUser) {
      fieldValidatorMap["kamUser"] = [
        {
          type: VALIDATIONS.REQUIRED,
          value: !isNew ? false : selectedKam?.length !== 1 ? true : false,
        },
      ];
    }

    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  useEffect(() => {
    const isAPXCompany =
      state.location && state.location?.pdxCompany?.value === APX_COMPANY;
    const maxQuantity = isAPXCompany ? 10000 : 24;
    if (state.pay_rate_type === "Daily" && +state.pay_quantity > 1.99) {
      const errorMessage = validate("pay_quantity", +state.pay_quantity);
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            pay_quantity: errorMessage,
          },
        }));
      }
    } else if (
      state.pay_rate_type === "Hourly" &&
      +state.pay_quantity > maxQuantity
    ) {
      const errorMessage = validate("pay_quantity", +state.pay_quantity);
      if (errorMessage) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            pay_quantity: errorMessage,
          },
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          pay_quantity: " ",
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pay_rate_type, state.pay_quantity]);

  useEffect(() => {
    let filteredList =
      !!selectedKAMId || !!state.kamUser || selectedKam.length === 1
        ? contractorList
            .filter((item) => item.default_kam_id !== null)
            .filter(
              (item) =>
                item.default_kam_id === selectedKAMId ||
                item.default_kam_id === state.kamUser?.id ||
                (selectedKam.length === 1 &&
                  item.default_kam_id === selectedKam[0].id)
            )
        : [];

    if (state.viewAllIC) {
      const restOfList = contractorList.filter(
        (item) =>
          item.default_kam_id !== selectedKAMId ||
          item.default_kam_id !== state.kamUser?.id ||
          (selectedKam.length === 1 &&
            item.default_kam_id !== selectedKam[0].id)
      );
      filteredList = filteredList.concat(restOfList);
    }
    setState((prevState) => ({
      ...prevState,
      defaultICList: filteredList,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewAllIC, selectedKAMId, state.kamUser, selectedKam]);

  const handleSubmit = async () => {
    if (
      !isRoleKAM &&
      state.route?.price_type === "Matrix" &&
      !settlementOptions?.includes(+state?.pay_rate)
    ) {
      return toast.error(
        "Settlement value doesn't exist in price matrix for this customer."
      );
    }
    if (
      !state.openReviewNotificationDialog &&
      (!isNew
        ? !!isRouteChanged
          ? (state.route !== null &&
              state.vehicle_type?.value != entry.vehicleType?.value &&
              state.route?.vehicleType?.value != state.vehicle_type?.value) ||
            (state.pay_rate_type != entry.pay_rate_type &&
              state.route?.default_pay_rate_type != state.pay_rate_type) ||
            (+state.pay_rate != +entry.pay_rate &&
              state.route?.default_pay_rate != state.pay_rate) ||
            (+state.pay_quantity != +entry.pay_quantity &&
              state.route?.default_pay_quantity != state.pay_quantity) ||
            (+state.pay_toll_amount != +entry.pay_toll_amount &&
              state.route?.pay_toll_amount != state.pay_toll_amount)
          : (isRosterTypeChanged &&
              state.is_weekday != entry.routeDetails?.is_weekday) ||
            (isPayQuantityChanged &&
              state.pay_quantity != entry.routeDetails?.default_pay_quantity) ||
            (isTollAmountChanged &&
              state.pay_toll_amount != entry.routeDetails?.pay_toll_amount) ||
            (isPayRateChanged &&
              state.pay_rate != entry.routeDetails?.default_pay_rate) ||
            (isPayRateTypeChanged &&
              state.pay_rate_type !=
                entry.routeDetails?.default_pay_rate_type) ||
            (isVehicleChanged &&
              state.vehicle_type?.value !=
                entry.routeDetails?.vehicleType?.value)
        : state.route !== null &&
          (state.route?.default_pay_rate_type != state.pay_rate_type ||
            state.route?.is_weekday != state.is_weekday ||
            state.route?.default_pay_quantity != state.pay_quantity ||
            state.route?.default_pay_rate != state.pay_rate ||
            state.route?.pay_toll_amount != state.pay_toll_amount ||
            state.route?.vehicleType?.value != state.vehicle_type?.value))
    ) {
      return setState((prevState) => ({
        ...prevState,
        openReviewNotificationDialog: true,
      }));
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const {
      individual_contractor,
      customer,
      kamUser,
      location,
      route,
      isLoading,
      errors,
      vehicle_type,
      defaultICList,
      representativeDriver,
      ...payload
    } = state;

    Object.keys(payload).map((key) => {
      if (NUMBER_TYPE_FIELDS.includes(key)) {
        payload[key] = Number.isInteger(+payload[key])
          ? +payload[key]
          : (+payload[key]).toFixed(2);
      }
    });

    const serviceMethod = isNew ? "post" : "patch";

    const { data, error } = await Service[serviceMethod](
      {
        ...payload,
        route_id: route?.id || null,
        vehicle_type: vehicle_type?.id || null,
        customer_branch_id: location?.id,
        individual_contractor_id: individual_contractor?.id || null,
        driver_id: representativeDriver?.id || null,
        kam_id: isKAMUser
          ? currentUser.id
          : !isNew
          ? kamUser.id
          : selectedKam.length === 1
          ? selectedKam[0].id
          : kamUser.id,
        notes: payload.notes?.trim(),
      },
      entry?.id
    );

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(`Entry ${isNew ? "created" : "updated"} successfully.`);
    if (!isNew) {
      handleUpdateEntries(data.updatedBRRecords);
    }
    setState((prevState) => ({ ...prevState, isLoading: false }));
    handleClose(true);
  };

  const handleClose = (isSubmitted = false) => {
    isFieldChange = false;
    setState(defaultState);
    onClose(isSubmitted, state.customer);
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    shouldShowAlert[field] = true;
    let errorMessage = validate(field, value) || " ";
    if (field === "pay_toll_amount") {
      isTollAmountChanged = true;
    }
    if (field === "pay_rate") {
      isPayRateChanged = true;
    }
    if (field === "pay_quantity") {
      isPayQuantityChanged = true;
    }
    if (
      (type === "number" && value.indexOf(".") !== -1) ||
      field === "pay_toll_amount"
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
        // value = value + "";
      }
    }

    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const billPayPrice = priceMatrix?.filter((item) => {
    return (
      item.customer_branch_id === state?.location?.id &&
      item.name === state.route?.matrix_name
    );
  });

  const settlementOptions = billPayPrice?.map((item) => item.pay_price);

  const handlePayRateChange = (event, value) => {
    isPayRateChanged = true;
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      pay_rate: value,
    }));
  };

  const handleFieldBlur = (evt) => {
    if (!isNew) {
      const field = evt.currentTarget?.name || evt.target?.name;
      const value = evt.currentTarget?.value || evt.target?.value;
      showFieldAlert(
        field,
        {
          ...state,
          [field]: value,
          pdxCompany: state.location?.pdxCompany?.value || "",
        },
        state.errors
      );
    }
  };

  const branchList = useMemo(
    () =>
      (
        customerList.find((customer) => customer.id === state.customer?.id)
          ?.customer_branches || []
      )
        .filter((branch) => branch.is_active && !branch.is_deleted)
        .sort((a, b) => {
          const valueA = (a.location || "").toLowerCase();
          const valueB = (b.location || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }),
    [state.customer]
  );
  const routeList = useMemo(() => {
    return (
      branchList
        .find((location) => location.id === state.location?.id)
        ?.routes?.filter((route) => route?.is_active) || []
    );
  }, [state.location]);

  const selectedRoute =
    customerList
      .filter((customer) => customer.id === state.customer?.id)[0]
      ?.customer_branches.map((item) => item.routes)
      .flat()
      .filter((route) => route.id === state.route?.id) || [];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Dialog.Title hasClose>
          {`${isNew ? "New" : "Update"} Entry`}
        </Dialog.Title>
        <Dialog.Content>
          <Box className="d-flex f-align-center mb-4">
            <Typography variant="body2" className="mr-2 text-bold">
              Roster Type:{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                name="roster_type"
                value={state.rosterValue}
                onChange={(evt) => {
                  if (!isNew) {
                    isRosterTypeChanged = true;
                  }
                  isFieldChange = true;
                  const value = evt.target.value;

                  setState((prevState) => ({
                    ...prevState,
                    is_weekday: value === "weekdays" ? true : false,
                    rosterValue: value,
                  }));
                }}
              >
                <FormControlLabel
                  value="weekdays"
                  control={<Radio size="small" color="primary" />}
                  label="Weekdays"
                />
                <FormControlLabel
                  value="weekend"
                  control={<Radio size="small" color="primary" />}
                  label="Weekend"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {((!isKAMUser && !isNew) ||
            (!isKAMUser && isNew && selectedKam.length === 1)) && (
            <>
              <TextField
                fullWidth
                disabled
                label="KAM"
                variant="outlined"
                className="mb-1"
                size="small"
                helperText=" "
                value={
                  !isNew
                    ? `${
                        (state.kamUser?.first_name || "") +
                        " " +
                        (state.kamUser?.last_name || "") +
                        " - " +
                        (state.kamUser?.email || "") +
                        " -(" +
                        (state.kamUser?.username || "") +
                        ")"
                      }`
                    : `${
                        (selectedKam[0].first_name || "") +
                        " " +
                        (selectedKam[0].last_name || "") +
                        " - " +
                        (selectedKam[0].email || "") +
                        " -(" +
                        (selectedKam[0].username || "") +
                        ")"
                      }`
                }
              />
            </>
          )}

          {!isKAMUser && isNew && selectedKam.length !== 1 && (
            <Autocomplete
              disableClearable
              //className="mr-2 w-50 mb-1"
              classes={{
                root: classes.autocompletePaper,
                listbox: classes.autocompletePaper,
              }}
              size="small"
              fullWidth
              value={state.kam}
              options={kamList}
              getOptionLabel={(option) =>
                `${option.first_name || ""} ${option.last_name || ""} - ${
                  option.email || ""
                }- (${option.username || ""})`
              }
              renderOption={({
                first_name,
                last_name,
                email,
                is_active,
                username,
              }) => (
                <Typography
                  variant="body1"
                  className={clsx({
                    "color-text-disabled": !is_active,
                  })}
                >{`${first_name || " "} ${
                  last_name || ""
                }-${email}-(${username})`}</Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="KAM"
                  required
                  variant="outlined"
                  error={!!state.errors.kamUser.trim()}
                  helperText={state.errors.kamUser}
                />
              )}
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  kamUser: value,
                }));
              }}
            />
          )}
          <div className="d-flex f-align-center">
            <Autocomplete
              disableClearable
              className="mr-2 w-50 mb-1"
              classes={{
                root: classes.autocompletePaper,
                listbox: classes.autocompletePaper,
              }}
              size="small"
              value={state.customer}
              options={customerList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Customer"
                  variant="outlined"
                  error={!!state.errors.customer.trim()}
                  helperText={state.errors.customer}
                />
              )}
              onChange={(evt, value) => {
                const isStandBy =
                  (
                    state?.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by";
                setState((prevState) => ({
                  ...prevState,
                  customer: value,
                  location: null,
                  state: null,
                  route: null,
                  vehicle_type: defaultState.vehicle_type,
                  bill_rate_type: defaultState.bill_rate_type,
                  bill_quantity: defaultState.bill_quantity,
                  bill_rate: defaultState.bill_rate,
                  // pay_rate_type: !isStandBy
                  //   ? defaultState.pay_rate_type
                  //   : prevState.pay_rate_type,
                  pay_quantity: defaultState.pay_quantity,
                  // pay_rate: !isStandBy
                  //   ? defaultState.pay_rate
                  //   : prevState.pay_rate,
                }));
              }}
            />
            {/* <Tooltip title="Do not bill, if this is checked" placement="top">
              <Checkbox
                className="mb-6"
                checked={state.confirmDontBillValue}
                onChange={(evt) => handleConfirmDontBill(evt.target.checked)}
                name="Bill"
              />
            </Tooltip> */}
            <Autocomplete
              disableClearable
              className="w-50 ml-2 mb-1"
              size="small"
              value={state.location}
              options={branchList}
              getOptionLabel={(option) =>
                `${option.location} (${option.state?.name || ""} ,${
                  option?.pdxCompany?.value || ""
                })`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Location"
                  variant="outlined"
                  error={!!state.errors.location.trim()}
                  helperText={state.errors.location}
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  location: value,
                  state: value.location?.state,
                  route: null,
                  vehicle_type: defaultState.vehicle_type,
                  bill_rate_type: defaultState.bill_rate_type,
                  bill_quantity: defaultState.bill_quantity,
                  bill_rate: defaultState.bill_rate,
                  // pay_rate_type: defaultState.pay_rate_type,
                  pay_quantity: defaultState.pay_quantity,
                  // pay_rate: defaultState.pay_rate,
                }));
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className="mr-2 w-50 mb-1"
              name="state"
              disabled={true}
              label="State"
              variant="outlined"
              size="small"
              value={state.location?.state?.name || ""}
              helperText=" "
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className="ml-2 w-50 mb-1"
              name="pdxCompany"
              disabled={true}
              label="PDX co"
              variant="outlined"
              size="small"
              value={state.location?.pdxCompany?.value || ""}
              helperText=" "
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <Autocomplete
              className="mr-2 w-50 mb-1"
              size="small"
              value={state.route}
              // getOptionLabel={(option) => option.name || ""}
              getOptionLabel={(option) =>
                `${option.name} (${
                  option.is_weekday === true ? "Weekday" : "Weekend"
                }, ${
                  option.price_type === null || option.price_type === "Standard"
                    ? "Standard"
                    : "Price Matrix"
                } )`
              }
              options={routeList}
              renderInput={(params) => (
                <TextField {...params} label="Route" variant="outlined" />
              )}
              onChange={(evt, value) => {
                if (!isNew) {
                  isRouteChanged = true;
                }

                const isStandBy =
                  (
                    state.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by";

                isFieldChange = true;

                setState((prevState) => ({
                  ...prevState,
                  route: value,
                  vehicle_type: value?.vehicleType || defaultState.vehicle_type,
                  bill_rate_type:
                    value?.default_bill_rate_type ||
                    defaultState.bill_rate_type,
                  bill_quantity:
                    value?.default_bill_quantity || defaultState.bill_quantity,
                  bill_rate: value?.default_bill_rate || defaultState.bill_rate,
                  pay_rate_type:
                    isStandBy &&
                    prevState?.route?.price_type === "Matrix" &&
                    (value?.price_type === null ||
                      value?.price_type === "Standard")
                      ? state.individual_contractor?.stand_by_rate_type
                      : value?.price_type === "Matrix"
                      ? RATE_TYPES[0]
                      : isStandBy
                      ? prevState.pay_rate_type
                      : value?.default_pay_rate_type ||
                        defaultState.pay_rate_type,
                  pay_rate:
                    isStandBy &&
                    prevState?.route?.price_type === "Matrix" &&
                    (value?.price_type === null ||
                      value?.price_type === "Standard")
                      ? state.individual_contractor?.stand_by_rate
                      : value?.price_type === "Matrix"
                      ? value?.default_pay_rate || ""
                      : isStandBy
                      ? prevState.pay_rate
                      : value?.default_pay_rate || defaultState.pay_rate,
                  pay_quantity:
                    value?.price_type === "Matrix"
                      ? "1"
                      : isStandBy && value === null
                      ? prevState.pay_rate_type === "Hourly"
                        ? "8"
                        : prevState.pay_rate_type === "Daily"
                        ? "1"
                        : ""
                      : value?.default_pay_quantity ||
                        defaultState.pay_quantity,
                  pay_toll_amount:
                    value?.pay_toll_amount || defaultState.pay_toll_amount,
                }));
              }}
            />
            <Autocomplete
              className="ml-2 mb-1 w-50"
              classes={{
                root: classes.autocompletePaper,
                listbox: classes.autocompletePaper,
              }}
              size="small"
              disabled={isRoleKAM}
              value={state.vehicle_type}
              options={vehicleTypeList}
              getOptionLabel={(option) => option.value || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle"
                  variant="outlined"
                  name="vehicle_type"
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                isVehicleChanged = true;
                setState((prevState) => ({
                  ...prevState,
                  vehicle_type: value,
                  errors: {
                    ...prevState.errors,
                    vehicle_type: " ",
                  },
                }));
              }}
            />
          </div>
          <FormControlLabel
            className=" mb-2 ml-1"
            labelPlacement="start"
            label="View all IC:"
            control={
              <Checkbox
                color="primary"
                checked={state.viewAllIC}
                onChange={(evt) => {
                  setState((prevState) => ({
                    ...prevState,
                    viewAllIC: evt.target.checked,
                    individual_contractor: defaultState.individual_contractor,
                  }));
                }}
              />
            }
          />
          <div className="d-flex f-align-center">
            <Autocomplete
              size="small"
              className="mr-2 mb-1 w-50"
              classes={{
                root: classes.autocompletePaper,
                listbox: classes.autocompletePaper,
              }}
              value={{
                label: `${`${state.individual_contractor?.last_name || ""} ${
                  state.individual_contractor?.first_name || ""
                }${
                  state.individual_contractor?.contractorTypeValue ===
                  "WCP Master Contractor"
                    ? ` - (${state.individual_contractor?.contractorTypeValue})`
                    : ""
                }`}`.trim(),
              }}
              options={state.defaultICList.map((item) => ({
                ...item,
                label: `${item.icid || ""} - ${item.last_name || ""} ${
                  item.first_name || ""
                }${
                  vehicleTypeList.some(
                    (ele) => ele.id === item.default_vehicle_type
                  )
                    ? `-(${
                        vehicleTypeList.find(
                          (ele) => ele.id === item.default_vehicle_type
                        ).value
                      })`
                    : ""
                } ${
                  item.contractorTypeValue === "WCP Master Contractor"
                    ? `- (${item?.contractorTypeValue})`
                    : ""
                }`,
              }))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    state?.individual_contractor &&
                    state?.individual_contractor?.contractorTypeValue ===
                      "WCP Master Contractor"
                      ? "WCP Master Contractor"
                      : "Contractor"
                  }
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, value) => {
                isFieldChange = true;
                setState((prevState) => {
                  const isStandBy =
                    (value?.contractorTypeValue || "").toLowerCase() ===
                    "stand-by";

                  return {
                    ...prevState,
                    individual_contractor: value,
                    representativeDriver: null,
                    pay_rate:
                      state.route?.price_type === "Matrix"
                        ? state.route?.default_pay_rate || entry?.pay_rate || ""
                        : isStandBy
                        ? value?.StandByRates?.find((rate) => rate.is_default)
                            ?.rate_value
                        : !!state.route
                        ? selectedRoute[0]?.default_pay_rate || entry.pay_rate
                        : prevState.pay_rate,
                    pay_quantity: isStandBy
                      ? "1"
                      : state.route?.price_type === "Matrix"
                      ? "1"
                      : value === null && !!selectedRoute?.length
                      ? selectedRoute[0]?.default_pay_quantity
                      : prevState.pay_quantity,
                    pay_rate_type:
                      state.route?.price_type === "Matrix"
                        ? RATE_TYPES[0]
                        : isStandBy
                        ? value?.StandByRates.find((rate) => rate.is_default)
                            ?.rateType?.value
                        : !!state.route
                        ? selectedRoute[0]?.default_pay_rate_type ||
                          entry?.pay_rate_type
                        : RATE_TYPES[0],
                    stanbyRateTypeArray: isStandBy
                      ? value?.StandByRates
                      : RATE_TYPES,
                  };
                });
              }}
            />

            <TextField
              disabled={true}
              label="ICID"
              variant="outlined"
              size="small"
              className="ml-2 w-50 mb-1"
              value={state.individual_contractor?.icid || ""}
              helperText=" "
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {state?.individual_contractor?.contractorTypeValue ===
            "WCP Master Contractor" && (
            <div className="d-flex f-align-center mb-6">
              <Autocomplete
                size="small"
                className="w-100 mb-1"
                fullWidth
                value={state.representativeDriver}
                options={state.representativeDriverList}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name || ""} (#${
                    option.id
                  })`
                }
                renderInput={(params) =>
                  state.isFetchingDriverList ? (
                    <Skeleton variant="rect" width="100%" height="41px" />
                  ) : (
                    <TextField
                      {...params}
                      label="WCP-Driver"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <InputAdornment
                              position="end"
                              className={classes.weekInputProps}
                            >
                              <Tooltip
                                title="This is the list of representative drivers for WCP."
                                placement="top"
                              >
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  className={classes.infoIcon}
                                />
                              </Tooltip>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  )
                }
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    representativeDriver: value,
                  }));
                }}
              />
            </div>
          )}
          <div className="d-flex f-align-center">
            <Autocomplete
              disabled={state.route?.price_type === "Matrix" || isRoleKAM}
              disableClearable
              className="w-50 mr-2 mb-1"
              size="small"
              value={state.pay_rate_type}
              options={
                state.stanbyRateTypeArray?.length > 0
                  ? state.stanbyRateTypeArray
                      .map((type) => type?.rateType?.value || type)
                      .sort((a, b) => a.localeCompare(b))
                  : entry?.individual_contractor?.StandByRates?.length > 0
                  ? entry.individual_contractor.StandByRates.map(
                      (type) => type.rateType.value
                    ).sort((a, b) => a.localeCompare(b))
                  : RATE_TYPES
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Pay Rate Type"
                  variant="outlined"
                  error={!!state.errors.pay_rate_type.trim()}
                  helperText={state.errors.pay_rate_type}
                />
              )}
              onChange={(evt, value) => {
                isPayRateTypeChanged = true;

                const selectedRate =
                  (state.stanbyRateTypeArray?.length > 0
                    ? state.stanbyRateTypeArray
                    : entry?.individual_contractor?.StandByRates
                  )?.find((rate) => rate?.rateType?.value === value) || null;

                if (state.pay_rate_type === value) {
                  return false;
                } else {
                  isFieldChange = true;
                  !isNew &&
                    showFieldAlert("pay_rate_type", {
                      ...state,
                      pay_quantity: !!selectedRate
                        ? "1"
                        : value === "Hourly"
                        ? "8"
                        : value === "Daily"
                        ? "1"
                        : "",
                      pay_rate_type: selectedRate?.rateType?.value || value,
                      pay_rate: selectedRate?.rate_value || state.pay_rate,
                      pdxCompany: state.location?.pdxCompany?.value || "",
                    });
                  setState((prevState) => ({
                    ...prevState,
                    pay_rate_type: value,
                    pay_rate: selectedRate?.rate_value || state.pay_rate,
                    pay_quantity: !!selectedRate
                      ? "1"
                      : value === "Hourly"
                      ? "8"
                      : value === "Daily"
                      ? "1"
                      : "",
                    errors: {
                      ...prevState.errors,
                      pay_quantity: " ",
                    },
                  }));
                }
              }}
            />
            <TextField
              className="ml-2 w-50 mb-1"
              type="number"
              name="pay_quantity"
              label="Pay Quantity"
              variant="outlined"
              size="small"
              disabled={state.route?.price_type === "Matrix" || isRoleKAM}
              value={state.pay_quantity}
              error={!!state.errors.pay_quantity.trim()}
              helperText={state.errors.pay_quantity}
              onWheel={(event) => event.target.blur()}
              onChange={handleFieldChange}
              onBlur={(evt) => {
                const { name } = evt.target;
                if (shouldShowAlert[name]) {
                  shouldShowAlert[name] = false;
                  handleFieldBlur(evt);
                }
              }}
              onKeyDown={preventInputKeyCodes}
              InputProps={{
                endAdornment: state.pay_rate_type === "Hourly" &&
                  state.location &&
                  state.location?.pdxCompany?.value === APX_COMPANY && (
                    <InputAdornment position="end">
                      <Tooltip title="Max Limit 10000" placement="top">
                        <InfoOutlinedIcon
                          style={{ cursor: "pointer" }}
                          color="disabled"
                          fontSize="small"
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            {state.route?.price_type === "Matrix" && (
              <TextField
                className="w-50 mr-2 mb-1"
                disabled={true}
                label="Price Matrix Name"
                variant="outlined"
                size="small"
                value={state.route?.matrix_name || ""}
                helperText=" "
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            {state.route?.price_type === undefined ||
            state.route?.price_type === null ||
            state.route?.price_type === "Standard" ? (
              <TextField
                disabled={
                  isRoleKAM ||
                  (
                    state.individual_contractor?.contractorTypeValue || ""
                  ).toLowerCase() === "stand-by"
                }
                className="w-50 mr-1 mb-1"
                fullWidth
                type="number"
                name="pay_rate"
                label="Pay Rate"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                size="small"
                value={state.pay_rate}
                error={!!state.errors.pay_rate.trim()}
                helperText={state.errors.pay_rate}
                onWheel={(event) => event.target.blur()}
                onChange={handleFieldChange}
                onBlur={(evt) => {
                  const { name } = evt.target;
                  if (shouldShowAlert[name]) {
                    shouldShowAlert[name] = false;
                    handleFieldBlur(evt);
                  }
                }}
                onKeyDown={preventInputKeyCodes}
              />
            ) : (
              <Autocomplete
                disableClearable
                size="small"
                className="ml-2 mb-1 w-50"
                value={state.pay_rate || ""}
                options={settlementOptions}
                disabled={isRoleKAM}
                getOptionLabel={(option) => {
                  const matchingBillPayPrice = billPayPrice?.find(
                    (billOption) => billOption?.pay_price == option
                  );
                  if (matchingBillPayPrice) {
                    return `$ ${option} (${matchingBillPayPrice.quantity})`;
                  } else {
                    return `$ ${option}`;
                  }
                }}
                renderInput={(params) =>
                  isPriceMatrixFetched ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      required
                      label="Pay Rate"
                      variant="outlined"
                      helperText={" "}
                    />
                  )
                }
                getOptionSelected={(option, value) => `${option}` === value}
                onChange={handlePayRateChange}
              />
            )}
          </div>
          <TextField
            className="mb-1"
            fullWidth
            type="number"
            name="pay_toll_amount"
            label="Pay Toll Amount"
            variant="outlined"
            disabled={isRoleKAM}
            size="small"
            InputProps={{
              startAdornment: <InputAdornment>$</InputAdornment>,
            }}
            value={state.pay_toll_amount}
            error={!!state.errors.pay_toll_amount.trim()}
            helperText={state.errors.pay_toll_amount}
            onWheel={(event) => event.target.blur()}
            onChange={handleFieldChange}
            onKeyDown={preventInputKeyCodes}
          />
          <TextField
            className="mb-1"
            fullWidth
            multiline
            name="notes"
            rows={4}
            label="KAM Notes"
            variant="outlined"
            size="small"
            value={state.notes}
            error={!!state.errors.notes.trim()}
            helperText={state.errors.notes}
            onChange={handleFieldChange}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button
              variant="outlined"
              onClick={handleClose}
              className="ml-2 mr-2"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={
                state.isLoading ||
                (state.route?.price_type === "Matrix" && !state.pay_rate) ||
                !isFieldChange ||
                validate()
              }
              onClick={handleSubmit}
            >
              {`${isNew ? "Create" : "Update"}`}
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
      {/* {state.confirmDontBill && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.confirmDontBill}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                By Checking, bill rate will become 0. This action will not be
                reverted once done.
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="dont_bill_notes"
                label={`Add Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.dont_bill_notes?.trim()}
                helperText={state.errors.dont_bill_notes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              confirmDontBill: false,
              confirmDontBillValue: false,
              dont_bill_notes: "",
              errors: defaultState.errors,
            }));
          }}
          isConfirmDisabled={
            state.isLoading ||
            !!state.errors.dont_bill_notes?.trim() ||
            !state.dont_bill_notes?.trim()
          }
          positiveActionLabel={
            <>
              OK
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )} */}
      {state.openReviewNotificationDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.openReviewNotificationDialog}
          positiveActionLabel="Ok"
          negativeActionLabel={null}
          content={"Base roster has been updated please contact Admin/AA."}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              openReviewNotificationDialog: false,
            }));
          }}
          onConfirm={() => {
            handleSubmit();
            setState((prevState) => ({
              ...prevState,
              openReviewNotificationDialog: false,
            }));
          }}
        />
      )}
    </>
  );
};

export default Form;
