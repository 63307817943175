import { createRef, useCallback, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { noop } from "shared/constants";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";

const defaultState = {
  selectedRows: [],
};

const BillGrid = ({
  columns = [],
  boxborder = null,
  tableHeadRef = null,
  rows = [],
  hasSelection = true,
  classes: passedClasses = {},
  selectedRef = null,
  rowEvents = [],
  onReady = noop,
  onSelectionChange = noop,
  customer = null,
  index = null,
  gridRefs = null,
  handleScroll = noop,
  onSortChange = noop,
  order = null,
  orderBy = null,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  let rafId = null;
  let resizeData = null;

  const columnRefs = useMemo(() => columns.map(() => createRef()), [columns]);

  const [columnWidths, setColumnWidths] = useState(() =>
    columns.map((col) => {
      const className = col.headerClassName;
      if (className) {
        const element = document.createElement("div");
        element.className = className;
        document.body.appendChild(element);
        const width = window.getComputedStyle(element).width;
        document.body.removeChild(element);
        return { minWidth: width, maxWidth: width };
      }
      return { minWidth: "auto", maxWidth: "auto" };
    })
  );

  const handleSelectVisibleChange = (event) => {
    const isChecked = event.currentTarget.checked;
    let selectedRows = [];
    if (isChecked) {
      selectedRows = rows
        .filter((row) => !row.preventSelection)
        .map((row) => row.id);
    }

    onSelectionChange(selectedRows);
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  };

  const createSelectionHandler = (rowId) => {
    return (event) => {
      handleSelection(rowId, event);
    };
  };

  const handleSelection = (rowId) => {
    setState((prevState) => {
      const existingSelectionIndex = prevState.selectedRows.indexOf(rowId);
      let selectedRows = prevState.selectedRows.slice();
      if (existingSelectionIndex === -1) {
        selectedRows.push(rowId, rowId);
      } else {
        selectedRows = selectedRows?.filter((id) => id !== rowId);
      }

      onSelectionChange(selectedRows);

      return {
        ...prevState,
        selectedRows,
      };
    });
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    onSortChange(property, isAsc ? "desc" : "asc", customer.customer_name);
  };

  const descendingComparator = (a, b, orderBy) => {
    const splittedOrderBy = (orderBy || "").split(".");
    [...Array(splittedOrderBy.length)].map((item, index) => {
      a = a && a[splittedOrderBy[index]];
      b = b && b[splittedOrderBy[index]];
    });

    if (a === null && b !== null) return 1;
    if (a !== null && b === null) return -1;
    if (a === null && b === null) return 0;

    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (rows, comparator) => {
    const stablized = rows.map((el, index) => [el, index]);
    stablized.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stablized.map((el) => el[0]);
  };

  onReady({
    resetSelection: () =>
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
      })),
    setSelection: (selectedRows) =>
      setState((prevState) => ({
        ...prevState,
        selectedRows,
      })),
    getSelection: () => state.selectedRows,
    toggle: (open = false) =>
      setState((prevState) => ({
        ...prevState,
        open,
      })),
  });

  const handleMouseMove = (event) => {
    if (!resizeData) return;

    const { initialCursorX, initialWidth, columnIndex, element } = resizeData;
    const diffX = event.pageX - initialCursorX;
    const newWidth = initialWidth + diffX;

    const isTargetColumn = element.textContent === "Review Notes ";
    const updatedWidth = isTargetColumn
      ? Math.max(newWidth, 367)
      : Math.max(newWidth, 100);

    if (rafId) {
      cancelAnimationFrame(rafId);
    }

    rafId = requestAnimationFrame(() => {
      element.style.minWidth = `${updatedWidth}px`;
      element.style.maxWidth = `${updatedWidth}px`;

      setColumnWidths((prevWidths) =>
        prevWidths.map((width, index) =>
          index === columnIndex
            ? {
                minWidth: `${updatedWidth}px`,
                maxWidth: `${updatedWidth}px`,
              }
            : width
        )
      );
    });
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    resizeData = null;
  };

  const initializeResize = (columnIndex, element) => {
    return (event) => {
      const initialCursorX = event.pageX;
      const initialWidth = element.offsetWidth;

      resizeData = {
        initialCursorX,
        initialWidth,
        columnIndex,
        element,
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };
  };

  const setGridRef = (key, node) => {
    if (node) {
      gridRefs.current[key] = node;
    } else {
      delete gridRefs.current[key];
    }
  };

  const slectableRows = rows?.filter((row) => !row.preventSelection);

  return (
    <TableContainer
      ref={(node) => setGridRef(`${customer.id}-${index}`, node)}
      onScroll={handleScroll}
      style={{
        display: "grid",
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&-ms-overflow-style:": {
          display: "none",
        },
        overflowY: "hidden",
      }}
    >
      <Table className={classes.borderCollapse}>
        <TableHead ref={tableHeadRef}>
          <TableRow className={clsx(classes.row, passedClasses.header)}>
            {hasSelection && (
              <TableCell padding="checkbox" className="no-border">
                <Checkbox
                  disabled={!slectableRows.length}
                  indeterminate={
                    state.selectedRows.length > 0 &&
                    state.selectedRows.length < slectableRows.length
                  }
                  checked={
                    slectableRows.length > 0 &&
                    state.selectedRows.length === slectableRows.length
                  }
                  onChange={handleSelectVisibleChange}
                  inputProps={{ "aria-label": "select visible" }}
                />
              </TableCell>
            )}
            {columns.map((column, columnIndex) => {
              if (column.isHidden) {
                return null;
              }
              columnRefs[columnIndex] = createRef();
              let content = <span></span>;
              if (column.renderHeader) {
                content = column.renderHeader();
              } else {
                content = column.canSort ? (
                  <TableSortLabel
                    key={column.id}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <span>{column.label}</span>
                );
              }

              return (
                <TableCell
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  padding={column.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === column.id ? order : false}
                  className={clsx(
                    "no-border p-relative",
                    classes.tableCell,
                    classes.tableHeadCell,
                    classes.tableHeadPadding,
                    column.headerClassName
                  )}
                  style={{
                    minWidth: columnWidths[columnIndex].minWidth,
                    maxWidth: columnWidths[columnIndex].maxWidth,
                  }}
                  ref={columnRefs[columnIndex]}
                >
                  <Tooltip
                    title={column.tooltip || column.label}
                    placement={column.tooltipPlacement || "top-start"}
                  >
                    <div className="d-flex f-justify-between">
                      {content}
                      {!column.noResize && (
                        <div
                          className={clsx(
                            "d-flex f-justify-between f-align-center p-absolute",
                            classes.resizeIconWrapper
                          )}
                          data-role="resize"
                          onMouseDown={(event) => {
                            if (
                              columnRefs[columnIndex] &&
                              columnRefs[columnIndex].current
                            ) {
                              initializeResize(
                                columnIndex,
                                columnRefs[columnIndex].current
                              )(event);
                            }
                          }}
                        >
                          <div className={classes.resizeIcon} />
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map(
            (row, rowIndex) => {
              let content = <></>;
              const selectionHandler = createSelectionHandler(row.id);
              if (rows.render) {
                content = rows.render();
              } else {
                const labelId = `table-checkbox-${rowIndex}`;
                content = (
                  <>
                    {hasSelection && (
                      <TableCell padding="checkbox" className="no-border">
                        <Checkbox
                          disabled={row.preventSelection}
                          checked={state.selectedRows.indexOf(row.id) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                          onChange={selectionHandler}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, columnIndex) => {
                      if (column.isHidden) {
                        return null;
                      }
                      let content = <span>{row[column.field] || ""}</span>;
                      if (column.render) {
                        content = column.render(row, row.rowIndex);
                      } else {
                        content = (
                          <Typography
                            className="d-inline-block w-100"
                            variant="body2"
                            component="span"
                            noWrap={column.hasEllipses}
                          >
                            {`${
                              row[column.field] === null ||
                              row[column.field] === undefined
                                ? ""
                                : column.startAdornment ?? ""
                            }${row[column.field] || column.placeholder || "-"}${
                              row[column.field] === null ||
                              row[column.field] === undefined
                                ? ""
                                : column.endAdornment ?? ""
                            }`}
                          </Typography>
                        );
                      }

                      return (
                        <TableCell
                          className={clsx(
                            "no-border",
                            classes.tableCell,
                            classes.tableHeadPadding,
                            row.classes && row.classes[column.field]
                          )}
                          style={{
                            minWidth: columnWidths[columnIndex].minWidth,
                            maxWidth: columnWidths[columnIndex].maxWidth,
                          }}
                          key={`${
                            column.label || "grid-column"
                          }-${columnIndex}${row.id}`}
                        >
                          <Tooltip
                            title={row[column.field] || ""}
                            placement={
                              column.dataTooltipPlacement || "top-start"
                            }
                          >
                            {content}
                          </Tooltip>
                        </TableCell>
                      );
                    })}
                  </>
                );
              }
              return (
                <TableRow
                  hover
                  key={row.id}
                  ref={boxborder === row.id ? selectedRef : null}
                  className={clsx(
                    classes.row,
                    passedClasses.row,
                    row.className,
                    {
                      [classes.boxborder]:
                        boxborder === row.id &&
                        row.id !== null &&
                        boxborder !== null,
                    }
                  )}
                  {...rowEvents.reduce(
                    (acc, { type, handler }) => ({
                      ...acc,
                      [type]: () => handler(row, rowIndex),
                    }),
                    {}
                  )}
                >
                  {content}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillGrid;
