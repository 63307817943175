/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import {
  ActionDialog,
  AppContext,
  Datepicker,
  Dialog,
  Grid,
  noop,
} from "shared";
import { getDateString, getUTCDateString, updatePageConfig } from "utils";
import { toast } from "react-toastify";
import { SharedService } from "modules/shared";
import Service from "../service";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { INVOICE_STATUS } from "modules/shared/constants";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import GridLoader from "shared/components/grid/loader";
import MemorizeGrid from "./memorizeGrid";
import NoRecords from "assets/images/norecord.svg";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

let gridHelper = null;

const defaultState = {
  isLoading: false,
  wantsToContinue: false,
  multipleInvoices: false,
  isRoutePresent: false,
  isinvoiceIdsPresent: false,
  isRecordPresent: false,
  pdxCompany: null,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
  },
  startDate: getDateString(new Date()),
  endDate: getDateString(new Date()),
  invoiceDate: getDateString(new Date()),
  customer: null,
  pdxCompanyList: [],
  routeIds: [],
  invoiceIds: [],
  presentInvoiceIds: [],
  entries: [],
  isDataFetching: false,
  branchIds: [],
  route: [],
  multipleLocation: [],
  location: null,
  noRecordBranch: false,
  presentBranchIds: [],
  isMemoize: false,
  selectedRows: [],
  companyValues: "",
  customerValues: "",
  locationValues: "",
  routeValues: "",
  currentRowSelected: null,
  customerEntries: [],
  isCustomerListFetching: false,
  rowEntries: [],
  isTableLoading: [],
  selectedMemoizedRows: [],
  deletingMemorizedInvoiceId: null,
  isMemorizedInvoiceDeleting: false,
  hasRouteConflict: false,
};

const GenerateInvoiceDialog = ({
  history = {},
  open = false,
  handleClose = noop,
}) => {
  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const [state, setState] = useState(defaultState);

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
    };
  }, [appData]);

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      // eslint-disable-next-line array-callback-return
      listData.map((item = {}) => {
        // eslint-disable-next-line array-callback-return
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const customerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany]);

  const branchList = useMemo(() => {
    const customer = customerList.find(
      (customer) => customer.id === state.customer?.id
    );
    if (!customer) return [];

    const branchMap = new Map();

    customer.customer_branches.forEach((branch) => {
      if (
        branch.pdx_company_id === state.pdxCompany?.id &&
        branch.is_active &&
        !branch.is_deleted
      ) {
        const location = (branch.location || "").toLowerCase();
        if (!branchMap.has(location)) {
          branchMap.set(location, branch);
        }
      }
    });

    const uniqueBranches = Array.from(branchMap.values());

    uniqueBranches.sort((a, b) => {
      const valueA = (a.location || "").toLowerCase();
      const valueB = (b.location || "").toLowerCase();
      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
    });

    return uniqueBranches;
  }, [state.customer, state.pdxCompany]);

  const routeList = useMemo(() => {
    const routes =
      branchList.find((location) => location.id === state.location?.id)
        ?.routes || [];

    const activeRoutes = routes?.filter((route) => route.is_active);
    const inactiveRoutes = routes?.filter((route) => !route.is_active);

    const sortedActiveRoutes = activeRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );
    const sortedInactiveRoutes = inactiveRoutes.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    return [...sortedActiveRoutes, ...sortedInactiveRoutes];
  }, [state.location]);

  useEffect(() => {
    fetchPDXCompanyList();
    fetchList("customers", noop);
  }, [open]);

  const onClose = () => {
    handleClose(false);
    setTimeout(() => {
      setState(defaultState);
    }, 150);
  };

  const hasRouteConflict = (selectedIds) => {
    setState((prevState) => {
      const rows =
        prevState.rowEntries[
          prevState.currentRowSelected?.customer?.customer_name
        ];
      const selectedRows = rows?.filter((row) => selectedIds.includes(row.id));

      const branchGroups = new Map();
      for (const row of selectedRows ?? []) {
        const branchId = row.customer_branch_id;
        if (!branchGroups.has(branchId)) {
          branchGroups.set(branchId, []);
        }
        branchGroups.get(branchId).push(row);
      }

      let hasConflict = false;

      for (const [, branchRows] of branchGroups) {
        if (branchRows.length <= 1) continue;

        let hasNull = false;
        let hasArray = false;
        const routeCounts = new Map();

        for (const row of branchRows) {
          if (row.route_ids === null) {
            hasNull = true;
          } else if (Array.isArray(row.route_ids)) {
            hasArray = true;
            for (const routeId of row.route_ids) {
              routeCounts.set(routeId, (routeCounts.get(routeId) || 0) + 1);
            }
          }
        }

        const hasOverlap = Array.from(routeCounts.values()).some(
          (count) => count > 1
        );
        const hasMix = hasNull && hasArray;

        if (hasOverlap || hasMix) {
          hasConflict = true;
          break;
        }
      }

      return {
        ...prevState,
        hasRouteConflict: hasConflict,
      };
    });
  };

  const handleMemorizedSelectionChange = useCallback((selectedMemoizedRows) => {
    hasRouteConflict(selectedMemoizedRows);
    setState((prevState) => ({
      ...prevState,
      selectedMemoizedRows,
    }));
  }, []);

  const handleSubmit = async (ignoreDupInvoices) => {
    const {
      startDate,
      endDate,
      location,
      route,
      invoiceDate,
      isMemoize,
      selectedMemoizedRows,
    } = state;
    if (new Date(invoiceDate) < new Date(startDate)) {
      return toast.error("Invoice date cannot be less than Start date.");
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const payload = isMemoize
      ? {
          from_date: startDate,
          to_date: endDate,
          ignoreDupInvoice: ignoreDupInvoices,
          invoice_date: invoiceDate,
          memorizedInvoiceIds: selectedMemoizedRows,
        }
      : {
          from_date: startDate,
          to_date: endDate,
          customer_branch_id: location?.id,
          route_ids: route.map((ele) => ele?.id) || null,
          ignoreDupInvoice: ignoreDupInvoices,
          invoice_date: invoiceDate,
        };

    const serviceMethod = isMemoize ? "generateFromMemorizedInvoices" : "post";

    const { data, error } = await Service[serviceMethod](payload);

    if (data?.code === "PDX-IVC-001") {
      return setState((prevState) => ({
        ...prevState,
        isLoading: false,
        wantsToContinue: true,
      }));
    }
    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (error[0]?.code === "PDX-IVP-001") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isinvoiceIdsPresent: true,
          invoiceIds: error[0]?.invoiceIds,
        }));
      }
      if (error[0]?.code === "PDX-IVP-002") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isRecordPresent: true,
          presentInvoiceIds: error[0]?.invoiceIds,
        }));
      }

      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice generated successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onClose();
    history.push({
      pathname: "/invoice/draft_invoice",
      state: {
        invoiceId: isMemoize ? data.invoiceIds[0] : data.invoiceDetails?.id,
        customerList: appDataList.customers,
      },
    });
  };

  const handleGenerateMultipleInvoices = async (ignoreDupInvoices) => {
    const {
      startDate,
      endDate,
      customer,
      multipleLocation,
      pdxCompany,
      invoiceDate,
      isMemoize,
      selectedMemoizedRows,
    } = state;

    if (new Date(invoiceDate) < new Date(startDate)) {
      return toast.error("Invoice date cannot be less than Start date.");
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));

    const payload = isMemoize
      ? {
          from_date: startDate,
          to_date: endDate,
          invoice_date: invoiceDate,
          ignoreDupInvoice: ignoreDupInvoices,
          memorizedInvoiceIds: selectedMemoizedRows,
        }
      : {
          from_date: startDate,
          to_date: endDate,
          customer_id: multipleLocation.length > 0 ? null : customer?.id,
          pdx_company_id: pdxCompany?.id,
          branchIds:
            multipleLocation.length > 0
              ? multipleLocation.map((ele) => ele?.id)
              : null,
          ignoreDupInvoice: ignoreDupInvoices,
          invoice_date: invoiceDate,
        };

    const serviceMethod = isMemoize
      ? "generateFromMemorizedInvoices"
      : "generateMultipleInvoices";

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (error[0]?.code === "PDX-IVP-001") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isinvoiceIdsPresent: true,
          invoiceIds: error[0]?.invoiceIds,
        }));
      }
      if (error[0]?.code === "PDX-IVP-002") {
        fetchEntries();
        return setState((prevState) => ({
          ...prevState,
          isRecordPresent: true,
          presentInvoiceIds: error[0]?.invoiceIds,
        }));
      }
      if (error[0]?.code === "PDX-IVMC-002") {
        return setState((prevState) => ({
          ...prevState,
          noRecordBranch: true,
          presentBranchIds: error[0]?.branchIds,
        }));
      }
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice generated successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onClose();
    updatePageConfig("view-invoice");
    history.push({
      pathname: "/invoice/view_invoice",
      state: { pulsingEffect: true },
    });
  };

  const fetchEntries = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isDataFetching: true }));
    const { data, error } = await Service.getAllInvoice();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isDataFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isDataFetching: false,
      entries: data.rows || defaultState.entries,
    }));
    return data;
  }, []);

  const fetchCustomerEntries = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isCustomerListFetching: true }));
    const { data, error } = await Service.getMemorizedInvoicesCustomerList();
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        isCustomerListFetching: false,
      }));
    } else {
      const collator = new Intl.Collator(undefined, { sensitivity: "base" });
      const sortedCustomerList = data.customersList.sort((a, b) =>
        collator.compare(a.customer.customer_name, b.customer.customer_name)
      );

      setState((prevState) => ({
        ...prevState,
        customerEntries: sortedCustomerList || defaultState.customerEntries,
      }));
      if (!sortedCustomerList.length) {
        setState((prevState) => ({
          ...prevState,
          isCustomerListFetching: false,
        }));
      }
    }
  }, []);

  const fetchRowEntries = useCallback(async (customer) => {
    let queryString = `?filter[where][and][0][or][0][customer_name]=${customer}`;
    setState((prevState) => ({
      ...prevState,
      isTableLoading: {
        ...prevState.isTableLoading,
        [customer]: true,
      },
    }));
    const { data, error } = await Service.getMemorizedInvoices(queryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        isTableLoading: {
          ...prevState.isTableLoading,
          [customer]: false,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        rowEntries: {
          ...prevState.rowEntries,
          [customer]: data.rows,
        },
        isTableLoading: {
          ...prevState.isTableLoading,
          [customer]: false,
        },
        isCustomerListFetching: false,
      }));
    }
    gridHelper && gridHelper.resetSelection();
  }, []);

  const fetchMemorizedInvoices = useCallback(
    (customerName) => {
      if (!state.rowEntries[customerName]) {
        fetchRowEntries(customerName);
      }
    },
    [fetchRowEntries, state.rowEntries]
  );

  const handleMemorizedInvoiceDelete = async (id, customerName) => {
    setState((prevState) => ({
      ...prevState,
      isMemorizedInvoiceDeleting: true,
    }));
    const { error } = await Service.deleteMemorizedInvoice(id);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isMemorizedInvoiceDeleting: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => {
      const updatedRowEntries =
        prevState.rowEntries[customerName]?.filter(
          (entry) => entry.id !== id
        ) || [];

      const updatedCustomerRowEntries = { ...prevState.rowEntries };
      if (updatedRowEntries.length > 0) {
        updatedCustomerRowEntries[customerName] = updatedRowEntries;
      } else {
        delete updatedCustomerRowEntries[customerName];
      }

      const updatedCustomerList = prevState.customerEntries.filter(
        (customerObj) => customerObj.customer.customer_name !== customerName
      );

      return {
        ...prevState,
        isMemorizedInvoiceDeleting: false,
        deletingMemorizedInvoiceId: null,
        customerEntries:
          updatedRowEntries.length > 0
            ? prevState.customerEntries
            : updatedCustomerList,
        currentRowSelected:
          updatedRowEntries.length > 0
            ? prevState.currentRowSelected
            : prevState.customerEntries[0]?.customer?.customer_name,
        rowEntries: updatedCustomerRowEntries,
      };
    });
  };

  const handleDeleteDialog = (id) => {
    setState((prevState) => ({
      ...prevState,
      deletingMemorizedInvoiceId: id,
    }));
    gridHelper && gridHelper.resetSelection();
  };

  const invoiceColumnConfig = [
    {
      id: "serial_no",
      label: "Invoice No.",
      field: "serial_no",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title = row.serial_no;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2">{title}</Typography>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      field: "status",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title =
          INVOICE_STATUS.find((item) => item.value === row?.status)?.label ||
          "-";

        const getStatusLabel = () => {
          if (row.is_overdue) {
            return `${title} / Overdue`;
          }
          if (
            getUTCDateString(row?.payment_completed_date) >
            getUTCDateString(row?.payment_due_date)
          ) {
            return `${title} / Late Payment Received`;
          }
          return title;
        };

        const statusLabel = getStatusLabel();

        return (
          <Tooltip title={statusLabel} placement="top-start">
            <Typography variant="body2">{statusLabel}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => {
        const title = getUTCDateString(row.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const branchColumnConfig = [
    {
      id: "location",
      label: "Location",
      field: "location",
      canSort: true,
      render: (row) => {
        const title = row.location;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2">{title}</Typography>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleItemClick = (row) => {
    setState((prevState) => ({
      ...prevState,
      currentRowSelected: row,
      selectedMemoizedRows: defaultState.selectedMemoizedRows,
      hasRouteConflict: defaultState.hasRouteConflict,
    }));
    fetchMemorizedInvoices(row?.customer?.customer_name);
    gridHelper && gridHelper.resetSelection();
  };

  useEffect(() => {
    if (state.customerEntries && state.customerEntries.length > 0) {
      setState((prevState) => ({
        ...prevState,
        currentRowSelected: state.customerEntries[0],
      }));
      fetchMemorizedInvoices(state.customerEntries[0]?.customer?.customer_name);
    }
  }, [state.customerEntries]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: !state.isMemoize && classes.paper,
        }}
        fullWidth={state.isMemoize}
        maxWidth={state.isMemoize && "lg"}
      >
        <Dialog.Title hasClose>Generate Invoice</Dialog.Title>
        <Dialog.Content>
          {state.isMemoize ? (
            <div className="m-2">
              <div className="ml-2">
                <FormGroup>
                  <FormControlLabel
                    className=" mr-1 mb-4"
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={state.isMemoize}
                        onChange={() => {
                          setState((prevState) => ({
                            ...prevState,
                            isMemoize: !prevState.isMemoize,
                            selectedMemoizedRows:
                              defaultState.selectedMemoizedRows,
                            hasRouteConflict: defaultState.hasRouteConflict,
                          }));
                          gridHelper && gridHelper.resetSelection();
                        }}
                      />
                    }
                    label="Use Memorize"
                  />
                </FormGroup>
                <div
                  className={clsx("d-flex f-align-center", classes.flexWrap)}
                >
                  <Datepicker
                    mask
                    preventClear
                    className="mb-4"
                    label="Start date"
                    selected={state.startDate}
                    maxDate={new Date(state.endDate)}
                    classes={{
                      input: {
                        root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(startDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        startDate: getDateString(startDate),
                      }));
                    }}
                  />
                  <Datepicker
                    mask
                    preventClear
                    label="End date"
                    className="mb-4"
                    selected={state.endDate}
                    minDate={new Date(state.startDate)}
                    maxDate={new Date()}
                    classes={{
                      input: {
                        root: clsx("ml-2 mr-2 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(endDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        endDate: getDateString(endDate),
                      }));
                    }}
                  />
                  <Datepicker
                    mask
                    preventClear
                    label="Invoice Date"
                    className="mb-4"
                    selected={state.invoiceDate}
                    minDate={new Date(state.startDate)}
                    maxDate={new Date()}
                    classes={{
                      input: {
                        root: clsx("ml-4 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(date) => {
                      setState((prevState) => ({
                        ...prevState,
                        invoiceDate: getDateString(date),
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="d-flex f-align-center w-100 overflow-hidden">
                {state.isCustomerListFetching ? (
                  <div className="w-100">
                    <GridLoader pageSize={4} columns={4} />
                  </div>
                ) : state.customerEntries &&
                  state.customerEntries.length > 0 ? (
                  <>
                    <div className=" m-4" style={{ width: "25%" }}>
                      <Typography variant="h6">Customers</Typography>
                      <Divider />

                      {!!state.customerEntries &&
                        state.customerEntries.length > 0 && (
                          <>
                            <List className={classes.list}>
                              {state.customerEntries.map((row) => (
                                <Fragment key={row.customer.id}>
                                  <div
                                    className={clsx("d-flex f-align-center", {
                                      [classes.listItemSelected]:
                                        state.currentRowSelected?.customer
                                          ?.id === row?.customer?.id,
                                    })}
                                  >
                                    <ListItem
                                      button
                                      className={classes.listItem}
                                      onClick={() => {
                                        if (
                                          state.selectedRows &&
                                          state.selectedRows.length > 0
                                        ) {
                                          setState((prevState) => ({
                                            ...prevState,
                                            selectedRows: [],
                                          }));
                                        }
                                        handleItemClick(row);
                                      }}
                                    >
                                      <div className={classes.listItemText}>
                                        <div className="d-flex f-align-center">
                                          <Typography variant="body1">
                                            {row?.customer?.customer_name}
                                          </Typography>
                                        </div>
                                      </div>
                                    </ListItem>
                                  </div>
                                  <Divider />
                                </Fragment>
                              ))}
                            </List>
                          </>
                        )}

                      <Divider />
                    </div>

                    <div className="m-4" style={{ width: "75%" }}>
                      <Typography variant="h6">Memorized Invoices</Typography>
                      <MemorizeGrid
                        entries={
                          state.rowEntries[
                            state.currentRowSelected?.customer?.customer_name
                          ] || []
                        }
                        isFetchingData={
                          !!state.isTableLoading[
                            state.currentRowSelected?.customer?.customer_name
                          ] || false
                        }
                        appData={appData}
                        handleSelection={handleMemorizedSelectionChange}
                        onReady={(instance) => (gridHelper = instance)}
                        handleDeleteDialog={handleDeleteDialog}
                      />
                      {state.hasRouteConflict && (
                        <Box
                          display="flex"
                          alignItems="center"
                          className="pt-2"
                        >
                          <ErrorOutlineIcon fontSize="small" color="error" />
                          <Typography color="error" className="ml-2">
                            Conflicting routes detected for the same
                            location—please remove duplicates to proceed.
                          </Typography>
                        </Box>
                      )}
                    </div>
                  </>
                ) : (
                  <div style={{ height: 300, width: 300, margin: "auto" }}>
                    <img
                      className={classes.noRecordsImg}
                      src={NoRecords}
                      alt="noRecord"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="w-70 m-4">
                <FormGroup className="d-flex flex-row f-align-center f-justify-between">
                  <FormControlLabel
                    className=" ml-1 mb-4"
                    labelPlacement="start"
                    label="Generate multiple invoices for a customer:"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.multipleInvoices}
                        onChange={(evt) => {
                          const isMultiple = evt.target.checked;
                          setState((prevState) => ({
                            ...prevState,
                            multipleInvoices: isMultiple,
                            location: defaultState.location,
                            route: defaultState.route,
                            multipleLocation: defaultState.multipleLocation,
                          }));
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    className=" mr-1 mb-4"
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={state.isMemoize}
                        onChange={() => {
                          setState((prevState) => ({
                            ...prevState,
                            isMemoize: !prevState.isMemoize,
                          }));
                          if (!state.customerEntries.length) {
                            fetchCustomerEntries();
                          }
                        }}
                      />
                    }
                    label="Use Memorize"
                  />
                </FormGroup>
                <div className="d-flex f-align-center">
                  <Datepicker
                    mask
                    preventClear
                    className="mb-4"
                    label="Start date"
                    selected={state.startDate}
                    maxDate={new Date(state.endDate)}
                    classes={{
                      input: {
                        root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(startDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        startDate: getDateString(startDate),
                      }));
                    }}
                  />
                  <Datepicker
                    mask
                    preventClear
                    label="End date"
                    className="mb-4"
                    selected={state.endDate}
                    minDate={new Date(state.startDate)}
                    maxDate={new Date()}
                    classes={{
                      input: {
                        root: clsx("ml-2 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(endDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        endDate: getDateString(endDate),
                      }));
                    }}
                  />
                </div>
                <Datepicker
                  mask
                  preventClear
                  label="Invoice Date"
                  className="mb-4"
                  selected={state.invoiceDate}
                  minDate={new Date(state.startDate)}
                  maxDate={new Date()}
                  classes={{
                    input: {
                      root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                    },
                  }}
                  onChange={(date) => {
                    setState((prevState) => ({
                      ...prevState,
                      invoiceDate: getDateString(date),
                    }));
                  }}
                />
                <div className="d-flex f-align-center">
                  <Autocomplete
                    disableClearable
                    fullWidth
                    className="mr-4 mt-4"
                    size="small"
                    value={state.pdxCompany}
                    options={state.pdxCompanyList}
                    getOptionLabel={(option) => option.value || ""}
                    renderInput={(params) =>
                      state.isFetchingList?.pdxCompany ? (
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height="42px"
                          className="mb-4"
                        />
                      ) : (
                        <TextField
                          {...params}
                          required
                          label="PDX Company"
                          variant="outlined"
                          helperText=" "
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        pdxCompany: value,
                        location: defaultState.location,
                        route: defaultState.route,
                        customer: defaultState.customer,
                        multipleLocation: defaultState.multipleLocation,
                      }));
                    }}
                  />
                  <Autocomplete
                    disableClearable
                    fullWidth
                    className="mt-4"
                    size="small"
                    disabled={!state.pdxCompany}
                    value={state.customer}
                    options={customerList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        variant="outlined"
                        helperText=" "
                      />
                    )}
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        customer: value,
                        location: defaultState.location,
                        route: defaultState.route,
                        multipleLocation: defaultState.multipleLocation,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex f-align-center">
                  {state.multipleInvoices ? (
                    <Autocomplete
                      className="mr-4 mt-4"
                      size="small"
                      fullWidth
                      classes={{
                        paper: "mb-2",
                      }}
                      value={state.multipleLocation}
                      multiple
                      disabled={!state.customer}
                      ChipProps={{
                        component: (a) => {
                          return (
                            <div>
                              <Tooltip
                                placement={"top-start"}
                                title={a.children[1]?.props?.children || ""}
                              >
                                <Chip
                                  size="small"
                                  className={classes.chip}
                                  width={15}
                                  label={
                                    <Typography variant="body3" noWrap>
                                      {a.children[1]?.props?.children.length > 4
                                        ? a.children[1]?.props?.children.slice(
                                            0,
                                            4
                                          ) + "..."
                                        : a.children[1]?.props?.children}
                                    </Typography>
                                  }
                                  deleteIcon
                                  onDelete={(evt) => {
                                    a.children[2]?.props?.onClick(evt);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      }}
                      options={branchList}
                      disableCloseOnSelect={true}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.location || option || ""}
                        </React.Fragment>
                      )}
                      getOptionLabel={(option) =>
                        option?.location || option || ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                          helperText=" "
                        />
                      )}
                      onChange={(evt, multipleLocation) => {
                        setState((prevState) => ({
                          ...prevState,
                          multipleLocation,
                          route: defaultState.route,
                        }));
                      }}
                    />
                  ) : (
                    <Autocomplete
                      fullWidth
                      disableClearable
                      className="mr-4 mt-4"
                      disabled={!state.customer}
                      size="small"
                      classes={{
                        paper: "mb-2",
                      }}
                      value={state.location}
                      options={branchList}
                      getOptionLabel={(option) =>
                        option?.location || option || ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                          required
                          helperText=" "
                        />
                      )}
                      onChange={(evt, location) => {
                        setState((prevState) => ({
                          ...prevState,
                          location,
                          route: defaultState.route,
                        }));
                      }}
                    />
                  )}

                  <Autocomplete
                    className="mt-4"
                    size="small"
                    fullWidth
                    classes={{
                      paper: "mb-2",
                    }}
                    value={state.route}
                    multiple
                    disabled={!state.location || state.multipleInvoices}
                    ChipProps={{
                      component: (a) => {
                        return (
                          <div>
                            <Tooltip
                              placement={"top-start"}
                              title={a.children[1]?.props?.children || ""}
                            >
                              <Chip
                                size="small"
                                className={classes.chip}
                                width={15}
                                label={
                                  <Typography variant="body3" noWrap>
                                    {a.children[1]?.props?.children.length > 4
                                      ? a.children[1]?.props?.children.slice(
                                          0,
                                          4
                                        ) + "..."
                                      : a.children[1]?.props?.children}
                                  </Typography>
                                }
                                deleteIcon
                                onDelete={(evt) => {
                                  a.children[2]?.props?.onClick(evt);
                                }}
                              />
                            </Tooltip>
                          </div>
                        );
                      },
                    }}
                    options={routeList}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          {option?.name || option || ""}
                          {!option?.is_active && (
                            <Tooltip
                              title="Inactive Route"
                              placement="top-start"
                            >
                              <ToggleOffIcon
                                style={{ color: "red", fontSize: 30 }}
                              />
                            </Tooltip>
                          )}
                        </span>
                      </React.Fragment>
                    )}
                    getOptionLabel={(option) => option?.name || option || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Route"
                        variant="outlined"
                        helperText=" "
                      />
                    )}
                    onChange={(evt, route) => {
                      setState((prevState) => ({
                        ...prevState,
                        route,
                      }));
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
            {state.isMemoize ? (
              <Button
                variant="contained"
                color="primary"
                className="ml-2 mr-2"
                disabled={
                  state.isLoading ||
                  !state.selectedMemoizedRows.length ||
                  state.hasRouteConflict
                }
                onClick={() =>
                  state.selectedMemoizedRows.length > 1
                    ? handleGenerateMultipleInvoices(false)
                    : handleSubmit(false)
                }
              >
                Generate
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="ml-2 mr-2"
                disabled={
                  state.isLoading ||
                  (!state.multipleInvoices && !state.location) ||
                  (state.multipleInvoices && !state.customer)
                }
                onClick={() =>
                  state.multipleInvoices
                    ? handleGenerateMultipleInvoices(false)
                    : handleSubmit(false)
                }
              >
                Generate
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </Button>
            )}
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.isRecordPresent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.isRecordPresent}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                For the selected date range, some invoices have been created.
                The list is shown below. Click Continue to create the remaining
                invoices.
              </Typography>
              <br></br>
              <Grid
                columns={invoiceColumnConfig}
                rows={state.entries.filter((entry) =>
                  state.presentInvoiceIds.includes(entry.id)
                )}
                actionBarConfig={null}
                isLoading={state.isDataFetching}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onConfirm={() => {
            (
              state.isMemoize
                ? state.selectedMemoizedRows.length > 1
                : state.multipleInvoices
            )
              ? handleGenerateMultipleInvoices(true)
              : handleSubmit(true);
            setState((prevState) => ({
              ...prevState,
              isRecordPresent: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              isRecordPresent: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              Continue
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isinvoiceIdsPresent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.isinvoiceIdsPresent}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                Invoice(s) have already been created for this date range.
              </Typography>
              <br></br>
              <Grid
                columns={invoiceColumnConfig}
                rows={state.entries.filter((entry) =>
                  state.invoiceIds.includes(entry.id)
                )}
                actionBarConfig={null}
                isLoading={state.isDataFetching}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onConfirm={() =>
            setState((prevState) => ({
              ...prevState,
              isinvoiceIdsPresent: false,
            }))
          }
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isinvoiceIdsPresent: false,
            }));
          }}
        />
      )}
      {state.noRecordBranch && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={!!state.noRecordBranch}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                The following location(s) have no records to create an invoice.
                Please unselect these location(s) first.
              </Typography>
              <br></br>
              <Grid
                columns={branchColumnConfig}
                rows={branchList.filter((obj) =>
                  state.presentBranchIds.includes(obj.id)
                )}
                actionBarConfig={null}
                isLoading={state.isDataFetching}
                hasSelection={false}
                hasPagination={false}
              />
            </>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              noRecordBranch: false,
            }));
          }}
          isConfirmDisabled={state.isLoading}
          positiveActionLabel="Close"
        />
      )}
      {state.deletingMemorizedInvoiceId && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperDialog,
          }}
          open={!!state.deletingMemorizedInvoiceId}
          contentText="Are you sure you want to delete?"
          onConfirm={() =>
            handleMemorizedInvoiceDelete(
              state.deletingMemorizedInvoiceId,
              state.currentRowSelected?.customer?.customer_name
            )
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingMemorizedInvoiceId: null,
            }))
          }
          isConfirmDisabled={state.isMemorizedInvoiceDeleting}
          positiveActionLabel={
            <>
              Delete
              {state.isMemorizedInvoiceDeleting && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default GenerateInvoiceDialog;
