import jsPDF from "jspdf";
import "jspdf-autotable";
import pdxSouthLogo from "assets/images/PDXSouth.png";
import pdxNorthLogo from "assets/images/PDXNorth.png";
import pdxWestLogo from "assets/images/PDXWest.png";
import pdxLogo from "assets/images/PDX.jpg";
import iconImage from "assets/icons/unbilledMark.png";
import { getUTCDateString } from "utils";

const transformRows = (rows) => {
  return rows.map((row) => {
    let days = `${row.bill_quantity}`;
    let description = `${row.route_name} - ${row.ic_name}`;
    const mainDescription = row?.description_names?.main_description?.trim();
    if (mainDescription && description?.trim() !== mainDescription) {
      description += ` (${row.description_names.main_description})`;
    }
    let rate = `${row.bill_rate}`;
    let amount = `${row.bill_amount}`;

    if (row.bill_fuel_surcharge && row.bill_fuel_surcharge !== "0.00") {
      days += `\n`;
      description += `\nFuel Surcharge`;
      const fuelDescription =
        row?.description_names?.fuel_surcharge_description?.trim();
      if (fuelDescription && fuelDescription !== "Fuel Surcharge") {
        description += ` (${row.description_names.fuel_surcharge_description})`;
      }
      rate += `\n${row.bill_fuel_surcharge}%`;
      amount += `\n${row.bill_fuel_amount}`;
    }

    if (row.bill_extra_miles && row.bill_extra_miles !== "0.00") {
      days += `\n${row.bill_extra_miles}`;
      description += `\nExtra Miles`;
      const extraMilesDescription =
        row?.description_names?.extra_miles_description?.trim();
      if (extraMilesDescription && extraMilesDescription !== "Extra Miles") {
        description += ` (${row.description_names.extra_miles_description})`;
      }
      rate += `\n${row.bill_rate_per_extra_mile}`;
      amount += `\n${row.bill_extra_mile_amount}`;
    }

    if (row.toll_amount && row.toll_amount !== "0.00") {
      days += `\n`;
      description += `\nTolls`;
      const tollsDescription =
        row?.description_names?.tolls_description?.trim();
      if (tollsDescription && tollsDescription !== "Tolls") {
        description += ` (${row.description_names.tolls_description})`;
      }
      rate += `\n${row.toll_amount}`;
      amount += `\n${row.toll_amount}`;
    }

    return {
      days,
      description,
      rate,
      amount,
      is_previously_unbilled: row.is_previously_unbilled,
      position: row.position,
    };
  });
};

const transformDescriptionRows = (rowDescription) => {
  return rowDescription.map((row) => {
    let description = row.customer_note;
    let days = "";
    let rate = "";
    let amount = "";
    let notes = "";

    return {
      description,
      days,
      rate,
      amount,
      notes,
      id: row.id,
      position: row?.position,
      isDescription: true,
    };
  });
};

const transformInvoiceTypeRows = (rowType) => {
  return rowType.map((row) => {
    return {
      description: row.description,
      days: row.quantity,
      rate: row.rate,
      amount: row.amount,
      id: row.id,
      position: row.position,
      isDescription: true,
      isInvoiceType: true,
    };
  });
};

const GenerateInvoicePDF = ({
  invoiceDetails = [],
  invoiceDescriptionDetails = [],
  invoiceAdditionalLineItems = [],
  invoiceNo,
  terms,
  termsAndCondition,
  invoiceDate,
  customerNotes,
  invoiceStatus,
  pdxCompany,
  billTo = {},
  discount = 0,
  balanceAmount,
  paidAmount,
  isLatePayment = false,
  isOverdue = false,
  isPreview = false,
  isPrinting = false,
  isArrayPrinting = false,
  completePaymentDoneOn,
  usedCreditAmount = 0,
  fromDate,
  toDate,
}) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });

  const rowTableData = transformRows(invoiceDetails);
  const rowDescriptionTableData = transformDescriptionRows(
    invoiceDescriptionDetails
  );
  const rowAdditionalLineItemData = transformInvoiceTypeRows(
    invoiceAdditionalLineItems
  );
  const combinedTableData = [
    ...(rowTableData || []),
    ...(rowDescriptionTableData || []),
    ...(rowAdditionalLineItemData || []),
  ];

  const tableData = combinedTableData.sort((a, b) => {
    if (a.position === null) return 1;
    if (b.position === null) return -1;
    return a.position - b.position;
  });

  const isPreviouslyBilled = invoiceDetails.some(
    (row) => row.is_previously_unbilled === true
  );
  const text = invoiceStatus !== "approved" ? invoiceStatus.toUpperCase() : " ";

  const termsArray = termsAndCondition?.split(". ");

  const initialTotalAmount = tableData
    .reduce((sum, row) => {
      const amountValue = row.amount || "0";

      const amounts = amountValue.includes("\n")
        ? amountValue.split("\n").map((value) => parseFloat(value) || 0)
        : [parseFloat(amountValue) || 0];

      return sum + amounts.reduce((acc, curr) => acc + curr, 0);
    }, 0)
    .toFixed(2);

  const newAmount = (+initialTotalAmount - +(discount || 0)).toFixed(2);
  const totalAmount = (+newAmount - +(usedCreditAmount || 0)).toFixed(2);

  // Function to add watermark
  const addWatermark = (text, x, y, options = {}) => {
    const {
      color = [255, 0, 0], // default color is red
      fontSize = 16, // default font size
      font = "helvetica", // default font
      fontType = "bold", // default font type
      align = "center", // default alignment
    } = options;

    doc.setTextColor(...color); // Set text color
    doc.setFontSize(fontSize); // Set font size
    doc.setFont(font, fontType); // Set font and font type
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.5 }));
    doc.text(text, x, y, null, null, align); // Add the watermark text
    doc.setTextColor(0, 0, 0); // Reset text color to black
    doc.restoreGraphicsState();
  };

  const addwatermarkDStripe = (text, options) => {
    const { fontSize, angle, color, xInterval, yInterval } = options;

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    doc.setFontSize(fontSize);
    doc.setTextColor(color);

    const radAngle = (angle * Math.PI) / 180;
    const cosAngle = Math.cos(radAngle);
    const sinAngle = Math.sin(radAngle);

    // Calculate the width and height of the rotated text box
    const textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    const textHeight = fontSize / doc.internal.scaleFactor;
    const rotatedTextWidth =
      Math.abs(textWidth * cosAngle) + Math.abs(textHeight * sinAngle);
    const rotatedTextHeight =
      Math.abs(textWidth * sinAngle) + Math.abs(textHeight * cosAngle);

    // Iterate over the defined pattern and place the text
    for (let y = -pageHeight; y < pageHeight * 2; y += yInterval) {
      for (let x = -pageWidth; x < pageWidth * 2; x += xInterval) {
        // Calculate rotated coordinates
        const rotatedX = x * cosAngle - y * sinAngle + pageWidth / 2;
        const rotatedY = x * sinAngle + y * cosAngle + pageHeight / 2;

        // Adjust for centering the text at (rotatedX, rotatedY)
        const textOffsetX = rotatedTextWidth / 2;
        const textOffsetY = rotatedTextHeight / 2;

        if (
          rotatedX - textOffsetX >= 0 &&
          rotatedX + textOffsetX <= pageWidth &&
          rotatedY - textOffsetY >= 0 &&
          rotatedY + textOffsetY <= pageHeight
        ) {
          doc.saveGraphicsState();
          doc.setGState(new doc.GState({ opacity: 0.4 }));
          doc.text(
            text,
            rotatedX - textOffsetX + (text === "PAID" ? 2 : 0),
            rotatedY - textOffsetY,
            { angle: angle }
          );

          if (text === "PAID") {
            doc.text(
              getUTCDateString(completePaymentDoneOn),
              rotatedX - textOffsetX - 6,
              rotatedY - textOffsetY,
              { angle: angle }
            );
          }

          doc.setTextColor(0, 0, 0); // Reset text color
          doc.restoreGraphicsState();
        }
      }
    }
  };

  const watermarkOptions = {
    fontSize: 12,
    angle: -45,
    color: (128, 128, 128),
    xInterval: 50, // Horizontal interval between watermarks
    yInterval: 70, // Vertical interval between watermarks
  };

  let footArray = [
    [
      {
        content: `${customerNotes || " "}`,
        colSpan: 2,
        styles: {
          halign: "left",
          valign: "top",
          lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0.1 },
        },
      },

      {
        content: "SubTotal",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: 10,
          lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: `$${initialTotalAmount || "0.00"}`,
        colSpan: 1,
        styles: {
          halign: "right",
          lineWidth: { top: 0.1, right: 0.1, bottom: 0, left: 0 },
          valign: "middle",
        },
      },
    ],
    [
      {
        content: "",
        colSpan: 2,
        styles: {
          halign: "left",
          valign: "top",
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: "Total",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          fontSize: invoiceStatus === "partially-paid" ? 12 : 20,
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
        },
      },
      {
        content: `$${totalAmount || "0.00"}`,
        colSpan: 1,
        styles: {
          halign: "right",
          fontSize: invoiceStatus !== "partially-paid" ? 15 : 9,
          lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
          valign: "middle",
        },
      },
    ],

    [
      {
        content: "Payment Terms and Conditions:",
        colSpan: 4,
        styles: {
          halign: "center",
          lineWidth: { top: 0.1, right: 0.1, bottom: 0, left: 0.1 },
          fontStyle: "italic",
          fontSize: 8,
          border: "bottom",
        },
      },
    ],
  ];

  if (+discount > 0) {
    const discountRow = [
      [
        {
          content: "",
          colSpan: 2,
          styles: {
            halign: "left",
            valign: "top",
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: "Discount",
          colSpan: 1,
          styles: {
            halign: "left",
            fontStyle: "bold",
            fontSize: 10,
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: `$${discount || "0.00"}`,
          colSpan: 1,
          styles: {
            halign: "right",
            lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
            valign: "middle",
          },
        },
      ],
    ];
    footArray.splice(1, 0, ...discountRow);
  }
  if (+usedCreditAmount > 0 && +discount === 0) {
    const creditRow = [
      [
        {
          content: "",
          colSpan: 2,
          styles: {
            halign: "left",
            valign: "top",
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: "Credit",
          colSpan: 1,
          styles: {
            halign: "left",
            fontStyle: "bold",
            fontSize: 10,
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: `$${usedCreditAmount || "0.00"}`,
          colSpan: 1,
          styles: {
            halign: "right",
            lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
            valign: "middle",
          },
        },
      ],
    ];
    footArray.splice(1, 0, ...creditRow);
  }
  if (+usedCreditAmount > 0 && +discount > 0) {
    const creditRow = [
      [
        {
          content: "",
          colSpan: 2,
          styles: {
            halign: "left",
            valign: "top",
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: "Credit",
          colSpan: 1,
          styles: {
            halign: "left",
            fontStyle: "bold",
            fontSize: 10,
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: `$${usedCreditAmount || "0.00"}`,
          colSpan: 1,
          styles: {
            halign: "right",
            lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
            valign: "middle",
          },
        },
      ],
    ];
    footArray.splice(2, 0, ...creditRow);
  }

  if (invoiceStatus === "partially-paid") {
    const newRows = [
      [
        {
          content: "",
          colSpan: 2,
          styles: {
            halign: "left",
            valign: "top",
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: "Amount Paid",
          colSpan: 1,
          styles: {
            halign: "left",
            fontStyle: "bold",
            fontSize: 10,
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: `$${paidAmount || "0.00"}`,
          colSpan: 1,
          styles: {
            halign: "right",
            lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
            valign: "middle",
          },
        },
      ],
      [
        {
          content: "",
          colSpan: 2,
          styles: {
            halign: "left",
            valign: "top",
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: "Amount Due",
          colSpan: 1,
          styles: {
            halign: "left",
            fontStyle: "bold",
            fontSize: 15,
            lineWidth: { top: 0, right: 0, bottom: 0, left: 0.1 },
          },
        },
        {
          content: `$${balanceAmount || "0.00"}`,
          colSpan: 1,
          styles: {
            halign: "right",
            fontSize: 15,
            lineWidth: { top: 0, right: 0.1, bottom: 0, left: 0 },
            valign: "middle",
          },
        },
      ],
    ];

    // eslint-disable-next-line default-case
    switch (true) {
      case +usedCreditAmount === 0 && +discount === 0:
        footArray.splice(2, 0, ...newRows);
        break;

      case +discount > 0 && +usedCreditAmount === 0:
        footArray.splice(3, 0, ...newRows);
        break;

      case +discount === 0 && +usedCreditAmount > 0:
        footArray.splice(3, 0, ...newRows);
        break;

      case +discount > 0 && +usedCreditAmount > 0:
        footArray.splice(4, 0, ...newRows);
        break;
    }
  }

  // Iterate over termsArray and add each term as a new row
  termsArray?.forEach((term, index) => {
    footArray.push([
      {
        content: term + (index < termsArray.length - 1 ? "." : ""),
        colSpan: 4,
        styles: {
          halign: "center",
          lineWidth: {
            top: 0,
            right: 0.1,
            bottom: index === termsArray.length - 1 ? 0.1 : 0,
            left: 0.1,
          },
          fontStyle: "normal",
          fontSize: 6,
          cellPadding: 1,
        },
      },
    ]);
  });

  const addHeaders = () => {
    // Set text color
    doc.setTextColor(0, 0, 0);

    // Switch case to handle different companies
    switch (pdxCompany.toUpperCase()) {
      case "PDX SOUTH":
        doc.text("PDX South Inc.", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        doc.addImage(pdxSouthLogo, "PNG", 60, 18, 40, 20); // assuming pdxSouthLogo is defined
        break;

      case "PDX NORTH":
        doc.text("PDX North", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        doc.addImage(pdxNorthLogo, "PNG", 60, 18, 40, 20); // assuming abcCorpLogo is defined
        break;

      case "PDX WEST":
        doc.text("PDX West", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        doc.addImage(pdxWestLogo, "PNG", 60, 18, 40, 20); // assuming xyzEnterprisesLogo is defined
        break;

      // Add more cases as needed for different companies

      default:
        doc.text("PDX Inc.", 9, 22);
        doc.setFontSize(11);
        doc.text("P.O. Box 329", 9, 28);
        doc.text("Essington, PA 19029", 9, 34);
        doc.text("(610) 595-3390", 9, 40);
        // Add a default logo if needed
        doc.addImage(pdxLogo, "JPEG", 60, 18, 40, 20); // assuming defaultLogo is defined
        break;
    }

    // Add invoice title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice", 181, 22);

    // Add Invoice Date and Number with border
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");

    // Draw rectangle for Invoice Date and Number
    doc.rect(141.5, 30, 62, 15); // Main border
    doc.line(173, 45, 173, 30); // Vertical line
    doc.line(141.5, 36, 203.5, 36); // Horizontal line

    // Add text inside the table
    doc.text("Date", 153, 34.5);
    doc.text("Invoice #", 182, 34.5);
    doc.text(`${getUTCDateString(invoiceDate) || "-"}`, 148, 42);
    doc.text(`${invoiceNo || "-"}`, 178, 42);

    // Add Bill To section with border box and header line
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text("Bill To", 14, 60);

    // Draw rectangle around Bill To section
    doc.rect(12, 54, 90, 45); // Adjust the size and position as needed

    // Draw horizontal line inside the rectangle for header separation
    doc.line(12, 62, 102, 62);

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(
      [billTo?.customerName, billTo?.branch]
        .filter((value) => value)
        .join(", "),
      14,
      67
    );
    doc.text(`${billTo?.primary_contact || ""}`, 14, 73);
    doc.text(`${billTo?.streetAddress || ""}`, 14, 79);
    doc.text(
      [billTo?.city, billTo?.state].filter((value) => value).join(", "),
      14,
      85
    );
    doc.text(
      [billTo?.zipCode, billTo?.country].filter((value) => value).join(", "),
      14,
      91
    );

    // Add Overdue watermark
    if (isOverdue) {
      addWatermark("Overdue", 125, 80, {
        color: [255, 0, 0],
        fontSize: 16,
        font: "helvetica",
        fontType: "italic",
        align: "center",
      });
    }
    if (isLatePayment) {
      addWatermark("Late Payment Received", 125, 80, {
        color: [255, 0, 0],
        fontSize: 16,
        font: "helvetica",
        fontType: "italic",
        align: "center",
      });
    }
  };

  addHeaders();

  const addWatermarkImage = (imgData) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const imgWidth = 100;
    const imgHeight = 60;

    // Calculate center position
    const xPos = (pageWidth - imgWidth) / 2;
    const yPos = (pageHeight - imgHeight) / 2;
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.1 }));

    doc.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
    doc.restoreGraphicsState();
  };

  const addBlankRows = (table) => {
    const rowsPerPage = 5;
    if (table.length < rowsPerPage) {
      const blankRowCount = rowsPerPage - tableData.length;
      const blankRowSpace = "\n\n\n";
      for (let i = 0; i < blankRowCount; i++) {
        table.push({
          days: blankRowSpace,
          description: blankRowSpace,
          rate: blankRowSpace,
          amount: blankRowSpace,
        });
      }
    }
  };

  addBlankRows(tableData);

  // Function to add table with autoTable
  const addTable = () => {
    doc.autoTable({
      rowPageBreak: "avoid",
      head: [
        [
          { content: "", styles: { lineWidth: 0 } },
          { content: "", styles: { lineWidth: 0 } },
          { content: "", styles: { lineWidth: 0 } },
          { content: "Terms", colSpan: 1 },
        ],
        [
          { content: "", styles: { lineWidth: 0 } },
          { content: "", styles: { lineWidth: 0 } },
          { content: "", styles: { lineWidth: 0 } },
          { content: `Net ${terms} Days`, colSpan: 1 },
        ],
        ["DAYS", "DESCRIPTION", "RATE", "AMOUNT"],
      ],
      columns: [
        { header: "DAYS", dataKey: "days" },
        { header: "DESCRIPTION", dataKey: "description" },
        { header: "RATE", dataKey: "rate" },
        { header: "AMOUNT", dataKey: "amount" },
      ],
      body: [
        {
          description: `Parts Delivery W/E ${
            `${getUTCDateString(fromDate)} - ${getUTCDateString(toDate)}` || " "
          }`,
        },
        ...tableData,
      ],
      showFoot: "lastPage",
      theme: "plain",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        halign: "center",
      },
      margin: { left: 6, right: 6, top: 100 },
      columnStyles: {
        days: {
          cellWidth: 20,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0.1 },
          halign: "right",
        },
        description: {
          cellWidth: 110,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0 },
          halign: "left",
        },
        rate: {
          cellWidth: 35,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0 },
          halign: "right",
        },
        amount: {
          cellWidth: 32,
          lineWidth: { top: 0, right: 0.1, bottom: 0.1, left: 0 },
          halign: "right",
        },
      },
      foot: footArray,
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        // lineWidth: { top: 0.1, right: 0, bottom: 0, left: 0 },
        lineWidth: 0.1,
        fontStyle: "normal",
      },
      styles: {
        fontSize: 9,
        cellPadding: 1,
        lineColor: [0, 0, 0],
        border: { bottom: 10 },
      },
      didDrawCell: (data) => {
        if (
          data.column.dataKey === "days" &&
          data.section === "body" &&
          data.row.raw.is_previously_unbilled
        ) {
          const { cell } = data;
          const iconSize = 4;
          const padding = 2;

          const iconX = cell.x + padding;
          const iconY = cell.y + (cell.height - iconSize) / 2;

          doc.addImage(iconImage, "PNG", iconX, iconY, iconSize, iconSize);
        }

        if (
          data.column.dataKey === "days" &&
          data.section === "foot" &&
          ((invoiceStatus !== "partially-paid" &&
            data.row.raw.find((cell) => cell.content === "Total")) ||
            (invoiceStatus === "partially-paid" &&
              data.row.raw.find((cell) => cell.content === "Amount Due"))) &&
          isPreviouslyBilled
        ) {
          const { cell } = data;
          const iconSize = 3.75;
          const padding = 2;

          const iconX = cell.x + padding;
          const iconY = cell.y + (cell.height - iconSize) / 2;
          const height = invoiceStatus === "partially-paid" ? 5.25 : 6.25;

          doc.addImage(iconImage, "PNG", iconX, iconY, iconSize, iconSize);
          doc.setFontSize(10);
          doc.text("= Previously Unbilled", cell.x + 7, cell.y + height);
        }
      },
      didDrawPage: (data) => {
        addwatermarkDStripe(text, watermarkOptions);

        switch (pdxCompany.toUpperCase()) {
          case "PDX SOUTH":
            addWatermarkImage(pdxSouthLogo);
            break;

          case "PDX NORTH":
            addWatermarkImage(pdxNorthLogo);
            break;

          case "PDX WEST":
            addWatermarkImage(pdxWestLogo);
            break;

          default:
            addWatermarkImage(pdxLogo);
            break;
        }

        // Add page number
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

        doc.setFontSize(10);

        // Calculate text width
        const textWidth =
          doc.getStringUnitWidth(`Page ${data.pageNumber}`) *
          doc.internal.scaleFactor;
        // Calculate x position to center text
        const textX = (pageWidth - textWidth) / 2;

        doc.text(`Page ${data.pageNumber}`, textX, pageHeight - 10);
        if (data.pageNumber > 1) {
          addHeaders();
        }
      },
    });
  };

  // Add the table
  addTable();

  doc.setProperties({
    title: `Invoice #${invoiceNo}`,
  });

  // Save the PDF
  if (isPreview) return doc.output("bloburl");
  else if (isPrinting) {
    window.open(doc.output("bloburl"));
  } else if (isArrayPrinting) {
    return doc.output("arraybuffer");
  } else {
    doc.save(`Invoice-${invoiceNo}.pdf`);
  }
};

export default GenerateInvoicePDF;
