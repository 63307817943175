/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import useStyles from "./style";
import clsx from "clsx";
import { ActionDialog, AppContext, Datepicker, VALIDATIONS } from "shared";
import InvoiceTable from "../grid";
import { toast } from "react-toastify";
import Service from "../service";
import {
  getDateString,
  getUTCDateString,
  LoadingOverlay,
  validator,
} from "utils";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import GenerateInvoicePDF from "../generateInvoicePdf";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddInvoiceItemDialog from "./invoiceItem/index";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import NavigationIcon from "@material-ui/icons/Navigation";
import UdateInvoiceItemDescriptionDialog from "./invoiceItem/updateDescription";
import AddBillingAndSettlemntInvoiceItemDialog from "./billingandSettlementInvoiceItem";
import AddIcon from "@material-ui/icons/Add";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PostAddIcon from "@material-ui/icons/PostAdd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddInvoiceType from "./invoiceItem/invoiceType";
import AddBoxIcon from "@material-ui/icons/AddBox";

let isFieldChange = false;
const maxChipsToShow = 2;
const chipTextLimit = 8;

const truncateText = (text, limit) => {
  return text.length > limit ? `${text.slice(0, limit)}...` : text;
};

const defaultState = {
  entries: {},
  pdxCompany: null,
  deletDarftInvoice: null,
  isInvoiceDeleteLoading: false,
  addBillTo: false,
  isRefresh: false,
  isBillToEmpty: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
  },
  startDate: getDateString(new Date()),
  endDate: getDateString(new Date()),
  invoiceNo: null,
  billTo: "",
  customerNotes: "",
  termsAndCondition: "",
  isDataSaved: false,
  route: null,
  selectedRoutes: [],
  terms: 7,
  invoiceDate: getDateString(new Date()),
  status: null,
  showGrid: false,
  customer: null,
  isDraft: false,
  anchorE2: false,
  anchorEll: false,
  pdxCompanyList: [],
  streetAddress: "",
  city: "",
  zipCode: "",
  state: "",
  country: "",
  primaryContact: "",
  primary_contact: "",
  discount: 0,
  discountAmount: 0,
  usedCreditAmount: 0,
  availableCredit: 0,
  isAvailableCredit: false,
  declineCreditDialog: false,
  totalAmountValidate: false,
  isTypeEditing: false,
  invoiceTypeRow: {},
  isMemorizeInvoiceLoading: false,
  memorizeInvoiceName: "",
  errors: {
    terms: " ",
    discount: " ",
    discountAmount: " ",
    usedCreditAmount: " ",
    primaryContact: " ",
    customerNotes: " ",
    termsAndCondition: " ",
    primary_contact: " ",
    streetAddress: " ",
    city: " ",
    zipCode: " ",
    state: " ",
    country: " ",
    lineItemDescriptionNotes: " ",
    memorizeInvoiceName: " ",
  },
  addInvoiceItem: false,
  isDiscountAmount: false,
  invoiceItemEdit: false,
  invoiceItemDelete: false,
  invoiceItemId: null,
  invoiceRowBeingEdited: [],
  pdfUrl: "",
  pdfViewerDialog: false,
  anchorEl: null,
  finalizeInvoiceData: false,
  isFinalised: false,
  openDescriptionDialog: false,
  selectedDescriptionRow: null,
  addLineItemDescription: false,
  lineItemDescriptionNotes: "",
  invoiceDescriptionId: null,
  isInvoiceDescriptionLoading: false,
  bsIds: [],
  memorizeInvoice: false,
};

const DraftInvoice = ({ history, location }) => {
  const { appData } = useContext(AppContext);
  const draftInvoice = location.state || {};
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const [state, setState] = useState(defaultState);

  const getDraftInvoice = async (isFetchingData = true, isRefresh = false) => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({
      ...prevState,
      isLoading: isFetchingData,
      isRefresh,
    }));

    let queryString = `?invoiceIds=${draftInvoice?.invoiceId}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isRefresh: false,
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const invoiceEntries = data?.invoiceDataArray?.[0];

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      isRefresh: false,
      entries: invoiceEntries || defaultState.entries,
    }));
    return invoiceEntries;
  };

  const saveInvoiceDetails = async (payload) => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({ ...prevState, isDataSaved: true }));

    const { error } = await Service.update(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isDataSaved: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice updated successfully.");
    setState((prevState) => ({
      ...prevState,
      isDataSaved: false,
      finalizeInvoiceData: false,
    }));
    isFieldChange = false;
    getDraftInvoice();
  };

  const updateBillAddress = async (payload) => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({ ...prevState, isBillLoading: true }));

    const { error } = await Service.updateBillAddress(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isBillLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice Bill To Address updated successfully.");
    setState((prevState) => ({
      ...prevState,
      isBillLoading: false,
    }));
    isFieldChange = false;
    getDraftInvoice();
  };

  const finalizeInvoiceDetails = async (payload) => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({ ...prevState, isFinalised: true }));

    const { data, error } = await Service.finalizeInvoice(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isFinalised: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    if (data.isAutoApprove) {
      toast.success("Invoice is finalized and approved.");
    } else {
      toast.success("Invoice is finalized.");
    }
    setState((prevState) => ({
      ...prevState,
      isFinalised: false,
      finalizeInvoiceData: false,
    }));
    isFieldChange = false;
    history.push({
      pathname: "/invoice/view_invoice",
      state: {
        viewInvoiceSummary: true,
        selectedInvoice: state.entries?.invoiceDetails,
      },
    });
  };

  const handleOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorE2: event.currentTarget,
    }));
  };

  const handleClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      addInvoiceItem: close,
    }));
  };
  const handleBillingAndSettlemntCloseDialog = (close) => {
    setState((prevState) => ({
      ...prevState,
      addBillingAndSettlementInvoiceItemDialog: close,
    }));
  };
  const handleEditClose = (close) => {
    setState((prevState) => ({
      ...prevState,

      invoiceItemEdit: close,
      invoiceRowBeingEdited: defaultState.invoiceRowBeingEdited,
    }));
  };

  const handleGetInvoice = useCallback(() => {
    let timeoutId;
    let retryCount = 0;
    const maxRetries = 10;

    const poll = async (conditionFn, onSuccess) => {
      try {
        const data = await getDraftInvoice();

        if (data?.status === "ERROR" || retryCount >= maxRetries) {
          clearTimeout(timeoutId);
          return;
        }

        if (conditionFn(data)) {
          onSuccess(data);
        } else {
          timeoutId = setTimeout(() => {
            retryCount++;
            poll(conditionFn, onSuccess);
          }, 1000);
        }
      } catch (error) {
        clearTimeout(timeoutId);
      }
    };

    // Phase 1: Poll until status is COMPLETED, undefined, or null
    const pollUntilCompleted = () => {
      poll(
        (data) =>
          data?.status === "COMPLETED" ||
          data?.status === undefined ||
          data?.status === null,
        (data) => {
          if (data?.lineItems?.length === 0) {
            pollUntilLineItemsExist();
          } else {
            clearTimeout(timeoutId);
          }
        }
      );
    };

    // Phase 2: Poll until lineItems is not empty
    const pollUntilLineItemsExist = () => {
      poll(
        (data) => data?.lineItems?.length > 0,
        () => clearTimeout(timeoutId)
      );
    };

    pollUntilCompleted();

    return () => clearTimeout(timeoutId);
  }, []);

  const handleGeneratePDF = (value) => {
    const invoiceData = {
      invoiceDetails: state.entries.lineItems,
      invoiceDescriptionDetails: state.entries?.lineItemNotes,
      invoiceAdditionalLineItems: state.entries?.additionalLineItemRecords,
      invoiceNo: state.invoiceNo,
      invoiceDate: state.invoiceDate,
      fromDate: state.entries?.invoiceDetails.from_date,
      toDate: state.entries?.invoiceDetails.to_date,
      termsAndCondition: state.termsAndCondition,
      terms: state.terms,
      customerNotes: state.customerNotes,
      invoiceStatus: state.entries?.invoiceDetails?.status,
      pdxCompany: state.pdxCompany.pdxCompany.value,
      balanceAmount: state.entries?.invoiceDetails?.balance_amt,
      paidAmount: state.entries?.invoiceDetails?.paid_amt,
      isOverdue: state.entries?.invoiceDetails?.is_overdue,
      completePaymentDoneOn:
        state.entries?.invoiceDetails?.complete_payment_done_on,
      isLatePayment:
        getUTCDateString(
          state.entries?.invoiceDetails?.payment_completed_date
        ) > getUTCDateString(state.entries?.invoiceDetails?.payment_due_date)
          ? true
          : false,
      billTo: {
        customerName: state.entries?.invoiceDetails?.bill_to?.customerName,
        branch: state.entries?.invoiceDetails?.bill_to?.branch,
        streetAddress: state.streetAddress,
        state: state.state,
        city: state.city,
        country: state.country,
        zipCode: state.zipCode,
        primary_contact: state.primary_contact,
      },
      discount: state.entries?.invoiceDetails?.discount_amt,
      usedCreditAmount: state.entries.invoiceDetails?.used_credit_amt,
      isPreview: value,
    };

    if (!value) {
      GenerateInvoicePDF(invoiceData);
    } else {
      const blob = GenerateInvoicePDF(invoiceData);
      setState((prevState) => ({
        ...prevState,
        pdfUrl: blob,
        pdfViewerDialog: true,
      }));
    }
  };

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const handleDescriptionNotesDialog = (
    value,
    id = null,
    description = null
  ) => {
    setState((prevState) => ({
      ...prevState,
      addLineItemDescription: value,
      invoiceDescriptionId: id,
      lineItemDescriptionNotes: description,
    }));
  };

  const handleIconClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEll: event.currentTarget,
    }));
  };

  const handleIconClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEll: null,
      memorizeInvoice: true,
    }));
  };

  useEffect(() => {
    handleGetInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!state.entries && Object.keys(state.entries).length > 0) {
      setState((prevState) => ({
        ...prevState,
        startDate:
          getUTCDateString(state.entries.invoiceDetails?.from_date) ||
          defaultState.startDate,
        endDate:
          getUTCDateString(state.entries.invoiceDetails?.to_date) ||
          defaultState.endDate,
        pdxCompany:
          (!!draftInvoice.customerList &&
            draftInvoice.customerList
              .map((item) =>
                item.customer_branches.find(
                  (branch) =>
                    branch.id ===
                    state.entries.invoiceDetails?.customer_branch_id
                )
              )
              .find((branch) => branch !== undefined)) ||
          defaultState.pdxCompany,
        discount:
          state.entries.invoiceDetails.discount || defaultState.discount,
        discountAmount:
          state.entries.invoiceDetails.discount_amt ||
          defaultState.discountAmount,
        availableCredit:
          state.entries.invoiceDetails?.customer_branch?.available_credit ||
          defaultState.availableCredit,
        usedCreditAmount:
          state.entries.invoiceDetails?.used_credit_amt ||
          defaultState.usedCreditAmount,
        isAvailableCredit:
          +state.entries.invoiceDetails?.used_credit_amt > 0 ||
          defaultState.isAvailableCredit,
        location:
          (!!draftInvoice.customerList &&
            draftInvoice.customerList
              .map((item) =>
                item.customer_branches.find(
                  (branch) =>
                    branch.id ===
                    state.entries.invoiceDetails?.customer_branch_id
                )
              )
              .find((branch) => branch !== undefined)) ||
          defaultState.location,
        selectedRoutes:
          (!!draftInvoice.customerList &&
            draftInvoice.customerList
              .flatMap((item) =>
                item.customer_branches.filter(
                  (branch) =>
                    branch.id ===
                    state.entries.invoiceDetails?.customer_branch_id
                )
              )
              .find((branch) => branch !== undefined)
              ?.routes.filter((entry) =>
                state.entries?.invoiceDetails?.route_ids?.includes(entry.id)
              )) ||
          defaultState.selectedRoutes,
        isDiscountAmount:
          (!!state.entries.invoiceDetails?.discount_method &&
            (state.entries.invoiceDetails?.discount_method === "percentage"
              ? false
              : true)) ||
          defaultState.isDiscountAmount,
        route:
          (!!state.entries.lineItems &&
            state.entries.lineItems.length === 1 &&
            state.entries.lineItems[0]?.route_name) ||
          defaultState.location,
        customer:
          (!!draftInvoice.customerList &&
            draftInvoice.customerList.find((item) =>
              item.customer_branches.some(
                (branch) =>
                  branch.id === state.entries.invoiceDetails?.customer_branch_id
              )
            )) ||
          defaultState.customer,
        invoiceNo:
          state.entries.invoiceDetails?.serial_no || defaultState.invoiceNo,
        terms: state.entries.invoiceDetails?.terms || defaultState.terms,
        billTo:
          [
            state.entries.invoiceDetails?.bill_to?.primary_contact ||
              state.entries.invoiceDetails?.bill_to?.contact,
            state.entries.invoiceDetails?.bill_to?.street_address,
            state.entries.invoiceDetails?.bill_to?.state,
            state.entries.invoiceDetails?.bill_to?.city,
            state.entries.invoiceDetails?.bill_to?.country,
            state.entries.invoiceDetails?.bill_to?.zip_code,
          ]
            .filter((detail) => detail)
            .join(",\n") || defaultState.billTo,
        isDraft: state.entries.invoiceDetails?.is_draft || defaultState.isDraft,
        customerNotes:
          state.entries.invoiceDetails?.customer_notes ||
          defaultState.customerNotes,
        termsAndCondition:
          state.entries.invoiceDetails?.terms_and_condition ||
          defaultState.termsAndCondition,
        streetAddress:
          state.entries.invoiceDetails?.bill_to?.street_address ||
          defaultState.streetAddress,
        state:
          state.entries.invoiceDetails?.bill_to?.state || defaultState.state,
        city: state.entries.invoiceDetails?.bill_to?.city || defaultState.city,
        country:
          state.entries.invoiceDetails?.bill_to?.country ||
          defaultState.country,
        zipCode:
          state.entries.invoiceDetails?.bill_to?.zip_code ||
          defaultState.zipCode,
        primary_contact:
          state.entries.invoiceDetails?.bill_to?.primary_contact ||
          state.entries.invoiceDetails?.bill_to?.contact ||
          defaultState.primary_contact,
        invoiceDate:
          getUTCDateString(state.entries.invoiceDetails?.invoice_date) ||
          defaultState.invoiceDate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entries]);

  useEffect(() => {
    const totalAmount =
      state.entries?.invoiceDetails?.total_amount_calculated || 0;
    if (
      draftInvoice.status === "draft" ||
      state.entries?.invoiceDetails?.status === "draft"
    ) {
      if (+state.discountAmount === 0) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            discountAmount: defaultState.errors.usedCreditAmount,
          },
        }));
        return;
      }
      if (+state.discountAmount > totalAmount && state.isDiscountAmount) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            discountAmount: "Cannot use amount more than sub total.",
          },
        }));
      } else if (+state.discountAmount <= totalAmount) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            discountAmount:
              prevState.errors.discountAmount ===
              "Cannot use amount more than sub total."
                ? defaultState.errors.discountAmount
                : prevState.errors.discountAmount,
          },
        }));
      }
    }
  }, [state.discountAmount, state.entries]);

  const handleDelete = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: true,
    }));

    const queryString = `?invoiceIds=${id}`;

    const { error } = await Service.deleteInvoices(queryString);

    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: false,
      deletDarftInvoice: null,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      history.push("/invoice/view_invoice");
    }
  };

  useEffect(() => {
    const totalAmount =
      state.entries?.invoiceDetails?.total_amount_calculated || 0;
    const inputCreditAmount = parseFloat(state.usedCreditAmount || 0);

    if (
      draftInvoice.status === "draft" ||
      state.entries?.invoiceDetails?.status === "draft"
    ) {
      if (inputCreditAmount === 0) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            usedCreditAmount: defaultState.errors.usedCreditAmount,
          },
        }));
        return;
      }

      if (
        (+state.discount !== 0 || +state.discountAmount !== 0
          ? inputCreditAmount <=
            (+totalAmount - parseFloat(state.discountAmount)).toFixed(2)
          : inputCreditAmount <= +totalAmount) &&
        +state.availableCredit !== 0 &&
        inputCreditAmount <= parseFloat(state.availableCredit)
      ) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            usedCreditAmount:
              prevState.errors.usedCreditAmount ===
              "Credit cannot exceed the available amount or total invoice amount."
                ? defaultState.errors.usedCreditAmount
                : prevState.errors.usedCreditAmount,
          },
        }));
      } else if (
        (+state.discount !== 0 || +state.discountAmount !== 0
          ? inputCreditAmount >
            (+totalAmount - parseFloat(state.discountAmount)).toFixed(2)
          : inputCreditAmount > +totalAmount) ||
        (parseFloat(state.availableCredit) !== 0 &&
          inputCreditAmount > parseFloat(state.availableCredit))
      ) {
        setState((prevState) => ({
          ...prevState,

          errors: {
            ...prevState.errors,
            usedCreditAmount:
              "Credit cannot exceed the available amount or total invoice amount.",
          },
        }));
      }
    }
  }, [
    state.usedCreditAmount,
    state.discountAmount,
    state.availableCredit,
    state.discount,
    state.entries,
  ]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      primaryContact: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      primary_contact: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      streetAddress: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      city: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      termsAndCondition: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
      customerNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      zipCode: [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }],
      state: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      country: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      terms: [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }],
      discount: [{ type: VALIDATIONS.MAX, value: 100 }],
      lineItemDescriptionNotes: [
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 200 },
      ],
      discountAmount: [
        { type: VALIDATIONS.MIN_LENGTH, value: 1 },
        { type: VALIDATIONS.MAX_LENGTH, value: 8 },
      ],
      usedCreditAmount: [
        { type: VALIDATIONS.MIN_LENGTH, value: 1 },
        { type: VALIDATIONS.MAX_LENGTH, value: 8 },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";
    const totalAmount =
      state.entries.invoiceDetails.total_amount_calculated || 0;

    if (
      (field === "discount" ||
        field === "discountAmount" ||
        field === "usedCreditAmount") &&
      value.indexOf(".") !== -1
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (
      (field === "discount" ||
        field === "discountAmount" ||
        field === "usedCreditAmount") &&
      value?.length > 1 &&
      value.indexOf(".") === -1
    ) {
      value = value?.replace(/^0+/, "");
    }

    if (field === "discount") {
      const discountAmount = (
        (parseFloat(totalAmount) * parseFloat(value || 0)) /
        100
      ).toFixed(2);

      setState((prevState) => ({
        ...prevState,
        [field]: value || 0,
        discountAmount: value == 0 ? 0 : discountAmount,

        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    } else if (field === "discountAmount") {
      const inputDiscountAmount = parseFloat(value || 0);

      if (inputDiscountAmount === 0) {
        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          errors: {
            ...prevState.errors,
            discountAmount: defaultState.errors.discountAmount,
          },
        }));
        return;
      }

      if (inputDiscountAmount <= totalAmount) {
        const discountPercentage = (
          (inputDiscountAmount / totalAmount) *
          100
        ).toFixed(2);

        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          discount: value == 0 ? 0 : discountPercentage,
          errors: {
            ...prevState.errors,
            discountAmount: errorMessage || defaultState.errors.discountAmount,
          },
        }));
      } else if (inputDiscountAmount > totalAmount) {
        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          errors: {
            ...prevState.errors,
            discountAmount: "Cannot use amount more than sub-total.",
          },
        }));
      }
    }

    if (field === "usedCreditAmount") {
      const inputCreditAmount = parseFloat(value || 0);

      if (inputCreditAmount === 0) {
        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          errors: {
            ...prevState.errors,
            usedCreditAmount: defaultState.errors.usedCreditAmount,
          },
        }));
        return;
      }

      if (
        (state.discount !== 0 || state.discountAmount !== 0
          ? inputCreditAmount <=
            (totalAmount - parseFloat(state.discountAmount)).toFixed(2)
          : inputCreditAmount <= totalAmount) &&
        +state.availableCredit !== 0 &&
        inputCreditAmount <= parseFloat(state.availableCredit)
      ) {
        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          errors: {
            ...prevState.errors,
            [field]: errorMessage || defaultState.errors.usedCreditAmount,
          },
        }));
      } else if (
        (state.discount !== 0 || state.discountAmount !== 0
          ? inputCreditAmount >
            (totalAmount - parseFloat(state.discountAmount)).toFixed(2)
          : inputCreditAmount > totalAmount) ||
        (parseFloat(state.availableCredit) !== 0 &&
          inputCreditAmount > parseFloat(state.availableCredit))
      ) {
        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          errors: {
            ...prevState.errors,
            [field]:
              "Credit cannot exceed the available amount or total invoice amount.",
          },
        }));
      }
    }

    if (
      field !== "usedCreditAmount" ||
      field !== "discountAmount" ||
      field !== "discount"
    ) {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    }
  };

  const handleAddAddress = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleDeleteDialog = (id, value, bsIds, typeValue) => {
    setState((prevState) => ({
      ...prevState,
      invoiceItemDelete: true,
      invoiceItemId: id,
      isDescription: value,
      bsIds,
      isInvoiceType: typeValue,
    }));
  };
  const handleDiscount = (isDiscountAmount) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      isDiscountAmount,
      discount: defaultState.discount,
      discountAmount: defaultState.discountAmount,
      errors: {
        ...prevState.errors,
        discount: " ",
        discountAmount: " ",
      },
    }));
  };
  const handleEditDialog = (id) => {
    setState((prevState) => ({
      ...prevState,
      invoiceItemEdit: true,
      invoiceRowBeingEdited: state.entries?.lineItems?.filter(
        (items) => items.id === id
      ),
      invoiceItemId: id,
    }));
  };

  const handleDeleteInvoiceRow = async (
    itemId,
    isDescription = false,
    bsIds = [],
    isInvoiceType = false
  ) => {
    setState((prevState) => ({
      ...prevState,
      isItemDeleting: true,
    }));

    let queryString =
      bsIds && bsIds.length > 0 ? `?lineItemIds=${itemId}` : `?id=${itemId}`;

    let serviceMethod =
      isDescription && !isInvoiceType
        ? Service.deleteInvoiceDescriptionNotes(itemId)
        : bsIds && bsIds.length > 0
        ? Service.deleteBSLineItem(queryString)
        : isInvoiceType
        ? Service.deleteInvoiceType(itemId)
        : Service.deleteLineItem(queryString);

    const { error } = await serviceMethod;

    setState((prevState) => ({
      ...prevState,
      isItemDeleting: false,
      invoiceItemDelete: null,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      handleGetInvoice();
    }
  };

  const handleMenuOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: defaultState.anchorEl,
      anchorE2: defaultState.anchorEl,
      anchorE3: defaultState.anchorEl,
    }));
  };

  const handleRowDescriptionEditClick = (row) => {
    setState((prevState) => ({
      ...prevState,
      selectedDescriptionRow: row,
      openDescriptionDialog: true,
    }));
  };
  const handleDescriptionDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      selectedDescriptionRow: defaultState.selectedDescriptionRow,
      openDescriptionDialog: defaultState.openDescriptionDialog,
    }));
  };
  const handleInvoiceTypeDialog = (value, row = {}) => {
    setState((prevState) => ({
      ...prevState,
      addTypeDialog: value,
      invoiceTypeRow: row,
    }));
  };

  const handleAddMenuOpen = (event) => {
    if (
      isFieldChange &&
      !(
        parseFloat(state.discountAmount).toFixed(2) ===
          parseFloat(state.entries.invoiceDetails?.discount_amt || 0).toFixed(
            2
          ) &&
        parseFloat(state.discount).toFixed(2) ===
          parseFloat(state.entries.invoiceDetails?.discount || 0).toFixed(2) &&
        state.invoiceDate ===
          getUTCDateString(state.entries.invoiceDetails?.invoice_date) &&
        state.terms == state.entries.invoiceDetails?.terms &&
        state.customerNotes === state.entries.invoiceDetails?.customer_notes &&
        state.termsAndCondition ===
          state.entries.invoiceDetails?.terms_and_condition
      )
    ) {
      return toast.error("Please save the changes first.");
    }
    setState((prevState) => ({
      ...prevState,
      anchorE3: event.currentTarget,
    }));
  };

  const handleInvoiceDescriptionNote = async (invoiceId, note, id = null) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceDescriptionLoading: true,
    }));

    const payload = {
      invoice_id: invoiceId,
      customer_note: note,
      ...(id && { id }),
    };

    const method = id
      ? "putInvoiceDescriptionNotes"
      : "postInvoiceDescriptionNotes";
    const successMessage = id
      ? "Invoice description updated successfully."
      : "Invoice description added successfully.";

    const { error } = await Service[method](payload);

    setState((prevState) => ({
      ...prevState,
      isInvoiceDescriptionLoading: false,
    }));

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(successMessage);
    handleDescriptionNotesDialog(false);
    handleGetInvoice();
  };
  const handleAddInvoiceType = async (
    invoiceId,
    description,
    rate,
    quantity,
    amount,
    type,
    id = null
  ) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceTypeLoading: true,
    }));

    const payload = {
      invoice_id: invoiceId,
      rate,
      quantity,
      amount,
      description,
      type,
      ...(id && { id }),
    };

    const method = id ? "updateInvoiceType" : "createInvoiceType";
    const successMessage = id
      ? "Invoice type updated successfully."
      : "Invoice type added successfully.";

    const { error } = await Service[method](payload);

    setState((prevState) => ({
      ...prevState,
      isInvoiceTypeLoading: false,
    }));

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(successMessage);
    handleInvoiceTypeDialog(false, null);
    handleGetInvoice();
  };

  const handleTotalAmountValidate = (value) => {
    setState((prevState) => ({
      ...prevState,
      totalAmountValidate: value,
    }));
  };

  const handleInvoiceMemorize = async (invoiceData, memorizedInvoiceName) => {
    setState((prevState) => ({
      ...prevState,
      isMemorizeInvoiceLoading: true,
    }));

    const { id, customer_branch_id, route_ids, from_date, to_date } =
      invoiceData;

    const payload = {
      invoice_id: id,
      customer_branch_id,
      name: memorizedInvoiceName,
      route_ids,
      from_date,
      to_date,
    };

    const { error } = await Service.createMemorizedInvoice(payload);

    setState((prevState) => ({
      ...prevState,
      isMemorizeInvoiceLoading: false,
    }));

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success("Memorized Invoice created successfully");
    setState((prevState) => ({
      ...prevState,
      memorizeInvoice: false,
      isMemorizeInvoiceLoading: false,
      memorizeInvoiceName: defaultState.memorizeInvoiceName,
    }));
  };

  return (
    <>
      {state.isLoading && (
        <LoadingOverlay message="Processing, please wait..." />
      )}
      <div className={clsx(state.isLoading && classes.blur)}>
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            {draftInvoice.status !== undefined &&
            draftInvoice.status !== "draft"
              ? "View Invoice"
              : "Draft Invoice"}
            <Button
              variant="outlined"
              color="primary"
              className={clsx("mt-1 mr-2 pl-4 pr-4", classes.sentIcon)}
              startIcon={<NavigationIcon style={{ rotate: "270deg" }} />}
              onClick={handleOpen}
            >
              Navigate
            </Button>
            <Menu
              anchorEl={state.anchorE2}
              open={Boolean(state.anchorE2)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => history.push("/home")}>
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To Home
              </MenuItem>
              <MenuItem onClick={() => history.push("/invoice")}>
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To Invoice
              </MenuItem>
              <MenuItem onClick={() => history.push("/invoice/view_invoice")}>
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To View Invoice
              </MenuItem>
              <MenuItem
                onClick={() =>
                  history.push({
                    pathname: "/invoice/view_invoice",
                    state: {
                      viewInvoiceSummary: true,
                      selectedInvoice: state.entries?.invoiceDetails,
                    },
                  })
                }
              >
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To View Invoice Summary
              </MenuItem>
            </Menu>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {draftInvoice.status !== undefined &&
            draftInvoice.status !== "draft"
              ? "View Invoice."
              : "View Draft Invoice."}
          </Typography>
        </div>
        <div className="d-flex f-align-center f-justify-between f-wrap">
          <div className="d-flex f-align-center f-wrap">
            <Datepicker
              mask
              preventClear
              disabled
              label="Start date"
              selected={new Date(state.startDate)}
              maxDate={new Date(state.endDate)}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
            />
            <Datepicker
              mask
              disabled
              preventClear
              label="End date"
              selected={new Date(state.endDate)}
              minDate={new Date(state.startDate)}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
            />
            <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="PDX Company"
              variant="outlined"
              size="small"
              value={state.pdxCompany?.pdxCompany?.value || "-"}
            />
            <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="Customer"
              variant="outlined"
              size="small"
              value={state.customer?.name || "-"}
            />
            <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="Location"
              variant="outlined"
              size="small"
              value={state.location?.location || "-"}
            />

            <Tooltip
              title={
                !state.entries?.invoiceDetails?.route_ids
                  ? state.location?.routes
                      ?.map((route) => route.name)
                      .join(", ")
                  : state.selectedRoutes?.map((route) => route.name).join(", ")
              }
            >
              <TextField
                className={clsx("mr-4 mt-4", classes.customerDropdown)}
                disabled
                label="Route"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!state.entries?.invoiceDetails?.route_ids ? (
                        <Chip
                          disabled
                          label="All"
                          size="small"
                          style={{ marginRight: 4, color: "black" }}
                        />
                      ) : (
                        <>
                          {state.selectedRoutes
                            ?.slice(0, maxChipsToShow)
                            ?.map((route, index) => (
                              <Chip
                                disabled
                                key={index}
                                label={truncateText(route.name, chipTextLimit)}
                                size="small"
                                style={{ marginRight: 4, color: "black" }}
                              />
                            ))}
                          {state.selectedRoutes?.length > maxChipsToShow && (
                            <Chip
                              disabled
                              style={{ color: "black" }}
                              label={`+${
                                state.selectedRoutes?.length - maxChipsToShow
                              }`}
                              size="small"
                            />
                          )}
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </div>
          <div className="d-flex f-align-center">
            <Datepicker
              mask
              preventClear
              label="Date"
              disabled={
                (draftInvoice.status !== undefined &&
                  draftInvoice.status !== "draft") ||
                (!!state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0) ||
                state.entries?.invoiceDetails?.status === "pending-approval" ||
                state.entries?.invoiceDetails?.status === "approved"
              }
              selected={new Date(state.invoiceDate)}
              minDate={new Date(state.startDate)}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
              onChange={(date) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  invoiceDate: getDateString(date),
                }));
              }}
            />
            <TextField
              className="w-50 mt-4"
              disabled
              label="Invoice #"
              variant="outlined"
              size="small"
              value={state.invoiceNo || ""}
            />
            {draftInvoice.status !== undefined &&
              draftInvoice.status !== "draft" && (
                <div className="mt-3">
                  <IconButton onClick={handleIconClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={state.anchorEll}
                    open={Boolean(state.anchorEll)}
                    onClose={handleIconClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <MenuItem onClick={handleIconClose}>Memorize</MenuItem>
                  </Menu>
                </div>
              )}
          </div>
        </div>
        <div
          className={clsx(
            "d-flex  mt-5",
            appData.isTabletView && classes.content
          )}
        >
          <div
            className={clsx(
              "p-4 mr-3",
              appData.isTabletView && classes.tabletView
            )}
          >
            <div className={clsx("d-flex flex-column", classes.paper)}>
              <Box className="d-flex f-align-center">
                <Typography
                  variant="body1"
                  style={{
                    marginRight: 8,
                    fontWeight: "bold",
                    marginBottom: 22,
                  }}
                >
                  TERMS:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginRight: 8, marginBottom: 22 }}
                >
                  Net
                </Typography>
                <TextField
                  style={{ width: 62 }}
                  label="Days"
                  variant="outlined"
                  disabled={
                    (draftInvoice.status !== undefined &&
                      draftInvoice.status !== "draft") ||
                    (!!state.entries?.lineItems &&
                      state.entries?.lineItems.length === 0) ||
                    state.entries?.invoiceDetails?.status ===
                      "pending-approval" ||
                    state.entries?.invoiceDetails?.status === "approved"
                  }
                  size="small"
                  type="number"
                  name="terms"
                  value={state.terms}
                  error={!!state.errors.terms?.trim()}
                  helperText={state.errors.terms}
                  onChange={handleFieldChange}
                />
              </Box>
              <FormControl
                variant="outlined"
                fullWidth
                error={state.isBillToEmpty && !state.billTo}
                className={classes.outlinedInputError}
              >
                <InputLabel htmlFor="bill-to">Bill To*</InputLabel>
                <OutlinedInput
                  id="bill-to"
                  label="Bill To"
                  variant="outlined"
                  size="small"
                  disabled
                  required
                  value={`${state.entries?.invoiceDetails?.bill_to.customerName}, ${state.entries?.invoiceDetails?.bill_to.branch}, ${state.billTo}`}
                  style={{ color: "black" }}
                  minRows={6}
                  maxRows={8}
                  multiline
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ marginTop: "90px" }}
                    >
                      <Tooltip
                        title={!!state.billTo ? "Edit Address" : "Add Address"}
                        placement="top-start"
                      >
                        <IconButton
                          disabled={
                            !!state.entries?.lineItems &&
                            state.entries?.lineItems.length === 0
                          }
                          color="primary"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              addBillTo: true,
                            }))
                          }
                        >
                          {!!state.billTo ? (
                            state.isBillLoading ? (
                              <CircularProgress
                                size={24}
                                className="p-absolute progress-btn"
                              />
                            ) : (
                              <EditIcon fontSize="medium" />
                            )
                          ) : (
                            <AddCircleIcon fontSize="large" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
                {state.isBillToEmpty && !state.billTo && (
                  <FormHelperText error>Address is Required...</FormHelperText>
                )}
              </FormControl>
              <TextField
                name="customerNotes"
                label="Customer Note"
                variant="outlined"
                size="small"
                value={state.customerNotes}
                className={classes.customerNotes}
                disabled={
                  (draftInvoice.status !== undefined &&
                    draftInvoice.status !== "draft") ||
                  (!!state.entries?.lineItems &&
                    state.entries?.lineItems.length === 0) ||
                  state.entries?.invoiceDetails?.status ===
                    "pending-approval" ||
                  state.entries?.invoiceDetails?.status === "approved"
                }
                minRows={2}
                maxRows={4}
                multiline
                error={!!state.errors.customerNotes?.trim()}
                helperText={state.errors.customerNotes}
                onChange={handleFieldChange}
              />
            </div>
            <div
              className={clsx(
                "d-flex flex-column",
                classes.paper,
                appData.isTabletView && classes.paymentMargin
              )}
            >
              <TextField
                name="termsAndCondition"
                label="Payment Terms And Condition"
                variant="outlined"
                size="small"
                className="mb-3"
                value={state.termsAndCondition}
                disabled={
                  (draftInvoice.status !== undefined &&
                    draftInvoice.status !== "draft") ||
                  (!!state.entries?.lineItems &&
                    state.entries?.lineItems.length === 0) ||
                  state.entries?.invoiceDetails?.status ===
                    "pending-approval" ||
                  state.entries?.invoiceDetails?.status === "approved"
                }
                minRows={4}
                maxRows={7}
                multiline
                error={!!state.errors.termsAndCondition?.trim()}
                helperText={state.errors.termsAndCondition}
                onChange={handleFieldChange}
              />
              {state.availableCredit > 0 && (
                <div>
                  <Typography variant="h6">{`Available Credit - $ ${state.availableCredit}`}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isAvailableCredit}
                        onChange={(evt) => {
                          const isAvailableCredit = evt.target.checked;
                          if (
                            +state.entries.invoiceDetails?.used_credit_amt !==
                              0 &&
                            state.isAvailableCredit
                          ) {
                            setState((prevState) => ({
                              ...prevState,
                              declineCreditDialog: true,
                            }));
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              isAvailableCredit,
                              usedCreditAmount: defaultState.usedCreditAmount,
                            }));
                          }
                        }}
                        color="primary"
                        disabled={
                          (draftInvoice.status !== undefined &&
                            draftInvoice.status !== "draft") ||
                          (!!state.entries?.lineItems &&
                            state.entries?.lineItems.length === 0) ||
                          state.entries?.invoiceDetails?.status ===
                            "pending-approval" ||
                          state.entries?.invoiceDetails?.status === "approved"
                        }
                      />
                    }
                    label="Do you want to avail?"
                  />
                </div>
              )}
            </div>
          </div>
          <Paper
            elevation={2}
            className={clsx("p-4 mt-10", classes.paperSpacing)}
          >
            <Grid container spacing={1} style={{ overflowX: "auto" }}>
              <Grid item xs={12}>
                <InvoiceTable
                  rows={state.entries?.lineItems || []}
                  rowDescription={state.entries?.lineItemNotes || []}
                  rowType={state.entries?.additionalLineItemRecords || []}
                  handleFieldChange={handleFieldChange}
                  getDraftInvoice={getDraftInvoice}
                  discount={state.discount}
                  discountAmount={state.discountAmount}
                  usedCreditAmount={state.usedCreditAmount}
                  isAvailableCredit={state.isAvailableCredit}
                  finalize={
                    !!(
                      draftInvoice.status !== undefined &&
                      draftInvoice.status !== "draft"
                    )
                  }
                  errors={state.errors}
                  isDiscountAmount={state.isDiscountAmount}
                  isDraft={state.isDraft}
                  handleDeleteDialog={handleDeleteDialog}
                  handleDiscount={handleDiscount}
                  handleEditDialog={handleEditDialog}
                  handleRowDescriptionEditClick={handleRowDescriptionEditClick}
                  handleDescriptionNotesDialog={handleDescriptionNotesDialog}
                  handleTotalAmountValidate={handleTotalAmountValidate}
                  handleInvoiceTypeDialog={handleInvoiceTypeDialog}
                />
              </Grid>
            </Grid>
            <Divider className="mt-2" />
            {!!state.entries?.lineItems &&
              state.entries?.lineItems.length > 0 && (
                <div className="d-flex f-align-center f-justify-between">
                  {(draftInvoice.status === "draft" ||
                    state.entries?.invoiceDetails?.status === "draft") && (
                    <div className="mt-2">
                      <Button
                        startIcon={<DeleteForeverOutlinedIcon color="error" />}
                        classes={{
                          root: "border-error ml-4",
                          label: "color-error",
                        }}
                        variant="outlined"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            deletDarftInvoice: draftInvoice?.invoiceId,
                          }));
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                  <div className={classes.footerContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="ml-2 mr-2 mt-2"
                      startIcon={<AddCircleOutlineIcon />}
                      disabled={
                        (draftInvoice.status !== undefined &&
                          draftInvoice.status !== "draft") ||
                        state.entries?.invoiceDetails?.status ===
                          "pending-approval" ||
                        state.entries?.invoiceDetails?.status === "approved"
                      }
                      onClick={handleAddMenuOpen}
                    >
                      Add
                    </Button>
                    <Menu
                      anchorEl={state.anchorE3}
                      open={Boolean(state.anchorE3)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            addInvoiceItem: true,
                            anchorE3: defaultState.anchorEl,
                          }));
                        }}
                      >
                        <ListItemIcon>
                          <AddIcon />
                        </ListItemIcon>
                        Add LineItem
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleInvoiceTypeDialog(true);
                          setState((prevState) => ({
                            ...prevState,
                            anchorE3: defaultState.anchorEl,
                          }));
                        }}
                      >
                        <ListItemIcon>
                          <AddBoxIcon />
                        </ListItemIcon>
                        Add Additional LineItem
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDescriptionNotesDialog(true);
                          setState((prevState) => ({
                            ...prevState,
                            anchorE3: defaultState.anchorEl,
                          }));
                        }}
                      >
                        {" "}
                        <ListItemIcon>
                          <PlaylistAddIcon />
                        </ListItemIcon>
                        Add Inline Description Note
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            addBillingAndSettlementInvoiceItemDialog: true,
                            anchorE3: defaultState.anchorEl,
                          }));
                        }}
                      >
                        <ListItemIcon>
                          <PostAddIcon />
                        </ListItemIcon>
                        Add LineItems from Billing and Settlement
                      </MenuItem>
                    </Menu>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="ml-2 mr-4 mt-2"
                      startIcon={<DescriptionIcon />}
                      disabled={
                        state.isRefresh ||
                        (state.entries?.lineItems &&
                          state.entries?.lineItems.length === 0) ||
                        (state.billTo && !state.billTo?.length) ||
                        (isFieldChange &&
                          !(
                            parseFloat(state.discountAmount).toFixed(2) ===
                              parseFloat(
                                state.entries.invoiceDetails?.discount_amt || 0
                              ).toFixed(2) &&
                            parseFloat(state.discount).toFixed(2) ===
                              parseFloat(
                                state.entries.invoiceDetails?.discount || 0
                              ).toFixed(2) &&
                            parseFloat(state.usedCreditAmount).toFixed(2) ===
                              parseFloat(
                                state.entries.invoiceDetails?.used_credit_amt ||
                                  0
                              ).toFixed(2) &&
                            state.invoiceDate ===
                              getUTCDateString(
                                state.entries.invoiceDetails?.invoice_date
                              ) &&
                            state.terms ==
                              state.entries.invoiceDetails?.terms &&
                            state.customerNotes ===
                              state.entries.invoiceDetails?.customer_notes &&
                            state.termsAndCondition ===
                              state.entries.invoiceDetails?.terms_and_condition
                          ))
                      }
                      onClick={handleMenuOpen}
                    >
                      View/Download
                      {state.isRefresh && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                    <Menu
                      anchorEl={state.anchorEl}
                      open={Boolean(state.anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleGeneratePDF(true);
                          setState((prevState) => ({
                            ...prevState,
                            anchorEl: defaultState.anchorEl,
                          }));
                        }}
                      >
                        <ListItemIcon>
                          <VisibilityIcon />
                        </ListItemIcon>
                        Preview
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleGeneratePDF(false);
                          setState((prevState) => ({
                            ...prevState,
                            anchorEl: defaultState.anchorEl,
                          }));
                        }}
                      >
                        <ListItemIcon>
                          <PdfIcon />
                        </ListItemIcon>
                        Download
                      </MenuItem>
                    </Menu>
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-4 mt-2"
                      disabled={
                        (draftInvoice.status !== undefined &&
                          draftInvoice.status !== "draft") ||
                        (parseFloat(state.discountAmount).toFixed(2) ===
                          parseFloat(
                            state.entries.invoiceDetails?.discount_amt || 0
                          ).toFixed(2) &&
                          parseFloat(state.discount).toFixed(2) ===
                            parseFloat(
                              state.entries.invoiceDetails?.discount || 0
                            ).toFixed(2) &&
                          parseFloat(state.usedCreditAmount).toFixed(2) ===
                            parseFloat(
                              state.entries.invoiceDetails?.used_credit_amt || 0
                            ).toFixed(2) &&
                          state.invoiceDate ===
                            getUTCDateString(
                              state.entries.invoiceDetails?.invoice_date
                            ) &&
                          state.terms == state.entries.invoiceDetails?.terms &&
                          state.customerNotes ===
                            state.entries.invoiceDetails?.customer_notes &&
                          state.termsAndCondition ===
                            state.entries.invoiceDetails
                              ?.terms_and_condition) ||
                        !isFieldChange ||
                        state.isDataSaved ||
                        validate() ||
                        !!state.errors.usedCreditAmount?.trim() ||
                        !!state.errors.discountAmount?.trim() ||
                        (!!state.entries?.lineItems &&
                          state.entries?.lineItems.length === 0)
                      }
                      onClick={() => {
                        const invoiceDetails = {
                          id: draftInvoice?.invoiceId,
                          customer_notes: state.customerNotes,
                          terms_and_condition: state.termsAndCondition,
                          discount_method: state.isDiscountAmount
                            ? "amount"
                            : "percentage",
                          terms: state.terms || 0,
                          invoice_date: state.invoiceDate,
                          bill_to: {
                            customerName:
                              state.entries?.invoiceDetails?.bill_to
                                ?.customerName,
                            branch:
                              state.entries?.invoiceDetails?.bill_to?.branch,
                            street_address: state.streetAddress,
                            state: state.state,
                            city: state.city,
                            country: state.country,
                            zip_code: state.zipCode,
                            primary_contact: state.primary_contact,
                          },
                          ...(state.isDiscountAmount
                            ? { discount_amt: state.discountAmount || 0 }
                            : { discount: state.discount || 0 }),
                          used_credit_amt: state.usedCreditAmount || 0,
                        };

                        saveInvoiceDetails(invoiceDetails);
                      }}
                    >
                      Save
                      {state.isDataSaved && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-10 mt-2"
                      disabled={
                        state.isRefresh ||
                        (draftInvoice.status !== undefined &&
                          draftInvoice.status !== "draft") ||
                        state.isFinalised ||
                        state.isDataSaved ||
                        validate() ||
                        !!state.errors.usedCreditAmount?.trim() ||
                        !!state.errors.discountAmount?.trim() ||
                        (!!state.entries?.lineItems &&
                          state.entries?.lineItems.length === 0) ||
                        state.entries?.invoiceDetails?.status ===
                          "pending-approval" ||
                        state.entries?.invoiceDetails?.status === "approved" ||
                        state.totalAmountValidate
                      }
                      onClick={() => {
                        if (
                          isFieldChange &&
                          !(
                            parseFloat(state.discountAmount).toFixed(2) ===
                              parseFloat(
                                state.entries.invoiceDetails?.discount_amt || 0
                              ).toFixed(2) &&
                            parseFloat(state.discount).toFixed(2) ===
                              parseFloat(
                                state.entries.invoiceDetails?.discount || 0
                              ).toFixed(2) &&
                            parseFloat(state.usedCreditAmount).toFixed(2) ===
                              parseFloat(
                                state.entries.invoiceDetails?.used_credit_amt ||
                                  0
                              ).toFixed(2) &&
                            state.invoiceDate ===
                              getUTCDateString(
                                state.entries.invoiceDetails?.invoice_date
                              ) &&
                            state.terms ==
                              state.entries.invoiceDetails?.terms &&
                            state.customerNotes ===
                              state.entries.invoiceDetails?.customer_notes &&
                            state.termsAndCondition ===
                              state.entries.invoiceDetails?.terms_and_condition
                          )
                        ) {
                          return toast.error("Please save your changes first.");
                        }
                        if (!state.billTo) {
                          setState((prevState) => ({
                            ...prevState,
                            isBillToEmpty: true,
                          }));
                          return toast.error(
                            `Please add the "Bill to" information before finalizing the invoice.`
                          );
                        }
                        setState((prevState) => ({
                          ...prevState,
                          finalizeInvoiceData: true,
                          isBillToEmpty: false,
                        }));
                      }}
                    >
                      {state.isFinalised && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                      Finalize Invoice
                    </Button>
                  </div>
                </div>
              )}
          </Paper>
        </div>
      </div>
      {state.addBillTo && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.addBillTo}
          contentText={
            <div>
              <Typography variant="h5" color="primary" className=" ml-2">
                {!!state.billTo ? "Edit Address" : "Add Address"}
              </Typography>

              <br />

              <div className={classes.root}>
                <div className="d-flex f-align-center">
                  <TextField
                    className={classes.contact}
                    name="primary_contact"
                    label="Primary Contact Name"
                    variant="outlined"
                    size="small"
                    value={state.primary_contact}
                    error={!!state.errors.primary_contact?.trim()}
                    helperText={state.errors.primary_contact}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.StreetAddress}
                    size="small"
                    variant="outlined"
                    name="streetAddress"
                    label="Street Address"
                    value={state.streetAddress}
                    error={!!state.errors.streetAddress?.trim()}
                    helperText={state.errors.streetAddress}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.city}
                    size="small"
                    variant="outlined"
                    label="City"
                    name="city"
                    value={state.city}
                    error={!!state.errors.city?.trim()}
                    helperText={state.errors.city}
                    onChange={handleAddAddress}
                  />

                  <TextField
                    required
                    className={classes.state}
                    size="small"
                    variant="outlined"
                    label="State"
                    name="state"
                    value={state.state}
                    error={!!state.errors.state?.trim()}
                    helperText={state.errors.state}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.zip}
                    size="small"
                    variant="outlined"
                    label="ZIP Code"
                    name="zipCode"
                    value={state.zipCode}
                    error={!!state.errors.zipCode?.trim()}
                    helperText={state.errors.zipCode}
                    onChange={handleAddAddress}
                  />
                  <TextField
                    required
                    className={classes.country}
                    name="country"
                    size="small"
                    variant="outlined"
                    label="Country"
                    value={state.country}
                    error={!!state.errors.country?.trim()}
                    helperText={state.errors.country}
                    onChange={handleAddAddress}
                  />
                </div>
              </div>
            </div>
          }
          onConfirm={() => {
            const invoiceDetails = {
              id: draftInvoice?.invoiceId,
              customer_notes: state.customerNotes,
              terms_and_condition: state.termsAndCondition,
              discount_method: state.isDiscountAmount ? "amount" : "percentage",
              terms: state.terms || 0,
              invoice_date: state.invoiceDate,
              bill_to: {
                customerName:
                  state.entries?.invoiceDetails?.bill_to?.customerName,
                branch: state.entries?.invoiceDetails?.bill_to?.branch,
                street_address: state.streetAddress,
                state: state.state,
                city: state.city,
                country: state.country,
                zip_code: state.zipCode,
                primary_contact: state.primary_contact,
              },
              ...(state.isDiscountAmount
                ? { discount_amt: state.discountAmount || 0 }
                : { discount: state.discount || 0 }),
              used_credit_amt: state.usedCreditAmount || 0,
            };

            if (
              draftInvoice.status === "draft" ||
              state.entries?.invoiceDetails?.status === "draft"
            ) {
              saveInvoiceDetails(invoiceDetails);
            } else {
              updateBillAddress({
                id: invoiceDetails?.id,
                bill_to: invoiceDetails?.bill_to,
              });
            }

            setState((prevState) => ({
              ...prevState,
              addBillTo: false,
              billTo:
                [
                  state.primary_contact,
                  state.streetAddress,
                  state.city,
                  state.state,
                  state.zipCode,
                  state.country,
                ]
                  .filter((detail) => detail)
                  .join(",\n") || defaultState.billTo,
            }));
          }}
          onCancel={() => {
            if (!!state.billTo) {
              setState((prevState) => ({
                ...prevState,
                addBillTo: false,
                state: state.entries?.invoiceDetails?.bill_to?.state,
                streetAddress:
                  state.entries?.invoiceDetails?.bill_to?.street_address,
                country: state.entries?.invoiceDetails?.bill_to?.country,
                city: state.entries?.invoiceDetails?.bill_to?.city,
                zipCode: state.entries?.invoiceDetails?.bill_to?.zip_code,
                primary_contact:
                  state.entries?.invoiceDetails?.bill_to?.primary_contact ||
                  state.entries?.invoiceDetails?.bill_to?.contact,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                addBillTo: false,
                state: defaultState.state,
                streetAddress: defaultState.streetAddress,
                country: defaultState.country,
                city: defaultState.city,
                zipCode: defaultState.zipCode,
                primary_contact: defaultState.primary_contact,
              }));
            }
          }}
          positiveActionLabel="Save"
          negativeActionLabel="Cancel"
          isConfirmDisabled={
            validate() ||
            !state.streetAddress ||
            !state.city ||
            !state.state ||
            !state.zipCode ||
            !state.country
          }
        />
      )}
      {state.finalizeInvoiceData && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.finalizeInvoiceData}
          contentText={
            <Typography variant="body2" color="primary" className=" ml-2">
              {`Are you sure you want to finalize the invoice?\n After this you cannot make any changes.`}
            </Typography>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              finalizeInvoiceData: false,
            }));
            finalizeInvoiceDetails({
              id: draftInvoice?.invoiceId,
            });
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              finalizeInvoiceData: false,
            }));
          }}
          positiveActionLabel={
            <>
              Save{" "}
              {(state.isDataSaved || state.isFinalised) && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
          isConfirmDisabled={state.isDataSaved || state.isFinalised}
        />
      )}
      {state.invoiceItemDelete && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={state.invoiceItemDelete}
          contentText="Are you sure you want to delete?"
          onConfirm={() =>
            handleDeleteInvoiceRow(
              state.invoiceItemId,
              state.isDescription,
              state.bsIds,
              state.isInvoiceType
            )
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              invoiceItemDelete: false,
            }))
          }
          isConfirmDisabled={state.isItemDeleting}
          positiveActionLabel={
            <>
              Delete
              {state.isItemDeleting && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {(state.addInvoiceItem || state.invoiceItemEdit) && (
        <AddInvoiceItemDialog
          open={state.addInvoiceItem || state.invoiceItemEdit}
          isEditing={state.invoiceItemEdit}
          handleClose={!state.addInvoiceItem ? handleEditClose : handleClose}
          customer={state.customer}
          location={state.location}
          // routeName={state.route}
          pdxCompany={state.pdxCompany?.pdxCompany}
          invoiceId={draftInvoice?.invoiceId}
          invoiceItemId={state.invoiceItemId}
          handleGetInvoice={handleGetInvoice}
          invoiceRowBeingEdited={state.invoiceRowBeingEdited}
          allRoutes={!state.entries?.invoiceDetails?.route_ids}
          selectedRoutes={state.selectedRoutes}
          invoiceDetails={state.entries?.invoiceDetails}
        />
      )}
      {state.addBillingAndSettlementInvoiceItemDialog && (
        <AddBillingAndSettlemntInvoiceItemDialog
          open={state.addBillingAndSettlementInvoiceItemDialog}
          isEditing={state.invoiceItemEdit}
          handleClose={handleBillingAndSettlemntCloseDialog}
          invoiceId={draftInvoice?.invoiceId}
          handleGetInvoice={handleGetInvoice}
        />
      )}
      {state.selectedDescriptionRow && (
        <UdateInvoiceItemDescriptionDialog
          open={state.openDescriptionDialog}
          onClose={handleDescriptionDialogClose}
          selectedRow={state.selectedDescriptionRow}
          handleGetInvoice={handleGetInvoice}
        />
      )}
      {state.addTypeDialog && (
        <AddInvoiceType
          open={state.addTypeDialog}
          onClose={handleInvoiceTypeDialog}
          isEditing={!!state.invoiceTypeRow}
          handleAddInvoiceType={handleAddInvoiceType}
          invoiceId={draftInvoice?.invoiceId}
          isLoading={state.isInvoiceTypeLoading}
          selectedRow={state.invoiceTypeRow}
        />
      )}
      {state.addLineItemDescription && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.dialogPaper,
          }}
          open={!!state.addLineItemDescription}
          contentText={
            <>
              <Typography variant="h6" color="primary" className="mt-2">
                {`${
                  state.invoiceDescriptionId ? "Edit" : "Add"
                } Inline Description Note`}
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="lineItemDescriptionNotes"
                label={`${state.invoiceDescriptionId ? "Edit" : "Add"} Note`}
                variant="outlined"
                size="small"
                value={state.lineItemDescriptionNotes}
                error={!!state.errors.lineItemDescriptionNotes?.trim()}
                helperText={state.errors.lineItemDescriptionNotes}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() =>
            state.invoiceDescriptionId
              ? handleInvoiceDescriptionNote(
                  draftInvoice?.invoiceId,
                  state.lineItemDescriptionNotes,
                  state.invoiceDescriptionId
                )
              : handleInvoiceDescriptionNote(
                  draftInvoice?.invoiceId,
                  state.lineItemDescriptionNotes
                )
          }
          onCancel={() => handleDescriptionNotesDialog(false)}
          isConfirmDisabled={
            state.isInvoiceDescriptionLoading ||
            !!state.errors.lineItemDescriptionNotes?.trim() ||
            !state.lineItemDescriptionNotes?.trim() ||
            !isFieldChange
          }
          positiveActionLabel={
            <>
              {state.invoiceDescriptionId ? "Edit" : "Add"}
              {state.isInvoiceDescriptionLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paperIframe }}
        >
          <DialogContent dividers>
            <iframe
              type="application/pdf"
              src={state.pdfUrl}
              title="Invoice PDF"
              width="1150px"
              height="700px"
              style={{ border: "none" }}
              // onLoad={handleIframeLoad}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {state.declineCreditDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.declineCreditDialog}
          contentText={
            <Typography variant="body2" color="error" className=" ml-2">
              {`Do you want to decline availed credit?`}
            </Typography>
          }
          onConfirm={() => {
            const invoiceDetails = {
              id: draftInvoice?.invoiceId,
              customer_notes: state.customerNotes,
              terms_and_condition: state.termsAndCondition,
              discount_method: state.isDiscountAmount ? "amount" : "percentage",
              terms: state.terms || 0,
              invoice_date: state.invoiceDate,
              bill_to: {
                customerName:
                  state.entries?.invoiceDetails?.bill_to?.customerName,
                branch: state.entries?.invoiceDetails?.bill_to?.branch,
                street_address: state.streetAddress,
                state: state.state,
                city: state.city,
                country: state.country,
                zip_code: state.zipCode,
                primary_contact: state.primary_contact,
              },
              ...(state.isDiscountAmount
                ? { discount_amt: state.discountAmount || 0 }
                : { discount: state.discount || 0 }),
              used_credit_amt: defaultState.usedCreditAmount,
            };

            saveInvoiceDetails(invoiceDetails);
            setState((prevState) => ({
              ...prevState,
              declineCreditDialog: false,
              isAvailableCredit: false,
            }));
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              declineCreditDialog: false,
            }));
          }}
          positiveActionLabel={
            <>
              Save{" "}
              {(state.isDataSaved || state.isFinalised) && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
          isConfirmDisabled={state.isDataSaved || state.isFinalised}
        />
      )}
      {state.deletDarftInvoice && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.deletPaperSize,
          }}
          open={!!state.deletDarftInvoice}
          contentText="Are you sure you want to delete?"
          onConfirm={() => handleDelete(state.deletDarftInvoice)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletDarftInvoice: null,
            }))
          }
          isConfirmDisabled={state.isInvoiceDeleteLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isInvoiceDeleteLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.memorizeInvoice && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.dialogPaper,
          }}
          open={!!state.memorizeInvoice}
          contentText={
            <>
              <Typography variant="h6" color="primary" className="mt-2">
                Memorize Invoice
              </Typography>
              <br></br>
              <TextField
                className="mb-1"
                fullWidth
                required
                name="memorizeInvoiceName"
                label="Add Name"
                variant="outlined"
                size="small"
                value={state.memorizeInvoiceName}
                error={!!state.errors.memorizeInvoiceName?.trim()}
                helperText={state.errors.memorizeInvoiceName}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() =>
            handleInvoiceMemorize(
              state.entries.invoiceDetails,
              state.memorizeInvoiceName
            )
          }
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              memorizeInvoice: false,
              isMemorizeInvoiceLoading: false,
              memorizeInvoiceName: defaultState.memorizeInvoiceName,
            }))
          }
          isConfirmDisabled={
            state.isMemorizeInvoiceLoading ||
            !!state.errors.memorizeInvoiceName?.trim() ||
            !state.memorizeInvoiceName?.trim() ||
            !isFieldChange
          }
          positiveActionLabel={
            <>
              Create
              {state.isMemorizeInvoiceLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>Cancel</>}
        />
      )}
    </>
  );
};

export default DraftInvoice;
