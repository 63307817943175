import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  Fragment,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  exportBase64ToFile,
  getFormattedTime,
  updatePageConfig,
  useOnScreen,
} from "utils";
import { Datepicker, FilterComponent, Grid } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Dropdown } from "shared/components";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { AppContext } from "shared/contexts";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { ROLE, RATE_TYPES } from "modules/shared/constants";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TuneIcon from "@material-ui/icons/Tune";
import { ActionDialog } from "shared/components";
import { APX_COMPANY, VALIDATIONS } from "shared/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { noop, PAGE_KEYS } from "shared/constants";
import { useLocation } from "react-router-dom";
import Service from "../service";
import SharedService from "../../shared/service";
import { InlineFieldForm } from "modules/shared/components";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PostAddIcon from "@material-ui/icons/PostAdd";
import WarningIcon from "@material-ui/icons/Warning";
import {
  getDateString,
  getTokenData,
  queryStringBuilderNew,
  getUTCDateString,
  getPageConfig,
  setPageConfig,
  validator,
} from "utils";
import useStyles from "./style";
import { Checkbox } from "@material-ui/core";
import IcForm from "modules/shared/components/ic-form";
import Form from "../form";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SingleCollapsibleGrid from "modules/shared/components/single-collapsible-grid";

const defaultState = {
  entries: [],
  totalEntries: 0,
  isFetching: false,
  isFetchingCautionFlagTypeList: false,
  isDownloadLoading: false,
  showAddLookupDialog: false,
  isLookupLoading: false,
  reviewNotesList: [],
  selectedRowId: 0,
  isFetchingList: {
    pdxCompany: false,
    reviewNotes: false,
    standByRateTypes: false,
  },
  standByRateTypeList: [],
  stateList: [],
  pdxCompanyList: [],
  doNotPayRowID: 0,
  doNotBillRowID: 0,
  dimensions: 0,
  doNotBillExtraMilesID: 0,
  doNotPayExtraMilesID: 0,
  doNotBillTollID: 0,
  doNotPayTollID: 0,
  confirmDontBill: false,
  confirmDontBillValue: false,
  confirmDontPay: false,
  confirmDontPayValue: false,
  confirmDontBillExtraMiles: false,
  confirmDontBillExtraMilesValue: false,
  confirmDontPayExtraMiles: false,
  confirmDontPayExtraMilesValue: false,
  confirmDontBillToll: false,
  confirmDontBillTollValue: false,
  confirmDontPayToll: false,
  confirmDontPayTollValue: false,
  priceMatrix: [],
  selectedRows: [],
  contractorList: [],
  AddReviewNote: false,
  errors: {
    notes: " ",
    reviewNotes: "",
  },
  notes: "",
  reviewNotes: "",
  locationList: [],
  customerlocationList: [],
  cautionFlagSubmitReasonList: [],
  scheduleDate: null,
  kam: null,
  manager: null,
  icDetails: {},
  showIcDetails: false,
  isIcFetching: false,
  selectedLocation: null,
  pageFilters: [],
  isFiltering: false,
  customer: null,
  editingNote: {},
  showConfirmationPopup: false,
  showRejectConfirmationPopup: false,
  testId: null,
  payQuantityExceedDaily: null,
  isRowOpen: false,
  clickedRowId: null,
  fetching: {
    users: false,
  },
  rowBeingEdited: null,
  rowEntries: [],
  rowTotalEntries: [],
  isTableLoading: [],
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    price_type: {
      label: "Price Type",
      value: "price_type",
      isSelected: true,
    },
    dont_bill: {
      label: "Do Not Bill",
      value: "dont_bill",
      isSelected: true,
    },
    dont_pay: {
      label: "Do Not Pay",
      value: "dont_pay",
      isSelected: true,
    },
    dont_bill_miles: {
      label: "Do Not Bill Extra Miles",
      value: "dont_bill_miles",
      isSelected: true,
    },
    dont_pay_miles: {
      label: "Do Not Pay Extra Miles",
      value: "dont_pay_miles",
      isSelected: true,
    },
    dont_bill_tolls: {
      label: "Do Not Bill Toll",
      value: "dont_bill_tolls",
      isSelected: true,
    },
    dont_pay_tolls: {
      label: "Do Not Pay Toll",
      value: "dont_pay_tolls",
      isSelected: true,
    },
    review_reason: {
      label: "Review Reason",
      value: "review_reason",
      isSelected: true,
    },
    kam_notes: {
      label: "KAM Notes",
      value: "kam_notes",
      isSelected: true,
    },
    review_notes: {
      label: "Review Notes",
      value: "review_notes",
      isSelected: true,
    },
    date: { label: "Schedule Date", value: "date", isSelected: true },
    kam: {
      label: "KAM",
      value: "kam",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },
    WCP_Driver: {
      label: "WCP-Driver",
      value: "WCP_Driver",
      isSelected: true,
    },

    ds_pay_rate_type: {
      label: "DS Pay Rate Type",
      value: "ds_pay_rate_type",
      isSelected: true,
    },

    br_pay_rate_type: {
      label: "BR Pay Rate Type",
      value: "br_pay_rate_type",
      isSelected: true,
    },
    ds_pay_rate: {
      label: "DS Pay Rate",
      value: "ds_pay_rate",
      isSelected: true,
    },
    br_pay_rate: {
      label: "BR Pay Rate",
      value: "br_pay_rate",
      isSelected: true,
    },

    ds_pay_quantity: {
      label: "DS Pay Quantity",
      value: "ds_pay_quantity",
      isSelected: true,
    },
    br_pay_quantity: {
      label: "BR Pay Quantity",
      value: "br_pay_quantity",
      isSelected: true,
    },
    org_bill_toll_amount: {
      label: "Original Bill Toll Amount",
      value: "org_bill_toll_amount",
      isSelected: true,
    },
    updated_bill_toll_amount: {
      label: "Updated Bill Toll Amount",
      value: "updated_bill_toll_amount",
      isSelected: true,
    },
    ds_toll_amount: {
      label: "DS Pay Toll Amount",
      value: "ds_toll_amount",
      isSelected: true,
    },
    br_toll_amount: {
      label: "BR Pay Toll Amount",
      value: "br_toll_amount",
      isSelected: true,
    },
    pay_extra_miles: {
      label: "DS Pay Extra Miles",
      value: "pay_extra_miles",
      isSelected: true,
    },
    reimbursement: {
      label: "Incentive",
      value: "reimbursement",
      isSelected: true,
    },
    submitted_by: {
      label: "Submitted By",
      value: "submitted_by",
      isSelected: true,
    },
    updated_by: {
      label: "Last Updated By",
      value: "updated_by",
      isSelected: true,
    },
    updated_at: {
      label: "Last Updated At",
      value: "updated_at",
      isSelected: true,
    },
  },
};

let gridHelper = null;

const ViewDailyScheduleReview = ({ match, history }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const isRoleView = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const actionRef = useRef(null);
  const isActionBarOnScreen = useOnScreen(actionRef);
  const pageConfig = getPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW);
  const isAdminRole = (userData.role || "").toLowerCase() === ROLE.ADMIN;
  const location = useLocation();
  const data = location.state || {};
  const { appData, updateContextData } = useContext(AppContext);
  const isRoleMGR = (userData?.role || "").toLowerCase() === ROLE.MGR;
  const wrapRef1 = useRef(null);
  const selectedRef = useRef(null);
  const tableHeadRef = useRef(null);
  const gridRefs = useRef({});

  const handleRowOpen = (value) =>
    setState((prevState) => ({
      ...prevState,
      isRowOpen: value || defaultState.isRowOpen,
    }));

  const handleScroll = (event) => {
    const targetDiv = event.target;

    if (wrapRef1.current) {
      if (targetDiv === wrapRef1.current) {
        syncChildGridsScroll(targetDiv.scrollLeft);
      } else {
        syncParentScroll(targetDiv.scrollLeft);
      }
    }
  };

  const syncChildGridsScroll = (scrollLeft) => {
    Object.values(gridRefs.current).forEach((grid) => {
      if (grid) {
        grid.scrollLeft = scrollLeft;
      }
    });
  };

  const syncParentScroll = (scrollLeft) => {
    if (wrapRef1.current) {
      wrapRef1.current.scrollLeft = scrollLeft;
    }
  };

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const IC = appData.IC;
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        mgr: [],
      }
    );

    return {
      customers,
      users,
      IC,
      icList,
    };
  }, [appData]);
  // const [state, setState] = useState({
  //   ...defaultState,
  //   scheduleDate: getDateString(match.params.schedule_date || new Date()),
  // });

  const fetchMasterIcList = async (id) => {
    let queryString = `?filter[where][and][0][or][0][master_ic_id]=${id}`;

    const { data, error } = await SharedService.getIcList(queryString);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      contractorList:
        data?.contractors?.filter((item) => item.id !== id) ||
        defaultState.contractorList,
    }));
  };

  useEffect(() => {
    if (appDataList?.users?.kam?.length && data?.kam_id) {
      const kam = appDataList?.users?.kam?.find((kam) => kam.id == data.kam_id);
      //  const customer = appDataList.customers?.find((customer)=>customer.id === data.customer_id)

      setState((prevState) => ({
        ...prevState,
        kam,
        // customer,
      }));
      window.history.replaceState(null, document.title);
      history.replace({ ...history.location, state: null });
    }
  }, [appDataList.users?.kam]);

  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    customer:
      (pageConfig && pageConfig?.customerFilter) || defaultState.customer,
    clickedRowId:
      (pageConfig && pageConfig?.clickedRowId) || defaultState.clickedRowId,
    selectedLocation:
      (pageConfig && pageConfig?.locationFilter) ||
      defaultState.selectedLocation,
    kam: (pageConfig && pageConfig?.kamFilter) || defaultState.kam,
    manager: !isRoleMGR
      ? (pageConfig && pageConfig?.managerFilter) || defaultState.manager
      : !!pageConfig?.managerFilter
      ? pageConfig?.managerFilter
      : userData,
    scheduleDate: data?.schedule_date
      ? getDateString(data.schedule_date)
      : (pageConfig && pageConfig?.scheduleDateFilter) || null,
    testId: data.record_id || defaultState.testId,
  });

  useEffect(() => {
    if (tableHeadRef.current) {
      const currentDimensions =
        tableHeadRef.current.clientWidth + tableHeadRef.current.clientHeight;

      setState((prevState) => {
        if (currentDimensions > prevState.dimensions) {
          return {
            ...prevState,
            dimensions: currentDimensions,
          };
        }
        return prevState;
      });
    }
  }, [state.isRowOpen, state.rowEntries]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (selectedRef.current && !!state.clickedRowId) {
        setState((prevState) => ({
          ...prevState,
          isRowOpen: [0],
        }));

        requestAnimationFrame(() => {
          setTimeout(() => {
            selectedRef.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });

            const rowElement = selectedRef.current;
            const rowOffsetTop = rowElement.offsetTop;
            window.scrollTo({
              top: rowOffsetTop,
              behavior: "smooth",
            });

            observer.disconnect();
          }, 0);
        });
      }
    });

    if (document.body) {
      observer.observe(document.body, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
        clickedRowId: null,
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const quantityExceedColumnConfig = useMemo(
    () => [
      {
        id: "schedule_date",
        label: "Schedule Date",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          const scheduleDate = getUTCDateString(row.schedule_date);
          return (
            <Tooltip title={scheduleDate || ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {scheduleDate || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "customer",
        label: "Customer Name",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.customer} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "location",
        label: "Location",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.location} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.location}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "route",
        label: "Route",
        headerClassName: classes.tableHeaderCell,
        render: (row) => {
          return (
            <Tooltip title={row.route} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.route}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const filterConfig = useMemo(
    () => [
      // {
      //   field: "location",
      //   fieldToDisplay: "Location",
      //   operatorType: "string",
      //   options: Object.entries(state.customerlocationList)
      //     .map(([state, value]) =>
      //       value.map((item) => ({
      //         label: `${item}-(${state})`,
      //         value: item,
      //       }))
      //     )
      //     .flat()
      //     .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      // },
      {
        field: "route_name",
        fieldToDisplay: "Route",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList?.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      {
        field: "price_type",
        fieldToDisplay: "Price Type",
        operatorType: "string",
        options: [
          { label: "Standard", value: "Standard" },
          { label: "Price Matrix", value: "Matrix" },
        ],
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "vehicle_type",
        fieldToDisplay: "Vehicle",
        operatorType: "string",
        options: state.vehicleTypeList?.map((vehicle) => ({
          ...vehicle,
          label: `${vehicle.value}`,
          value: vehicle.value,
        })),
      },
      {
        field: "ic_name",
        fieldToDisplay: "ICID/IC Name",
        operatorType: "string",
        options: appDataList.icList.map((ic) => ({
          ...ic,
          label: `${ic.icid}/${ic.last_name || ""} ${ic.first_name || ""}`,
          value: `${ic.icid}/${ic.last_name || ""} ${ic.first_name || ""}`,
        })),
      },
      {
        field: "driver_name",
        fieldToDisplay: "WCP-Driver",
        operatorType: "contain",
      },
      {
        field: "DS_pay_rate_type",
        fieldToDisplay: "DS Pay Rate Type",
        operatorType: "string",
        options: Array.from(
          new Map(
            [
              { label: "Daily", value: "Daily" },
              { label: "Hourly", value: "Hourly" },
              { label: "Per Miles", value: "Per Miles" },
              { label: "Per Tire", value: "Per Tire" },
              { label: "Per Run", value: "Per Run" },
              ...state.standByRateTypeList?.map((standRateType) => ({
                label: standRateType.value,
                value: standRateType.value,
              })),
            ].map((item) => [item.value, item])
          ).values()
        ),
      },
      {
        field: "BR_pay_rate_type",
        fieldToDisplay: "BR Pay Rate Type",
        operatorType: "string",
        options: Array.from(
          new Map(
            [
              { label: "Daily", value: "Daily" },
              { label: "Hourly", value: "Hourly" },
              { label: "Per Miles", value: "Per Miles" },
              { label: "Per Tire", value: "Per Tire" },
              { label: "Per Run", value: "Per Run" },
              ...state.standByRateTypeList?.map((standRateType) => ({
                label: standRateType.value,
                value: standRateType.value,
              })),
            ].map((item) => [item.value, item])
          ).values()
        ),
      },
      {
        field: "DS_pay_rate",
        fieldToDisplay: "DS Pay Rate",
        operatorType: "number",
      },
      {
        field: "BR_pay_rate",
        fieldToDisplay: "BR Pay Rate",
        operatorType: "number",
      },
      {
        field: "DS_pay_quantity",
        fieldToDisplay: "DS Pay Quantity",
        operatorType: "number",
      },
      {
        field: "BR_pay_quantity",
        fieldToDisplay: "BR Pay Quantity",
        operatorType: "number",
      },
      {
        field: "DS_toll_amount",
        fieldToDisplay: "DS Pay Toll Amount",
        operatorType: "number",
      },
      {
        field: "BR_toll_amount",
        fieldToDisplay: "BR Pay Toll Amount",
        operatorType: "number",
      },
      {
        field: "DS_extra_miles",
        fieldToDisplay: "DS Pay Extra Miles",
        operatorType: "number",
      },
      {
        field: "DS_reimbursement",
        fieldToDisplay: "Incentive",
        operatorType: "number",
      },
      {
        field: "DS_submitted_by",
        fieldToDisplay: "Submitted By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "last_updated_by",
        fieldToDisplay: "Last Updated By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "last_updated_at",
        fieldToDisplay: "Last Updated At",
        operatorType: "range",
        isDateField: true,
      },
    ],
    [
      state.vehicleTypeList,
      appDataList.icList,
      state.customerlocationList,
      state.stateList,
      state.pdxCompanyList,
      appDataList?.customers,
      appDataList?.icList,
    ]
  );

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };
  const fetchReviewNotesList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: true,
      },
    }));

    const { data, error } = await SharedService.getReviewNotesList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          reviewNotes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      reviewNotesList:
        data?.rows.filter((reviewNotes) => reviewNotes.is_active) ||
        defaultState.reviewNotesList,
      isFetchingList: {
        ...prevState.isFetchingList,
        reviewNotes: false,
      },
    }));
  };

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const fetchStandByRateTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: true,
      },
    }));
    const { data, error } = await SharedService.getstandByRateTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          standByRateTypes: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      standByRateTypeList:
        data?.rows.filter((standByRateType) => standByRateType.is_active) ||
        defaultState.standByRateTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        standByRateTypes: false,
      },
    }));
  };

  const fetchList = async (listType = "users", callback = noop, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      fetching: {
        ...prevState.fetching,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        fetching: {
          ...prevState.fetching,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((rowA, rowB) => {
            const valueA = (rowA?.name || "").toLowerCase();
            const valueB = (rowB?.name || "").toLowerCase();
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          });
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      fetching: {
        ...prevState.fetching,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchEntries = useCallback(
    async (
      scheduleDate,
      kam,
      location,
      manager,
      customer,
      hasLoader = false,
      pageFilters = []
    ) => {
      const filters = [
        ...pageFilters.map((filter) => {
          if (filter?.field === "last_updated_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "last_updated_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "last_updated_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (
            filter?.field === "last_updated_at" &&
            filter?.type === "ra"
          ) {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "last_updated_at",
                type: "gte",
                value: `${startDate}  00:00:00`,
              },
              {
                ...filter,
                field: "last_updated_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else {
            return {
              ...filter,
              value: filter?.value?.value || filter?.value,
            };
          }
        }),
      ].flat();
      scheduleDate &&
        filters.push({
          field: "schedule_date",
          type: "=",
          value: scheduleDate,
        });
      kam && filters.push({ field: "kam_id", type: "=", value: kam.id });
      manager &&
        filters.push({ field: "mgr_id", type: "=", value: manager.id });
      customer &&
        filters.push({
          field: "customer_name",
          type: "=",
          value: customer.name,
        });
      location &&
        filters.push({
          field: "location",
          type: "=",
          value: location.value,
        });

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, false);
      if (queryString.includes("last_updated_at_gte")) {
        queryString = queryString.replace(
          /last_updated_at_gte/g,
          "last_updated_at"
        );
      }
      setState((prevState) => ({ ...prevState, isFetching: true }));
      const { data, error } = await Service.getCustomerList(queryString);
      if (error) {
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
        setState((prevState) => ({ ...prevState, isFetching: false }));
      } else {
        const sortCustomerLocations = (data) => {
          return data.customerList.sort((a, b) =>
            a.customer.customer_name.localeCompare(b.customer.customer_name)
          );
        };

        const sortedCustomerLocations = sortCustomerLocations(data);

        setState((prevState) => ({
          ...prevState,
          customerEntries:
            sortedCustomerLocations || defaultState.customerEntries,
          isFetching: false,
        }));
      }
      gridHelper && gridHelper.toggleAll(false);
    },
    []
  );

  const fetchRowEntries = useCallback(
    async (
      scheduleDate,
      kam,
      location,
      manager,
      customer,
      hasLoader = false,
      pageFilters = []
    ) => {
      const filters = [
        ...pageFilters.map((filter) => {
          if (filter?.field === "last_updated_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "last_updated_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "last_updated_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (
            filter?.field === "last_updated_at" &&
            filter?.type === "ra"
          ) {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "last_updated_at",
                type: "gte",
                value: `${startDate}  00:00:00`,
              },
              {
                ...filter,
                field: "last_updated_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else {
            return {
              ...filter,
              value: filter?.value?.value || filter?.value,
            };
          }
        }),
      ].flat();
      scheduleDate &&
        filters.push({
          field: "schedule_date",
          type: "=",
          value: scheduleDate,
        });
      kam && filters.push({ field: "kam_id", type: "=", value: kam.id });
      manager &&
        filters.push({ field: "mgr_id", type: "=", value: manager.id });
      customer &&
        filters.push({
          field: "customer_name",
          type: "=",
          value: customer,
        });
      location &&
        filters.push({
          field: "location",
          type: "=",
          value: location.value,
        });

      let queryString = queryStringBuilderNew(0, 0, [], filters, {}, false);
      if (queryString.includes("last_updated_at_gte")) {
        queryString = queryString.replace(
          /last_updated_at_gte/g,
          "last_updated_at"
        );
      }
      // setState((prevState) => ({ ...prevState, isFetching: true }));
      setState((prevState) => ({
        ...prevState,
        isTableLoading: {
          ...prevState.isTableLoading,
          [customer]: hasLoader,
        },
      }));
      const { data, error } = await Service.get(queryString);
      if (error) {
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
        // setState((prevState) => ({ ...prevState, isFetching: false }));
        setState((prevState) => ({
          ...prevState,
          isTableLoading: {
            ...prevState.isTableLoading,
            [customer]: false,
          },
        }));
      } else {
        const sortedEntries = (data.rows || defaultState.entries).sort(
          (rowA, rowB) => {
            const rowAcustomerName = (
              rowA?.daily_schedule?.customer_branch?.customer?.name || ""
            ).toLowerCase();
            const rowBcustomerName = (
              rowB?.daily_schedule?.customer_branch?.customer?.name || ""
            ).toLowerCase();
            return Intl.Collator().compare(rowAcustomerName, rowBcustomerName);
          }
        );

        const updatedEntries = sortedEntries.map((entry) => {
          entry.initialReviewNotes = entry.daily_schedule.review_notes;
          return entry;
        });

        setState((prevState) => ({
          ...prevState,
          entries: [...prevState.entries, ...updatedEntries],
          totalEntries: [...prevState.entries, ...updatedEntries].length,
          rowEntries: {
            ...prevState.rowEntries,
            [customer]: updatedEntries,
          },
          rowTotalEntries: {
            ...prevState.rowTotalEntries,
            [customer]: data.count || defaultState.rowTotalEntries,
          },
          isTableLoading: {
            ...prevState.isTableLoading,
            [customer]: false,
          },
        }));
      }
    },
    []
  );

  const getPriceMatrix = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingPriceMatrix: true,
    }));
    const { data, error } = await Service.priceMatrix();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingPriceMatrix: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingPriceMatrix: false,
      priceMatrix: data.rows || defaultState.priceMatrix,
    }));
  }, []);

  useEffect(() => {
    getPriceMatrix();
  }, []);

  const locationOPtions = Object.entries(state.customerlocationList)
    .map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const handleConfirmDontBill = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBill: true,
      confirmDontBillValue: isCheckBoxChecked,
    }));
  };

  const calculateBill = (rowID, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID) {
          entry.bill_toll_amount = 0;
          entry.daily_schedule.dont_bill = isCheckBoxChecked;
          entry.daily_schedule.dont_bill_notes = null;
          entry.daily_schedule.dont_bill_tolls = false;
          entry.daily_schedule.dont_bill_miles = false;
          entry.daily_schedule.dont_bill_tolls_notes = null;
          entry.daily_schedule.dont_bill_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };

  const handleConfirmDontPay = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPay: true,
      confirmDontPayValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontBillExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillExtraMiles: isCheckBoxChecked,
      confirmDontBillExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPayExtraMiles = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayExtraMiles: isCheckBoxChecked,
      confirmDontPayExtraMilesValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontBillToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontBillToll: isCheckBoxChecked,
      confirmDontBillTollValue: isCheckBoxChecked,
    }));
  };

  const handleConfirmDontPayToll = (isCheckBoxChecked = false) => {
    setState((prevState) => ({
      ...prevState,
      confirmDontPayToll: isCheckBoxChecked,
      confirmDontPayTollValue: isCheckBoxChecked,
    }));
  };

  const calculatePay = (rowID, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID) {
          entry.daily_schedule.pay_rate = "0.00";
          entry.daily_schedule.pay_toll_amount = 0;
          entry.daily_schedule.pay_extra_miles = 0;
          entry.daily_schedule.dont_pay = isCheckBoxChecked;
          entry.daily_schedule.dont_pay_notes = null;
          entry.daily_schedule.dont_pay_tolls = false;
          entry.daily_schedule.dont_pay_miles = false;
          entry.daily_schedule.dont_pay_tolls_notes = null;
          entry.daily_schedule.dont_pay_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculateBillToll = (rowID, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID) {
          entry.bill_toll_amount = 0;
          entry.daily_schedule.dont_bill_tolls = isCheckBoxChecked;
          entry.daily_schedule.dont_bill_tolls_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculatePayToll = (rowID, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID) {
          entry.daily_schedule.pay_toll_amount = 0;
          entry.daily_schedule.dont_pay_tolls = isCheckBoxChecked;
          entry.daily_schedule.dont_pay_tolls_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculateBillMiles = (rowID, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID) {
          entry.daily_schedule.dont_bill_miles = isCheckBoxChecked;
          entry.daily_schedule.dont_bill_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };
  const calculatePayMiles = (rowID, isCheckBoxChecked = false) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === rowID) {
          entry.daily_schedule.pay_extra_miles = 0;
          entry.daily_schedule.dont_pay_miles = isCheckBoxChecked;
          entry.daily_schedule.dont_pay_miles_notes = null;
        }
        return entry;
      });
      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };

  const handleDownload = async (
    scheduleDate,
    kam,
    location,
    manager,
    customer,
    hasLoader = false,
    pageFilters = []
  ) => {
    const filters = [
      ...pageFilters.map((filter) => {
        if (filter?.field === "last_updated_at" && filter?.type === "eq") {
          return [
            {
              ...filter,
              field: "last_updated_at",
              type: "lte",
              value: `${filter?.value} 23:59:59.999999`,
            },
            {
              ...filter,
              field: "last_updated_at_gte",
              type: "gte",
              value: `${filter?.value} 00:00:00`,
            },
          ];
        } else if (
          filter?.field === "last_updated_at" &&
          filter?.type === "ra"
        ) {
          const [startDate, endDate] = filter?.value.split(" - ");
          return [
            {
              ...filter,
              field: "last_updated_at",
              type: "gte",
              value: `${startDate}  00:00:00`,
            },
            {
              ...filter,
              field: "last_updated_at_gte",
              type: "lte",
              value: `${endDate} 23:59:59.999999`,
            },
          ];
        } else {
          return {
            ...filter,
            value: filter?.value?.value || filter?.value,
          };
        }
      }),
    ].flat();
    scheduleDate &&
      filters.push({
        field: "schedule_date",
        type: "=",
        value: scheduleDate,
      });
    kam && filters.push({ field: "kam_id", type: "=", value: kam.id });
    manager && filters.push({ field: "mgr_id", type: "=", value: manager.id });
    customer &&
      filters.push({
        field: "customer_name",
        type: "=",
        value: customer.name,
      });
    location &&
      filters.push({
        field: "location",
        type: "=",
        value: location.value,
      });

    let queryString = queryStringBuilderNew(0, 0, [], filters, {}, false);
    if (queryString.includes("last_updated_at_gte")) {
      queryString = queryString.replace(
        /last_updated_at_gte/g,
        "last_updated_at"
      );
    }
    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));
    const { data, error } = await Service.download(queryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success("Manager Review records downloaded successfully.");
      exportBase64ToFile(data, "Manager Review");
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const newReviewNotes = state.entries.find(
    (entry) => entry.id === state.selectedRowId
  );

  const addLookupValue = async () => {
    setState((prevState) => ({ ...prevState, isLookupLoading: true }));

    const payload = {
      type: "Review Notes",
      value:
        state.notes ||
        (newReviewNotes ? newReviewNotes.daily_schedule.sharedNotes : ""),
      is_active: true,
      description: "hii",
    };

    const { error } = await Service.addNotesInLookup(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLookupLoading: false }));
      if (error.message === "This record already exists.") {
        error.message = "This Value already exists in Lookup(Review Notes).";
      }
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Value added in Lookup(Review Notes) successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLookupLoading: false,
      showAddLookupDialog: false,
    }));
  };
  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
      filters: pageFilters,
    });
  }, []);

  const handleApprove = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const payloadEntries = state.entries.filter((entry) =>
      state.selectedRows.includes(entry.id)
    );

    const found = payloadEntries.some(
      (entry) => entry.daily_schedule.review_notes?.trim().length > 0
    );
    if (!found) {
      toast.error("All selected entries must contain notes.");
      setState((prevState) => ({
        ...prevState,
        selectedRows: state.rowBeingEdited ? prevState.selectedRows : [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      return;
    }
    const payload = payloadEntries.map((entry) => ({
      id: entry.id,
      kam_notes: entry.daily_schedule.kam_notes,
      review_notes:
        entry.daily_schedule?.sharedNotes === undefined
          ? ""
          : entry.daily_schedule.sharedNotes,
      bill_rate: entry.daily_schedule.bill_rate,
      bill_rate_type: entry.daily_schedule.bill_rate_type,
      dont_bill: entry.daily_schedule.dont_bill,
      dont_bill_notes: entry.daily_schedule.dont_bill_notes,
      dont_pay_notes: entry.daily_schedule.dont_pay_notes,
      dont_pay: entry.daily_schedule.dont_pay,
      bill_toll_amount: entry.bill_toll_amount,
      dont_pay_tolls: entry.daily_schedule.dont_pay_tolls,
      dont_pay_miles: entry.daily_schedule.dont_pay_miles,
      dont_bill_tolls: entry.daily_schedule.dont_bill_tolls,
      reimbursement: entry.daily_schedule.reimbursement,
      dont_bill_miles: entry.daily_schedule.dont_bill_miles,
      dont_bill_tolls_notes: entry.daily_schedule.dont_bill_tolls_notes,
      dont_bill_miles_notes: entry.daily_schedule.dont_bill_miles_notes,
      dont_pay_tolls_notes: entry.daily_schedule.dont_pay_tolls_notes,
      dont_pay_miles_notes: entry.daily_schedule.dont_pay_miles_notes,
      bill_quantity: entry.daily_schedule.bill_quantity,
      pay_rate: entry.daily_schedule.pay_rate,
      pay_rate_type: entry.daily_schedule.pay_rate_type,
      pay_quantity: entry.daily_schedule.pay_quantity,
      pay_toll_amount: entry.daily_schedule.pay_toll_amount,
    }));

    const { data, error } = await Service.approve(payload);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        selectedRows: state.rowBeingEdited ? prevState.selectedRows : [],
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    } else {
      toast.success("Approved successfully.");

      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        rowBeingEdited: null,
        isLoading: false,
        showConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      fetchEntries(
        state.scheduleDate,
        state.kam,
        state.selectedLocation,
        state.manager,
        state.customer,
        true,
        state.pageFilters
      );
    }
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      reviewNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt, value) => {
    const field = "notes";
    let inputValue = evt.currentTarget?.value || evt.target?.value;

    if (value) {
      inputValue = value;
    }

    const errorMessage = validate(field, inputValue) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: inputValue.value === undefined ? inputValue : inputValue.value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };
  const handleShowAddLookupDialog = (id) => {
    setState((prevState) => ({
      ...prevState,
      showAddLookupDialog: true,
      selectedRowId: id,
    }));
  };
  const handleReject = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const payloadEntries = state.entries.filter((entry) =>
      state.selectedRows.includes(entry.id)
    );

    const found = payloadEntries.some(
      (entry) => entry.daily_schedule.review_notes?.trim().length > 0
    );
    if (!found) {
      toast.error("All selected entries must contain notes.");
      setState((prevState) => ({
        ...prevState,
        selectedRows: state.rowBeingEdited ? prevState.selectedRows : [],
        isLoading: false,
        showRejectConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      return;
    }

    const payload = payloadEntries.map((entry) => ({
      id: entry.id,
      kam_notes: entry.daily_schedule.kam_notes,
      dont_bill: entry.daily_schedule.dont_bill,
      dont_bill_notes: entry.daily_schedule.dont_bill_notes,
      bill_toll_amount: entry.bill_toll_amount,
      dont_pay_tolls: entry.daily_schedule.dont_pay_tolls,
      dont_pay_miles: entry.daily_schedule.dont_pay_miles,
      dont_bill_tolls: entry.daily_schedule.dont_bill_tolls,
      reimbursement: entry.daily_schedule.reimbursement,
      dont_bill_miles: entry.daily_schedule.dont_bill_miles,
      dont_bill_tolls_notes: entry.daily_schedule.dont_bill_tolls_notes,
      dont_bill_miles_notes: entry.daily_schedule.dont_bill_miles_notes,
      dont_pay_tolls_notes: entry.daily_schedule.dont_pay_tolls_notes,
      dont_pay_miles_notes: entry.daily_schedule.dont_pay_miles_notes,
      review_notes:
        entry.daily_schedule?.sharedNotes === undefined
          ? ""
          : entry.daily_schedule.sharedNotes,
    }));

    const { data, error } = await Service.reject(payload);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        selectedRows: state.rowBeingEdited ? prevState.selectedRows : [],
        isLoading: false,
        showRejectConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
    } else {
      toast.success("Rejected successfully.");
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
        rowBeingEdited: null,
        isLoading: false,
        showConfirmationPopup: false,
        showRejectConfirmationPopup: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      fetchEntries(
        state.scheduleDate,
        state.kam,
        state.selectedLocation,
        state.manager,
        state.customer,
        true,
        state.pageFilters
      );
    }
  };

  const renderGridActions = (callback, validate, isNote = true, updateNote) => {
    const isRowSelected = !!state.selectedRows.length;
    return [
      {
        label: "Reject",
        className: classes.rejectBtn,
        disabled: !isRowSelected || !isNote,
        onClick: () => {
          if (callback && !validate) callback();
          if (updateNote && validate) updateNote();
          setState((prevState) => ({
            ...prevState,
            showRejectConfirmationPopup: true,
          }));
        },
      },
      {
        label: "Approve",
        disabled: !isRowSelected || !isNote,
        onClick: async () => {
          if (callback && !validate) await callback();
          const exceededEntries = state.entries.filter(
            (data) =>
              state.selectedRows?.includes(data.id) &&
              data.daily_schedule.pay_rate_type === "Daily" &&
              +data.daily_schedule.pay_quantity > 1.99
          );

          if (exceededEntries.length > 0) {
            const locationDetails = exceededEntries.map((entry) => ({
              customer: entry.daily_schedule.customer_branch.customer.name,
              route: entry.daily_schedule.route,
              schedule_date: entry.schedule_date,
              location: entry.daily_schedule.customer_branch.location,
            }));

            setState((prevState) => ({
              ...prevState,
              payQuantityExceedDaily: locationDetails,
            }));

            return;
          }
          setState((prevState) => ({
            ...prevState,
            showConfirmationPopup: true,
          }));
        },
      },
    ].map((action, index) => (
      <Button
        key={index}
        variant="contained"
        color={action.color || "primary"}
        disabled={action.disabled}
        onClick={action.onClick}
        className={clsx(action.className, "ml-2 mr-2")}
      >
        {action.label}
      </Button>
    ));
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const getFormattedDate = (date) => {
    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const handleLabelClick = (row) => {
    if (!!row.daily_schedule?.individual_contractor?.icid) {
      fetchList(
        "IC",
        noop,
        `?filter[limit]=1&filter[where][icid]=${row.daily_schedule?.individual_contractor?.icid}`
      );
      setState((prevState) => ({
        ...prevState,
        showIcDetails: true,
      }));
    }
  };

  const columnConfig = [
    {
      id: "col-edit",
      noResize: true,
      headerClassName: classes.wrapTableAction,
      render: (row) => (
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className="ml-4 c-pointer"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: row,
                selectedRows: [row.id],
              }));
              gridHelper && gridHelper.resetSelection();
            }}
          />
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.price_type?.isSelected,
      id: "price_type",
      label: "Price Type",
      fieldName: "daily_schedule.routeDetails.price_type",
      headerClassName: classes.wrapTableTitle,
      render: (row) => {
        const routeDetails = row.daily_schedule?.routeDetails;
        const priceType = routeDetails?.price_type;
        const matrixName = routeDetails?.matrix_name;

        const titleTooltip =
          priceType === "Matrix"
            ? `Matrix Name: ${matrixName}`
            : priceType === "Standard"
            ? "Standard"
            : null;

        const title =
          priceType === "Matrix"
            ? "Price Matrix"
            : priceType === "Standard"
            ? "Standard"
            : null;

        return (
          <Tooltip title={titleTooltip ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill",
      label: "Do Not Bill",
      fieldName: "dont_bill",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.daily_schedule?.dont_bill ? (
                row.daily_schedule?.dont_bill_notes === null ||
                row.daily_schedule?.dont_bill_notes === "" ? (
                  "This record will not be included in billing"
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.daily_schedule?.dont_bill_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              color="primary"
              checked={row.daily_schedule?.dont_bill}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontBill(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotBillRowID: row.id,
                  }));
                } else {
                  calculateBill(row.id, evt.target.checked);
                }
              }}
              name="Bill"
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay",
      label: "Do Not Pay",
      fieldName: "dont_pay",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.daily_schedule?.dont_pay ? (
                row.daily_schedule?.dont_pay_notes === null ||
                row.daily_schedule?.dont_pay_notes === "" ? (
                  "This record will not be included in settlement"
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.daily_schedule?.dont_pay_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              color="primary"
              checked={row.daily_schedule?.dont_pay}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontPay(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotPayRowID: row.id,
                  }));
                } else {
                  calculatePay(row.id, evt.target.checked);
                }
              }}
              name="pay"
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_miles?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill_miles",
      label: "Do Not Bill Extra Miles",
      field: "dont_bill_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.daily_schedule.dont_bill_miles ? (
                row.daily_schedule?.dont_bill_miles_notes === null ||
                row.daily_schedule?.dont_bill_miles_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.daily_schedule.dont_bill_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              color="primary"
              checked={row.daily_schedule?.dont_bill_miles}
              disabled={!!row.daily_schedule?.dont_bill}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontBillExtraMiles(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotBillExtraMilesID: row.id,
                  }));
                } else {
                  calculateBillMiles(row.id, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_miles?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay_miles",
      label: "Do Not Pay Extra Miles",
      field: "dont_pay_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.daily_schedule.dont_pay_miles ? (
                row.daily_schedule?.dont_pay_miles_notes === null ||
                row.daily_schedule?.dont_pay_miles_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.daily_schedule.dont_pay_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              color="primary"
              checked={row.daily_schedule.dont_pay_miles}
              disabled={!!row.daily_schedule?.dont_pay}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontPayExtraMiles(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotPayExtraMilesID: row.id,
                  }));
                } else {
                  calculatePayMiles(row.id, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_tolls?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_bill_tolls",
      label: "Do Not Bill Toll",
      field: "dont_bill_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.daily_schedule.dont_bill_tolls ? (
                row.daily_schedule?.dont_bill_tolls_notes === null ||
                row.daily_schedule?.dont_bill_tolls_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.daily_schedule.dont_bill_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              color="primary"
              checked={row.daily_schedule.dont_bill_tolls}
              disabled={!!row.daily_schedule?.dont_bill}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontBillToll(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotBillTollID: row.id,
                  }));
                } else {
                  calculateBillToll(row.id, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_tolls?.isSelected,
      headerClassName: classes.wrapTableTitle,
      id: "dont_pay_tolls",
      label: "Do Not Pay Toll",
      field: "dont_pay_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.daily_schedule.dont_pay_tolls ? (
                row.daily_schedule?.dont_pay_tolls_notes === null ||
                row.daily_schedule?.dont_pay_tolls_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.daily_schedule.dont_pay_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              color="primary"
              checked={row.daily_schedule.dont_pay_tolls}
              disabled={!!row.daily_schedule?.dont_pay}
              onChange={(evt) => {
                if (evt.target.checked === true) {
                  handleConfirmDontPayToll(evt.target.checked);
                  setState((prevState) => ({
                    ...prevState,
                    doNotPayTollID: row.id,
                  }));
                } else {
                  calculatePayToll(row.id, evt.target.checked);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_reason?.isSelected,
      id: "daily_schedule.review_reason",
      label: "Review Reason",
      fieldName: "daily_schedule.review_reason",
      headerClassName: classes.tableHeaderNotesWidth,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.review_reason || ""} placement="top-start">
          <Typography variant="body2" noWrap>
            {row.review_reason || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.kam_notes?.isSelected,
      id: "daily_schedule.kam_notes",
      label: "KAM Notes",
      fieldName: "daily_schedule.kam_notes",
      canSort: true,
      headerClassName: classes.tableHeaderNotesWidth,
      render: (row, rowIndex) => (
        <Tooltip
          title={row.daily_schedule.kam_notes || "-"}
          placement="top-start"
          disableFocusListener
        >
          <Typography variant="body2" noWrap>
            {row.daily_schedule.kam_notes || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.review_notes?.isSelected,
      id: "review_notes",
      label: "Review Notes ",
      fieldName: "review_notes",
      headerClassName: classes.addNewreviewNotesHeaderCell,
      render: (row, rowIndex) => {
        let finalNotes;

        if (row.initialReviewNotes !== null && !row.review_notes?.length) {
          let splitNotes = row.initialReviewNotes?.split("||");

          finalNotes = splitNotes?.map((note) => {
            let str = note;

            const startIndexOfDate = str?.indexOf("-");
            const lastIndexOfDate = str?.indexOf(": ");
            const formattedDate = new Date(
                str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
              )?.toLocaleString(),
              str1 =
                str.substring(0, startIndexOfDate) +
                "-" +
                formattedDate +
                str.substring(lastIndexOfDate);
            return str1;
          });
        }

        const title = (
          <div
            dangerouslySetInnerHTML={{
              __html: (finalNotes || []).join("<br>"),
            }}
          />
        );

        let lastNote = !!finalNotes ? finalNotes[0] : "";
        let x = lastNote.split(": ");
        const userName = x[0].split("-")[0];
        const noteTime = x[0].split("-")[1]?.split(",");
        delete x[0];
        const message = x.join(": ");

        return (
          <div className="d-flex f-justify-between">
            <div style={{ width: 284 }}>
              {!finalNotes ? (
                <span>-</span>
              ) : !userName ? (
                "-"
              ) : (
                <Tooltip title={title || "-"} placement="top-start">
                  <div>
                    <Typography
                      style={{ display: "inline" }}
                      variant="caption"
                      className="text-bold"
                    >
                      {userName}{" "}
                      {/* {finalNotes?.join(" ").length > 80
                    ? finalNotes?.join(" ").slice(0, 80) + "..."
                    : finalNotes?.join(" ")} */}
                    </Typography>
                    <Typography variant="caption">{`${noteTime[0]}, ${noteTime[1]}:`}</Typography>
                    <Typography variant="body2">
                      {message.length > 50
                        ? message.slice(2, 50) + "..."
                        : message.slice(2, 100)}
                    </Typography>
                  </div>
                </Tooltip>
              )}{" "}
            </div>
            {!isRoleView && (
              <div className="d-flex" style={{ width: 290, gap: "6px" }}>
                <Autocomplete
                  required
                  fullWidth
                  size="small"
                  freeSolo
                  className={classes.addNewReviewNote}
                  options={state.reviewNotesList}
                  getOptionLabel={(option) => option.value}
                  disableClearable
                  inputValue={row.daily_schedule.sharedNotes || ""}
                  onInputChange={(event, value) => {
                    setState((prevState) => {
                      const updatedEntries = prevState.entries?.map((entry) => {
                        if (entry.id === row.id) {
                          entry.daily_schedule.review_notes = value || "";
                          entry.daily_schedule.sharedNotes = value || "";
                        }
                        return entry;
                      });
                      return {
                        ...prevState,
                        entries: updatedEntries,
                      };
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant="outlined"
                      label="Add New Review Note"
                      name="reviewNotes"
                      error={!!state.errors[row.id]?.trim()}
                      helperText={state.errors[row.id]}
                      onChange={(evt) => {
                        const field = evt.target.name;
                        const value = evt.target.value;
                        const errorMessage = validate(field, value) || "";

                        setState((prevState) => {
                          const updatedEntries = prevState.entries.map(
                            (entry) => {
                              if (entry.id === row.id) {
                                entry.daily_schedule.review_notes = value || "";
                                entry.daily_schedule.sharedNotes = value || "";
                              }
                              return entry;
                            }
                          );

                          return {
                            ...prevState,
                            errors: {
                              ...prevState.errors,
                              [row.id]: errorMessage,
                            },
                            entries: updatedEntries,
                          };
                        });
                      }}
                    />
                  )}
                />

                {!isRoleMGR && (
                  <Tooltip
                    title="Add To Lookup(Review Notes)"
                    placement="top-end"
                  >
                    <Button
                      type="submit"
                      onClick={() => handleShowAddLookupDialog(row.id)}
                      variant="contained"
                      color="primary"
                      disabled={
                        !row.daily_schedule.sharedNotes ||
                        state.errors?.[row.id]?.length > 1
                      }
                      className={classes.buttonStyle}
                    >
                      <PostAddIcon />
                    </Button>
                  </Tooltip>
                )}

                {/* <InlineFieldForm
                  name="review_notes"
                  showInputOnly
                  updateOnError
                  label="Add New Review Note"
                  // initialValue={row.daily_schedule.review_notes || ""}
                  validations={[{ type: VALIDATIONS.MAX_LENGTH, value: 200 }]}
                  onUpdate={(updatedData) => {
                    setState((prevState) => {
                      const updatedEntries = prevState.entries.map((entry) => {
                        if (entry.id === row.id) {
                          entry.daily_schedule.review_notes = (
                            updatedData.value || ""
                          ).trim();
                        }
                        return entry;
                      });

                      return {
                        ...prevState,
                        entries: updatedEntries,
                      };
                    });
                  }}
                /> */}
              </div>
            )}
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.date?.isSelected,
      id: "schedule_date",
      fieldName: "schedule_date",
      label: "Schedule Date",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip
          title={getFormattedDate(getUTCDateString(row.schedule_date)) || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {getFormattedDate(getUTCDateString(row.schedule_date))}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.kam?.isSelected,
      id: "kamUser.first_name",
      label: "KAM",
      fieldName: "kamUser.first_name",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => {
        const kamName =
          row.kamUser.first_name + " " + (row.kamUser.last_name || "");
        const title = row.kamUser?.username
          ? `${kamName}-(${row.kamUser?.username})`
          : "";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "daily_schedule.customer_branch.customer.name",
      fieldName: "daily_schedule.customer_branch.customer.name",
      label: "Customer Name",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={row.daily_schedule.customer_branch?.customer?.name || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.daily_schedule.customer_branch?.customer?.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "daily_schedule.customer_branch.location",
      label: "Location",
      fieldName: "daily_schedule.customer_branch.location",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => {
        // const title = `State: ${
        //   row.daily_schedule.customer_branch?.state?.name || "-"
        // }, PDX Company: ${
        //   row.daily_schedule.customer_branch?.pdxCompany?.value || "-"
        // }`;
        return (
          <Tooltip
            title={row.daily_schedule.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.daily_schedule.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "daily_schedule.routeDetails.name",
      label: "Route",
      fieldName: "daily_schedule.routeDetails.name",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip
          title={row.daily_schedule?.routeDetails?.name || "-"}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.daily_schedule?.routeDetails?.name || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "daily_schedule.customer_branch.state.name",
      label: "State",
      fieldName: "daily_schedule.customer_branch.state.name",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.daily_schedule.customer_branch?.state?.name ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.daily_schedule.customer_branch?.state?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "daily_schedule.customer_branch.pdxCompany.value",
      label: "PDX Company",
      fieldName: "daily_schedule.customer_branch.pdxCompany.value",
      headerClassName: classes.pdxCompanyHeader,
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.daily_schedule.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.daily_schedule.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "daily_schedule.vehicleType.value",
      label: "Vehicle",
      fieldName: "daily_schedule.vehicleType.value",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip
          title={row.daily_schedule.vehicleType?.value || "-"}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.daily_schedule.vehicleType?.value || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      id: "daily_schedule.individual_contractor.first_name",
      headerClassName: classes.icidFlagTitle,
      label: "ICID/IC Name",
      fieldName: "daily_schedule.individual_contractor.first_name",
      canSort: true,
      render: (row, rowIndex) => {
        let title = !!row.daily_schedule?.individual_contractor?.icid
          ? `
          IC Settlement Company Name: ${
            row.daily_schedule.individual_contractor?.settlementCompany
              ?.value || "-"
          }, 
          IC Name:${
            (row.daily_schedule?.individual_contractor?.first_name || "") +
            " " +
            (row.daily_schedule?.individual_contractor?.last_name || "")
          },
           ICID:${row.daily_schedule?.individual_contractor?.icid || ""},
          Contractor Type:${
            row.daily_schedule?.individual_contractor?.contractorType?.value ||
            ""
          },
         
        `
          : "";
        const label = row.daily_schedule.individual_contractor?.icid
          ? `${row.daily_schedule.individual_contractor?.icid || ""}/${
              row.daily_schedule.individual_contractor?.last_name || ""
            } ${row.daily_schedule.individual_contractor?.first_name || ""}`
          : "";

        const flagLabel =
          (!!row.daily_schedule?.individual_contractor?.is_flagged &&
            !!row.daily_schedule?.individual_contractor?.is_cautious_flagged) ||
          !!row.daily_schedule?.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.daily_schedule?.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );
        const cautionFlaggedBy = userInfo(
          row.daily_schedule?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row.daily_schedule?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.daily_schedule.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.daily_schedule.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.daily_schedule.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.daily_schedule.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.daily_schedule.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.daily_schedule.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;
        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title ?? ""} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    style={{
                      textDecoration:
                        !!row.daily_schedule?.individual_contractor?.icid &&
                        "underline",
                      cursor:
                        !!row.daily_schedule?.individual_contractor?.icid &&
                        "pointer",
                    }}
                    onClick={() => {
                      !!row.daily_schedule?.individual_contractor?.icid &&
                        handleLabelClick(row);
                    }}
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.WCP_Driver?.isSelected,
      id: "daily_schedule.driverContractor.first_name",
      label: "WCP-Driver",
      field: "WCP_Driver",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => {
        const dailyScheduleRow = row?.daily_schedule;
        const driver = dailyScheduleRow?.driverContractor;
        const driverName = driver
          ? ` ${driver?.first_name || ""} ${driver?.last_name || ""} (#${
              driver?.id
            })`
          : null;
        return (
          <Tooltip title={driverName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {driverName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ds_pay_rate_type?.isSelected,
      id: "daily_schedule.pay_rate_type",
      label: "DS Pay Rate Type",
      fieldName: "daily_schedule.pay_rate_type",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => (
        <InlineFieldForm
          dropdownLabel={row.daily_schedule.pay_rate_type}
          name="pay_rate_type"
          disabled={
            isRoleView ||
            row.daily_schedule?.routeDetails?.price_type === "Matrix"
          }
          enableFlip={true}
          disablePortal={false}
          initialValue={{ label: row.daily_schedule.pay_rate_type || "" }}
          type="dropdown"
          dropdownLabelClassName={
            row.base_roster !== null &&
            row.daily_schedule?.pay_rate_type !==
              row.base_roster?.pay_rate_type &&
            ((row.daily_schedule?.pay_rate_type === "Daily" &&
              row.daily_schedule?.pay_quantity > 1.99) ||
              row.daily_schedule?.pay_rate_type !== "Daily") &&
            "color-error"
          }
          options={
            row.daily_schedule?.individual_contractor?.contractorType?.value ===
            "Stand-by"
              ? appDataList.icList
                  ?.find(
                    (ele) =>
                      ele.id === row?.daily_schedule?.individual_contractor?.id
                  )
                  ?.StandByRates?.map((rate) => ({
                    value: rate,
                    label: rate?.rateType?.value,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))
              : RATE_TYPES.map((rateType) => ({
                  value: rateType,
                  label: rateType,
                }))
          }
          onUpdate={(updatedData) => {
            setState((prevState) => {
              const updatedEntries = prevState.entries.map((entry) => {
                if (entry.id === row.id) {
                  entry.daily_schedule.pay_rate_type =
                    updatedData?.value?.rateType?.value || updatedData?.value;
                  if (updatedData?.value?.rate_value) {
                    entry.daily_schedule.pay_rate =
                      updatedData?.value?.rate_value;
                  }
                }
                return entry;
              });
              return {
                ...prevState,
                entries: updatedEntries,
              };
            });
          }}
        />
      ),
    },
    {
      isHidden: !state.dynamicColumns?.br_pay_rate_type?.isSelected,
      id: "base_roster.pay_rate_type",
      label: "BR pay Rate Type",
      fieldName: "base_roster.pay_rate_type",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip
          title={row.base_roster?.pay_rate_type || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.base_roster !== null ? row.base_roster?.pay_rate_type : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.ds_pay_rate?.isSelected,
      id: "daily_schedule.pay_rate",
      label: "DS Pay Rate",
      fieldName: "daily_schedule.pay_rate",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row, rowIndex) => {
        let billPayPrice = [];
        if (row.daily_schedule?.routeDetails?.price_type === "Matrix") {
          billPayPrice = state.priceMatrix?.filter(
            (item) =>
              row.daily_schedule?.customer_branch?.id ===
                item?.customer_branch_id &&
              item.name === row?.daily_schedule?.routeDetails?.matrix_name
          );
        }

        return row.daily_schedule?.routeDetails?.price_type === "Matrix" ? (
          <InlineFieldForm
            dropdownLabel={
              row.daily_schedule?.pay_rate
                ? (() => {
                    const matchingBillOption = billPayPrice?.find(
                      (billOption) =>
                        billOption?.pay_price == row.daily_schedule?.pay_rate
                    );

                    if (matchingBillOption) {
                      return `$ ${row.daily_schedule?.pay_rate} (${matchingBillOption.quantity})`;
                    } else {
                      return `$ ${row.daily_schedule?.pay_rate}`;
                    }
                  })()
                : "-"
            }
            enableFlip={true}
            disablePortal={false}
            dropdownLabelClassName={
              row.base_roster !== null &&
              row.daily_schedule.pay_rate !== row.base_roster?.pay_rate &&
              "color-error"
            }
            dropdownPaperClass={!!row.daily_schedule?.dont_pay && "no-events"}
            name="pay_rate"
            startAdornment="$"
            disabled={
              isRoleView ||
              row.daily_schedule?.individual_contractor?.contractorType ===
                "Stand-by"
            }
            initialValue={row.daily_schedule?.pay_rate}
            type="dropdown"
            options={billPayPrice?.map((item) => ({
              value: item.pay_price,
              label: `${item.pay_price} (${item.quantity})`,
            }))}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id) {
                    entry.daily_schedule.pay_rate = updatedData.value;
                  }

                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        ) : (
          <InlineFieldForm
            autoSize
            labelClassName={
              row.base_roster !== null &&
              row.daily_schedule.pay_rate !== row.base_roster?.pay_rate &&
              "color-error"
            }
            disabled={
              isRoleView ||
              row.daily_schedule?.individual_contractor?.contractorType
                ?.value === "Stand-by" ||
              !!row.daily_schedule?.dont_pay
            }
            name="pay_rate"
            startAdornment="$"
            initialValue={row.daily_schedule.pay_rate}
            inputType="number"
            validations={[
              { type: VALIDATIONS.MIN, value: 0 },
              { type: VALIDATIONS.LIMIT, value: 10.2 },
            ]}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id) {
                    entry.daily_schedule.pay_rate = updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.br_pay_rate?.isSelected,
      id: "base_roster.pay_rate",
      label: "BR Pay Rate",
      fieldName: "base_roster.pay_rate",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.base_roster?.pay_rate || ""} placement="top-start">
          <Typography variant="body2" noWrap>
            {row.base_roster !== null ? "$" + row.base_roster?.pay_rate : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.ds_pay_quantity?.isSelected,
      id: "daily_schedule.pay_quantity",
      label: "DS Pay Quantity",
      fieldName: "daily_schedule.pay_quantity",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];

        if (row.daily_schedule?.pay_rate_type === "Hourly") {
          validations = [
            {
              type: VALIDATIONS.MAX,
              value:
                row.daily_schedule?.customer_branch &&
                row.daily_schedule.customer_branch?.pdxCompany?.value ===
                  APX_COMPANY
                  ? 10000
                  : 24,
            },
            ...validations,
          ];
        }
        if (row.daily_schedule?.pay_rate_type === "Daily") {
          validations = [
            {
              type: VALIDATIONS.DAILY_QUANTITY_LIMIT,
              value: 1.99,
            },
            ...validations,
          ];
        }
        return (
          <InlineFieldForm
            autoSize
            name="pay_quantity"
            labelClassName={
              row.base_roster !== null &&
              row.daily_schedule?.pay_rate_type !== "Per Tire" &&
              ((row.daily_schedule?.pay_rate_type === "Daily" &&
                row.daily_schedule?.pay_quantity > 1.99) ||
                (row.daily_schedule?.pay_rate_type !== "Daily" &&
                  row.daily_schedule?.pay_quantity !==
                    row.base_roster?.pay_quantity)) &&
              "color-error"
            }
            initialValue={row.daily_schedule.pay_quantity}
            inputType="number"
            validations={validations}
            disabled={
              isRoleView ||
              row.daily_schedule?.routeDetails?.price_type === "Matrix"
            }
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id) {
                    entry.daily_schedule.pay_quantity = updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.br_pay_quantity?.isSelected,
      id: "base_roster.pay_quantity",
      label: "BR Pay Quantity",
      fieldName: "base_roster.pay_quantity",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip
          title={row.base_roster?.pay_quantity || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.base_roster !== null ? row.base_roster?.pay_quantity : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.org_bill_toll_amount?.isSelected,
      id: "org_bill_toll_amount",
      label: "Original Bill Toll Amount",
      fieldName: "org_bill_toll_amount",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip title={row.org_bill_toll_amount ?? ""} placement="top-start">
          <Typography variant="body2" noWrap>
            {`$${row.org_bill_toll_amount}` || "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.updated_bill_toll_amount?.isSelected,
      id: "bill_toll_amount",
      label: "Updated Bill Toll Amount",
      fieldName: "bill_toll_amount",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];
        return (
          <InlineFieldForm
            autoSize
            name="bill_toll_amount"
            startAdornment="$"
            labelClassName={
              (row.bill_toll_amount != row.org_bill_toll_amount &&
                "color-error") ||
              (row.base_roster !== null &&
                row.bill_toll_amount > 999 &&
                "color-error")
            }
            disabled={
              !!row.daily_schedule?.dont_bill ||
              !!row.daily_schedule?.dont_bill_tolls
            }
            initialValue={row.bill_toll_amount}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id) {
                    entry.bill_toll_amount = updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ds_toll_amount?.isSelected,
      id: "daily_schedule.pay_toll_amount",
      label: "DS Pay Toll Amount",
      fieldName: "daily_schedule.pay_toll_amount",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row, rowIndex) => {
        let validations = [
          { type: VALIDATIONS.MIN, value: 0 },
          { type: VALIDATIONS.LIMIT, value: 10.2 },
        ];
        return (
          <InlineFieldForm
            autoSize
            name="pay_toll_amount"
            startAdornment="$"
            labelClassName={
              row.base_roster !== null &&
              row.daily_schedule.pay_toll_amount > 999 &&
              "color-error"
            }
            disabled={
              !!row.daily_schedule?.dont_pay ||
              !!row.daily_schedule?.dont_pay_tolls
            }
            initialValue={row.daily_schedule.pay_toll_amount}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id) {
                    entry.daily_schedule.pay_toll_amount = updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.br_toll_amount?.isSelected,
      id: "base_roster.pay_toll_amount",
      label: "BR Pay Toll Amount",
      fieldName: "base_roster.pay_toll_amount",
      canSort: true,
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip
          title={row.base_roster?.pay_toll_amount || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.base_roster !== null
              ? "$" + row.base_roster?.pay_toll_amount
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_miles?.isSelected,
      id: "daily_schedule.pay_extra_miles",
      label: "DS Pay Extra Miles",
      fieldName: "daily_schedule.pay_extra_miles",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => (
        <Tooltip
          title={row.daily_schedule?.pay_extra_miles || ""}
          placement="top-start"
        >
          <Typography variant="body2" noWrap>
            {row.daily_schedule !== null
              ? row.daily_schedule?.pay_extra_miles
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.reimbursement?.isSelected,
      id: "daily_schedule.reimbursement",
      label: "Incentive",
      fieldName: "daily_schedule.reimbursement",
      headerClassName: classes.wrapTableTitle,
      canSort: true,
      render: (row, rowIndex) => {
        let validations = [{ type: VALIDATIONS.MIN, value: 0 }];
        return (
          <InlineFieldForm
            autoSize
            name="reimbursement"
            startAdornment="$"
            labelClassName={
              !!row.daily_schedule?.reimbursement &&
              row.daily_schedule?.reimbursement != 0 &&
              "color-error"
            }
            initialValue={row.daily_schedule?.reimbursement || 0}
            inputType="number"
            validations={validations}
            onUpdate={(updatedData) => {
              setState((prevState) => {
                const updatedEntries = prevState.entries.map((entry) => {
                  if (entry.id === row.id && entry.keyType === row.keyType) {
                    entry.daily_schedule.reimbursement = updatedData.value;
                  }
                  return entry;
                });
                return {
                  ...prevState,
                  entries: updatedEntries,
                };
              });
            }}
          />
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.submitted_by?.isSelected,
      id: "daily_schedule.submitted_by",
      label: "Submitted By",
      fieldName: "daily_schedule.submitted_by",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => {
        const kam = appData.users.find(
          (ele) => ele?.id === row.daily_schedule?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }
        return (
          <Tooltip title={submittedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submittedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.updated_by?.isSelected,
      id: "daily_schedule.updated_by",
      label: "Last Updated By",
      field: "daily_schedule.updated_by",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => {
        const updatedBy = appData.users.find(
          (ele) => ele?.id === row?.daily_schedule?.updated_by || null
        );
        let user = "";
        if (updatedBy) {
          user = `${updatedBy.first_name} ${updatedBy.last_name || ""} - (${
            updatedBy.username
          })`;
        }
        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.updated_at?.isSelected,
      id: "daily_schedule.updated_at",
      label: "Last Updated At",
      fieldName: "daily_schedule.updated_at",
      headerClassName: classes.tableHeaderwidth,
      canSort: true,
      render: (row) => {
        const updatedAt = !!row.daily_schedule.updated_at
          ? `${getDateString(row.daily_schedule.updated_at)} ${getFormattedTime(
              new Date(row.daily_schedule.updated_at)
            )}`
          : "";
        return (
          <Tooltip title={updatedAt ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {updatedAt || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const renderGridActionTab = (isBottomNav = false) => {
    return (
      <div
        className={clsx("w-100 d-flex f-align-center", {
          "f-justify-between": !isBottomNav,
          "f-justify-center": isBottomNav,
        })}
      >
        {!isRoleView && <div>{renderGridActions()}</div>}

        <div>
          {" "}
          <Dropdown
            classes={{
              paper: classes.tunePaper,
            }}
            disablePortal={false}
            remainOpen
            isMultiSelect
            placement={isBottomNav ? "top-end" : "bottom-end"}
            options={Object.keys(state.dynamicColumns).map(
              (key) => state.dynamicColumns[key]
            )}
            customToggle={({ anchorRef, onClick }) => (
              <Tooltip title="Show/Hide column(s)" placement="top-start">
                <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            )}
            onChange={(options) => {
              const isSelectAll = options.some(
                (item) => item.value === "select_all"
              );
              const isCurrentSelectAll =
                state.dynamicColumns?.select_all?.isSelected;

              const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

              const isAllOptionSelected =
                Object.keys(state.dynamicColumns).length - 1 ===
                options.filter((item) => item.value !== "select_all").length;
              const updatedDynamicColumns = Object.keys(
                state.dynamicColumns
              ).reduce((acc, key) => {
                const isSelected = isClickedOnSelectAll
                  ? isSelectAll
                  : key === "select_all"
                  ? isAllOptionSelected
                  : !!options.some((item) => item.value === key);
                return {
                  ...acc,
                  [key]: {
                    ...state.dynamicColumns[key],
                    isSelected,
                  },
                };
              }, {});
              setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                dynamicColumns: updatedDynamicColumns,
              });
              setState((prevState) => ({
                ...prevState,
                dynamicColumns: {
                  ...prevState.dynamicColumns,
                  ...updatedDynamicColumns,
                },
              }));
            }}
          />
        </div>
      </div>
    );
  };

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingCautionFlagTypeList: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingCautionFlagTypeList: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  const expandLocation = useCallback(
    (customerName) => {
      if (!state.rowEntries[customerName]) {
        fetchRowEntries(
          state.scheduleDate,
          state.kam,
          state.selectedLocation,
          state.manager,
          customerName,
          true,
          state.pageFilters
        );
      }
    },
    [
      fetchRowEntries,
      state.rowEntries,
      state.scheduleDate,
      state.kam,
      state.selectedLocation,
      state.manager,
      state.pageFilters,
    ]
  );

  const clearAllEntries = () => {
    setState((prevState) => ({
      ...prevState,
      rowEntries: defaultState.rowEntries,
      rowTotalEntries: defaultState.rowTotalEntries,
      entries: defaultState.entries,
    }));
  };

  useEffect(() => {
    fetchList("customers", noop);
    // fetchList("IC", noop);
    fetchList("icList", noop);
    fetchVehicleTypeList();
    fetchCautionFlagTypeList();
    fetchLocalResources();
    fetchCustomerLocation();
    fetchPDXCompanyList();
    fetchReviewNotesList();
    fetchStandByRateTypeList();
    fetchEntries(
      state.scheduleDate,
      state.kam,
      state.selectedLocation,
      state.manager,
      state.customer,
      true,
      state.pageFilters
    );
  }, [
    fetchEntries,
    state.scheduleDate,
    state.kam,
    state.selectedLocation,
    state.manager,
    state.customer,
    state.pageFilters,
  ]);

  useEffect(() => {
    fetchList("users");
  }, [match.params.path]);

  const kamOptions = appDataList.users.kam?.sort(
    (a, b) => b.is_active - a.is_active
  );

  const updateEntryValue = (entryId, keyPath, value) => {
    setState((prevState) => {
      const updatedEntries = prevState.entries.map((entry) => {
        if (entry.id === entryId) {
          const keys = keyPath.split(".");
          let current = entry;

          for (let i = 0; i < keys.length - 1; i++) {
            current = current[keys[i]];
          }

          current[keys[keys.length - 1]] = value || "";
        }
        return entry;
      });

      return {
        ...prevState,
        entries: updatedEntries,
      };
    });
  };

  const events = {
    calculateBill,
    calculatePay,
    calculateBillMiles,
    calculatePayMiles,
    calculateBillToll,
    calculatePayToll,
  };

  useEffect(() => {
    document.body.style.overflowX = "hidden";
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  return (
    <>
      <div className="mr-5">
        <div className={clsx("mb-2 mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className="  ml-2">
            Manager Review
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleView
              ? " Approve/Reject Daily Schedule changes."
              : "View Daily Schedule changes."}
          </Typography>
        </div>
        <div className="d-flex f-justify-between ">
          <div className="d-flex f-wrap">
            <div className="mb-2 mr-2">
              <Datepicker
                mask
                label="Schedule Date"
                placement={"bottom-start"}
                selected={state.scheduleDate}
                classes={{
                  datepickerWrapper: classes.scheduleDateWrapper,
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                isClearable
                onCalendarClose={() => {}}
                onCalendarOpen={() => {}}
                onMonthChange={() => {}}
                onChange={(scheduleDate) => {
                  setState((prevState) => ({
                    ...prevState,
                    scheduleDate: getDateString(scheduleDate),
                    testId: defaultState.testId,
                  }));
                  setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                    scheduleDateFilter: getDateString(scheduleDate),
                  });
                }}
              />
            </div>

            <div className="mr-2 mb-2">
              <Autocomplete
                // fullWidth
                size="small"
                classes={{
                  root: classes.autocompleteFilters,
                  listbox: classes.autocompleteFilters,
                }}
                value={state.kam}
                options={kamOptions}
                getOptionLabel={({ first_name, last_name, email, username }) =>
                  `${first_name || " "} ${
                    last_name || ""
                  }-${email}-(${username})`
                }
                renderOption={({
                  first_name,
                  last_name,
                  email,
                  is_active,
                  username,
                }) => (
                  <Typography
                    variant="body1"
                    className={clsx({
                      "color-text-disabled": !is_active,
                    })}
                  >{`${first_name || " "} ${
                    last_name || ""
                  }-${email}-(${username})`}</Typography>
                )}
                renderInput={(params) =>
                  state.fetching.users ? (
                    <Skeleton
                      variant="rect"
                      width={148}
                      height={36}
                      className="ml-5 mr-4"
                    />
                  ) : (
                    <TextField
                      {...params}
                      label="KAM"
                      variant="outlined"
                      // className="ml-5"
                    />
                  )
                }
                onChange={(evt, kam) => {
                  setState((prevState) => ({
                    ...prevState,
                    kam,
                    testId: defaultState.testId,
                  }));
                  setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                    kamFilter: kam,
                  });
                }}
              />
            </div>
            <div
              className={clsx("mr-2 mb-2", { "mt-2": appData.isTabletView })}
            >
              <Autocomplete
                fullWidth
                size="small"
                value={state.customer}
                classes={{
                  root: classes.autocompleteFilters,
                }}
                options={appDataList.customers}
                getOptionLabel={({ name }) => `${name}`}
                renderInput={(params) =>
                  state.fetching.users ? (
                    <Skeleton variant="rect" width={148} height={36} />
                  ) : (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                      // className="ml-10 mr-10"
                    />
                  )
                }
                onChange={(evt, customer) => {
                  setState((prevState) => ({
                    ...prevState,
                    customer,
                  }));
                  setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                    customerFilter: customer,
                  });
                }}
              />
            </div>
            <div
              className={clsx("mr-2 mb-2", { "mt-2": appData.isTabletView })}
            >
              <Autocomplete
                classes={{
                  root: classes.autocompleteFilters,
                }}
                size="small"
                fullWidth
                value={state.selectedLocation}
                blurOnSelect={true}
                options={locationOPtions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) =>
                  state.fetching.users ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                    />
                  )
                }
                getOptionSelected={(option, value) =>
                  option.label === value.label
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedLocation: value,
                  }));
                  setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                    locationFilter: value,
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
            </div>
            {(isAdminRole || isRoleMGR) && (
              <div
                className={clsx("mr-2 mb-2", {
                  "mt-2": appData.isTabletView,
                })}
              >
                <Autocomplete
                  fullWidth
                  size="small"
                  value={state.manager}
                  classes={{
                    root: classes.autocompleteFilters,
                  }}
                  options={appDataList.users.mgr.filter(
                    (mgr) => mgr.is_active === true
                  )}
                  getOptionLabel={({
                    first_name,
                    last_name,
                    email,
                    username,
                  }) => `${first_name} ${last_name}-${email}-(${username})`}
                  renderInput={(params) =>
                    state.fetching.users ? (
                      <Skeleton variant="rect" width={148} height={36} />
                    ) : (
                      <TextField
                        {...params}
                        label="Manager"
                        variant="outlined"
                        className="w-100"
                      />
                    )
                  }
                  onChange={(evt, manager) => {
                    setState((prevState) => ({
                      ...prevState,
                      manager,
                    }));
                    setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                      managerFilter: manager,
                    });
                  }}
                />
              </div>
            )}
            <Dropdown
              classes={{
                paper: classes.tunePaper,
              }}
              disablePortal={false}
              remainOpen
              isMultiSelect
              placement="bottom-end"
              options={Object.keys(state.dynamicColumns).map(
                (key) => state.dynamicColumns[key]
              )}
              customToggle={({ anchorRef, onClick }) => (
                <Tooltip title="Show/Hide column(s)" placement="top-start">
                  <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
              )}
              onChange={(options) => {
                const isSelectAll = options.some(
                  (item) => item.value === "select_all"
                );
                const isCurrentSelectAll =
                  state.dynamicColumns?.select_all?.isSelected;

                const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

                const isAllOptionSelected =
                  Object.keys(state.dynamicColumns).length - 1 ===
                  options.filter((item) => item.value !== "select_all").length;
                const updatedDynamicColumns = Object.keys(
                  state.dynamicColumns
                ).reduce((acc, key) => {
                  const isSelected = isClickedOnSelectAll
                    ? isSelectAll
                    : key === "select_all"
                    ? isAllOptionSelected
                    : !!options.some((item) => item.value === key);
                  return {
                    ...acc,
                    [key]: {
                      ...state.dynamicColumns[key],
                      isSelected,
                    },
                  };
                }, {});
                setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                  dynamicColumns: updatedDynamicColumns,
                });
                setState((prevState) => ({
                  ...prevState,
                  dynamicColumns: {
                    ...prevState.dynamicColumns,
                    ...updatedDynamicColumns,
                  },
                }));
              }}
            />
            <Tooltip placement="top-start" title="Filter">
              <IconButton
                color="primary"
                className={clsx({
                  "bg-primary": !!state.pageFilters?.length,
                })}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isFiltering: true,
                  }));
                }}
              >
                <FilterListIcon
                  className={clsx({
                    "color-white": !!state.pageFilters?.length,
                  })}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                // disabled={pageConfig === undefined || pageConfig === null}
                color="primary"
                onClick={() => {
                  updatePageConfig("daily-schedule-review");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
          {!appData.isTabletView && (
            <div
              className={clsx(
                "d-flex f-align-start f-justify-end",
                classes.rightAction
              )}
            >
              <Typography variant="body2">
                <b>BR</b>- Base Roster <br></br>
                <b>DS</b>- Daily Schedule
              </Typography>
            </div>
          )}
        </div>
        {appData.isTabletView && (
          <div className="d-flex f-justify-end ml-2 mt-6">
            {" "}
            <Typography variant="body2">
              <b>BR</b>- Base Roster <b>DS</b>- Daily Schedule
            </Typography>
          </div>
        )}

        <Paper elevation={2} className="p-4 mt-2 h-100 mb-4">
          <div
            className={clsx(
              "d-flex f-justify-between p-2 pl-4 pr-4",
              classes.actionsWrapper
            )}
            ref={actionRef}
          >
            <Button
              startIcon={<NoteAddIcon />}
              className="mr-5"
              variant="outlined"
              disabled={state.selectedRows.length < 1}
              color="primary"
              onClick={() =>
                setState((prevState) => ({ ...prevState, AddReviewNote: true }))
              }
            >
              Add Shared Note
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              startIcon={<CloudDownloadIcon />}
              disabled={
                state.isDownloadLoading ||
                (state.customerEntries && state.customerEntries.length === 0)
              }
              onClick={() => {
                handleDownload(
                  state.scheduleDate,
                  state.kam,
                  state.selectedLocation,
                  state.manager,
                  state.customer,
                  true,
                  state.pageFilters
                );
              }}
            >
              Download
              {state.isDownloadLoading && (
                <CircularProgress size={24} className={classes.progressBtn} />
              )}
            </Button>
          </div>
          {appData.isTabletView && (
            <div className="d-flex f-justify-start f-align-end ml-4">
              {renderGridActionTab()}
            </div>
          )}
          <div
            ref={
              state.entries.some((row) => row.id === state.clickedRowId)
                ? selectedRef
                : null
            }
          >
            <SingleCollapsibleGrid
              rows={state.customerEntries}
              columns={columnConfig}
              wrapRef1={wrapRef1}
              syncChildGridsScroll={syncChildGridsScroll}
              isTableLoading={state.isTableLoading}
              expandLocation={expandLocation}
              clearAllEntries={clearAllEntries}
              entries={state.rowEntries}
              totalEntries={state.rowTotalEntries}
              isLoading={state.isFetching}
              onReady={(gridInstance) => (gridHelper = gridInstance)}
              hasSelection={!isRoleView}
              onSelectionChange={handleSelectionChange}
              boxborder={state.clickedRowId}
              selectedRef={selectedRef}
              handleScroll={handleScroll}
              handleRowOpen={handleRowOpen}
              gridRefs={gridRefs}
              tableHeadRef={tableHeadRef}
              gridActions={
                !appData.isTabletView
                  ? !isRoleView
                    ? renderGridActions()
                    : []
                  : []
              }
              rowEvents={[
                {
                  type: "onDoubleClick",
                  handler: (row) => {
                    setState((prevState) => ({
                      ...prevState,
                      clickedRowId: row.id,
                      rowBeingEdited: row,
                      selectedRows: [row.id],
                    }));
                    gridHelper && gridHelper.resetSelection();
                    setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                      clickedRowId: row.id,
                    });
                  },
                },
                {
                  type: "onClick",
                  handler: (row) => {
                    setState((prevState) => ({
                      ...prevState,
                      clickedRowId: row.id,
                    }));
                    setPageConfig(PAGE_KEYS.DAILY_SCHEDULE_REVIEW, {
                      clickedRowId: row.id,
                    });
                  },
                },
              ]}
              gridClasses={{
                gridActions: "f-justify-end mt-2",
              }}
            />
          </div>
        </Paper>
        {appData.isTabletView && (
          <div className={clsx({ "d-none mt-2": isActionBarOnScreen })}>
            <BottomNavigation
              component={Paper}
              elevation={4}
              className={classes.bottomNavigation}
            >
              <BottomNavigationAction
                component={() => renderGridActionTab(true)}
              />
            </BottomNavigation>
          </div>
        )}
        {state.showConfirmationPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.showConfirmationPopup}
            contentText="Are you sure you want to approve the selected record's?"
            onConfirm={() => handleApprove()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showConfirmationPopup: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.showIcDetails && (
          <IcForm
            open={!!state.showIcDetails}
            isTabletView={appData.isTabletView}
            isFetching={state.isIcFetching}
            entry={appDataList.IC[0]}
            usersList={appData.users}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                showIcDetails: false,
              }));
            }}
          />
        )}
        {state.AddReviewNote && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.notesPaperSize,
            }}
            open={!!state.AddReviewNote}
            contentText={
              <>
                <Typography variant="body1" className="mt-2">
                  <strong>Add new review notes for selected records.</strong>
                </Typography>
                <br></br>{" "}
                <div className="d-flex" style={{ gap: "6px" }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    freeSolo
                    options={state.reviewNotesList}
                    getOptionLabel={(option) => option.value}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={!!state.errors?.notes?.trim()}
                        helperText={state.errors?.notes}
                        variant="outlined"
                        label="Add New Review Note"
                        onChange={handleFieldChange}
                      />
                    )}
                    onChange={handleFieldChange}
                  />
                  {!isRoleMGR && (
                    <Tooltip
                      title="Add To Lookup(Review Notes)"
                      placement="top-end"
                    >
                      <Button
                        type="submit"
                        onClick={handleShowAddLookupDialog}
                        variant="contained"
                        color="primary"
                        disabled={!state.notes || validate()}
                        className={classes.buttonStyle}
                      >
                        <PostAddIcon />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </>
            }
            onConfirm={() => {
              if (state.selectedRows.length > 0) {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (state.selectedRows.includes(entry.id)) {
                      return {
                        ...entry,
                        daily_schedule: {
                          ...entry.daily_schedule,
                          review_notes: state.notes,
                          sharedNotes: state.notes,
                        },
                      };
                    }
                    return entry;
                  });

                  const updatedRowEntries = { ...prevState.rowEntries };

                  updatedEntries.forEach((entry) => {
                    const customer =
                      entry.daily_schedule.customer_branch.customer.name; // Assuming each row has a `customer` key

                    if (!updatedRowEntries[customer]) {
                      updatedRowEntries[customer] = [];
                    }

                    const index = updatedRowEntries[customer].findIndex(
                      (row) => row.id === entry.id
                    );
                    if (index !== -1) {
                      updatedRowEntries[customer][index] = entry;
                    }
                  });

                  return {
                    ...prevState,
                    rowEntries: updatedRowEntries,
                    entries: updatedEntries,
                  };
                });
              }

              setState((prevState) => ({
                ...prevState,
                AddReviewNote: false,
                notes: defaultState.notes,
              }));
            }}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                AddReviewNote: false,
                notes: defaultState.notes,
                errors: {
                  notes: "",
                },
              }))
            }
            isConfirmDisabled={validate() || !state.notes?.trim()}
            positiveActionLabel={
              <>
                Add
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.showAddLookupDialog && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.addLookupEmailpaperSize,
            }}
            open={!!state.showAddLookupDialog}
            contentText={
              <>
                <Typography variant="body2" className="mt-2 text-bold">
                  {`Would you also like to add this review note `}
                  <span style={{ color: "Black" }}>
                    {state.notes ||
                      (newReviewNotes
                        ? newReviewNotes.daily_schedule.sharedNotes
                        : "")}
                  </span>
                  {` in the Lookup(Review Notes)?`}
                </Typography>
              </>
            }
            onConfirm={() => {
              addLookupValue(state.showAddLookupDialog);
              setTimeout(() => {
                fetchReviewNotesList();
              }, 2000);
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                showAddLookupDialog: false,
              }));
            }}
            isConfirmDisabled={state.isLookupLoading}
            positiveActionLabel={
              <>
                ADD TO LOOKUP
                {state.isLookupLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>CANCEL</>}
          />
        )}
        {state.showExistingNotesModal && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.notesDesign,
            }}
            open={!!state.showExistingNotesModal}
            contentText={
              !!state.existingModalContent?.length
                ? state.existingModalContent
                : "No Existing Notes."
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                showExistingNotesModal: false,
              }))
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showExistingNotesModal: false,
              }))
            }
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={null}
          />
        )}
        {state.showRejectConfirmationPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.showRejectConfirmationPopup}
            contentText="Are you sure you want to reject the selected record's?"
            onConfirm={() => handleReject()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showRejectConfirmationPopup: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.confirmDontBill && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontBill}
            contentText="By Checking, bill rate will become 0. This action will not be reverted once done"
            onConfirm={() => {
              calculateBill(state.doNotBillRowID, state.confirmDontBillValue);
              setState((prevState) => ({
                ...prevState,
                confirmDontBill: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontBill: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontPay && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontPay}
            contentText="By Checking, pay rate will become 0. This action will not be reverted once done"
            onConfirm={() => {
              calculatePay(state.doNotPayRowID, state.confirmDontPayValue);
              setState((prevState) => ({
                ...prevState,
                confirmDontPay: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontPay: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontBillExtraMiles && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontBillExtraMiles}
            contentText=" By Checking, bill extra miles will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculateBillMiles(
                state.doNotBillExtraMilesID,
                state.confirmDontBillExtraMilesValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontBillExtraMiles: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontBillExtraMiles: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontPayExtraMiles && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontPayExtraMiles}
            contentText="By Checking, pay extra miles will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculatePayMiles(
                state.doNotPayExtraMilesID,
                state.confirmDontPayExtraMilesValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontPayExtraMiles: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontPayExtraMiles: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontBillToll && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontBillToll}
            contentText=" By Checking, bill toll amount will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculateBillToll(
                state.doNotBillTollID,
                state.confirmDontBillTollValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontBillToll: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontBillToll: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.confirmDontPayToll && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.confirmDontPayToll}
            contentText=" By Checking, pay toll amount will become 0. This action will not be reverted once done."
            onConfirm={() => {
              calculatePayToll(
                state.doNotPayTollID,
                state.confirmDontPayTollValue
              );
              setState((prevState) => ({
                ...prevState,
                confirmDontPayToll: false,
              }));
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDontPayToll: false,
              }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel="OK"
            negativeActionLabel={<>Cancel</>}
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {state.payQuantityExceedDaily && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.quantityExceedpaperSize,
            }}
            open={state.payQuantityExceedDaily}
            contentText={
              <>
                <Typography
                  variant="h6"
                  color="error"
                  className="mb-4 text-bold"
                >
                  Pay Quantity should not exceed 1.99 for Pay Rate Type 'Daily'
                  in the following entrie(s):
                </Typography>
                <Grid
                  columns={quantityExceedColumnConfig}
                  rows={state.payQuantityExceedDaily}
                  actionBarConfig={null}
                  isLoading={false}
                  hasSelection={false}
                  hasPagination={false}
                />
              </>
            }
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                payQuantityExceedDaily: defaultState.payQuantityExceedDaily,
              }));
            }}
            showPositiveActionLabel={false}
            hasClose={true}
            negativeActionLabel="Close"
          />
        )}
      </div>
      {state.rowBeingEdited && (
        <Form
          open={!!state.rowBeingEdited}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              rowBeingEdited: null,
              selectedRows: defaultState.selectedRows,
            }));
            gridHelper && gridHelper.resetSelection();
          }}
          row={state.rowBeingEdited}
          appData={appData}
          appDataList={appDataList}
          onSave={updateEntryValue}
          isRoleView={isRoleView}
          isRoleMGR={isRoleMGR}
          handleShowAddLookupDialog={handleShowAddLookupDialog}
          handleLabelClick={handleLabelClick}
          userInfo={userInfo}
          userFlagInfo={userFlagInfo}
          reviewNotesOptions={state.reviewNotesList}
          renderGridActions={renderGridActions}
          onConfirm={(isChecked, id, callback) => {
            callback(id, isChecked);
          }}
          priceMatrix={state.priceMatrix}
          events={events}
        />
      )}
      {state.isRowOpen.length > 0 && (
        <div
          ref={wrapRef1}
          onScroll={handleScroll}
          style={{
            overflowX: "auto",
            width: appData.isTabletView
              ? "calc(100vw - 42px)"
              : "calc(100vw - 110px)",
            height: "2%",
            bottom: 0,
            position: "fixed",
          }}
        >
          <div
            style={{
              width: state.dimensions,
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
    </>
  );
};

export default ViewDailyScheduleReview;
