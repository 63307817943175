/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { getTokenData, queryStringBuilder } from "utils";
import { AppContext } from "shared/contexts";
import SharedService from "modules/shared/service";
import Service from "../service";
import NotificationNotFound from "../notification-not-found";
import NotificationLoader from "../notification-loader";
import { ActionDialog } from "shared/components";
import Button from "@material-ui/core/Button";
import independentContractors from "../../../assets/icons/independentContractor.svg";
import billingandsettlement from "../../../assets/icons/billingSettlement.svg";
import dailySchedule from "../../../assets/icons/dailySchedule.svg";
import dailyScheduleReview from "../../../assets/icons/dailyScheduleReview.svg";
import customers from "../../../assets/icons/customers.svg";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ROLE } from "modules/shared/constants";
import BillingsettlementReview from "../../../assets/icons/billingSettlementReview.svg";
import TransferIcon from "../../../assets/icons/transfer-white.svg";
import baserosterIcon from "../../../assets/icons/baseRoster.svg";
import ChatIcon from "@material-ui/icons/Chat";
import CollapsibleGrid from "../grid/CollapsibleGrid";
import { isTransferScheduleEnable } from "shared";

const currentUser = getTokenData() || {};
const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
let gridHelper = null;

const NOTIFICATION_TYPES = {
  "bs-approval": isRoleKAM
    ? {
        icon: <img src={dailySchedule} alt="dailyschedule" />,
        path: (metadata = {}) =>
          `/${metadata.screen}/day/${metadata["approval-date"]}`,
      }
    : {
        icon: <img src={billingandsettlement} alt="billing and settlement" />,
        path: (metadata = {}) => `/${metadata.screen}`,
      },
  "rds-approved": !isRoleKAM
    ? {
        icon: <img src={billingandsettlement} alt="billing and settlement" />,
        path: (metadata = {}) => `/${metadata.screen}`,
      }
    : {
        icon: <img src={dailySchedule} alt="daily-schedule" />,
        path: (metadata = {}) =>
          `/${metadata.screen}/day/${metadata.schedule_date}/kam/${metadata.kam_id}`,
      },
  "rds-rejected": {
    icon: <img src={dailySchedule} alt="dailyschedule" />,
    // path: (metadata = {}) => `/${metadata.screen}`,
    path: (metadata = {}) =>
      `/${metadata.screen}/day/${metadata.schedule_date}/kam/${metadata.kam_id}`,
  },
  "bs-approved": {
    icon: <img src={billingandsettlement} alt="billing and settlement" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "bs-reject": {
    icon: <img src={billingandsettlement} alt="billing and settlement" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "ic-flagged": {
    icon: <img src={independentContractors} alt="independentContractors" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "ic-unflagged": {
    icon: <img src={independentContractors} alt="independentContractors" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "new/update-record": {
    icon: (
      <img src={BillingsettlementReview} alt="billing and settlement review" />
    ),
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "customer-route-changed": {
    icon: <img src={customers} alt="customers" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "route-details-changed": {
    icon: <img src={baserosterIcon} alt="baseroster" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "price-changed": {
    icon: <img src={dailyScheduleReview} alt="dailyScheduleReview" />,
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "transfer-schedule-approved": {
    icon: (
      <img src={TransferIcon} alt="transferSchedule" height={30} width={30} />
    ),
    path: (metadata = {}) =>
      `/daily-schedule/day/${metadata.scheduleDate}/kam/${metadata.kam_id}`,
  },
  "transfer-schedule-rejected": {
    icon: (
      <img src={TransferIcon} alt="transferSchedule" height={30} width={30} />
    ),
    path: (metadata = {}) =>
      `/daily-schedule/day/${metadata.scheduleDate}/kam/${metadata.kam_id}`,
  },
  "transfer-schedule": {
    icon: (
      <img src={TransferIcon} alt="transferSchedule" height={30} width={30} />
    ),
    path: (metadata = {}) => `/${metadata.screen}`,
  },
  "room-user-removed": {
    icon: <ChatIcon />,
  },
  "room-left": {
    icon: <ChatIcon />,
  },
  "room-user-added": {
    icon: <ChatIcon />,
  },
  "room-name-changed": {
    icon: <ChatIcon />,
  },
  "ds-reverted": {
    icon: <img src={dailySchedule} alt="dailyschedule" />,
    path: (metadata = {}) =>
      `/${metadata.screen}/day/${metadata.schedule_date}/kam/${metadata.kam_id}`,
  },
};

const defaultState = {
  isFetching: false,
  isMarkReading: false,
  openForceSubmitDialog: false,
  confirmRemove: false,
  confirmMarkAsRead: false,
  selectedNotificationType: [],
  selectedNotifications: [],
  notifications: [],
  hasMore: true,
  typeOffsets: {},
  loadingStates: {},
  limit: 10,
};

const ViewNotifications = () => {
  const [state, setState] = useState(defaultState);
  const userData = getTokenData();
  const { appData, updateContextData, setHeaderElements } =
    useContext(AppContext);

  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;

  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;

  const fetchList = async (listType = "pendingItems", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const handleSelection = (type, ids) => {
    const filteredIds = ids.filter((id) => {
      const isInSpecifiedType = state.notifications.some(
        (group) => type.includes(group.type) && group.notifications.includes(id)
      );
      return !isInSpecifiedType;
    });

    setState((prevState) => ({
      ...prevState,
      selectedNotifications: filteredIds,
      selectedNotificationType: type,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };
  const fetchNotifications = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const query = `?filter[order]=created_at DESC`;

    const { data, error } = await Service.getNotifications(query);

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return;
    }

    const initialTypeOffsets = {};
    const initialTypeHasMore = {};

    data?.notificationTypeList?.forEach((notification) => {
      initialTypeOffsets[notification.type] = 10;
      initialTypeHasMore[notification.type] =
        notification.notifications.length >= state.limit;
    });

    setState((prevState) => ({
      ...prevState,
      notifications: data?.notificationTypeList || defaultState.notifications,
      typeOffsets: defaultState.typeOffsets,
      hasMore: initialTypeHasMore,
      selectedNotifications: defaultState.selectedNotifications,
      selectedNotificationType: defaultState.selectedNotificationType,
    }));
  };

  const fetchMoreNotifications = async (type) => {
    if (state.loadingStates[type] || !state.hasMore[type]) return;

    setState((prevState) => ({
      ...prevState,
      loadingStates: {
        ...prevState.loadingStates,
        [type]: true,
      },
    }));

    const offset = state.typeOffsets[type] || 10;
    const query = `?filter[where][type]=${type}&filter[limit]=${state.limit}&filter[offset]=${offset}`;
    const { data, error } = await Service.get(query);

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        loadingStates: {
          ...prevState.loadingStates,
          [type]: false,
        },
      }));
      return;
    }

    const newNotifications = data?.notifications || [];

    setState((prevState) => {
      const notificationIndex = prevState.notifications.findIndex(
        (notification) => notification.type === type
      );

      let updatedNotifications = [...prevState.notifications];

      if (notificationIndex !== -1) {
        updatedNotifications[notificationIndex] = {
          ...updatedNotifications[notificationIndex],
          notifications: [
            ...updatedNotifications[notificationIndex].notifications,
            ...newNotifications,
          ],
        };
      }

      const updatedTypeOffsets = {
        ...prevState.typeOffsets,
        [type]: offset + state.limit,
      };

      const updatedTypeHasMore = {
        ...prevState.hasMore,
        [type]: newNotifications.length === state.limit,
      };

      return {
        ...prevState,
        notifications: updatedNotifications,
        loadingStates: {
          ...prevState.loadingStates,
          [type]: false,
        },
        hasMore: updatedTypeHasMore,
        typeOffsets: updatedTypeOffsets,
      };
    });
  };

  const fetchNotificationCount = async () => {
    const { data, error } = await SharedService.getNotificationCount();
    if (error) {
      console.error(
        `Error while fetching unread notifications: ${
          Array.isArray(error) ? error[0]?.message : error.message
        }`
      );
    } else {
      updateContextData("notificationCount", data?.unread || 0);
    }
  };

  const removeSelectedNotif = async (type, ids) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let query = "";

    if (ids.length > 0) {
      query += `notificationIds=${ids.join(",")}`;
    }

    if (type.length > 0) {
      if (query) query += "&";
      query += `notificationType=${type}`;
    }

    const { error } = await Service.deleteSelectedNotification(
      query ? `?${query}` : ""
    );

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      confirmRemove: false,
      selectedNotifications: defaultState.selectedNotifications,
      selectedNotificationType: defaultState.selectedNotificationType,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Notification(s) removed successfully.");

      setState((prevState) => {
        // Step 1: Filter by types
        let filteredByType = prevState.notifications;

        if (type.length > 0) {
          filteredByType = prevState.notifications.filter(
            (notificationGroup) => !type.includes(notificationGroup.type)
          );
        }

        // Step 2: Filter by IDs (only on the data filtered by type)
        let filteredByIds = filteredByType;

        if (ids.length > 0) {
          filteredByIds = filteredByType.map((notificationGroup) => {
            const filteredNotifications =
              notificationGroup.notifications.filter(
                (notification) => !ids.includes(notification.id)
              );

            // Calculate the number of unread notifications being deleted
            const deletedUnreadCount = notificationGroup.notifications
              .filter((notification) => ids.includes(notification.id))
              .filter((notification) => !notification.is_read).length;

            // Update the count for the group
            const updatedCount = notificationGroup.count - deletedUnreadCount;

            return {
              ...notificationGroup,
              notifications: filteredNotifications,
              count: updatedCount,
            };
          });
        }

        return {
          ...prevState,
          notifications: filteredByIds,
        };
      });
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      fetchNotificationCount();
    }
  };

  const handleDeleteNotification = (notificationId) => {
    setState((prevState) => {
      const notificationGroupIndex = prevState.notifications.findIndex(
        (group) =>
          group.notifications.some((notif) => notif.id === notificationId)
      );

      if (notificationGroupIndex === -1) {
        return prevState;
      }

      const notificationGroup = prevState.notifications[notificationGroupIndex];

      const deletedNotification = notificationGroup.notifications.find(
        (notif) => notif.id === notificationId
      );

      const filteredNotifications = notificationGroup.notifications.filter(
        (notif) => notif.id !== notificationId
      );

      const updatedCount =
        deletedNotification && !deletedNotification.is_read
          ? notificationGroup.count - 1
          : notificationGroup.count;

      const updatedNotifications = [...prevState.notifications];

      if (filteredNotifications.length === 0) {
        updatedNotifications.splice(notificationGroupIndex, 1);
      } else {
        updatedNotifications[notificationGroupIndex] = {
          ...notificationGroup,
          notifications: filteredNotifications,
          count: updatedCount,
        };
      }

      return {
        ...prevState,
        notifications: updatedNotifications,
      };
    });

    fetchNotificationCount();
  };

  const readSelectedNotif = async (type, ids) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let query = "";

    if (ids.length > 0) {
      query += `notificationIds=${ids.join(",")}`;
    }

    if (type.length > 0) {
      if (query) query += "&";
      query += `notificationType=${type}`;
    }

    const { error } = await Service.markReadNotification(
      query ? `?${query}` : ""
    );

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      confirmMarkAsRead: false,
      selectedNotifications: defaultState.selectedNotifications,
      selectedNotificationType: defaultState.selectedNotificationType,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Notification(s) mark as read successfully.");
      fetchNotifications();
      fetchNotificationCount();
    }
  };

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const markNotificationRead = async (id) => {
    setState((prevState) => ({
      ...prevState,
      isMarkReading: true,
    }));
    const { error } = await Service.markNotificationRead(id);
    setState((prevState) => ({
      ...prevState,
      isMarkReading: false,
    }));
    if (error) {
      console.error(
        `Error while mark unread notification id ${id}: ${
          Array.isArray(error) ? error[0]?.message : error.message
        }`
      );
    } else {
      fetchNotificationCount();
    }
  };

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchLeaveTypeList();
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);
  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Grid container className="mt-2">
      {!!state.notifications.length &&
        (state.selectedNotificationType.length > 0 ||
          state.selectedNotifications.length > 0) && (
          <>
            <Grid
              item
              xs={10}
              className="d-flex f-align-center f-justify-end mb-2"
            >
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  className="mr-4"
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      confirmMarkAsRead: true,
                    }));
                  }}
                >
                  Mark as Read
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      confirmRemove: true,
                    }));
                  }}
                >
                  Remove Selected Notifications
                </Button>
              </>
            </Grid>
            <Grid item xs={2} />
          </>
        )}
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8} component={Paper} elevation={0}>
        {!state.notifications.length && !state.isFetching ? (
          <NotificationNotFound />
        ) : state.isFetching ? (
          <NotificationLoader />
        ) : (
          state.notifications?.map((notification) => {
            return (
              <CollapsibleGrid
                key={notification.type}
                notification={notification}
                selectedNotification={state.selectedNotifications}
                selectedNotificationType={state.selectedNotificationType}
                isLoadingMore={state.loadingStates[notification.type] || false}
                hasMore={state.hasMore[notification.type]}
                notificationType={NOTIFICATION_TYPES}
                handleSelection={handleSelection}
                markNotificationRead={markNotificationRead}
                handleDeleteNotification={handleDeleteNotification}
                fetchMoreNotifications={fetchMoreNotifications}
                onReady={(instance) => (gridHelper = instance)}
              />
            );
          })
        )}
        {state.openForceSubmitDialog && (
          <ForceSubmitDialog
            open={state.openForceSubmitDialog}
            pendingItems={appData.pendingItems.map((date, index) => ({
              date,
              id: index,
            }))}
            onClose={handleClose}
            kamId={kamId}
            forceSubmitReasonList={state.forceSubmitReasonList}
            onForceSubmit={() => fetchList("pendingItems", kamId)}
          />
        )}
        {state.confirmRemove && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
            }}
            open={!!state.confirmRemove}
            contentText="Are you sure you want to remove all selected notifications?"
            onConfirm={() =>
              removeSelectedNotif(
                state.selectedNotificationType,
                state.selectedNotifications
              )
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                confirmRemove: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Remove
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.confirmMarkAsRead && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
            }}
            open={!!state.confirmMarkAsRead}
            contentText="Are you sure you want to mark all selected notifications as read?"
            onConfirm={() =>
              readSelectedNotif(
                state.selectedNotificationType,
                state.selectedNotifications
              )
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                confirmMarkAsRead: false,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Mark as Read
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  );
};

export default ViewNotifications;
