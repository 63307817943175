import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    maxWidth: props.isTabletView ? "98%" : "89%",
    minWidth: props.isTabletView ? "98%" : "89%",
    maxHeight: props.isTabletView ? "95%" : "90%",
  }),

  disabledText: {
    // color: "rgba(0, 0, 0, 0.87)",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  },
  autocompletePaper: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  field: (props) => ({
    width: props.isTabletView ? 264 : 200,
    marginRight: 24,
    marginBottom: 5,
  }),
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "20px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  addNewReviewNote: {
    minWidth: "200px",
  },
  paperSize: {
    minWidth: "30%",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
}));

export default useStyles;
