import { lighten, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tablePadding: {
    cursor: "pointer",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    width: "100%",
  },
  fullWidthCell: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fullWidthTable: {
    width: "100%",
  },
  root: {
    width: "100%",
    marginBottom: 30,
  },
  row: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableHeadPadding: {
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
  },
  boxborder: {
    boxShadow: "inset 0px 0px 4px 2px #00008B, inset 0px 0px 4px 2px #00008B",
  },
  borderCollapse: {
    borderCollapse: "collapse",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableCell: {
    minWidth: 72,
    maxWidth: 200,
  },
  tableHeadCell: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
      "& div[data-role='resize']": {
        visibility: "visible",
      },
    },
  },
  noRecordsImg: {
    maxWidth: 600,
    maxHeight: 500,
  },
  resizeIconWrapper: {
    width: 2,
    cursor: "col-resize",
    userSelect: "none",
    visibility: "hidden",
    right: 0,
    top: 0,
    bottom: 0,
  },
  resizeIcon: {
    background: lighten(theme.palette.primary.light, 0.9),
    opacity: 0.6,
    width: 2,
    height: 15,
  },
  scroll: {
    display: "grid",
    overflow: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&-ms-overflow-style:": {
      display: "none",
    },
    overflowY: "hidden",
  },
  customerName: { fontWeight: "bold", color: "#000000" },
  tableContainer: {
    display: "grid",
    width: "100%",
    overflowX: "hidden",
  },
  tableIndex: {
    fontWeight: "bold",
    color: "black",
    width: "40px",
    display: "block",
  },
  headerIndex: { color: "#ffffff", width: 40 },
  stickyScrollbar: {
    position: "sticky",
    right: 0,
    zIndex: 100,
    overflowY: "scroll",
    height: "100%",
    "&::-webkit-overflow-scrolling": "auto",
  },
  tableCellShadow: {
    // borderRadius: 8,
    // border: "1px solid #ddd",
    // boxShadow:
    //   "inset 0px 0px 4px 4px rgba(0, 0, 0, 0.2), inset 0px 0px 4px 8px rgba(255, 255, 255, 0.5)",
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  kamName: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
}));

export default useStyles;
